const UserGroupService = {
    fetch: () => {
        return [
            {
                id: 'USER_INVESTOR_INDIVIDUAL',
                value: 'Investor'
            },
            {
                id: 'USER_INVESTOR_COMPANY',
                value: 'Institution'
            },
            {
                id: 'USER_ISSUER',
                value: 'Issuer'
            },
            {
                id: 'USER_ADMIN',
                value: 'Admin'
            },
            {
                id: 'USER_SUPER_ADMIN',
                value: 'Super Admin'
            }
        ]
    }
}

export default UserGroupService;