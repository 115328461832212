import axios from '../custom-axios/backoffice';

const SettingGroupService = {
    transform: (requests) => {
        return requests.map(request => {
            return {
                id: request.id ? request.id : '',
                value: request.name ? request.name : '',
            }
        })
    },

    fetchSettingGroups: () => {
        return axios.get('/api/setting-groups');
    },
}

export default SettingGroupService