import React, {useState} from 'react';
import {Card, CardContent, Typography} from "@material-ui/core";
import {Content, Tab, Tabs} from "./Tab";
import {UsersAll} from "./graph/UsersAll";
import {IssuersGraph} from "./graph/IssuersGraph";
import {InvestorsGraph} from "./graph/InvestorsGraph";
import {CompanyGraph} from "./graph/CompanyGraph";
import {makeStyles} from "@material-ui/styles";


const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '16px',
        textTransform: 'uppercase'
    },

}));


export const UserStatus = (props) => {

    const classes = useStyles();

    const [active, setActive] = useState(0);

    const handleClick = e => {
        const index = parseInt(e.target.id, 0);
        if (index !== active) {
            setActive(index);
        }
    }

    return (
        <Card raised>
            <CardContent>
                <Typography className={classes.header} variant="h6">Users Status</Typography>

                <Tabs>
                    <Tab onClick={handleClick} active={active === 0} id={0}>
                        All users
                    </Tab>
                    <Tab onClick={handleClick} active={active === 1} id={1}>
                        Investors
                    </Tab>
                    <Tab onClick={handleClick} active={active === 2} id={2}>
                        Issuers
                    </Tab>
                    <Tab onClick={handleClick} active={active === 3} id={3}>
                        Companies
                    </Tab>
                </Tabs>

                <>
                    <Content active={active === 0}>
                        <UsersAll
                            usersData={props.usersData}
                        />
                    </Content>

                    <Content active={active === 1}>
                        <InvestorsGraph
                            usersData={props.usersData}/>
                    </Content>

                    <Content active={active === 2}>
                        <IssuersGraph
                            usersData={props.usersData}/>
                    </Content>

                    <Content active={active === 3}>
                        <CompanyGraph
                            usersData={props.usersData}/>
                    </Content>
                </>
            </CardContent>
        </Card>
    )
}