import React, {useEffect} from 'react';

import clsx from 'clsx'
import CssBaseline from "@material-ui/core/CssBaseline";
import {RouteList} from "../../RouteList";
import {Container} from "@material-ui/core";
import Sidebar from "../Sidebar";
import Header from "../../components/Header";
import {makeStyles} from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

        [theme.breakpoints.down("md")]: {
            zIndex: 9999,
            position: 'fixed',
            marginLeft: 0,
            width: '100%'
        },

    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

        [theme.breakpoints.down("md")]: {
            zIndex: 9999,
            position: 'fixed',
            width: '250'
        },
    },

    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,

        [theme.breakpoints.down("md")]: {
            width: 0,
        },

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 0, 0, 4),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,

        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 1),
        },

    },
    content: {
        flexGrow: 1,
        backgroundColor: '#F4F6F8',
        padding: theme.spacing(3),
    },
}));


export const Main = (props) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
       const screenWidth = window.screen.width;

       if (screenWidth < 1280) {
           setOpen(false)
       }
    },[]);

    return (
        <div className={classes.root}>
            <CssBaseline/>

            <Header
                headerClass={clsx(classes.appBar, {[classes.appBarShift]: open})}
                classTest={clsx(classes.menuButton, {[classes.hide]: open})}
                onClick={handleDrawerOpen}
            />

            <Sidebar
                path={props.path}
                classToolbar={classes.toolbar}
                drawerClose={handleDrawerClose}
                classesDrawer={{paper: clsx({[classes.drawerOpen]: open, [classes.drawerClose]: !open})}}
                classDrawer={clsx(classes.drawer, {[classes.drawerOpen]: open, [classes.drawerClose]: !open})}
            />

            <main className={classes.content}>
                {/*<Container maxWidth="xl">*/}
                {/*    <RouteList/>*/}
                {/*</Container>*/}

                <RouteList/>
            </main>
        </div>
    )

}