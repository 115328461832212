import React from 'react';

import {Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import NumberFormat from 'react-number-format';

const NumberFormatCustom = ({inputRef, onChange, name, prefix, ...other}) => {

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            thousandSeparator
            isNumericString
            allowNegative={false}
            prefix={prefix}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: name,
                        value: values.value
                    }
                })
            }}
        />
    )
}

const CurrencyFormatField = ({title, name, required = false, value, prefix, disabled = false,
                                 onChange, hasError = false, errorMessage}) => {

    return (
        <>
            <Typography>
                {title}
                {required && <span className="required__circle"> *</span>}
            </Typography>
            <TextField
                fullWidth
                value={value}
                onChange={(event) => onChange(event.target.value, name)}
                variant={"outlined"}
                disabled={disabled}
                error={hasError}
                helperText={hasError && errorMessage}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {prefix: prefix}
                }}
            />
        </>
    )
}

export default CurrencyFormatField