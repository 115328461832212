import React from "react";

import {Redirect, Route, Switch} from "react-router-dom";
import Dashboard from "./containers/dashboard/Dashboard";
import Issuers from "./containers/Issuers/Issuers";
import IssuerFullEdit from "./containers/Issuers/IssuerFullEdit";
import Offerings from "./containers/Offerings/Offerings";
import OfferingsFullEdit from "./containers/Offerings/OfferingsFullEdit";
import Investors from "./containers/Investors/Investments";
import InvestorEdit from "./containers/Investors/InvestorEdit";
import InvestorStatusPageCustom from "./containers/Investors/edit/InvestorStatusPageCustom";
import InvestorCompanyEdit from "./containers/Investors/InvestorCompanyEdit";
import AuditLog from "./containers/AuditLog";
import Users from "./containers/Users/Users";
import CreateCompanyPage from "./containers/cms/CreateCompanyPage";
import Logout from "./components/Logout";
import InvestmentDetailsPage from "./containers/Investors/InvestmentDetailsPage";
import UserDetails from "./containers/Users/UserDetails";
import UnvalidatedIssuerDetails from "./containers/Issuers/UnvalidatedIssuerDetails";
import UserRequests from "./containers/Users/UserRequests";
import Settings from "./containers/setting/Settings";


export const RouteList = () => {
    return (
        <>
            <Switch>
                <Route path="/dashboard" exact component={Dashboard}/>
                <Route path="/users" exact component={Users}/>
                <Route path="/users/type/:type" exact component={Users}/>
                <Route path="/users/:id" exact component={UserDetails}/>
                <Route path="/users/unvalidated_issuer/:id" exact component={UnvalidatedIssuerDetails}/>
                <Route path="/issuers" exact component={Issuers}/>
                <Route path="/issuers/edit/:issuerId" exact component={IssuerFullEdit}/>
                <Route path="/offerings" exact component={Offerings}/>
                <Route path="/offerings/edit/:dealId" exact component={OfferingsFullEdit}/>
                <Route path="/investors/:code" exact component={Investors}/>
                <Route path="/investments/:id" exact component={InvestmentDetailsPage}/>
                <Route path="/investors/individual/edit/:id" exact component={InvestorEdit}/>
                <Route path="/investor/investor-status/:id" exact component={InvestorStatusPageCustom}/>
                <Route path="/investors/company/edit/:id" exact component={InvestorCompanyEdit}/>
                <Route path="/wait-list" exact component={UserRequests}/>
                <Route path="/wait-list/type/:type" exact component={UserRequests}/>
                <Route path="/auditlog" exact component={AuditLog}/>
                <Route path="/auditlog/:email" exact component={AuditLog}/>
                <Route path="/cms/create/:dealId" exact component={CreateCompanyPage}/>
                <Route path="/cms/copy/:copy" exact component={CreateCompanyPage}/>
                <Route path="/cms/edit/:uri" exact component={CreateCompanyPage}/>
                <Route path="/settings" exact component={Settings}/>
                <Route path="/logout" exact component={Logout}/>
                <Redirect to="/dashboard"/>
            </Switch>
        </>
    )

}