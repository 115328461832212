import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import InputField from "../../components/Molecules/InputField";
import CheckBoxField from "../../components/Molecules/CheckBoxField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";


const OfferingInfoModal = ({offer, show, close}) => {

    return (
        <Dialog open={show}
                onClose={close}
                fullWidth
                maxWidth="lg"
        >
            <DialogTitle>
                Offering information
                <Divider/>
            </DialogTitle>

            <DialogContent style={{overflow: 'hidden', paddingTop: 24}}>
                <Grid container spacing={2}>
                    <Grid item lg={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={3} xs={6}>
                                <InputField
                                    title={'Name'}
                                    disabled
                                    value={offer.name}
                                />
                            </Grid>

                            <Grid item lg={3} xs={6}>
                                <InputField
                                    title={'Reg Types'}
                                    disabled
                                    value={offer.regTypes.map(type => type.name)}

                                />
                            </Grid>

                            <Grid item lg={3} xs={6}>
                                <InputField
                                    title={'Amount to be raised'}
                                    disabled
                                    value={offer.raiseTarget}
                                    type={'number'}
                                />
                            </Grid>

                            <Grid item lg={3} xs={6}>
                                <InputField
                                    title={'Status'}
                                    disabled
                                    value={offer.dealStatus}
                                />
                            </Grid>

                            <Grid item lg={3} xs={6}>
                                <InputField
                                    title={'Pre-Money Valuation'}
                                    disabled
                                    value={offer.preMoney}
                                    type={'number'}
                                />
                            </Grid>

                            <Grid item lg={3} xs={6}>
                                <InputField
                                    title={'Share Price'}
                                    disabled
                                    value={offer.sharePrice}
                                    type={'number'}
                                />
                            </Grid>

                            <Grid item lg={3} xs={6}>
                                <InputField
                                    title={'Issuer'}
                                    value={`${offer.issuer.user.firstName} ${offer.issuer.user.lastName}`}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={3} xs={6}>
                                <CheckBoxField
                                    title={"Require a signature from Issuer"}
                                    name={"issuerSignature"}
                                    disabled
                                    options={[
                                        {id: 'true', value: "Yes"},
                                        {id: 'false', value: "No"}
                                    ]}
                                    value={offer.issuerSignature.toString()}
                                />
                            </Grid>

                            <Grid item lg={3} xs={6}>
                                <CheckBoxField
                                    title={"Auto approve"}
                                    name={"autoApprove"}
                                    disabled
                                    options={[
                                        {id: 'true', value: "Yes"},
                                        {id: 'false', value: "No"}
                                    ]}
                                    value={offer.autoApprove.toString()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    type="button"
                    data-dismiss="modal"
                    onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default OfferingInfoModal