import React, {Component} from 'react';
import axios from '../custom-axios/backoffice';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';

class SelectCustom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            row: props.row,
            data: [],
            key: props.keyCode || 'id'
        }

        this.fetchOptions();
    }

    fetchOptions() {
        if (this.props.url) {
            axios.get(this.props.url + "?sorting%5Bid%5D=ASC")
                .then(data => {
                    let listOfData = [];

                    if (this.props.checkEmpty) {
                        data.data.forEach(value => {
                            if (value.pocFullName) {
                                listOfData.push(value)
                            }
                        })
                    } else {
                        listOfData = data.data
                    }

                    this.setState({data: listOfData});
                })
        }
    }

    editText = (value) => {
        let fullText = [];

        let textArray = value.split('_');
        for (let i = 0; i < textArray.length; i++) {
            fullText.push(textArray[i][0] + textArray[i].toLowerCase().slice(1))
        }

        return fullText.join(" ")
    }


    render() {
        // let className = "form-control " + this.props.className;
        let options = this.state.data.map(item => {
            return (
                <option
                    value={item[this.state.key]}
                    defaultValue={this.props.defaultValue ? item[this.state.key] === this.props.defaultValue : false}
                    key={item[this.state.key]}>
                    {item[this.props.colName || 'name' || 'value']}
                </option>
            );
        });

        if (this.props.data !== undefined) {
            options = this.props.data.map(option => {
                return (
                    <option
                        key={option.id}
                        defaultValue={this.props.defaultValue ? option.id === this.props.defaultValue : false}
                        value={option[this.state.key]}>
                        {this.props.translate ? this.props.translate(option.name ? option.name : option.value)
                            : this.props.mustEditText
                                ? this.editText(option.name ? option.name : option.value) : option.name ? option.name : option.value}
                    </option>
                )
            })
        }

        return (
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor={this.props.name.toString()}>
                    {this.props.labelName}
                </InputLabel>
                <Select
                    style={this.props.style ? this.props.style : {fontSize: 14}}
                    native
                    fullWidth
                    disabled={this.props.disabled ? this.props.disabled : false}
                    onChange={this.props.onChange ? (e) => this.state.row ? this.props.onChange(e, this.state.row) : this.props.onChange(e) : null}
                    defaultValue={this.props.defaultValue}
                    data-width="100%"
                    label={this.props.labelName}
                    inputProps={{
                        name: this.props.name.toString(),
                        id: this.props.name.toString(),
                    }}
                    title="&nbsp;">
                    <option/>
                    {options}
                </Select>
            </FormControl>
        )
    }
}

export default SelectCustom;