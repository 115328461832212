import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";


const DeleteModal = (props) => {

    const [acceptRemoval, setAcceptRemoval] = useState(false)

    let userInfo = JSON.parse(localStorage.getItem('userInfo'));

    let handleFormSubmit = (e, isFull) => {
        e.preventDefault();
        props.submit(props.entity, isFull);
        props.close();
    };

    let close = (e) => {
        props.close();
    };

    return (
        <Dialog open={props.show}
                maxWidth={"lg"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                DELETE CONFIRMATION
            </DialogTitle>

            <DialogContent style={{overflow: 'hidden'}}>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Typography>
                            Are you sure you want to delete?
                        </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <DialogActions>
                            {(userInfo.userGroup && userInfo.userGroup.id === 'USER_SUPER_ADMIN') && (
                                <>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(event) => setAcceptRemoval(!acceptRemoval)}
                                                color="primary"
                                                checked={acceptRemoval}
                                            />
                                        }
                                        label={'Remove all data'}
                                    />
                                    {/*<CheckBoxField*/}
                                    {/*    options={["Remove all data"]}*/}
                                    {/*    value={acceptRemoval}*/}
                                    {/*    onChange={() => setAcceptRemoval(true)}*/}
                                    {/*/>*/}

                                    <Button
                                        style={{backgroundColor: '#f54646'}}
                                        variant="contained"
                                        disabled={!acceptRemoval}
                                        onClick={(e) => handleFormSubmit(e, true)}>
                                        Hard deletion
                                    </Button>
                                </>
                            )}

                            <Button
                                color="primary"
                                variant="contained"
                                onClick={(e) => handleFormSubmit(e, false)}>
                                Soft deletion
                            </Button>

                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={close}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default DeleteModal;