import React, {useState} from 'react';
import AccreditationSaveModal2 from "./AccreditationSaveModal2";
import {toast} from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";


const AccreditationSaveModal1 = (props) => {

    const [isShowing, setIsShowing] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [editedDate,setEditedDate]=useState('123')



    const handleDateChange = (e) => {
        setSelectedDate(e);

    };

    let handleFormSubmit = (e) => {
        handleDateChange(selectedDate);
        let formattedDate = formatDate(selectedDate)

        if (formattedDate !== "") {
            if (props.verifyDate(formattedDate)) {
                setEditedDate(formattedDate);
                setIsShowing(true);
                props.close();
            } else {
                toast.error("ERROR: wrong date!")
            }
        } else {
            toast.error("ERROR: enter the date!")
        }

    };

    let formatDate = (e) => {
        let date = new Date(e);
        let dateUtc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return dateUtc.toLocaleDateString('en-US')
    };

    let close = (e) => {
        props.close();
    };


    let closeModal2 = () => {
        setIsShowing(false)
    };

    return (
        <>
            <AccreditationSaveModal2
                show={isShowing}
                close={closeModal2}
                standard={props.standard}
                submit={props.submit}
                date={editedDate}
            />

            <Dialog
                open={props.show}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">SET EXPIRATION DATE</DialogTitle>

                <DialogContent style={{overflow: 'hidden'}}>
                    <Grid container spacing={3}>
                        <Grid item lg={12} xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Expiration Date"
                                        format="MM/dd/yyyy"
                                        value={selectedDate}
                                        onChange={(e) => handleDateChange(e)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item lg={6} xs={6}>
                            <Typography>Accreditation Standard:</Typography>

                        </Grid>
                        <Grid item lg={6} xs={6}>
                            <Typography>{props.standard}</Typography>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <DialogActions>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleFormSubmit}>Set Expiration Date
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={close}>Cancel
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>




            {/*<div id="investDeleteModal" className="modal modal-open" role="dialog" style={{*/}
            {/*    transform:  props.show ? 'translateY(0vh)' : 'translateY(-100vh)',*/}
            {/*    display:  props.show ? 'block' : 'none',*/}
            {/*    zIndex: 10000*/}
            {/*}}>*/}
            {/*    <div className="modal-dialog modal-lg" style={{*/}
            {/*        zIndex: 100*/}
            {/*    }}>*/}
            {/*        <div className="modal-content">*/}
            {/*            <form onSubmit={props.submit}>*/}
            {/*                <div className="modal-header">*/}
            {/*                    <h4 className="modal-title">Change confirmation</h4>*/}
            {/*                </div>*/}
            {/*                <div className="modal-body">*/}

            {/*                    <div className="form-horizontal">*/}
            {/*                        <div className="form-group">*/}
            {/*                            <label className="col-xs-12 col-sm-4 control-label width-35 control-label-lg">Accreditation*/}
            {/*                                Expiration Date</label>*/}
            {/*                            <div className="col-sm-15">*/}
            {/*                                <input*/}
            {/*                                    className="form-control input-lg"  type="date" required pattern="[0-9]{1,2}/[0-9]{1,2}/[0-9]{4}" onChange={(e)=>setStateDate(e)}/>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="form-group">*/}
            {/*                            <label className="col-xs-12 col-sm-4 control-label width-35 control-label-lg">Relevant*/}
            {/*                                Accreditation Standard</label>*/}
            {/*                            <div className="col-sm-15">*/}
            {/*                                <input*/}
            {/*                                    className="form-control input-lg" readOnly={true}*/}
            {/*                                    defaultValue={props.standard}/>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}

            {/*                    </div>*/}

            {/*                </div>*/}
            {/*                <div className="modal-footer text-center">*/}
            {/*                    <button type="submit" className="btn btn-orange btn-xl" data-dismiss="modal"*/}
            {/*                            onClick={handleFormSubmit}>*/}
            {/*                        Verify*/}
            {/*                    </button>*/}
            {/*                    <button type="button" className="btn btn-danger btn-xl"*/}
            {/*                            data-dismiss="modal"*/}
            {/*                            onClick={close}>Cancel*/}
            {/*                    </button>*/}
            {/*                </div>*/}
            {/*            </form>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </>
    )
}

export default AccreditationSaveModal1;