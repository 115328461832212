import React from 'react';
import {CardContent, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({

    ListItem: {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: '1px solid #ECEFF1'
    },

    header: {
        fontSize: '16px',
        textTransform: 'uppercase'
    },

    ListItemText: {
        color: '#607D8B'
    },

    ListItemTextCount: {
        fontWeight: '500'
    }

}));


export const InvestmentStatus = ({investmentsData}) => {

    const classes = useStyles();
    const data = [
        {
            name: 'Pending KYC/AML',
            count: investmentsData.investmentPendingKyc,
        },
        {
            name: 'Approved Pending Docs:',
            count: investmentsData.investmentApprovedDocs,
        },
        {
            name: 'Approved Pending Funds:',
            count: investmentsData.investmentApprovedFunds,
        },
        {
            name: 'Rejected:',
            count: investmentsData.investmentRejected,
        },
    ]

    return (
        <Card raised>
            <CardContent>
                <Typography className={classes.header}
                            variant="h6">
                    Investment Status
                </Typography>

                <Box pt="19px">
                    <List component="nav">
                        {data.map((item) => {
                            return (
                                <ListItem
                                    key={item.name}
                                    className={classes.ListItem}>
                                    <ListItemText
                                        className={classes.ListItemText}
                                        primary={item.name}
                                    />

                                    <Box edge="end">
                                        <Typography
                                            className={classes.ListItemTextCount}
                                            variant="subtitle1">
                                            {item.count}
                                        </Typography>
                                    </Box>
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            </CardContent>
        </Card>
    )
}