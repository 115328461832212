import React from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


const EditModal = (props) => {

    let handleFormSubmit = (e) => {
        e.preventDefault();
        props.submit();
        e.target.reset();
        props.close();
    };

    let close = (e) => {
        // e.target.form.reset();
        props.close();
    };

    return (
        <Dialog
            open={props.show}
            maxWidth="md"
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">

            <form
                onSubmit={handleFormSubmit}
                id={"edit-modal" + (props.editModalId ? props.editModalId : '')}>

                <DialogTitle id="alert-dialog-title">
                    EDIT
                </DialogTitle>

                <DialogContent style={{overflow: 'hidden'}}>
                    {props.body}
                </DialogContent>

                <DialogContent>
                    <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        onClick={() => props.deleteTableHandler(props.entity)}>
                        Delete
                    </Button>

                    <Button
                        form={"edit-modal" + (props.editModalId ? props.editModalId : '')}
                        type="submit"
                        color="primary"
                        variant="contained"
                        style={{marginLeft:10}}
                        data-dismiss="modal">
                        Submit
                    </Button>
                </DialogContent>

                <DialogActions>
                    <Button
                        type="button"
                        data-dismiss="modal"
                        onClick={close}>
                        Close
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default EditModal;