import React, {useEffect, useState} from 'react';

import {Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const InputFieldS = ({
                        title, name, value, size, multiline, placeholder, required = false, disabled = false, onChange,
                        onBlur, hasError = false, errorMessage, type
                    }) => {

    const [value1, setValue] = useState('')

    useEffect(() => {
        setValue(value)
    }, [])

    const defaultValue = {
        type: 'text'
    }

    return (
        <>
            <Typography>
                {title}
                {required && <span className="required__circle"> *</span>}
            </Typography>
            <TextField
                fullWidth
                size={size}
                multiline={multiline}
                value={value1}
                disabled={disabled}
                error={hasError}
                placeholder={placeholder}
                helperText={hasError && errorMessage}
                onChange={event => setValue(event.target.value)}
                onBlur={event => onBlur(event.target.value, name)}
                type={type ? type : defaultValue.type}
                variant="outlined"
            />
        </>
    )
}

export default InputFieldS;