import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Avatar, Card, Typography} from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

function CountIcon({...rest}) {

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: 0,
        },

        header: {
            color: '#546E7A',
        },

        details: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
        },

        avatar: {
            backgroundImage: '#ddd',
            height: 58,
            width: 58
        },

        blockIcon: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: rest.bottomPanel ? '' : '40px'
        }

    }));

    const classes = useStyles();

    const card = <Card raised className={classes.root + ' all-users'}>
        <Box className={classes.blockIcon}>
            <div>
                <Typography
                    className={classes.header}
                    component="h3"
                    gutterBottom
                    variant="overline"
                >
                    {rest.name}
                </Typography>

                <div className={classes.details}>
                    <Typography variant="h5">
                        {rest.count ? rest.count : 0}
                    </Typography>
                </div>
            </div>

            <Avatar
                style={{backgroundColor: `${rest.backgroundColor}`}}
                className={classes.avatar}>

                {rest.icon}

            </Avatar>
        </Box>

        {rest.bottomPanel ?
            <Box
                pb="10px"
                display="flex"
                mt="10px"
            >
                {rest.data.length !== 0 ? rest.data.map((item) => {
                    return (
                        <Link
                            color={"secondary"}
                            className={"link"}
                            onClick={() => rest.onClick(item.id)}>
                            <Box
                                key={item.name}
                                display="flex" mr="10px"
                            >
                                <Box mr="5px">
                                    <Typography variant="body2">
                                        {item.name}:
                                    </Typography></Box>
                                <Box>
                                    <Typography variant="subtitle2">
                                        {item.count}
                                    </Typography></Box>
                            </Box>
                        </Link>
                    )
                }) : <Box display="flex" pb='20px'/>}
            </Box>
            : null}
    </Card>

    return (
        rest.bottomPanel
            ? <Box>{card}</Box>
            : <Box onClick={rest.onClick}>{card}</Box>
    );
}

export default CountIcon;
