import React, {Component} from 'react';
import ConversationService from "../services/ConversationService";
import Moment from 'react-moment';
import MessageComposeModal from "../containers/modal/MessageComposeModal";
import Grid from "@material-ui/core/Grid";
import {Button, CardContent, Divider, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import TaskService from "../services/TaskService";

class MessagePanelComponentOld extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            show: false,
            channel: {},
            noMessage: false,
            loader: true
        }

    }

    componentDidMount() {
        if (this.props.user.id) {
            this.loadConversation(this.props.user.id);
        }
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.user.id !== prevProps.user.id) {
    //         this.loadConversation(this.props.user.id);
    //     }
    // }

    loadConversation = (userId) => {
        this.setState({
            loader: true
        })

        ConversationService.fetchByUserId(userId)
            .then(data => {
                this.setState({
                    messages: data.data.messages,
                    channel: {id: data.data.channelId, name: data.data.name},
                    loader: false,
                    noMessages: data.data.messages.length === 0,
                }, this.updateMessage);
            })
    }

    updateMessage = () => {
        const mes = this.state.messages.filter(message => !message.read)
        mes.map(message => ConversationService.updateMessage(message.id)
            .then(response => {

            })
            .catch(error => {
            }))

        if (this.props.location.state) {
            TaskService.deleteTask(Number.parseInt(this.props.location.state.taskId))
                .then(response => {
                })
                .catch(error => {
                })
        }
    }

    showComposeModal = () => {
        this.setState({
            ...this.state,
            show: true
        })
    }

    onMessageSend = (req) => {
        ConversationService.postMessage(req.channelId, req.content)
            .then(data => {
                this.loadConversation(this.props.user.id);
            })
    }

    handleClose = () => {
        this.setState({
            ...this.state,
            show: false
        })
    }

    // handleModifyPoc = (e) => {
    //     e.preventDefault();
    //     let req = {
    //         pocFullName: e.target.pocFullName.value,
    //         pocIssuerEmail: e.target.pocIssuerEmail.value,
    //         postalCode: e.target.postalCode.value
    //     }
    // }

    render() {
        return (
            <>
                <MessageComposeModal
                    className="modal"
                    submit={this.onMessageSend}
                    show={this.state.show}
                    channels={[this.state.channel]}
                    close={this.handleClose}>
                </MessageComposeModal>

                <Grid container spacing={3}>
                    <Grid item lg={6} xs={6}>
                        <Typography variant="h6">
                            Inbox Panel
                        </Typography>
                    </Grid>

                    <Grid item lg={6} xs={6} style={{display: "flex", justifyContent: 'flex-end'}}>
                        <Button variant='contained'
                                color='primary'
                                startIcon={<AddIcon/>}
                                onClick={this.showComposeModal}>
                            Send message
                        </Button>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        {this.state.loader ?
                            <Grid item
                                  lg={12}
                                  xs={12}
                                  style={{display: "flex", justifyContent: "center"}}>
                                <CircularProgress/>
                            </Grid>
                            : this.state.noMessages ?
                                <Grid item lg={12} xs={12}>
                                    <Typography>
                                        No Messages
                                    </Typography>
                                </Grid> :
                                this.state.messages.map(v => {
                                    return (
                                        <Grid xs={12} lg={12} style={{paddingBottom: 10}}>
                                            <Card>
                                                <CardContent style={{backgroundColor: '#D9E9F3'}}>
                                                    <Grid container spacing={1}>
                                                        <Grid item lg={6} xs={6}>
                                                            <Typography variant='subtitle2'>
                                                                Date:
                                                                <Moment format={'LLL'}>
                                                                    {v.dateTime}
                                                                </Moment>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <Typography
                                                                variant='subtitle2'>Author: {v.nameFrom + ' ' + v.surnameFrom}</Typography>
                                                        </Grid>
                                                        <Grid item lg={12} xs={12}>
                                                            <Divider/>
                                                        </Grid>
                                                        <Grid item lg={12} xs={12}>
                                                            <Typography
                                                                variant='subtitle1'>{ConversationService.formatMessage(v.content)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )
                                })
                        }
                    </Grid>
                </Grid>
            </>

        )
    }
}

export default MessagePanelComponentOld;