import React from 'react';
import {Component} from "react/cjs/react.production.min";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class TimerLogoutModal extends Component {

    constructor() {
        super();
        this.state = {
            time: {},
            seconds: 120
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({time: timeLeftVar});
        this.startTimer();
    }

    startTimer() {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(this.timer);
            this.logout()
        }
    }

    logout = () => {
        window.location.href = '/logout'
    };


    render() {
        return (
            <>
                <Dialog open={this.props.close}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">SESSION INFORMATION</DialogTitle>
                    <DialogContent style={{overflow: 'hidden'}}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <Typography>Your session is about to expire. You will be automatically signed out
                                    in</Typography>
                            </Grid>
                            <Grid item lg={12} xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <Typography
                                    variant='h5'>  {this.state.time.m} : {this.state.time.s > 9 ? "" + this.state.time.s : "0" + this.state.time.s}</Typography>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <DialogActions>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={this.props.close}>Stay Signed In
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={this.logout}>Sign out
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}

export default TimerLogoutModal;