import React, {useEffect, useState} from "react";

import UserService from "../../services/UserService";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputField from "../../components/Molecules/InputField";
import SelectField from "../../components/Molecules/SelectField";
import UserGroupService from "../../services/UserGroupService";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {toast} from "react-toastify";
import Button from "@material-ui/core/Button";

const AdminEdit = (props) => {

    const [user, setUser] = useState({})
    const [errors, setErrors] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        fetchUser(props.userId)
    }, [])

    const fetchUser = (id) => {
        setLoader(true)

        UserService.fetchUser(id)
            .then(response => {
                setUser(response.data)
                setLoader(false)
            })
            .catch(error => {
                setLoader(false)
            })
    }

    const handleSubmit = () => {
        if (handleValidation()) {
            const request = {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName
            }

            setLoader(true)

            UserService.updateUser(request)
                .then(response => {
                    toast.success('Successfully edited!')
                    setUser(response.data)
                    setLoader(false)
                })
                .catch(error => {
                    toast.error('Error');
                    setLoader(false)
                })
        } else {
            toast.error('Please fill all the required fields!')
        }
    }

    const handleValidation = () => {
        let errors = {}
        let valid = true

        if (!user.firstName) {
            valid = false
            errors.firstName = 'Cannot be empty'
        }

        if (!user.lastName) {
            valid = false
            errors.lastName = 'Cannot be empty'
        }

        setErrors(errors)

        return valid
    }

    const handleUserChange = (value, prop) => {
        const newUser = {...user}
        newUser[prop] = value
        setUser(newUser)

        validateValue(value, prop)
    }

    const validateValue = (value, prop) => {
        if (value || value === '') {
            const newErrors = {...errors}
            newErrors[prop] = ''
            setErrors(newErrors)
        }
    }


    return (
        loader ? (
            <Container maxWidth='lg'>
                <Grid container
                      justify={"center"}
                      alignItems={"center"}
                      style={{height: '100vh'}}>
                    <Grid item>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            </Container>
        ) : (
            <Grid container spacing={3}
                  style={{paddingTop: 80}}>
                <Grid item lg={12} xs={12}>
                    <Card raised>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={12}>
                                    <Typography variant="h6">
                                        General information
                                    </Typography>

                                    <Divider/>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <InputField
                                        title={"First Name"}
                                        name={'firstName'}
                                        value={user.firstName}
                                        required
                                        onChange={handleUserChange}
                                        hasError={!!errors.firstName}
                                        errorMessage={errors.firstName}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <InputField
                                        title={"Last Name"}
                                        name={'lastName'}
                                        value={user.lastName}
                                        required
                                        onChange={handleUserChange}
                                        hasError={!!errors.lastName}
                                        errorMessage={errors.lastName}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <InputField
                                        title={"Email"}
                                        name={'email'}
                                        value={user.email}
                                        disabled
                                        onChange={handleUserChange}
                                        hasError={!!errors.email}
                                        errorMessage={errors.email}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <SelectField
                                        title={"User Group"}
                                        name={"userGroup"}
                                        value={user.userGroup && user.userGroup.id}
                                        disabled
                                        required
                                        options={UserGroupService.fetch()}
                                        hasError={!!errors.state}
                                        errorMessage={errors.state}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={12} xs={12}
                      style={{textAlign: 'end'}}>
                    <Button
                        type={"button"}
                        color="primary"
                        variant={"contained"}
                        onClick={handleSubmit}
                    >
                        Update
                    </Button>
                </Grid>
            </Grid>
        )
    )
}

export default AdminEdit