import React, {useState} from 'react';
import {toast} from "react-toastify";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import 'date-fns';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";


const PassportVerificationModal = (props) => {

    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    let handleFormSubmit = (e) => {
        handleDateChange(selectedDate);
        let formattedDate = formatDate(selectedDate)

        if (formattedDate !== "") {
            
            if (props.verifyDate(formattedDate)) {
                props.submit(formattedDate, props.blockName);
                props.close(props.blockName);
            } else {
                toast.error("ERROR: wrong date!")
            }
        } else {
            toast.error("ERROR: enter the date!")
        }

    };

    let formatDate = (e) => {
        let date = new Date(e);
        let dateUtc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return dateUtc.toLocaleDateString('en-US');
    };

    let close = (e) => {
        props.close(props.blockName);
    };


    return (
        <>
            <Dialog
                open={props.show}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">CHANGE CONFIRMATION</DialogTitle>

                <DialogContent style={{overflow: 'hidden'}}>
                    <Grid container spacing={3}>
                        <Grid item lg={12} xs={12}>
                            <Typography> Set the expiration date for the section "Passport Number, User Name,
                                Date of Birth & Country of Citizenship"
                            </Typography>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around">
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Expiration Date"
                                        format="MM/dd/yyyy"
                                        value={selectedDate}
                                        onChange={(e) => handleDateChange(e)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <DialogActions>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleFormSubmit}>Set Expiration Date
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={close}>Cancel
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default PassportVerificationModal;