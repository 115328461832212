import React, {Component} from 'react';

import Aux from '../hoc/AuxWrapper'
import AuthenticationService from '../services/AuthenticationService';
import {AUTH_TOKEN} from "../shared/utility";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import NavLink from "react-bootstrap/NavLink";
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import UserService from "../services/UserService";


const IconBlock = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: absolute;
  top: -25px;
  left: 30px;
  background: linear-gradient(0deg, rgba(102,187,106,1) 0%, rgba(67,160,71,1) 100%);;
`;

const HeaderTextLogin = styled.div`
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 500;
`;

const CartComponent = styled.div`
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
     0px 1px 1px 0px rgba(0,0,0,0.14),
      0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 60px 30px 35px 30px;
    position: relative;
    background: white;
    max-width: 480px;
    min-width: 480px;
    
    @media (max-width: 600px) {
        min-width: 100%;
        padding: 60px 15px 35px 15px;
  }
    
`;

const MainWrap = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const WrapBoxLogin = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 75px 0;
`;

const WrapMainElement = styled.div`
  display: flex;
  flex-direction: column;
  
   @media (max-width: 600px) {
    min-width: 85%;
   }
`;

const TextErrorMessage = styled.div`
  color: #B71C1C;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  text-decoration: none;
`;


class Login extends Component {

    state = {
        username: '',
        password: '',
        loginError: null
    }

    componentDidMount() {
        document.body.setAttribute('class', '');
    }

    componentWillUnmount() {
        document.body.setAttribute('class', '');
    }

    loginHandler(e) {
        e.preventDefault();

        AuthenticationService.loginUser({
            email: this.state.username,
            password: this.state.password
        }).then(response => {
            localStorage.setItem(AUTH_TOKEN, response.data);

            UserService.fetchCurrentUser()
                .then(response => {
                    localStorage.setItem('userInfo', JSON.stringify(response.data));
                }).catch(error => {
                console.log(error);
            })

            this.clearErrorMessage();

            this.props.history.push('/');
        }).catch(error => {
            this.setErrorMessage(error);
        });
    }

    usernameChangeHandler(e) {
        this.setState({
            username: e.target.value
        });
    }

    passwordChangeHandler(e) {
        this.setState({
            password: e.target.value
        });
    }

    clearErrorMessage() {
        this.setState({
            loginError: null
        });
    }

    setErrorMessage(statusCode) {
        if (statusCode.response.status === 403) {
            this.setState({
                loginError: 'Email or password are incorrect'
            });
        } else {
            this.setState({
                loginError: 'Server temporarily unavailable'
            });
        }
    }

    test() {
        this.props.history.push('/test');
    }

    render() {
        return (
            <Aux>

                <MainWrap>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}>
                        <WrapMainElement>
                            <WrapBoxLogin>
                                <NavLink
                                    to="index.php"
                                    href="index.php">
                                    <img
                                        src={require('../assets/images/logo-blue.png')}
                                        width="140"
                                        height="51"
                                        alt=""/>
                                </NavLink>
                            </WrapBoxLogin>

                            <CartComponent>

                                <IconBlock>
                                    <PermIdentityOutlinedIcon
                                        style={{color: 'white', fontSize: '30px', margin: '0 auto'}}/>
                                </IconBlock>

                                <HeaderTextLogin>Login</HeaderTextLogin>
                                <hr/>

                                <form onSubmit={(e) => this.loginHandler(e)}>
                                    <div className="form-group">
                                        <TextField
                                            onChange={(e) => this.usernameChangeHandler(e)}
                                            value={this.state.username}
                                            autoFocus={true}
                                            placeholder="Email"
                                            variant="outlined"
                                            label="Email"
                                            type="text"
                                            fullWidth/>
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            onChange={(e) => this.passwordChangeHandler(e)}
                                            value={this.state.password}
                                            placeholder="Password"
                                            variant="outlined"
                                            label="Password"
                                            type="password"
                                            fullWidth/>
                                    </div>

                                    <div className="form-group">
                                        <TextErrorMessage>{this.state.loginError}</TextErrorMessage>
                                    </div>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        type="submit">
                                        Submit
                                    </Button>
                                </form>

                            </CartComponent>
                        </WrapMainElement>

                    </Grid>
                </MainWrap>

            </Aux>
        );
    }
}


export default Login;