import axios from '../custom-axios/backoffice';
import axiosDms from '../custom-axios/dms-app';
import {toast} from "react-toastify";

const CMSService = {

    savePage(page) {
        return axios.post("/rest/page", page)
    },

    updatePage(page) {
        return axios.post("/rest/page/update", page)
    },

    revertPage(page) {
        return axios.post("/rest/page/update", page).then(data => {
            toast.success('Reverted!');
        }).catch(error => {
            toast.error('Error!');
        });
    },

    checkUri(uri) {
        return axios.get("/rest/page/check/" + uri)
    },

    removeImageByUuid(uuid) {
        return axiosDms.get('/v1/api/document/delete/' + uuid)
    },

    switchPageActive(uri) {
        return axios.get('/rest/page/delete/' + uri)
    },

    saveImagesMultiple: (groupId, files, uri, edit) => {
        const formData = new FormData();

        files.forEach((file) => {
            formData.append("files", file);
        });

        return axiosDms.post('/v1/api/document/document_group/' + groupId + '/' + uri + '/upload/multiple', formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },

    getAllPages: () => {
        return axios.get('rest/page')
    },

    getPagesByDealId: (dealId) => {
        return axios.get('rest/page/deal/' + dealId)
    },

    getOldPages: () => {
        return axios.get('rest/page/old')
    },

    getPageByUri: (uri) => {
        return axios.get('rest/page/' + uri)
    },

    getImage: (uri) => {
        return axiosDms.get(uri)
    },

    getImagesByUri: (uri) => {
        return axiosDms.get('/v1/api/document/document_group/cms/' + uri + '/download/multiple')
    }
};

export default CMSService;