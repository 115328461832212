import React, {useEffect, useState} from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Moment from "react-moment";
import TaskService from "../../services/TaskService";

const TasksCard = (props) => {

    const [tasks, setTasks] = useState([])
    // const [tasksData, setTasksData] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        fetchTasks()
    }, [])

    const fetchTasks = () => {
        setLoader(true)

        TaskService.fetchTasks()
            .then(response => {
                setTasks(response.data)
                setLoader(false)
            })
            .catch(error => console.log(error))
    }

    // const buildTaskData = (_tasks) => {
    //     let _tasksData = []
    //
    //     _tasks.forEach(task => {
    //         switch (task.taskType) {
    //             case 'APPROVAL':
    //                 let approvalTaskData = {
    //                     task: task,
    //                 }
    //
    //                 _tasksData.push(approvalTaskData)
    //                 break
    //             case 'USER_APPROVAL':
    //                 UserService.fetchUser(task.value)
    //                     .then(response => {
    //                         let taskData = {
    //                             task: task,
    //                             firstName: response.data.firstName,
    //                             lastName: response.data.lastName,
    //                             email: response.data.email,
    //                         }
    //
    //                         _tasksData.push(taskData)
    //                         setTasksData(_tasksData)
    //                     })
    //                     .catch(error => {
    //
    //                     })
    //                 break
    //             case 'MESSAGE':
    //                 let messageTaskData = {
    //                     task: task,
    //                 }
    //
    //                 _tasksData.push(messageTaskData)
    //                 break
    //             case 'ISSUER_REQUEST':
    //                 IssuerService.fetchIssuerById(task.value)
    //                     .then(response => {
    //                         let taskData = {
    //                             task: task,
    //                             firstName: response.data.unvalidatedUser ? response.data.unvalidatedUser.firstName : response.data.user.firstName,
    //                             lastName: response.data.unvalidatedUser ? response.data.unvalidatedUser.lastName : response.data.user.lastName,
    //                             email: response.data.unvalidatedUser ? response.data.unvalidatedUser.email : response.data.user.email,
    //                         }
    //
    //                         _tasksData.push(taskData)
    //                         setTasksData(_tasksData)
    //                     })
    //                 break
    //             default:
    //         }
    //     })
    //
    //     //setTasksData(_tasksData)
    //     setLoader(false)
    // }

    const handleOnClickItem = (task) => {
        switch (task.taskType) {
            case 'APPROVAL':
                props.history.push(`/investments/${task.value}`, {taskId: task.id})
                break
            case 'USER_APPROVAL':
                props.history.push(`/users/${task.value}`, {taskId: task.id})
                break
            case 'MESSAGE':
                props.history.push(`/users/${task.value}`, {taskId: task.id})
                break
            case 'ISSUER_REQUEST':
                props.history.push(`/users/unvalidated_issuer/${task.value}`, {taskId: task.id})
                break
            default:

        }
    }

    // const buildItem = (taskData) => {
    //     switch (taskData.task.taskType) {
    //         case 'APPROVAL':
    //             return (
    //                 <>
    //                     <ListItem
    //                         key={taskData.task.id}
    //                         button
    //                         onClick={() => handleOnClickItem(taskData.task)}>
    //                         <ListItemText
    //                             primary={`${taskData.task.title}`}
    //                             secondary={
    //                                 <>
    //                                     <Moment format={'LLL'}>
    //                                         {taskData.task.dateCreated}
    //                                     </Moment>
    //                                 </>
    //                             }/>
    //                     </ListItem>
    //
    //                     <Divider variant="middle" component="li"/>
    //                 </>
    //             )
    //         case 'USER_APPROVAL':
    //             return (
    //                 <>
    //                     <ListItem
    //                         key={taskData.task.id}
    //                         button
    //                         onClick={() => handleOnClickItem(taskData.task)}>
    //                         <ListItemText
    //                             primary={`${taskData.task.title}`}
    //                             secondary={
    //                                 <>
    //                                     <Moment format={'LLL'}>
    //                                         {taskData.task.dateCreated}
    //                                     </Moment>
    //                                 </>
    //                             }/>
    //
    //                         {taskData.firstName} {taskData.lastName} (email:{taskData.email})
    //                     </ListItem>
    //
    //                     <Divider variant="middle" component="li"/>
    //                 </>
    //             )
    //         case 'MESSAGE':
    //             return (
    //                 <>
    //                     <ListItem
    //                         key={taskData.task.id}
    //                         button
    //                         onClick={() => handleOnClickItem(taskData.task)}>
    //                         <ListItemText
    //                             primary={`${taskData.task.title}`}
    //                             secondary={
    //                                 <>
    //                                     <Moment format={'LLL'}>
    //                                         {taskData.task.dateCreated}
    //                                     </Moment>
    //                                 </>
    //                             }/>
    //                     </ListItem>
    //
    //                     <Divider variant="middle" component="li"/>
    //                 </>
    //             )
    //         case 'ISSUER_REQUEST':
    //             return (
    //                 <>
    //                     <ListItem
    //                         key={taskData.task.id}
    //                         button
    //                         onClick={() => handleOnClickItem(taskData.task)}>
    //                         <ListItemText
    //                             primary={`${taskData.task.title}`}
    //                             secondary={
    //                                 <>
    //                                     <Moment format={'LLL'}>
    //                                         {taskData.task.dateCreated}
    //                                     </Moment>
    //                                 </>
    //                             }/>
    //
    //                         {taskData.firstName} {taskData.lastName} (email:{taskData.email})
    //                     </ListItem>
    //
    //                     <Divider variant="middle" component="li"/>
    //                 </>
    //             )
    //         default:
    //             return null
    //     }
    // }


    return (
        <Grid container spacing={2}>
            {loader ? (
                <Grid item lg={12} xs={12}
                      style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: '50vh'
                      }}>
                    <CircularProgress/>
                </Grid>
            ) : (
                <Grid item lg={12} xs={12}>
                    <Card raised
                          style={{height: 500}}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={12} xs={12}>
                                    <Typography variant='h6'>
                                        Todo
                                    </Typography>

                                    <Divider/>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    {tasks.length === 0 ? (
                                        <Typography>
                                            No tasks
                                        </Typography>
                                    ) : (
                                        <List dense
                                              style={{overflow: 'auto', maxHeight: 400}}>
                                            {tasks.map((task) => {
                                                return (
                                                    <>
                                                        <ListItem
                                                            key={task.id}
                                                            button
                                                            onClick={() => handleOnClickItem(task)}>
                                                            <ListItemText
                                                                primary={task.title}
                                                                secondary={
                                                                    <Moment format={'LLL'}>
                                                                        {task.dateCreated}
                                                                    </Moment>
                                                                }/>
                                                        </ListItem>

                                                        <Divider variant="middle" component="li"/>
                                                    </>
                                                )
                                            })}
                                        </List>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Grid>
    )
}

export default TasksCard