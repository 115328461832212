import React, {Component} from 'react';
import Checkbox from '../../../components/input/Checkbox';
import DocumentScroll from '../../../components/DocumentScroll';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import {Button} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";

class FinalSubmissionPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'PRIVACY_DISCLOSURE': [],
            'TERMS_CONDITIONS': [],
            'RISK_DICLOSURE': [],
            'INFORMATION_IS_CORRECT': [],
            step: null
        };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        const step = this.props.steps.filter(step => step.step.id === 'STEP_FINAL_SUBMISSION')[0]
        this.setState({
            step: step
        })

        this.props.fetchAttributeOptions('PRIVACY_DISCLOSURE').then((data) => {
            this.setState({
                ...this.state,
                'PRIVACY_DISCLOSURE': data.data
            });
        });

        this.props.fetchAttributeOptions('TERMS_CONDITIONS').then((data) => {
            this.setState({
                ...this.state,
                'TERMS_CONDITIONS': data.data
            });
        });

        this.props.fetchAttributeOptions('RISK_DICLOSURE').then((data) => {
            this.setState({
                ...this.state,
                'RISK_DICLOSURE': data.data
            });
        });

        if (this.props.user && this.props.user.userGroup.id === 'USER_INVESTOR_INDIVIDUAL') {
            this.setState({
                ...this.state,
                'INFORMATION_IS_CORRECT': this.props.investorData['INFORMATION_IS_CORRECT']
            });
        } else {
            this.setState({
                ...this.state,
                'INFORMATION_IS_CORRECT': this.props.investorData['INFORMATION_IS_CORRECT_COMPANY']
            });
        }
    }


    render() {
        return (
            <>
                <Grid container
                      spacing={3}
                      justify='center'
                      alignItems='center'>
                    <Grid item lg={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}
                                  style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    variant='contained'
                                    color={"primary"}
                                    // style={{
                                    //     margin: 15
                                    // }}
                                    startIcon={<ClearIcon/>}
                                    disabled={this.state.step?.status !== 'APPROVED'}
                                    onClick={(e) => this.props.rejectStep('STEP_FINAL_SUBMISSION')}>
                                    Reject
                                </Button>

                                <Button
                                    variant='contained'
                                    color='secondary'
                                    style={{marginLeft: 15}}
                                    startIcon={<CheckIcon/>}
                                    disabled={this.state.step?.status === 'APPROVED'}
                                    onClick={() => this.props.approveStep('STEP_FINAL_SUBMISSION')}>
                                    {
                                        this.state.step?.status === 'APPROVED'
                                            ? 'Approved'
                                            : 'Approve'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={8} xs={12}>
                        <Card>
                            <CardContent>
                                <Box pb="20px">
                                    <Typography variant="h6">
                                        Disclosures & Final Submission
                                    </Typography>
                                    <Divider/>
                                </Box>

                                <Box>
                                    <DocumentScroll
                                        pushInvestorChange={this.props.pushInvestorChange}
                                        attributeCode={'RISK_DISCLOSURE_READ_INDIVIDUAL'}/>
                                </Box>

                                <form>
                                    <Box mt="35px" mb="25px">
                                        <div className="checkbox">
                                            <Checkbox
                                                disabled={true}
                                                attributeCode="INFORMATION_IS_CORRECT_COMPANY"
                                                pushInvestorChange={this.props.pushInvestorChange}
                                                defaultValue={this.state.INFORMATION_IS_CORRECT}
                                                options={this.state['INFORMATION_IS_CORRECT']}
                                                label="Information is Correct to the Best of my Knowledge"
                                                attributes={[]}/>
                                        </div>
                                    </Box>

                                    <Grid container>
                                        <Grid item lg="12">
                                            <Grid item lg="6">
                                                <Box>
                                                    <Typography variant="subtitle2">First Name
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Box>

                                                <Box pb={3}>
                                                    <input type={'text'}
                                                           className='input__custom--big'
                                                           disabled
                                                           value={this.props.investorData['FIRST_NAME_FINAL'] || this.props.investorData['FIRST_NAME_FINAL_COMPANY'] ? this.props.investorData['FIRST_NAME_FINAL'].chosenValue || this.props.investorData['FIRST_NAME_FINAL_COMPANY'].chosenValue : ''}/>
                                                </Box>

                                            </Grid>
                                        </Grid>

                                        <Grid item lg="12">
                                            <Grid item lg="6">

                                                <Box>
                                                    <Typography variant="subtitle2">Last Name
                                                        <span className="required__circle"> *</span>
                                                    </Typography>
                                                </Box>

                                                <input type={'text'}
                                                       className='input__custom--big'
                                                       disabled
                                                       value={this.props.investorData['LAST_NAME_FINAL'] || this.props.investorData['LAST_NAME_FINAL_COMPANY'] ? this.props.investorData['LAST_NAME_FINAL'].chosenValue || this.props.investorData['LAST_NAME_FINAL_COMPANY'].chosenValue : ''}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default FinalSubmissionPage;