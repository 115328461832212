import axios from '../custom-axios/backoffice';

const UserCommentsService = {
    fetchByUserId: (userId) => {
        return axios.get(`/rest/user_comment/user/${userId}`);
    },
    postMessage: (userId,content) => {
        return axios.post("/rest/user_comment",{"user":{id:userId},"comment":content});
    }
}

export default UserCommentsService;