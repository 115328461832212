const RequestStatusService = {
    fetch: () => {
        return [
            {
                id: 'PENDING',
                value: 'Pending'
            },
            {
                id: 'INVITED',
                value: 'Invited'
            },
            {
                id: 'PROCESSED',
                value: 'Processed'
            },
            {
                id: 'ARCHIVED',
                value: 'Archived'
            }
        ]
    }
}

export default RequestStatusService;