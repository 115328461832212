import React from "react";

import {Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FormHelperText from "@material-ui/core/FormHelperText";

const SelectMultipleField = (props) => {

    const handleChange = (_values) => {
        const selectedValues = props.options.filter(value => _values.includes(value.id ? value.id : value))
        props.onChange(selectedValues, props.name)
    }

    return (
        <>
            <Typography>
                {props.title}
                {props.required && <span className="required__circle"> *</span>}
            </Typography>
            <FormControl variant="outlined"
                         fullWidth
                         disabled={props.disabled}
                         error={props.hasError}>
                <Select
                    multiple
                    value={props.value}
                    onChange={event => handleChange(event.target.value)}
                    renderValue={(selected) => props.options
                        .filter(value => selected.includes(value.id ? value.id : value))
                        .map(value => value.value
                            ? value.value
                            : value).join(', ')}
                >
                    {
                        props.emptyValue && <MenuItem key={props.emptyValue} value="">
                            {props.emptyValue}
                        </MenuItem>
                    }

                    {
                        props.options.map(option =>
                            <MenuItem key={option.id ? option.id : option}
                                      value={option.id ? option.id : option}
                                      disabled={props.requiredItems && props.requiredItems.includes(option.id ? option.id : option)}
                            >
                                <Checkbox
                                    checked={props.value.some(value => option.id
                                        ? value === option.id
                                        : value === option)}
                                />
                                <ListItemText primary={option.value ? option.value : option}/>
                            </MenuItem>
                        )
                    }
                </Select>
                <FormHelperText>
                    {props.hasError && props.errorMessage}
                </FormHelperText>
            </FormControl>
        </>
    )
}

export default SelectMultipleField;