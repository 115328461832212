import axios from '../custom-axios/backoffice';

const InvestmentService = {
    transform: (investments) => {
        return investments.map(investment => {
            return {
                id: investment.id,
                investmentStatus: investment.investmentStatus.value,
                label: 'Status',
                //paymentType: investment.paymentType.type,
                //payTypeId: investment.paymentType.id,
                firstName: investment.investor.firstName,
                lastName: investment.investor.lastName,
                fullName: `${investment.investor.firstName} ${investment.investor.lastName}`,
                userId: investment.investor.id,
                email: investment.investor.email,
                amount: investment.amount,
                dateCreated: investment.dateCreated,
                dateApproved: investment.dateApproved,
                regTypes: investment.regTypes,
                user: investment.investor,
                investmentAmount: investment.investmentAmount,
                dealName: investment.deal.name,
                dealId: investment.deal.id,
                country: investment.country,
                investmentType: investment.investmentType,
                //regType:investment.regTypes[0].name
            }
        })
    },
    postNewInvestment: (data) => {
        return axios.post('/rest/investment', data);
    },
    postNewSurroagateInvestment: (data) => {
        return axios.post('/rest/investment/surrogate', data)
    },
    fetchInvestmentsPerIssuer: (issuerId) => {
        return axios.get('/rest/investment/issuer/' + issuerId);
    },
    fetchInvestmentsPerDeal: (dealId) => {
        return axios.get('/rest/investment/deal/' + dealId);
    },
    createInvestment: (data) => {
        return axios.post('/rest/investment/create', data)
    },

    updateInvestment: (status, investmentId) => {
        return axios.post('/rest/investment/update/' + status + '/' + investmentId)
    },

    pushInvestmentDocumentChanges: (data) => {
        return axios.post('/rest/investment/document/create', data)
    },

    fetchAllDocuments: (investmentId) => {
        return axios.get('/rest/investment/document/' + investmentId)
    },

    deleteDocument: (investmentId, documentId) => {
        return axios.post('/rest/investment/document/delete/' + investmentId + '/' + documentId)
    },

    updateInvestmentData: (data) => {
        return axios.post('/rest/investment/investmentData/update', data)
    },

    fetchInvestmentData: (investmentId) => {
        return axios.get('/rest/investment/investmentData/' + investmentId)
    },

    fetchAllInvestment: () => {
        return axios.get('/rest/investment/investments')
    },

    fetchInvestmentStatuses: () => {
        return axios.get('/rest/investment/statuses')
    },

    fetchInvestment: (id) => {
        return axios.get('/rest/investment/' + id)
    }

}

export default InvestmentService;