import axios from '../custom-axios/dms-app';

const DocumentService = {
    // saveDocument: (groupCode, file, folderName) => {
    //     const formData = new FormData();
    //     formData.append('file', file[0]);
    //
    //     return axios.post(`/api/documents?groupCode=${groupCode}&folderName=${folderName}`, formData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     });
    // },

    // saveDocumentMultiple: (groupCode, files, folderName) => {
    //     const formData = new FormData();
    //     files.forEach((file) => {
    //         formData.append("files", file);
    //     });
    //
    //     return axios.post(`/api/documents/multiple?groupCode=${groupCode}&folderName=${folderName}`, formData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     });
    // },



    saveDocument: (groupId, file) => {
        const formData = new FormData();
        formData.append('file', file[0]);

        return axios.post('/v1/api/document/document_group/' + groupId + '/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    saveDocumentMultiple: (groupId, files) => {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append("files", file);
        });

        return axios.post('/v1/api/document/document_group/' + groupId + '/upload/multiple', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    downloadUserFiles: (email) => {
        return axios.get('/v1/api/document/download/files?email=' + email)
    }
}

export default DocumentService;