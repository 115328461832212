import React, {Component} from 'react';
import SaveModal from "../../containers/modal/SaveModal";
import Aux from "../../hoc/AuxWrapper";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";


class TextPassword extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isShowing: false,
            value: '',
            error: '',
            defaultValue: undefined,
            firstValue: '',
            type: 'password',
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.defaultValue !== prevState.defaultValue) {
            return {
                value: nextProps.defaultValue.chosenValue,
                defaultValue: nextProps.defaultValue,
                firstValue: nextProps.defaultValue.chosenValue
            }
        }
    }


    handleOnBlur = (e) => {
        if (this.state.firstValue !== '') {
            if (e.target.value !== this.props.defaultValue.chosenValue) {
                if (e.target.value.length <= 11) {
                    this.setState({
                        isShowing: !this.state.isShowing
                    })
                }
            }
        }
    };

    onChange = (e) => {
        let value;
        let error;

        if (e.target.value.length > 11) {
            error = "Error: max number of characters"
            value = this.state.value
        } else {
            error = ''
            value = e.target.value;
        }
        this.setState({
            error: error,
            value: value
        })
    }

    saveChanges = () => {
        if (this.props.capitalize) {
            this.setState({
                value: this.state.value.charAt(0).toUpperCase() + this.state.value.slice(1)
            })
        }
        let request = {
            "attributeCode": this.props.attributeCode,
            "value": this.state.value
        };
        this.props.onBlurHandler(request);
        this.setState({
            isShowing: !this.state.isShowing
        })
    };

    changeInput = () => {
        this.setState({
            value: this.state.firstValue,
            error: ''
        })
    };

    closeModal = () => {
        this.setState({
            isShowing: !this.state.isShowing
        })
    };

    changeType = () => {
        let oldState = this.state.type;
        let isTextOrHide = (oldState === 'password');
        let newState = (isTextOrHide) ? 'text' : 'password';
        this.setState({
            type: newState,

        })
    };

    render() {
        return (
            <Aux>
                <SaveModal show={this.state.isShowing}
                           close={this.closeModal}
                           submit={this.saveChanges}
                           changeInput={this.changeInput}
                />
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        {/*<TextField*/}
                        {/*    style={{display:'flex'}}*/}
                        {/*    onBlur={(e) => this.handleOnBlur(e)}*/}
                        {/*    onChange={(e) => this.onChange(e)}*/}
                        {/*    variant="outlined"*/}
                        {/*    id={this.props.id ? this.props.id : null}*/}
                        {/*    value={this.state.value}*/}
                        {/*    type={this.props.type ? this.props.type : "text"}*/}
                        {/*    // InputProps={{*/}
                        {/*    //     readOnly: this.props.flagDisabled*/}
                        {/*    // }}*/}
                        {/*    disabled={this.props.flagDisabled}*/}
                        {/*    error={this.state.error !== ''}*/}
                        {/*    helperText={this.state.error}*/}
                        {/*/>*/}
                        <OutlinedInput
                            id="outlined-adornment-password"
                            fullWidth
                            type={this.state.type}
                            value={this.state.value}
                            onChange={(e) => this.onChange(e)}
                            onBlur={(e) => this.handleOnBlur(e)}
                            disabled={this.props.flagDisabled}
                            error={this.state.error !== ''}
                            helperText={this.state.error}

                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.changeType}
                                        onMouseDown={this.changeType}
                                        edge="end">
                                        {this.state.type !== 'password' ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                </Grid>
            </Aux>
        );
    }
};

export default TextPassword;