import React, {Component} from 'react';

import InvestmentUploadDocument from "../../components/input/InvestmentUploadDocument";
import DocumentService from "../../services/DocumentService";
import PdfPreviewModal from "../modal/PdfPreviewModal";
import ImagePreviewModal from "../modal/ImagePreviewModal";
import InvestmentService from "../../services/InvestmentService";
import {toast} from "react-toastify";
import InvestorService from "../../services/InvestorService";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import TaskService from "../../services/TaskService";


const styles = (theme) => ({
    root: {
        backgroundColor: '#F4F6F8',
    },
})

class InvestmentDetailsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            investment: '',
            documentsKycAml: [],
            documentsPreFunding: [],
            documentsFunds: [],
            documentsRescission: [],
            showPdfPreview: false,
            showPreview: false,
            exceptions: ["No Exceptions", "Exceptions"],
            options: ["No", "Yes"],
            options1: ["Yes", "No"],
            exception: "",
            select1: "",
            select2: "",
            select3: "",
            select4: "",
            select5: "",
            select6: "",
            text1: "",
            text2: "",
            text3: "",
            text4: "",
            investorStatus: false,
            payTypeId: "",
            amount: "",
            flag: false,
        }
    }

    componentDidMount() {
        this.fetchInvestment(Number.parseInt(this.props.match.params.id))
        this.fetchInvestmentData(Number.parseInt(this.props.match.params.id));
        this.fetchAllDocuments(Number.parseInt(this.props.match.params.id));
        //this.fetchInvestorStatus(Number.parseInt(this.props.match.params.id))
    }

    fetchInvestorStatus = (id) => {
        InvestorService.fetchInvestorStatusByUserId(id)
            .then(response => {
                this.setState({
                    investorData: response.data
                })

                if (response.data['A_MEMBER_OR_EMP'].chosenValue || response.data['EMPL_BROKER'].chosenValue
                    || response.data['SENIOR_OFFICER_BANK'].chosenValue || response.data['EMPLOYEE_INVESTMENT'].chosenValue
                    || response.data['EMPLOYEE_STATE'].chosenValue || response.data['PERSON_SEC'].chosenValue
                    || response.data['PERSON_CONTR'].chosenValue || response.data['IF_CHECKED_ANY'].chosenValue) {
                    this.setState({
                        investorStatus: true
                    });
                }
            })
    }

    onChangeMoney = (e) => {
        let value = e.target.value;
        let input1;
        let input2 = "";

        if (value.split(".")[1] || value.split(".")[1] === "") {
            input1 = value.split(".")[0]

            if (value.split(".")[1]) {
                let newInput2 = value.split(".")[1].replace(/[\D\s]+/g, "")

                if (newInput2.length > 8) {
                    input2 = "." + newInput2.slice(0, -1)
                } else {
                    input2 = "." + newInput2;
                }
            } else {
                input2 = ".";
            }
        } else {
            input1 = value
        }

        let newInput1 = input1.replace(/[\D\s]+/g, "")
        newInput1 = newInput1 ? parseInt(newInput1, 10) : 0;
        let newInput1Final;

        if (newInput1 === 0) {
            newInput1Final = '';
        } else {
            newInput1Final = newInput1.toLocaleString("en-US", {maximumFractionDigits: 8});
        }

        let newMoney = newInput1Final + input2;

        let doc = document.getElementById('amount');
        doc.value = newMoney;

        this.setState({
            amount: newMoney
        })
    }

    saveDocument = (docGroupCode, file) => {
        DocumentService.saveDocument(docGroupCode, file)
            .then(response => {
                this.pushInvestmentDocumentChange({
                    investmentId: this.state.investment.id,
                    documentGroupCode: docGroupCode,
                    documentId: +response.data.id
                });
            })
    };

    pushInvestmentDocumentChange = (request) => {
        InvestmentService.pushInvestmentDocumentChanges(request).then((data) => {
            this.fetchAllDocuments(this.state.investment.id);
        })
    };

    checkFields = () => {
        let messages = [];

        if (this.state.select1 === "No" && this.state.text1 === "") {
            messages.push("Reason #1")
        }

        if (this.state.select2 === "No" && this.state.text2 === "") {
            messages.push("Reason #2")
        }

        if (this.state.select3 === "Yes" && this.state.text3 === "") {
            messages.push("Reason #3")
        }

        if (messages.length === 0) {
            this.onUpdateInvest();
        } else {
            let message = "";
            messages.forEach(element => {
                message = message + element + ', '
            })

            let s = 'Please fill in the following field(s): ' + message.slice(0, -2);
            toast.error(s);
        }
    }

    onUpdateInvest = () => {
        let payTypeId;

        if (this.state.payTypeId === "") {
            payTypeId = 0;
        } else {
            payTypeId = this.state.payTypeId
        }

        let data = {
            investmentId: this.state.investment.id,
            exception: this.state.exception,
            select1: this.state.select1,
            select2: this.state.select2,
            select3: this.state.select3,
            select4: this.state.select4,
            select5: this.state.select5,
            select6: this.state.select6,
            text1: this.state.text1,
            text2: this.state.text2,
            text3: this.state.text3,
            text4: this.state.text4,
            investorStatus: this.state.investorStatus,
            payTypeId: payTypeId,
            amount: this.state.amount
        }

        InvestmentService.updateInvestmentData(data);

        if ((this.state.documentsKycAml.length !== 0 && this.state.investment.investmentStatus === "PENDING_KYC_AML")
            || (this.state.documentsKycAml.length !== 0 && this.state.investment.investmentStatus === "REJECTED") ||
            (this.state.documentsKycAml.length !== 0 && this.state.investment.investmentStatus === "APPROVED_PENDING_DOCS")) {
            if ((this.state.select2 !== "Yes" && this.state.select3 === "No" && this.state.select1 === "No" && this.state.exception === "Exceptions") ||
                (this.state.select2 !== "Yes" && this.state.select3 === "No" && this.state.exception === "No Exceptions")) {
                if (this.state.investment.investmentStatus !== "APPROVED_PENDING_DOCS") {
                    InvestmentService.updateInvestment("APPROVED_PENDING_DOCS", this.state.investment.id)
                }
            }
            if ((this.state.select1 === "Yes" || this.state.select2 === "Yes" || this.state.select3 === "Yes"
                || this.state.select1 !== "No") && this.state.exception === "Exceptions") {
                if (this.state.investment.investmentStatus !== "REJECTED") {
                    InvestmentService.updateInvestment("REJECTED", this.state.investment.id)
                }
            }
        }

        if (this.state.investment.investmentStatus === "APPROVED_PENDING_DOCS" && this.state.documentsPreFunding.length !== 0 && this.state.select4 === "Yes") {
            InvestmentService.updateInvestment("APPROVED_PENDING_FUNDS", this.state.investment.id)
        }

        window.location.href = "/investors/all";
    }

    onException = (e) => {
        let value = e.target.value;
        this.setState({
            exception: value
        })

        if (value !== "No Exceptions") {
            this.setState({
                select2: '',
                text2: '',
            })
        }

        if (value !== "Exceptions" && this.state.select1 === "No") {
            this.setState({
                select2: '',
                text2: '',
            })
        }

        if (value !== "Exceptions") {
            this.setState({
                select1: '',
                text1: '',
            })
        }

        if (value === "Exceptions") {
            this.setState({
                select1: 'No',
            })
        }
    };

    changeYesNo = (e, number) => {
        let value = e.target.value;
        this.setState({
                ['select' + number]: value
            }
        );

        if (number === 1 && value !== "No" && this.state.exception === "Exceptions") {
            this.setState({
                select2: '',
                text2: '',
            })

        }

        if (number === 1 && value !== "No") {
            this.setState({
                text1: '',
            })

        }

        if (number === 2 && value !== "No") {
            this.setState({
                text2: '',
            })

        }

        if (number === 3 && value !== "Yes") {
            this.setState({
                text3: '',
            })

        }
    };

    changeText = (e, number) => {
        let value = e.target.value;
        this.setState({
                ['text' + number]: value
            }
        )
    }

    fetchAllDocuments = (investmentId) => {
        InvestmentService.fetchAllDocuments(investmentId)
            .then(response => {
                let documentsKycAml = [];
                let documentsPreFunding = [];
                let documentsFunds = [];
                let documentsRescission = [];

                response.data.forEach(res => {
                    if (res.documentGroup.name === "investment-background") {
                        documentsKycAml.push(res.document)
                    }
                    if (res.documentGroup.name === "investment-pre-funding") {
                        documentsPreFunding.push(res.document)
                    }
                    if (res.documentGroup.name === "investment-proof-of-funds") {
                        documentsFunds.push(res.document)
                    }
                    if (res.documentGroup.name === "investment-rescission") {
                        documentsRescission.push(res.document)
                    }
                });
                this.setState({
                    documentsKycAml: documentsKycAml,
                    documentsPreFunding: documentsPreFunding,
                    documentsFunds: documentsFunds,
                    documentsRescission: documentsRescission
                });

                if (documentsKycAml.length === 0) {
                    this.setState({
                        exception: "",
                        select1: "",
                        select2: "",
                        select3: "",
                        select4: "",
                        select5: "",
                        select6: "",
                        text1: "",
                        text2: "",
                        text3: "",
                        text4: "",
                        amount: "",
                        payTypeId: 0,
                    })
                }
                if (documentsPreFunding.length === 0) {
                    this.setState({
                        select4: "",
                    })
                }

            })

    }

    fetchInvestment = (id) => {
        InvestmentService.fetchInvestment(id)
            .then(response => {
                this.setState({
                    investment: response.data
                })
            })
            .catch(error => {
            })
    }

    fetchInvestmentData = (id) => {
        InvestmentService.fetchInvestmentData(id)
            .then(response => {
                let data = response.data;

                this.setState({
                    exception: data.exception,
                    select1: data.select1,
                    select2: data.select2,
                    select3: data.select3,
                    select4: data.select4,
                    select5: data.select5,
                    select6: data.select6,
                    text1: data.text1,
                    text2: data.text2,
                    text3: data.text3,
                    text4: data.text4,
                    amount: data.amount,
                    payTypeId: data.payType ? data.payType.id : 0,
                })
            })
    };

    onFilePreview = (e, document) => {
        e.stopPropagation();
        let fileToPreview = document.orginalFilename.split('.');
        if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
            this.setState({
                fileToPreview: document.uuid + "." + fileToPreview[fileToPreview.length - 1],
                showPdfPreview: true
            });
        } else {
            this.setState({
                fileToPreview: document.uuid + "." + fileToPreview[fileToPreview.length - 1],
                showPreview: true
            });
        }
    };

    onDeleteDocument = (document) => {
        InvestmentService.deleteDocument(this.state.investment.id, document.id)
            .then(response => {
                toast.success("The document was successfully deleted!");
                this.fetchAllDocuments(this.state.investment.id);
            })
    };


    handleClose = (e) => {
        this.setState({
            showPreview: false
        })
    };

    handlePdfClose = (e) => {
        this.setState({
            showPdfPreview: false
        })
    };

    onLink = () => {
        window.open("/investor/investor-status/" + this.state.investment.user.id)
    }

    approveInvestment = () => {
        InvestmentService.updateInvestment("APPROVED", this.state.investment.id)
            .then(response => {
                if (this.props.location.state) {
                    TaskService.deleteTask(Number.parseInt(this.props.location.state.taskId))
                        .then(response => {
                        })
                        .catch(error => {
                        })
                }

                window.location.href = "/investors/all";
                toast.success('Investment approved.')
            })
            .catch(error => {
            })
    }


    render() {
        return (
            <>
                <PdfPreviewModal
                    className="modal z-index-100"
                    show={this.state.showPdfPreview}
                    file={this.state.fileToPreview}
                    close={this.handlePdfClose}/>

                <ImagePreviewModal
                    className="modal z-index-100"
                    show={this.state.showPreview}
                    file={this.state.fileToPreview}
                    close={this.handleClose}/>

                <form>
                    <Grid container spacing={2}
                          style={{paddingTop: 80}}>
                        {this.state.investment ? (
                            <>
                                <Grid item lg={12}>
                                    <Typography variant="h5">
                                        Manage investment
                                    </Typography>

                                    <Divider/>
                                </Grid>

                                <Grid item lg={12}>
                                    <Typography variant="h6">
                                        Offer: {this.state.investment.dealName}
                                    </Typography>

                                    <Typography variant="subtitle2">
                                        {this.state.investment.investmentType} INVESTMENT #{this.state.investment.id}
                                    </Typography>
                                </Grid>

                                <Grid item lg={12}>
                                    <Typography>
                                        Status: {this.state.investment.status}
                                    </Typography>
                                </Grid>

                                <Grid item lg={12}>
                                    <Box mt={3}>
                                        <Typography>
                                            Investor: {this.state.investment.investorFirstName} {this.state.investment.investorLastName}
                                        </Typography>

                                        <Typography variant="caption">
                                            Amount: {this.state.investment.amount}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        ) : null}

                        <Grid item lg={12}
                              style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={!!this.state.investment.dateApproved}
                                onClick={this.approveInvestment}>
                                Approve investment
                            </Button>

                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                style={{color: 'white', marginLeft: 5}}
                                onClick={this.checkFields}>
                                Update
                            </Button>
                        </Grid>

                        <Grid item lg={6}>
                            <Card raised>
                                <CardContent>
                                    <Box p={3}>
                                        <Typography variant="h6">
                                            KYC/AML
                                        </Typography>

                                        <Box my={3}>
                                            <Typography variant="button">
                                                Background Check Docs:
                                            </Typography>
                                        </Box>

                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            Document Filename
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            Actions
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {this.state.documentsKycAml.map(document => {
                                                        return (
                                                            <TableRow key={document.id}>
                                                                <TableCell align="left">
                                                                    {document.orginalFilename}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <IconButton
                                                                        color="primary"
                                                                        variant="contained"
                                                                        type={"button"}
                                                                        onClick={(e) => this.onFilePreview(e, document)}>
                                                                        <VisibilityIcon/>
                                                                    </IconButton>

                                                                    <IconButton
                                                                        color="primary"
                                                                        type={"button"}
                                                                        onClick={() => this.onDeleteDocument(document)}>
                                                                        <DeleteIcon/>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <Box pt={3}>
                                            <Typography variant="subtitle2">
                                                Please upload file
                                            </Typography>

                                            <InvestmentUploadDocument
                                                accept={'application/pdf,image/png,image/jpeg'}
                                                documentGroupCode={'investment-background'}
                                                onFileSelected={this.saveDocument}
                                                className="state-id front weight-100"
                                                fileNumber="file-6"
                                                wrapperClass=""/>
                                        </Box>

                                        <Box my={3}>
                                            <Typography variant="subtitle2">
                                                Result of Background Checks:
                                            </Typography>

                                            <FormControl fullWidth variant="outlined">
                                                <Select
                                                    native
                                                    name={'deal'}
                                                    disabled={this.state.documentsKycAml.length === 0}
                                                    onChange={(e) => this.onException(e)}
                                                >
                                                    <option selected/>

                                                    {this.state.exceptions.map(exception => {
                                                        return (<option
                                                            key={exception}
                                                            selected={this.state.exception === exception}
                                                            value={exception}>{exception}
                                                        </option>)
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box>
                                            <Typography variant="subtitle2">
                                                Do these exceptions preclude this user from making this
                                                investment?
                                            </Typography>

                                            <FormControl fullWidth variant="outlined">
                                                <Select
                                                    native
                                                    disabled={this.state.documentsKycAml.length === 0 || this.state.exception !== "Exceptions"}
                                                    name={'deal'}
                                                    onChange={(e) => this.changeYesNo(e, 1)}>

                                                    <option selected/>

                                                    {this.state.options1.map(option => {
                                                        return (<option
                                                            key={option}
                                                            selected={this.state.select1 === option}
                                                            value={option}>{option} </option>)
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box my={3}>
                                            <Typography variant="subtitle2">
                                                If No, REQUIRED: Why are you allowing this investor
                                                to invest anyway?
                                            </Typography>

                                            <TextField
                                                multiline
                                                rows={4}
                                                fullWidth
                                                variant="outlined"
                                                value={this.state.text1}
                                                placeholder="Reason #1"
                                                onChange={(e) => this.changeText(e, 1)}
                                                readOnly={this.state.documentsKycAml.length === 0 || this.state.exception !== "Exceptions" || this.state.select1 !== "No"}/>
                                        </Box>

                                        <Typography variant="caption">
                                            Due to Disclosures by the User please Review this User's
                                            <Button onClick={this.onLink}>
                                                Investor Status (click)
                                            </Button>
                                        </Typography>

                                        <Box my={3}>
                                            <Typography variant="subtitle2">
                                                Does this user's Investor Status preclude this
                                                user from making this investment?
                                            </Typography>

                                            <FormControl fullWidth variant="outlined">
                                                <Select
                                                    native
                                                    fullWidth
                                                    disabled={
                                                        (this.state.exception === "No Exceptions" && this.state.investorStatus) ? false : (this.state.exception === "Exceptions" && this.state.select1 === "No" && this.state.investorStatus) ? false : true
                                                    }
                                                    name={'deal'}
                                                    onChange={(e) => this.changeYesNo(e, 2)}
                                                >
                                                    <option selected></option>

                                                    {this.state.options1.map(option => {
                                                        return (<option
                                                            key={option}
                                                            selected={this.state.select2 === option}
                                                            value={option}>{option} </option>)
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box my={3}>
                                            <Typography variant="subtitle2">
                                                Is there any other reason the user cannot
                                                make this investment?
                                            </Typography>

                                            <FormControl fullWidth variant="outlined">
                                                <Select
                                                    native
                                                    disabled={this.state.select1 === "Yes" && this.state.select2 === "Yes"}
                                                    name={'deal'}
                                                    onChange={(e) => this.changeYesNo(e, 3)}
                                                >
                                                    <option selected></option>

                                                    {
                                                        this.state.options.map(option => {
                                                            return (<option
                                                                key={option}
                                                                selected={this.state.select3 === option}
                                                                value={option}>{option} </option>)
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Typography variant="subtitle2">
                                            If Yes, REQUIRED: Please Describe
                                            Reason Below:
                                        </Typography>

                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            rows={4}
                                            value={this.state.text3}
                                            placeholder="Reason #3"
                                            onChange={(e) => this.changeText(e, 3)}
                                            readOnly={this.state.select3 !== "Yes"}/>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item lg={6}>
                            <Card raised>
                                <CardContent>
                                    <Box p={3}>
                                        <Typography variant="h6">
                                            Pre-Funding Docs (Subscription Agreement, Big Boy Letter, etc.)
                                        </Typography>

                                        <Box my={3}>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Document Filename</TableCell>
                                                            <TableCell>Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <tbody>

                                                    {this.state.documentsPreFunding.map(document => {
                                                        return (
                                                            <TableRow key={document.id}>
                                                                <TableCell
                                                                    align="left">{document.orginalFilename}</TableCell>
                                                                <TableCell align="left">
                                                                    <IconButton
                                                                        type={"button"}
                                                                        color="primary"
                                                                        variant="contained"
                                                                        onClick={(e) => this.onFilePreview(e, document)}>
                                                                        <VisibilityIcon/>
                                                                    </IconButton>

                                                                    <IconButton
                                                                        color="primary"
                                                                        type={"button"}
                                                                        onClick={() => this.onDeleteDocument(document)}>
                                                                        <DeleteIcon/>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                    }
                                                    </tbody>
                                                </Table>
                                            </TableContainer>


                                            <Box my={3}>
                                                <Typography variant="subtitle2">
                                                    Please upload file
                                                </Typography>

                                                <InvestmentUploadDocument
                                                    accept={'application/pdf,image/png,image/jpeg'}
                                                    documentGroupCode={'investment-pre-funding'}
                                                    onFileSelected={this.saveDocument}
                                                    className="state-id front weight-100"
                                                    fileNumber="file-7"
                                                    wrapperClass=""/>
                                            </Box>


                                            <Typography variant="subtitle2">
                                                Is all pre-funding documentation for this investment fully
                                                executed
                                                and on file in this portal?
                                            </Typography>

                                            <FormControl fullWidth variant="outlined">
                                                <Select
                                                    native
                                                    disabled={!(this.state.documentsPreFunding.length !== 0)}
                                                    name={'deal'}
                                                    onChange={(e) => this.changeYesNo(e, 4)}
                                                >
                                                    <option selected/>

                                                    {this.state.options.map(option => {
                                                        return (<option
                                                            key={option}
                                                            selected={this.state.select4 === option}
                                                            value={option}>{option} </option>)
                                                    })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box>
                                            <Divider/>

                                            <Box mt={3} mb={3}>
                                                <Typography variant="button">
                                                    Incoming Funds
                                                </Typography>
                                            </Box>

                                            <Typography variant="subtitle2">
                                                Proof of Funds Docs:
                                            </Typography>
                                            <Box>

                                                <TableContainer component={Paper}>
                                                    <Table aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="left">
                                                                    Document Filename
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    Actions
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>

                                                        <tbody>
                                                        {this.state.documentsFunds.map(document => {
                                                            return (
                                                                <>
                                                                    <TableRow key={document.id}>
                                                                        <TableCell
                                                                            align="left">
                                                                            {document.orginalFilename}
                                                                        </TableCell>

                                                                        <TableCell align="right">
                                                                            <IconButton
                                                                                type={"button"}
                                                                                onClick={(e) => this.onFilePreview(e, document)}>
                                                                                <VisibilityIcon/>
                                                                            </IconButton>

                                                                            <IconButton
                                                                                type={"button"}
                                                                                onClick={() => this.onDeleteDocument(document)}>
                                                                                <DeleteIcon/>
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            )
                                                        })
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </TableContainer>

                                                <Box my={2}>
                                                    <Typography variant="subtitle2">
                                                        Please upload file</Typography>

                                                    <InvestmentUploadDocument
                                                        accept={'application/pdf,image/png,image/jpeg'}
                                                        documentGroupCode={'investment-proof-of-funds'}
                                                        onFileSelected={this.saveDocument}
                                                        disabled={true}
                                                        className="state-id front"
                                                        fileNumber="file-8"
                                                        wrapperClass=""/>
                                                </Box>
                                            </Box>

                                            <Box my={2}>
                                                <Typography variant="subtitle2">
                                                    Has the issuer received the funds from the investor?
                                                </Typography>

                                                <FormControl fullWidth variant="outlined">
                                                    <Select
                                                        native
                                                        disabled={true}
                                                        name={'deal'}
                                                        onChange={(e) => this.changeYesNo(e, 5)}>
                                                        <option selected></option>

                                                        {
                                                            this.state.options.map(option => {
                                                                return (<option
                                                                    key={option}
                                                                    selected={this.state.selected5 === option}
                                                                    value={option}>{option} </option>)
                                                            })
                                                        }

                                                    </Select>
                                                </FormControl>
                                            </Box>

                                            <Box my={2}>
                                                <Typography variant="subtitle2">
                                                    What amount of which currency was actually funded?
                                                </Typography>

                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    name={'amount'}
                                                    readOnly={true}
                                                    id={'amount'}
                                                    type="text"
                                                    placeholder="0.0"
                                                    onChange={(e) => this.onChangeMoney(e)}/>
                                            </Box>
                                        </Box>

                                        <Box my={2}>
                                            <Divider/>
                                            <Box mt={3} mb={3}>
                                                <Typography variant="button">Rescission</Typography>
                                            </Box>

                                            <Typography variant="subtitle2">Rescission Docs: </Typography>

                                            <TableContainer component={Paper}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">Document
                                                                Filename</TableCell>
                                                            <TableCell align="right">Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {
                                                            this.state.documentsRescission.map(document => {
                                                                return (

                                                                    <TableRow key={document.id}>
                                                                        <TableCell
                                                                            align="left">{document.orginalFilename}</TableCell>

                                                                        <TableCell align="right">

                                                                            <IconButton
                                                                                type={"button"}
                                                                                onClick={(e) => this.onFilePreview(e, document)}>
                                                                                <VisibilityIcon/>
                                                                            </IconButton>

                                                                            <IconButton
                                                                                type={"button"}
                                                                                onClick={() => this.onDeleteDocument(document)}><DeleteIcon/>
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>

                                                                )
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>


                                            <Box my={2}>
                                                <Typography variant="subtitle2">Please upload file</Typography>

                                                <InvestmentUploadDocument
                                                    accept={'application/pdf,image/png,image/jpeg'}
                                                    documentGroupCode={'investment-rescission'}
                                                    onFileSelected={this.saveDocument}
                                                    className="state-id front weight-100"
                                                    fileNumber="file-9"
                                                    wrapperClass=""/>
                                            </Box>


                                            <Box>
                                                <Typography variant="subtitle2">
                                                    Has the investor received their funds back from the issuer?
                                                </Typography>

                                                <FormControl fullWidth variant="outlined">
                                                    <Select
                                                        native
                                                        disabled={true}
                                                        name={'deal'}
                                                        onChange={(e) => this.changeYesNo(e, 6)}>

                                                        <option selected></option>
                                                        {
                                                            this.state.options.map(option => {
                                                                return (<option
                                                                    key={option}
                                                                    selected={this.state.select6 === option}
                                                                    value={option}>{option} </option>)
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>

                                        </Box>

                                        <Typography variant="subtitle2">General notes on this investment (if
                                            any):</Typography>

                                        <TextField
                                            multiline
                                            fullWidth
                                            variant="outlined"
                                            rows={4}
                                            readOnly={true}
                                            value={this.state.text4}
                                            onChange={(e) => this.changeText(e, 4)}/>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
}


export default withStyles(styles)(InvestmentDetailsPage);