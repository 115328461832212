import React, {Component} from 'react';
import Checkbox from '../../../components/input/Checkbox';
import Textarea from '../../../components/input/Textarea';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import {Button} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";

class InvestorStatusPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            'A_MEMBER_OR_EMP': [],
            'EMPL_BROKER': [],
            'SENIOR_OFFICER_BANK': [],
            'EMPLOYEE_INVESTMENT': [],
            'EMPLOYEE_STATE': [],
            'PERSON_SEC': [],
            'PERSON_CONTR': [],
            'RELATIONSHIP_INST': [],
            step: null,
            nonMandatoryFields: ['A_MEMBER_OR_EMP', 'EMPL_BROKER', 'SENIOR_OFFICER_BANK', 'EMPLOYEE_INVESTMENT', 'EMPLOYEE_STATE', 'PERSON_SEC', 'PERSON_CONTR', 'IF_CHECKED_ANY']
        };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        const step = this.props.steps.filter(step => step.step.id === 'STEP_INVESTOR_STATUS')[0]

        this.setState({
            step: step
        })

        this.setState({
            loader: true
        })
        this.props.fetchAttributeOptions('A_MEMBER_OR_EMP').then((data) => {
            this.setState({
                ...this.state,
                'A_MEMBER_OR_EMP': data.data
            });
        });

        this.props.fetchAttributeOptions('EMPL_BROKER').then((data) => {
            this.setState({
                ...this.state,
                'EMPL_BROKER': data.data
            });
        });

        this.props.fetchAttributeOptions('SENIOR_OFFICER_BANK').then((data) => {
            this.setState({
                ...this.state,
                'SENIOR_OFFICER_BANK': data.data
            });
        });

        this.props.fetchAttributeOptions('EMPLOYEE_INVESTMENT').then((data) => {
            this.setState({
                ...this.state,
                'EMPLOYEE_INVESTMENT': data.data
            });
        });

        this.props.fetchAttributeOptions('EMPLOYEE_STATE').then((data) => {
            this.setState({
                ...this.state,
                'EMPLOYEE_STATE': data.data
            });
        });

        this.props.fetchAttributeOptions('PERSON_SEC').then((data) => {
            this.setState({
                ...this.state,
                'PERSON_SEC': data.data
            });
        });

        this.props.fetchAttributeOptions('PERSON_CONTR').then((data) => {
            this.setState({
                ...this.state,
                'PERSON_CONTR': data.data
            });
        });

        this.props.fetchAttributeOptions('RELATIONSHIP_INST').then((data) => {
            this.setState({
                ...this.state,
                'RELATIONSHIP_INST': data.data
            });
        });
    }

    nextStep(e) {
        e.preventDefault();
        this.props.setRegistrationStatus('STEP_FINAL_SUBMISSION')
            .then(res => {
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                this.props.history.push('/final-submission');
            }).catch(err => {
        });
        /*this.props.fetchInvestorStatusByStep('STEP_INVESTOR_STATUS')
            .then(response => {
                if(this.props.validate(this.props.investorData, response.data)) {
                    this.props.history.push('/final-submission');
                }

                else alert('All fields must be filled');
            }).catch(error => {
            console.log(error);
        });*/
    }

    changeStep(e, step, path) {

        e.preventDefault();
        this.props.fetchInvestorStatusByStep('STEP_INVESTOR_STATUS')
            .then(response => {
                let newNonMandatoryFields = [...this.state.nonMandatoryFields];
                this.state.nonMandatoryFields.forEach(f => {
                    if (f !== 'IF_CHECKED_ANY') {
                        if (this.props.investorData[f].chosenValue === 'true')
                            newNonMandatoryFields = newNonMandatoryFields.filter(f => f !== 'IF_CHECKED_ANY');
                    }
                });

                if (this.props.validate(this.props.investorData, response.data, newNonMandatoryFields)) {
                    this.navigate(e, step, path);
                } else {
                    alert('Please fill the required fields');
                }
            }).catch(err => {
            console.log(err);
        });
    }

    navigate(e, step, path) {
        e.preventDefault();

        this.props.setRegistrationStatus(step)
            .then(res => {
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                this.props.history.push(path);
            }).catch(err => {
        });
    }

    render() {
        return (
            <>

                <Grid container spacing={3}
                      justify="center"
                      alignItems="center">
                    <Grid item lg={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}
                                  style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    variant='contained'
                                    color={"primary"}
                                    // style={{
                                    //     margin: 15
                                    // }}
                                    startIcon={<ClearIcon/>}
                                    disabled={this.state.step?.status !== 'APPROVED'}
                                    onClick={(e) => this.props.rejectStep('STEP_INVESTOR_STATUS')}>
                                    Reject
                                </Button>

                                <Button
                                    variant='contained'
                                    color='secondary'
                                    style={{marginLeft: 15}}
                                    startIcon={<CheckIcon/>}
                                    disabled={this.state.step?.status === 'APPROVED'}
                                    onClick={() => this.props.approveStep('STEP_INVESTOR_STATUS')}>
                                    {
                                        this.state.step?.status === 'APPROVED'
                                            ? 'Approved'
                                            : 'Approve'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={8} xs={12}>
                        <Card>
                            <CardContent>
                                <Grid item lg={23} xs={12}>
                                    <Typography variant='h6' style={{fontSize: 18}}>Check all applicable boxes and
                                        provide an explanation
                                        in the space provided if
                                        you or
                                        if an immediate family member (spouse, brother, sister, parent, child,
                                        mother-in-law, father-in-law, sister-in-law, brother-in-law,
                                        daughter-in-law,
                                        son-in-law), or any other person who supports you or who you support to a
                                        material
                                        extent, or an additional account holder, is a described person below:
                                    </Typography>
                                    <Divider/>
                                </Grid>


                                <Grid container spacing={3} style={{paddingTop: 24}}>
                                    <Grid item lg={12} xs={12}>
                                        <form>
                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    label={'A member or employee of any securities, options or commodities\n' +
                                                    '                                                    Exchange or other Self-Regulatory Organization including a\n' +
                                                    '                                                    registered securities association, registered clearing\n' +
                                                    '                                                    organization or the Municipal Securities Rulemaking Board.'}
                                                    attributeCode="A_MEMBER_OR_EMP"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['A_MEMBER_OR_EMP']}
                                                    options={this.state['A_MEMBER_OR_EMP']}
                                                    attributes={[]}/>
                                            </div>


                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    label={' An employee of a broker/dealer or other member of the Financial\n' +
                                                    '                                                    Industry Regulatory Authority (FINRA).'}
                                                    attributeCode="EMPL_BROKER"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['EMPL_BROKER']}
                                                    options={this.state['EMPL_BROKER']}
                                                    attributes={[]}/>
                                            </div>


                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    label={'A senior officer of a bank, savings and loan institution,\n' +
                                                    '                                                    insurance company, investment company, or investment advisory\n' +
                                                    '                                                    firm, or other financial institution.'}
                                                    attributeCode="SENIOR_OFFICER_BANK"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['SENIOR_OFFICER_BANK']}
                                                    options={this.state['SENIOR_OFFICER_BANK']}
                                                    attributes={[]}/>
                                            </div>


                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    label={'An employee of an investment advisor.'}
                                                    attributeCode="EMPLOYEE_INVESTMENT"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['EMPLOYEE_INVESTMENT']}
                                                    options={this.state['EMPLOYEE_INVESTMENT']}
                                                    attributes={[]}/>
                                            </div>


                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    label={'An employee of a state or federal securities regulator.'}
                                                    attributeCode="EMPLOYEE_STATE"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['EMPLOYEE_STATE']}
                                                    options={this.state['EMPLOYEE_STATE']}
                                                    attributes={[]}/>
                                            </div>


                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    label={'A person in the securities department of any of the entities\n' +
                                                    '                                                    listed above, or a person in a position to influence, or whose\n' +
                                                    '                                                    activities directly or indirectly involve, or are related to, the\n' +
                                                    '                                                    function of buying or selling securities for any such entites.'}
                                                    attributeCode="PERSON_SEC"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['PERSON_SEC']}
                                                    options={this.state['PERSON_SEC']}
                                                    attributes={[]}/>
                                            </div>


                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    label={'A person who has contributed to the equity or capital of a\n' +
                                                    '                                                    broker/dealer, directly or indirectly, in an amount that exceeds\n' +
                                                    '                                                    10% of the broker/dealer\'s equity or capital.'}
                                                    attributeCode="PERSON_CONTR"
                                                    pushInvestorChange={this.props.pushInvestorChange}
                                                    defaultValue={this.props.investorData['PERSON_CONTR']}
                                                    options={this.state['PERSON_CONTR']}
                                                    attributes={[]}/>
                                            </div>


                                            <Box mt="40px" mb="25px">
                                                <Typography variant="subtitle2">
                                                    {(this.props.investorData['A_MEMBER_OR_EMP'] && this.props.investorData['A_MEMBER_OR_EMP'].chosenValue === 'true') ||
                                                    (this.props.investorData['EMPL_BROKER'] && this.props.investorData['EMPL_BROKER'].chosenValue === 'true') ||
                                                    (this.props.investorData['SENIOR_OFFICER_BANK'] && this.props.investorData['SENIOR_OFFICER_BANK'].chosenValue === 'true') ||
                                                    (this.props.investorData['EMPLOYEE_INVESTMENT'] && this.props.investorData['EMPLOYEE_INVESTMENT'].chosenValue === 'true') ||
                                                    (this.props.investorData['EMPLOYEE_STATE'] && this.props.investorData['EMPLOYEE_STATE'].chosenValue === 'true') ||
                                                    (this.props.investorData['PERSON_SEC'] && this.props.investorData['PERSON_SEC'].chosenValue === 'true') ||
                                                    (this.props.investorData['PERSON_CONTR'] && this.props.investorData['PERSON_CONTR'].chosenValue === 'true') ?
                                                        'If you checked any of the above, please explain in detail: *' :
                                                        'If you checked any of the above, please explain in detail:'
                                                    }
                                                </Typography>

                                                <Textarea
                                                    defaultValue={this.props.investorData['IF_CHECKED_ANY']}
                                                    attributeCode="IF_CHECKED_ANY"
                                                    onBlurHandler={this.props.pushInvestorChange}
                                                    className="input__custom"
                                                    readOnly={true}
                                                    rows="5"/>
                                            </Box>
                                        </form>

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default InvestorStatusPage;