import React, {Component} from 'react';
import InvestorService from '../../services/InvestorService';
import DocumentService from "../../services/DocumentService";
import AttributeService from "../../services/AttributeService";
import {toast} from 'react-toastify';
import FinalSubmissionPage from "./edit/FinalSubmissionPage";
import UserCommentsPanelComponent from "../../components/UserCommentsPanel";
import InstitutionInfoPage from "./edit/InstitutionInfoPage";
import BackgroundInfoPage from "./edit/BackgroundInfoPage";
import MessagePanelComponent from "../../components/MessagePanelOld";
import CrudTable from "../tables/CrudTable";
import InvestmentService from "../../services/InvestmentService";
import EntityTranslationService from "../../services/EntityTranslationService";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import {Card, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CardContent from "@material-ui/core/CardContent";
import UserService from "../../services/UserService";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class InvestorCompanyEdit extends Component {


    constructor(props) {
        super(props);

        this.state = {
            investor: {user: {}},
            id: null,
            investorData: {},
            key: 'institution-info',
            value: 0,
            filterInvestment: {
                'investor.id': -1
            },
            offeringUrl: '/rest/investment',
            steps: [],
            investmentsColumnNames: [
                {
                    id: 'dateCreated',
                    label: 'Investment Submitted',
                    type: 'date',
                    datePattern: "DD.MM.YYYY"
                },
                {
                    id: 'dateApproved',
                    label: 'Investment Approved',
                    type: 'date',
                    datePattern: "DD.MM.YYYY"
                },
                {
                    id: 'dealCode',
                    label: 'Deal code'
                },
                {
                    id: 'investmentStatus',
                    label: 'Status',
                    type: 'translation',
                    translate: EntityTranslationService.translateInvestmentStatus
                },
                {
                    id: 'amount',
                    label: 'Amount',
                    type: 'currency',
                    symbol: '$'
                },
                {
                    id: 'payType',
                    label: 'Pay Type'
                },
                {
                    id: 'firstName',
                    label: 'Investor First Name'
                },
                {
                    id: 'lastName',
                    label: 'Investor Last Name'
                },
                {
                    id: 'email',
                    label: 'E-mail',
                    type: 'link',
                    url: '/investors/edit',
                    paramLink: 'userId',
                    paramValue: 'email'
                }
            ],
        }
    }

    componentDidMount() {
        const {match: {params}} = this.props;
        this.setState({
            id: params.id
        }, () => {
            this.fetchInvestor(params.id);
        });
        this.fetchInvestorStatus(params.id);
        this.fetchUserSteps(params.id)

    }

    onBlurHandler(request) {
        let newInvestor = JSON.parse(JSON.stringify(this.state.investor));
        newInvestor[request.attributeCode] = request.value;
        this.setState({
            investor: newInvestor
        }, () => {
        });

    }

    pushInvestorChange = (request) => {
        request.investorId = this.state.investor.id;
        return InvestorService.saveAttributeData(request).then((data) => {
            let investorData = this.state.investorData;
            investorData[data.data.attributeCode] = data.data;
            this.setState({
                ...this.state,
                investorData: investorData
            })
        })

    };

    fetchInvestor = (id) => {
        InvestorService.fetchInvestorByUserId(id)
            .then(response => {
                this.setState({
                    investor: response.data,
                    filterInvestment: {
                        'investor.id': response.data.id
                    }
                });
            }).catch(error => {
            console.log(error);
        });
    };

    updateInvestorInfo = (request) => {
        InvestorService.updateInvestorInfo(request)
            .then(response => {
                this.setState({
                    investor: response.data
                });
                toast.success('Saved!')
            }).catch(error => {
            toast.error(error)
        });
    };

    deleteInvestorData = (request) => {
        InvestorService.deleteAttributeData(request)
            .then(response => {
                let investorData = this.state.investorData;
                investorData[request.attributeCode] = {};
                this.setState({
                    ...this.state,
                    investorData: investorData
                });
            }).catch(error => {
            console.log(error);
        });
    };

    onDocumentDelete = (groupId, attrCode) => {
        this.deleteInvestorData({
            attributeCode: attrCode
        });
    };

    saveDocument = (groupId, file, attrCode) => {
        DocumentService.saveDocument(groupId, file)
            .then(response => {
                this.pushInvestorChange({
                    value: response.data.filename,
                    attributeCode: attrCode
                });
            }).catch(error => {
            console.log(error);
        });
    };

    fetchInvestorStatus = (id) => {
        this.setState({
            loader: true
        })
        InvestorService.fetchInvestorStatusByUserId(id)
            .then(response => {
                console.log(response.data);
                this.setState({
                    investorData: response.data,
                    loader: false
                });
            })
            .catch(error => {
                console.log(error);
            })

    };

    fetchAttributeOptions = (code) => {
        return AttributeService.getAttributeOptions(code);
    };

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        });
    };

    fetchUserSteps = (id) => {
        this.setState({
            loader: true
        })

        UserService.fetchUserSteps(id)
            .then(response => {
                this.setState({
                    steps: response.data,
                    loader: false
                });
            })
            .catch(error => {
                console.log(error);
            })
    };

    approveUserStep = (step) => {
        UserService.updateUserStepStatus(this.state.investor.user.id,
            {
                step: step,
                status: 'APPROVED'
            })
            .then(response => {
                toast.success('Approved successfully');
                this.fetchUserSteps(this.state.investor.user.id)
            })
            .catch(error => {
                console.log(error);
            });
    }

    rejectUserStep = (step) => {
        UserService.updateUserStepStatus(this.state.investor.user.id,
            {
                step: step,
                status: 'PENDING_APPROVAL'
            })
            .then(response => {
                this.fetchUserSteps(this.state.investor.user.id)
                toast.success('Rejected successfully');
            })
            .catch(error => {
                console.log(error);
            });
    }


    render() {
        return (
            <>

                <Grid lg={12} xs={12} style={{paddingTop: 80}}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="simple tabs example">
                            <Tab label="Institution Info" {...a11yProps(0)} />
                            <Tab label="Documentation" {...a11yProps(1)} />
                            <Tab label="Disclosures" {...a11yProps(2)} />
                            <Tab label="Investments" {...a11yProps(3)} />
                            {/*<Tab label="Documentation" {...a11yProps(4)} />*/}
                            <Tab label="Messages/Comments" {...a11yProps(4)} />
                        </Tabs>
                    </AppBar>
                </Grid>
                <TabPanel value={this.state.value} index={0}>
                    <InstitutionInfoPage
                        {...this.props}
                        pushInvestorChange={this.pushInvestorChange}
                        updateInvestorInfo={this.updateInvestorInfo}
                        deleteInvestorData={this.deleteInvestorData}
                        investor={this.state.investor}
                        investorData={this.state.investorData}
                        fetchInvestor={(id) => this.fetchInvestor(id)}
                        fetchAttributeOptions={this.fetchAttributeOptions}
                        fetchInvestorStatusByStep={(step) => this.fetchInvestorStatusByStep(step)}
                        investorAttributeOptions={this.investorAttributeOptions}
                        validate={this.validate}
                        steps={this.state.steps}
                        approveStep={this.approveUserStep}
                        rejectStep={this.rejectUserStep}
                        loader={this.state.loader}
                    />
                </TabPanel>
                <TabPanel index={this.state.value} value={1}>
                    <BackgroundInfoPage pushInvestorChange={this.pushInvestorChange}
                                        updateInvestorInfo={this.updateInvestorInfo}
                                        deleteInvestorData={this.deleteInvestorData}
                                        onDocumentDelete={this.onDocumentDelete}
                                        investor={this.state.investor}
                                        investorData={this.state.investorData}
                                        saveDocument={this.saveDocument}
                                        steps={this.state.steps}
                                        approveStep={this.approveUserStep}
                                        rejectStep={this.rejectUserStep}
                                        fetchAttributeOptions={this.fetchAttributeOptions}
                                        fetchInvestorStatusByStep={(step) => this.fetchInvestorStatusByStep(step)}
                                        investorAttributeOptions={this.investorAttributeOptions}
                    />
                </TabPanel>
                <TabPanel index={this.state.value} value={2}>
                    <FinalSubmissionPage
                        pushInvestorChange={this.pushInvestorChange}
                        updateInvestorInfo={this.updateInvestorInfo}
                        deleteInvestorData={this.deleteInvestorData}
                        onDocumentDelete={this.onDocumentDelete}
                        investorData={this.state.investorData}
                        saveDocument={this.saveDocument}
                        fetchAttributeOptions={this.fetchAttributeOptions}
                        fetchInvestorStatusByStep={(step) => this.fetchInvestorStatusByStep(step)}
                        investorAttributeOptions={this.investorAttributeOptions}
                        steps={this.state.steps}
                        approveStep={this.approveUserStep}
                        rejectStep={this.rejectUserStep}
                    />
                </TabPanel>
                <TabPanel index={this.state.value} value={3}>
                    {this.state.filterInvestment['investor.id'] !== -1 ?
                        <CrudTable url={this.state.offeringUrl}
                            //ref={(c) => this.table = c}
                                   theadClassName="text-uppercase thead-orange"
                                   columns={this.state.investmentsColumnNames}
                                   filter={this.state.filterInvestment}
                                   sort={''}
                                   pageSize={10}
                                   transform={InvestmentService.transform}
                                   entityName={"Investment"}
                                   editModalBody="investment"
                                   canEdit={true}/>
                        : null}
                </TabPanel>

                <TabPanel index={this.state.value} value={4}>
                    <Grid container spacing={3} style={{paddingTop: 10}}>
                        <Grid item lg={6} xs={6}>
                            <Card>
                                <CardContent>
                                    <MessagePanelComponent
                                        {...this.props}
                                        user={this.state.investor.user}/>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={6} xs={6}>
                            <Card>
                                <CardContent>
                                    <UserCommentsPanelComponent user={this.state.investor.user}/>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
            </>
        );
    }
}

export default InvestorCompanyEdit;