import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";


const AccreditationDeleteModal = (props) => {
    let handleFormSubmit = (e) => {
        e.preventDefault();
        props.submit();
        props.close();
    };

    let close = (e) => {
        if (props.changeInput) {
            props.changeInput();
        }
        props.close();
    };

    return (
        <> <Dialog open={props.show}>
            <DialogTitle id="alert-dialog-title">CHANGE CONFIRMATION</DialogTitle>
            <DialogContent style={{overflow: 'hidden'}}>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Typography> Are you sure you want to void this Expiration Date?</Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <DialogActions>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleFormSubmit}>Yes, VOID EXPIRATION DATE
                            </Button>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={close}>Cancel
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>

            {/*<div id="investDeleteModal" className="modal modal-open" role="dialog" style={{*/}
            {/*    transform: props.show ? 'translateY(0vh)' : 'translateY(-100vh)',*/}
            {/*    display: props.show ? 'block' : 'none',*/}
            {/*    zIndex: 10000*/}
            {/*}}>*/}
            {/*    <div className="modal-dialog modal-lg" style={{*/}
            {/*        zIndex: 100*/}
            {/*    }}>*/}
            {/*        <div className="modal-content">*/}
            {/*            <form onSubmit={props.submit}>*/}
            {/*                <div className="modal-header">*/}
            {/*                    <h4 className="modal-title">Change confirmation</h4>*/}
            {/*                </div>*/}
            {/*                <div className="modal-body">*/}

            {/*                    <div className="row">*/}
            {/*                        <div*/}
            {/*                            className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 text-center">*/}
            {/*                            <label className="weight400 text-upper control-label control-label-xl">*/}
            {/*                                Are you sure you want to void this Expiration Date?</label>*/}
            {/*                        </div>*/}

            {/*                    </div>*/}

            {/*                </div>*/}
            {/*                <div className="modal-footer text-center">*/}
            {/*                    <button type="submit" className="btn btn-orange btn-xl" data-dismiss="modal"*/}
            {/*                            onClick={handleFormSubmit}>*/}
            {/*                        Yes, Void this Accreditation*/}
            {/*                    </button>*/}
            {/*                    <button type="button" className="btn btn-danger btn-xl"*/}
            {/*                            data-dismiss="modal"*/}
            {/*                            onClick={close}>Cancel*/}
            {/*                    </button>*/}
            {/*                </div>*/}
            {/*            </form>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </>
    )
}

export default AccreditationDeleteModal;