import React, {useState} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import InputField from "../../components/Molecules/InputField";
import {toast} from "react-toastify";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

const InviteIssuerModal = (props) => {

    const [issuerEmail, setIssuerEmail] = useState('')
    const [errors, setErrors] = useState({})

    const handleValidation = () => {
        let errors = {}
        let valid = true

        if (!issuerEmail) {
            valid = false
            errors.issuerEmail = 'Cannot be empty'
        } else if (!validateEmail(issuerEmail)) {
            valid = false
            errors.issuerEmail = 'Invalid email address format'
        }

        setErrors(errors)

        return valid
    }

    const validateEmail = (email) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return pattern.test(email);
    }

    const handleSubmit = () => {
        if (handleValidation()) {
            props.submit(issuerEmail);
        } else {
            toast.error('Check required field')
        }
    };

    const handleClose = () => {
        props.close();
    };

    return (
        <Dialog
            open={props.show}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Invite new Issuer
                <Divider/>
            </DialogTitle>

            <DialogContent>
                <Grid item lg={12}>
                    <InputField
                        title={'E-mail address'}
                        required={true}
                        value={issuerEmail}
                        type={'email'}
                        hasError={errors.issuerEmail}
                        errorMessage={errors.issuerEmail}
                        onChange={setIssuerEmail}/>
                </Grid>

                <DialogActions>
                    <Button
                        type="button"
                        data-dismiss="modal"
                        onClick={handleClose}>
                        Close
                    </Button>

                    <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        data-dismiss="modal"
                        onClick={handleSubmit}>
                        Invite
                    </Button>

                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default InviteIssuerModal;