import React, {Component} from "react";
import ImageGallery from 'react-image-gallery';
import {Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import parse from 'html-react-parser';


class PreviewPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            images: [],
        }
    }

    componentDidMount() {
        if (this.props.pageInfo.mainVideoLink) {
            let video = this.state.images;
            video.push({
                    original: this.props.pageInfo.mainVideoLink,
                    embedUrl: this.props.pageInfo.mainVideoLink,
                    renderItem: this.renderVideo.bind(this)
                }
            );

            this.setState({
                images: video
            })
        } else {
            this.props.images.forEach(img => {
                let images = this.state.images;
                images.push({
                    original: process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/cms/download/' + img.filename
                });

                this.setState({
                    images: images
                })
            })
        }
    }

    renderVideo(item) {
        return (
            <iframe
                title={item.embedUrl}
                width='100%'
                height='420'
                src={item.embedUrl}
                frameBorder='0'
                allowFullScreen>
            </iframe>
        )
    }

    handleClose = () => {
        this.props.close();
    };

    render() {
        return (
            <>
                <Dialog fullScreen
                        open={this.props.show}
                        onClose={this.handleClose}>
                    <AppBar position={"relative"}>
                        <Toolbar>
                            <IconButton edge="start"
                                        color="inherit"
                                        onClick={this.handleClose} aria-label="close">
                                <CloseIcon/>
                            </IconButton>

                            <Grid container
                                  justify={"center"}>
                                <Grid item>
                                    <Typography variant="h5">
                                        Preview
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>

                    <Grid container
                          spacing={3}
                          justify={"center"}
                          style={{marginTop: 50}}>
                        <Grid item lg={4} xs={4}>
                            <ImageGallery
                                items={this.state.images}
                                showThumbnails={false}
                                showFullscreenButton={false}
                                showPlayButton={false}
                                infinite={false}/>
                        </Grid>

                        <Grid item lg={3} xs={3}>
                            <Grid container spacing={3}>
                                <Grid item lg={12} xs={12}>
                                    <Typography variant="h3">
                                        {this.props.pageInfo.title}
                                    </Typography>

                                    <Typography>
                                        {this.props.pageInfo.description}
                                    </Typography>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Typography>
                                        Amount raised
                                    </Typography>
                                    <Typography>
                                        {this.props.pageInfo.amountRaised ? this.props.pageInfo.amountRaised : 0}
                                    </Typography>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Typography>
                                        Target Amount
                                    </Typography>
                                    <Typography>
                                        {this.props.pageInfo.target ? this.props.pageInfo.target : 0}
                                    </Typography>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Typography>
                                        Pre-Money valuation
                                    </Typography>
                                    <Typography>
                                        {this.props.pageInfo.preMoney ? this.props.pageInfo.preMoney : 0}
                                    </Typography>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Typography>
                                        Share Price
                                    </Typography>
                                    <Typography>
                                        {this.props.pageInfo.sharePrice ? this.props.pageInfo.sharePrice : 0}
                                    </Typography>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        type="button">
                                        SIGN UP TO INVEST
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={7} xs={7}>
                            {parse(this.props.bodyCode)}
                        </Grid>
                    </Grid>
                </Dialog>
            </>
        );
    }
}

export default PreviewPage;