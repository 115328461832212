import React from 'react';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";


const ConfirmNewInvestmentModal = (props) => {

    let handleFormSubmit = (e) => {
        e.preventDefault();
        props.submit(props.data);
        props.close();
    };

    let close = (e) => {
        props.close();
    };

    return (
        <>
            <Dialog open={props.show}
                    maxWidth="md"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">CHANGE CONFIRMATION</DialogTitle>
                <DialogContent style={{overflow: 'hidden'}}>
                    <Grid container spacing={3}>
                        <Grid item lg={12} xs={12}>
                            <Typography>Are you sure you want to submit this investment?</Typography>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <DialogActions>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleFormSubmit}>Yes, I'M SURE
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={close}>Cancel
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ConfirmNewInvestmentModal;