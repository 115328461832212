import React, {useEffect} from 'react';
import Iframe from 'react-iframe'
import {AUTH_TOKEN} from "../../shared/utility";
import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";


const PdfPreviewModal = (props) => {

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(!!props.show);
    })

    const PaperComponent = (props) => {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper {...props} />
            </Draggable>
        );
    }

    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            onClose={props.close}
            open={open}
            disableScrollLock
            disableBackdropClick
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title">

            <DialogTitle
                style={{cursor: 'move'}}
                id="draggable-dialog-title">
                File Preview
            </DialogTitle>

            <DialogContent dividers>
                <Iframe
                    url={
                        props.cms
                            ? process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/cms/download/' + props.file
                            + '?access_token=' + localStorage.getItem(AUTH_TOKEN)
                            + '&embedded=true'
                            : process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/' + props.file
                            + '?access_token=' + localStorage.getItem(AUTH_TOKEN)
                            + '&embedded=true'
                    }
                    width="100%"
                    height="500px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"/>
            </DialogContent>

            <DialogActions>
                <Button
                    type="button"
                    data-dismiss="modal"
                    onClick={props.close}>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    )


    // return (
    //     <>
    //         <div id="composeMessageModal" className="modal modal-open" role="dialog" style={{
    //             transform: props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
    //             display: props.show ? 'block' : 'none'
    //         }}>
    //             <div className="modal-dialog modal-lg float-right">
    //
    //                 <div className="modal-content">
    //                     <div className="modal-header">
    //                         <h4 className="modal-title">File Preview</h4>
    //                     </div>
    //                     <div className="container-fluid">
    //                         <div className={"row"}>
    //                             <div className={"col-md-12"}>
    //                                 <Iframe
    //                                     url={
    //                                         props.cms
    //                                         ? process.env.REACT_APP_DMS_GATEWAY + 'v1/api/document/cms/download/' + props.file + '?access_token=' + localStorage.getItem(AUTH_TOKEN) + '&embedded=true'
    //                                         : process.env.REACT_APP_DMS_GATEWAY + 'v1/api/document/download/' + props.file + '?access_token=' + localStorage.getItem(AUTH_TOKEN) + '&embedded=true'
    //                                     }
    //                                     width="100%"
    //                                     height="450px"
    //                                     id="myId"
    //                                     className="myClassname"
    //                                     display="initial"
    //                                     position="relative"/>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="modal-footer text-center">
    //                         <button type="button" className="btn btn-primary btn-xl" data-dismiss="modal"
    //                                 onClick={props.close}>close
    //                         </button>
    //                     </div>
    //                 </div>
    //
    //             </div>
    //
    //         </div>
    //
    //     </>
    // )
}

export default PdfPreviewModal;