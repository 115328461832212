import React, {useEffect, useState} from 'react';

import PhoneInput from "react-phone-input-2";

import 'react-phone-input-2/lib/bootstrap.css'

const InputPhone = (props) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        if (props.defaultValue && props.defaultValue.chosenValue) {
            setValue(props.defaultValue.chosenValue)
        }
    }, [props.defaultValue])

    const handleOnBlur = () => {
        props.onCnangeHandler({
            attributeCode: props.attributeCode,
            value: value
        });
    }

    const handleOnChange = (_value) => {
        setValue(_value)
    }

    return (
        <PhoneInput
            value={value}
            country={'us'}
            placeholder={''}
            inputClass='tel-input'
            disabled={props.disabled}
            containerClass="tel-input-container"
            onBlur={handleOnBlur}
            onChange={(value) => handleOnChange(value)}/>
    )
};

export default InputPhone;