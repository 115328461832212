import axios from '../custom-axios/backoffice';

const AttributeService = {

    fetchAttributes: () => {
        return axios.get(`/api/attributes`);
    },

    getAttributeOptions: (attrCode) => {
        return axios.get('/api/attribute_options/attribute_code/' + attrCode);
    },

    fetchAttributesByStep: (step) => {
        return axios.get(`/api/attributes/registration_status/${step}`);
    },

    transform: (data) => {
        return data.map(value => {
            return {
                id: value.id ? value.id : '',
                value: value.name ? value.name : '',
            }
        })
    }
}

export default AttributeService;