import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CompanyDocuments from "../../../components/input/CompanyDocuments";
import Box from "@material-ui/core/Box";
import Dropzone from "react-dropzone";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SaveIcon from '@material-ui/icons/Save';
import Button from "@material-ui/core/Button";
import InvestorService from "../../../services/InvestorService";
import DocumentService from "../../../services/DocumentService";
import PdfPreviewModal from "../../modal/PdfPreviewModal";
import DocxPreviewModal from "../../modal/DocxPreviewModal";
import ImagePreviewModal from "../../modal/ImagePreviewModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Moment from "react-moment";
import {AUTH_TOKEN} from "../../../shared/utility";
import IssuerService from "../../../services/IssuerService";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

class BackgroundInfoPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            documents: [],
            documentsToSave: [],
            isLoading: false,
            fileToPreview: null,
            showPreview: false,
            showDocxPreview: false,
            showPreviewImage: false,
            loader: true,
            noDocuments: false,
            documentBlockVerified: false,
            step: null
        }
    }

    componentDidMount() {
        const step = this.props.steps.filter(step => step.step.id === 'STEP_BACKGROUND_INFO')[0]

        this.setState({
            step: step
        })

        this.fetchDocuments();
    }

    fetchDocuments = () => {
        this.setState({
            loader: true
        })

        if (this.props.issuerId) {
            InvestorService.fetchByAttributeCodeAndIssuerId(this.props.attributeCode, this.props.issuerId)
                .then(data => {
                    this.setState({
                        documents: data.data,
                        noDocuments: data.data.length === 0,
                        loader: false,
                    })
                })
        } else if (this.props.investor) {
            InvestorService.fetchByAttributeCodeAndInvestorId('OTHER', this.props.investor.id)
                .then(data => {
                    this.setState({
                        documents: data.data,
                        noDocuments: data.data.length === 0,
                        loader: false,
                    })
                })
        }
    }

    saveDocument = (files) => {
        this.setState({isLoading: true});
        const groupId = 'other'

        DocumentService.saveDocumentMultiple(groupId, files)
            .then(response => {
                let files = response.data.map(file => {
                    return {
                        'id': file.id,
                        'filename': file.filename,
                        'originalFilename': file.originalFilename
                    }
                })

                this.setState(
                    {
                        'documentsToSave':
                            [...this.state.documentsToSave,
                                ...files
                            ]
                    })
            }).catch(error => {
            this.setState({isLoading: false});
        })
    }

    remove(document) {
        let documents = [...this.state.documentsToSave];
        let newDocuments = documents.filter(d => {
            return (d.id !== document.id);
        });
        this.setState({documentsToSave: newDocuments});
    }

    onFilePreview = (item) => {
        let fileToPreview = '';

        if (item.filename) {
            fileToPreview = item.filename.split('.');

            if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
                this.setState({
                    fileToPreview: item.filename,
                    showPreview: true
                });
            } else if (fileToPreview[fileToPreview.length - 1] === 'docx') {
                this.setState({
                    fileToPreview: item.filename,
                    showDocxPreview: true
                });
            } else {
                this.setState({
                    fileToPreview: item.filename,
                    showPreviewImage: true
                });
            }
        } else {
            fileToPreview = item.chosenValue.split('.');

            if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreview: true
                });
            } else if (fileToPreview[fileToPreview.length - 1] === 'docx') {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showDocxPreview: true
                });
            } else {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreviewImage: true
                });
            }
        }
    };

    startLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        })
    }

    handleClose = () => {
        this.setState({
            fileToPreview: null,
            showPreview: false
        })
    }

    handleCloseDocxModel = () => {
        this.setState({
            fileToPreview: null,
            showDocxPreview: false
        })
    }

    handleCloseImage = () => {
        this.setState({
            showPreviewImage: false
        })
    }

    submit = () => {
        this.state.documentsToSave.forEach(item => {
            this.pushInvestorChange({
                value: item.filename,
                attributeCode: 'OTHER',
                documentId: item.id
            })
        })

        this.setState({documentsToSave: []})
        this.fetchDocuments()
    }

    pushInvestorChange = (request) => {
        if (this.props.issuerId) {
            request.issuerId = this.props.issuerId;

            IssuerService.saveAttributeData(request)
                .then(data => {
                    this.fetchDocuments();
                    // toast.success("Updated!");
                })
        }
        if (this.props.investor) {
            request.investorId = this.props.investor.id;

            InvestorService.saveAttributeData(request)
                .then((data) => {
                    this.fetchDocuments();
                    // toast.success("Updated!");
                })
        }
    }


    render() {
        return (
            <>
                <PdfPreviewModal
                    className="modal"
                    show={this.state.showPreview}
                    file={this.state.fileToPreview}
                    close={this.handleClose}/>

                <DocxPreviewModal
                    className="modal"
                    show={this.state.showDocxPreview}
                    file={this.state.fileToPreview}
                    close={this.handleCloseDocxModel}/>

                <ImagePreviewModal
                    className="modal"
                    show={this.state.showPreviewImage}
                    file={this.state.fileToPreview}
                    close={this.handleCloseImage}/>

                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}
                                  style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    variant='contained'
                                    color={"primary"}
                                    // style={{
                                    //     margin: 15
                                    // }}
                                    startIcon={<ClearIcon/>}
                                    disabled={this.state.step?.status !== 'APPROVED'}
                                    onClick={(e) => this.props.rejectStep('STEP_BACKGROUND_INFO')}>
                                    Reject
                                </Button>

                                <Button
                                    variant='contained'
                                    color='secondary'
                                    style={{marginLeft: 15}}
                                    startIcon={<CheckIcon/>}
                                    disabled={this.state.step?.status === 'APPROVED'}
                                    onClick={() => this.props.approveStep('STEP_BACKGROUND_INFO')}>
                                    {
                                        this.state.step?.status === 'APPROVED'
                                            ? 'Approved'
                                            : 'Approve'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={6} xs={6}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} xs={12}>
                                        <Typography variant='h6'>
                                            Documentation
                                        </Typography>

                                        <Divider/>
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <CompanyDocuments
                                            headText={'Copy of Articles of Incorporation/Certificate of Domicile (Date of Formation)'}
                                            currentValue={this.props.investorData['COPY_OF_ARTICLES_OR_INC']}
                                            open={this.props.investorData['COPY_OF_ARTICLES_OR_INC']
                                            && this.props.investorData['COPY_OF_ARTICLES_OR_INC'][0].chosenValue}
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <CompanyDocuments
                                            headText={'Operating Agreement'}
                                            currentValue={this.props.investorData['OPERATING_AGREEMENT']}
                                            open={this.props.investorData['OPERATING_AGREEMENT']
                                            && this.props.investorData['OPERATING_AGREEMENT'][0].chosenValue}
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <CompanyDocuments
                                            headText={'Copy of SS4/tax doc that reflects companies EIN'}
                                            currentValue={this.props.investorData['COPY_OF_SS4']}
                                            open={this.props.investorData['COPY_OF_SS4']
                                            && this.props.investorData['COPY_OF_SS4'][0].chosenValue}
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <CompanyDocuments
                                            headText={'Copies of the driver\'s license/passport etc for POC & Officers'}
                                            currentValue={this.props.investorData['COPIES_OF_DRIVER_LICENSE']}
                                            open={this.props.investorData['COPIES_OF_DRIVER_LICENSE']
                                            && this.props.investorData['COPIES_OF_DRIVER_LICENSE'][0].chosenValue}
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <CompanyDocuments
                                            headText={'Institutional accreditation letter'}
                                            currentValue={this.props.investorData['INSITUTIONAL_ACC_LETTER']}
                                            open={this.props.investorData['INSITUTIONAL_ACC_LETTER']
                                            && this.props.investorData['INSITUTIONAL_ACC_LETTER'][0].chosenValue}
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <CompanyDocuments
                                            headText={'Utility bill or lease agreement'}
                                            currentValue={this.props.investorData['UTILITY_BILL']}
                                            open={this.props.investorData['UTILITY_BILL']
                                            && this.props.investorData['UTILITY_BILL'][0].chosenValue}
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <CompanyDocuments
                                            headText={'"Register of Managers" document, Memorandum of Association and BOD meeting'}
                                            currentValue={this.props.investorData['REGISTER_MANAGERS']}
                                            open={this.props.investorData['REGISTER_MANAGERS']
                                            && this.props.investorData['REGISTER_MANAGERS'][0].chosenValue}
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <CompanyDocuments
                                            headText={'FinCen CDD Form'}
                                            currentValue={this.props.investorData['FINCEN_CSS_fORM']}
                                            open={this.props.investorData['FINCEN_CSS_fORM']
                                            && this.props.investorData['FINCEN_CSS_fORM'][0].chosenValue}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={6} xs={6}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <Card>
                                    <CardContent>
                                        <Box className="upload__block--title"
                                             onClick={this.startLoading}>
                                            {this.state.isLoading
                                                ? <RemoveIcon/>
                                                : <AddIcon/>
                                            }

                                            <Box ml="8px" component="span">
                                                Add document
                                            </Box>
                                        </Box>

                                        <Dropzone multiple={true}
                                                  accept={'application/pdf,image/png,image/jpeg'}
                                                  onDrop={acceptedFiles => this.saveDocument(acceptedFiles)}>
                                            {({getRootProps, getInputProps}) => (
                                                <Box
                                                    mt="15px"
                                                    mb="15px">

                                                    <Box mb="15px" p="25px"
                                                         className={this.state.isLoading ? 'upload__view' : 'upload__hidden'}
                                                         style={{background: '#FCFCFC'}}>
                                                        <Box>
                                                            <Box>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    gutterBottom>
                                                                    Only JPG, PNG or PDF
                                                                    files. File must be less than 20 Mb

                                                                    <span className="required__circle">*</span>
                                                                </Typography>
                                                            </Box>

                                                            {this.state.documentsToSave.map(document => {
                                                                    return (
                                                                        <Box
                                                                            pt="20px"
                                                                            pb="5px"
                                                                            style={{borderColor: '#DFDFDF'}}
                                                                            borderBottom={1}
                                                                            display="flex"
                                                                            key={document.id}>

                                                                            <Box flexGrow={1}>
                                                                                {document.originalFilename}
                                                                            </Box>
                                                                            <Box>
                                                                                <Button
                                                                                    color="primary"
                                                                                    name={'file-01'}
                                                                                    type={"button"}
                                                                                    onClick={() => this.onFilePreview(document)}>
                                                                                    Preview
                                                                                </Button>

                                                                                <Button
                                                                                    style={{color: '#B71C1C'}}
                                                                                    name={'file-01'}
                                                                                    type={"button"}
                                                                                    onClick={(e) => this.remove(document)}>
                                                                                    Delete
                                                                                </Button>
                                                                            </Box>
                                                                        </Box>
                                                                    )
                                                                }
                                                            )}

                                                            {this.state.documentsToSave.length !== 0
                                                                ? <Box style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-end',
                                                                    paddingTop: 10
                                                                }}>

                                                                    <Button variant='contained'
                                                                            color='primary'
                                                                            onClick={this.submit}>
                                                                        Submit Documents
                                                                    </Button>

                                                                </Box>
                                                                : null
                                                            }
                                                        </Box>

                                                        <Box mt="25px" display="flex">
                                                            <Button onClick={this.startLoading}>
                                                                Cancel
                                                            </Button>

                                                            <Box ml="20px">
                                                                <input
                                                                    id={'file-01'}
                                                                    type={"file"}
                                                                    {...getInputProps()}
                                                                    className="custom-file-input"
                                                                    accept={'application/pdf,image/png,image/jpeg'}
                                                                    required>
                                                                </input>

                                                                <label htmlFor={'file-01'}>
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        endIcon={<PublishIcon/>}
                                                                        component="span">
                                                                        Upload document
                                                                    </Button>
                                                                </label>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Dropzone>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item lg={12} xs={12}>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={9} xs={9}>
                                                <Typography variant='h6'>
                                                    Uploaded Documents
                                                </Typography>
                                            </Grid>

                                            <Divider/>

                                            {this.state.loader
                                                ? <Grid item lg={12} xs={12}
                                                        style={{display: "flex", justifyContent: "center"}}>
                                                    <CircularProgress/>
                                                </Grid>
                                                : this.state.noDocuments
                                                    ? <Grid item lg={12} xs={12}>
                                                        <Typography>
                                                            No Documents
                                                        </Typography>
                                                    </Grid>
                                                    : <TableContainer>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Document Name</TableCell>
                                                                    <TableCell>Date Uploaded</TableCell>
                                                                    <TableCell width={257}/>
                                                                </TableRow>
                                                            </TableHead>

                                                            {this.state.documents.map(document => {
                                                                return (
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            {document.documentOriginalName}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {<Moment
                                                                                format={'LLL'}>
                                                                                {document.dateCreated}
                                                                            </Moment>
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <div>
                                                                                <Button
                                                                                    onClick={() => this.onFilePreview(document)}
                                                                                    variant='contained'
                                                                                    color='primary'
                                                                                    size='small'
                                                                                    startIcon={<VisibilityIcon/>}>
                                                                                    Preview
                                                                                </Button>

                                                                                <Button
                                                                                    onClick={() => window.open(process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/'
                                                                                        + document.chosenValue + '?access_token=' + localStorage.getItem(AUTH_TOKEN)
                                                                                        + '&embedded=false')}
                                                                                    type={"submit"}
                                                                                    variant='contained'
                                                                                    color='primary'
                                                                                    size='small'
                                                                                    style={{marginLeft: 6}}
                                                                                    startIcon={<SaveIcon/>}>
                                                                                    Download
                                                                                </Button>
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                            }
                                                        </Table>
                                                    </TableContainer>
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default BackgroundInfoPage;