import React, {Component} from 'react';
import Aux from '../../hoc/AuxWrapper';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

class MultiCheckbox extends Component {
    onChangeHandler = (checked) => {

        if (checked) {
            this.props.pushInvestorChange({
                attributeCode: this.props.attributeCode,
                value: this.props.optionValue,
                investorAttributeOptionId: this.props.optionId
            });

            // added for sectors state management because of multiple select input
            this.props.stateChange(this.props.optionValue, 'ADD')
        } else {
            this.props.deleteInvestorData({
                attributeCode: this.props.attributeCode,
                value: this.props.optionValue,
                investorAttributeOptionId: this.props.optionId,
                investorId: this.props.investor.id,
                userId: this.props.investor.user.id
            });

            this.props.stateChange(this.props.optionValue, 'REMOVE');

            this.forceUpdate();
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.defaultValue && Array.isArray(nextProps.defaultValue);
    }

    render() {
        return (
            <Aux>
                <FormControlLabel
                    label={this.props.label}
                    control={
                        <Checkbox
                            id={this.props.label}
                            disabled={this.props.disabled?this.props.disabled:false}
                            className=""
                            color="primary"
                            onChange={(e) => this.onChangeHandler(e.target.checked)}
                            checked={this.props.defaultValue && Array.isArray(this.props.defaultValue)  && this.props.defaultValue.find(v => v.chosenValue === this.props.optionValue)}
                            type="checkbox"/>
                    }/>

                {/*<input*/}
                {/*    id={this.props.label}*/}
                {/*    disabled={this.props.disabled?this.props.disabled:false}*/}
                {/*    className="same-mailing-address"*/}
                {/*    onChange={(e) => this.onChangeHandler(e.target.checked)}*/}
                {/*    checked={this.props.defaultValue && Array.isArray(this.props.defaultValue)  && this.props.defaultValue.find(v => v.chosenValue === this.props.optionValue)}*/}
                {/*    type="checkbox"/>*/}

                {/*<label for={this.props.label}>*/}
                {/*    {this.props.label}*/}
                {/*</label>*/}

            </Aux>
        );
    }
}

export default MultiCheckbox;