import React from 'react';

import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import Box from "@material-ui/core/Box";


export const InvestorsGraph = ({usersData}) => {
    const data = [
        {
            name: 'Waiting for Approval:', investors: usersData.investorsWaiting,
        },
        {
            name: 'Pending', investors: usersData.investorsPending,
        },
        {
            name: 'Pending KYC/AML:', investors: usersData.investorsPendingKyc,
        },
        {
            name: 'Active:', investors: usersData.investorsActive,
        },
        {
            name: 'Blocked:', investors: usersData.investorsBlocked,
        },
        {
            name: 'Removed:', investors: usersData.investorsRemoved,
        },
        {
            name: 'On Registration:', investors: usersData.investorsOnRegistration,
        },
    ];

    return (
        <Box pt="20px">
            <ResponsiveContainer height={400}>
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5, right: 0, left: 0, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis
                        dataKey="name"
                        style={{fontSize: 12}}/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Bar
                        dataKey="investors"
                        fill="#032F66"/>

                </BarChart>
            </ResponsiveContainer>
        </Box>
    )
}