import axios from '../custom-axios/backoffice';

const UserService = {
    transform: (users) => {
        return users.map(i => {
            return {
                id: i.id ? i.id : '',
                firstName: i.firstName ? i.firstName : '',
                lastName: i.lastName ? i.lastName : '',
                fullName: i.firstName || i.lastName ? `${i.firstName} ${i.lastName}` : '',
                email: i.email ? i.email : '',
                registrationStatus: i.registrationStatus ? i.registrationStatus : '',
                registrationType: i.registrationType ? i.registrationType.value : '',
                userGroup: i.userGroup ? i.userGroup : 'N/A',
                role_id: i.role ? i.role_id : '',
                role_name: i.role ? i.role_name : '',
                active: i.active ? i.active : '',
                dateCreated: i.dateCreated ? i.dateCreated : '',
                accountStatus: i.accountStatus ? i.accountStatus : '',
                dateRegistrationCompleted: i.dateRegistrationCompleted ? i.dateRegistrationCompleted : '',
                expirationDate: i.expirationDate ? i.expirationDate : '',
                regType: i.regType ? i.regType : '',
                offers: i.offers
            }
        })
    },

    fetchAllUsers: () => {
        return axios.get('rest/users')
    },

    fetchFilteredUsers: (sort, page, pageSize, filter) => {
        let filterOpt = [];

        if (filter) {
            filterOpt = Object.keys(filter).map(function (key) {
                if (filter[key] !== undefined && filter[key] !== '') return '&' + key + '=' + filter[key]

                return ''
            })
        }

        return axios.get(`/rest/users/paged?sort=${sort}&page=${page}&pageSize=${pageSize}` + filterOpt.join(''));
    },

    fetchCurrentUser: () => {
        return axios.get('/rest/users/current');
    },

    fetchUser: (id) => {
        return axios.get(`/rest/users/${id}`);
    },

    updateUser: (request) => {
        return axios.put(`/rest/users/`, request)
    },

    deleteUser: (id) => {
        return axios.delete('/rest/users/' + id);
    },

    deleteFullUser: (id) => {
        return axios.delete(`/rest/users/${id}/full`);
    },

    fetchUserSteps: (id) => {
        return axios.get(`/rest/users/${id}/steps`);
    },

    updateUserStepStatus: (id, body) => {
        return axios.put(`/rest/users/${id}/steps`, body);
    },

    addUserByAdmin: (body) => {
        return axios.post('/rest/users', body);
    },

    exportUsers: () => {
        axios({
            url: `${process.env.REACT_APP_BACKOFFICE_GATEWAY}/rest/users/export`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'users.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    },

}

export default UserService;