import React, {Component} from 'react';

import CMSService from "../../services/CMSService";
import {toast} from "react-toastify";
import PreviewPage from "./PreviewPage";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {NavLink} from "react-router-dom";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import PublishIcon from '@material-ui/icons/Publish';
import {Typography} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import TableContainer from "@material-ui/core/TableContainer";


class Cms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pages: [],
            oldPages: [],
            bodyCode: '',
            images: [],
            showPage: {},
            key: 'pages',
            edit: false,
            showPreview: false,
            columns: [
                // {
                //     id: 'regType',
                //     label: 'Reg Type Visibility'
                // },

                {
                    id: 'uri',
                    label: 'URL'
                },
                {
                    id: 'title',
                    label: 'Title'
                },
                {
                    id: 'description',
                    label: 'Description'
                }
            ],
            oldPageColumns: [
                {
                    id: 'dealName',
                    label: 'Assigned Offer'
                },
                {
                    id: 'regType',
                    label: 'Reg Type Visibility'
                },
                {
                    id: 'uri',
                    label: 'URL'
                },
                {
                    id: 'title',
                    label: 'Title'
                },
                {
                    id: 'version',
                    label: 'Version'
                },
                {
                    id: 'description',
                    label: 'Description'
                }
            ]
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        CMSService.getPagesByDealId(this.props.dealId)
            .then(response => {
                let pages = response.data;
                pages.reverse();

                this.setState({
                    pages: pages
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    // addVersions() {
    //     let oldPages = this.state.oldPages
    //     oldPages.reverse();
    //     let oldPagesVersions = [];
    //     let version = 1;
    //     let uri = oldPages[0].uri
    //     oldPages.map(page => {
    //         if (page.uri === uri) {
    //             page.version = version++
    //             oldPagesVersions.push(page)
    //         } else {
    //             version = 1
    //             uri = page.uri
    //             page.version = version++
    //             oldPagesVersions.push(page)
    //         }
    //     })
    //     oldPagesVersions.reverse()
    //     this.setState({
    //         oldPages: oldPagesVersions
    //     })
    // }

    generateColumn(col, row) {
        return (row[col.id]);
    }

    editTableHandler(row) {
        window.location.href = '/cms/edit/' + row.uri
    };

    copyPage(row) {
        window.location.href = '/cms/copy/' + row.uri
    };

    switchPageActive(row) {
        CMSService.switchPageActive(row.uri)
            .then(response => {
                this.fetchData()
            })
            .catch(err => {
                toast.error("Error!")
            })
    }

    showPreview = (page) => {
        CMSService.getImagesByUri(page.uri).then(response => {
            let files = response.data.map(file => {
                return {
                    'id': file.id,
                    'filename': file.uuid + '.' + file.extension,
                    'originalFilename': file.orginalFilename
                }
            });
            this.setState(
                {
                    images: files,
                    bodyCode: page.elements[0].text,
                    showPage: page,
                    showPreview: true,
                });
        })
    };

    togglePreview() {
        this.setState({
            showPreview: false,
            bodyCode: ''
        })
    }

    revertPage(page) {
        if (window.confirm('Revert this page?')) {
            if (window.confirm('Warning! The selected page will replace the current one. Do you want to continue?')) {
                CMSService.revertPage(page).then(res => {
                    this.fetchData()
                })
            }
        }
    }


    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    <Typography variant="h6">
                        Offering Pages
                    </Typography>

                    <Divider/>
                </Grid>

                <Grid item xs={12} lg={12}>
                    <NavLink to={'/cms/create/' + this.props.dealId}>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{color: 'white', marginRight: '10px'}}
                            endIcon={<AddIcon/>}
                            component="span">
                            Create Offering Page
                        </Button>
                    </NavLink>
                </Grid>

                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {this.state.columns.map((column) => {
                                    return (
                                        <TableCell align="left">
                                            {column.label}
                                        </TableCell>
                                    )
                                })}

                                <TableCell  align={"center"}>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {this.state.pages.map(row =>
                                <TableRow className={!row.active ? 'inactivePage' : null}>
                                    {this.state.columns.map((col) => {
                                        if (col.visible === undefined || col.visible === true) {
                                            return (
                                                <TableCell>
                                                    {this.generateColumn(col, row)}
                                                </TableCell>
                                            )
                                        }

                                        return null
                                    })}

                                    <TableCell align={"right"}>
                                        <Tooltip title="Preview">
                                            <IconButton
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.showPreview(row)}
                                            >
                                                <VisibilityIcon/>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Edit">
                                            <IconButton
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.editTableHandler(row)}
                                            >
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Copy">
                                            <IconButton
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.copyPage(row)}
                                            >
                                                <FileCopyIcon/>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Activate/Deactivate">
                                            <IconButton
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                // className={!row.active ? "btn btn-success" : "btn btn-danger"}
                                                onClick={() => this.switchPageActive(row)}
                                            >
                                                {!row.active ? <PublishIcon/> : <CancelPresentationIcon/>}
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {this.state.showPreview && (
                    <PreviewPage
                        show={this.state.showPreview}
                        images={this.state.images}
                        pageInfo={this.state.showPage}
                        bodyCode={this.state.bodyCode}
                        close={() => this.togglePreview()}>
                    </PreviewPage>
                )}
            </Grid>
        )
    }
}

export default Cms;