import axios from '../custom-axios/backoffice';


const MiscService = {
    fetchCountries: () => {
        return axios.get('/rest/country');
    },

    fetchStates: () => {
        return axios.get('/rest/state')
    }
}

export default MiscService;