import axios from '../custom-axios/backoffice';
import * as qs from "qs";

const RequestService = {

    fetchUserRequests: () => {
        return axios.get(`/api/requests`);
    },

    fetchFilteredUserRequests: (sort, page, pageSize, filter) => {
        let filterOpt = [];

        if (filter) {
            filterOpt = Object.keys(filter).map(function (key) {
                if (filter[key] !== undefined && filter[key] !== '') return '&' + key + '=' + filter[key]

                return ''
            })
        }

        return axios.get(`/api/requests/paged?sort=${sort}&page=${page}&pageSize=${pageSize}` + filterOpt.join(''));
    },

    updateRequests: (body) => {
        return axios.put('/api/requests', body);
    },

    exportUserRequest: (ids) => {
        axios({
            url: `${process.env.REACT_APP_BACKOFFICE_GATEWAY}/api/requests/export`,
            method: 'GET',
            responseType: 'blob', // important
            params: {
                'ids': ids
            },
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        })
            .then((response) => {
                const fileNameHeader = "x-suggested-filename";
                const suggestedFileName = response.headers[fileNameHeader]

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `User requests.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.remove()
            })
    },
    // exportUserRequest: (id) => {
    //     window.open(`${process.env.REACT_APP_BACKOFFICE_GATEWAY}/api/requests/${id}/export?access_token=${localStorage.getItem(AUTH_TOKEN)}`)
    // }

    transform: (requests) => {
        return requests.map(request => {
            return {
                id: request.id ? request.id : '',
                fullName: request.firstName || request.lastName ? `${request.firstName} ${request.lastName}` : '',
                firstName: request.firstName ? request.firstName : '',
                lastName: request.lastName ? request.lastName : '',
                email: request.email ? request.email : '',
                dateCreated: request.dateCreated ? request.dateCreated : '',
                amount: request.amount ? request.amount : '',
                paymentMethod: request.paymentMethod ? request.paymentMethod : '',
                phone: request.phone ? request.phone : '',
                userGroup: request.userGroup ? request.userGroup : '',
                userGroupName: request.userGroup ? request.userGroup.value : '',
                institutionName: request.institutionName ? request.institutionName : '',
                country: request.country ? request.country : '',
                countryName: request.country ? request.country.name : '',
                requestStatus: request.requestStatus,
                requestStatusName: request.requestStatus.value
            }
        })
    },
}

export default RequestService;