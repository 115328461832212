import React from 'react';
import BottomScrollListener from 'react-bottom-scroll-listener';


const documentScroll = (props) => {
    const scrolledToBottom = () => {
        props.pushInvestorChange({
            attributeCode: props.attributeCode,
            value: 'yes'
        });
    };

    return (

        <BottomScrollListener onBottom={scrolledToBottom}>
            {scrollRef => (
                <div className="document" ref={scrollRef}>
                    <h4 className="margin-top-0 text-uppercase weight600">Risk Disclosure: </h4>
                    <p>
                        Investments in private placements and start-up investments in particular are speculative and
                        involve
                        a high degree of risk and those investors who cannot afford to lose their entire investment
                        should
                        not invest in start-ups. Companies seeking startup investments tend to be in earlier stages of
                        development and their business model, products and services may not yet be fully developed,
                        operational
                        or tested in the public marketplace. There is no guarantee that the stated valuation and other
                        terms
                        are accurate or in agreement with the market or industry valuations. Additionally, investors may
                        receive restricted stock that may be subject to holding period requirements. In the most
                        sensible
                        investment strategy for start-up investing, start-ups should only be part of your overall
                        investment
                        portfolio. </p>
                    <p>
                        Investments involve risk. Past performance does not represent or indicate future results.
                        Diversification
                        may help spread risk, but does not protect investors from declines in the market. <strong> You
                        may lose
                        all of your
                        investment. </strong> Investors should consider their investment objectives and goals before
                        investing. <strong> No
                        information on this website should be considered an offer to buy or sell a
                        security. </strong> No offers
                        or solicitations should be inferred in any jurisdiction where we are not advertised to do
                        business.
                    </p>
                    <hr className="grey"/>
                    <h4 className="margin-bottom-0 text-uppercase weight600">Privacy and Cookies Policy </h4>
                    <h5 className="margin-top-10 margin-bottom-30">Effective date: July 12th, 2018 </h5>

                    <p>
                        The privacy of our customers and business partners, and the security of their information, is of
                        paramount concern. This Privacy and Cookies Policy (hereinafter referred to as “the Policy”)
                        describes AmerX’s policies and procedures on the collection, use and disclosure of your
                        information
                        when you use the services, websites and applications offered by AmerX (hereinafter collectively
                        referred to as “the Products and Services”). It also details your privacy rights, and how the
                        law
                        protects you. By using these Products and Services, you consent to our use of information in
                        accordance with this Policy and with applicable laws and regulations. <strong>We will not use
                        your
                        personal
                        information, or share it with any third party, except as described in this Policy. </strong>
                    </p>
                    <p>
                        This Policy is intended to meet AmerX’s duties of transparency under the General Data Protection
                        Regulation of the European Union (GDPR). It is designed to cover:
                    </p>
                    <ol>
                        <li><strong> Who we are and how to contact us </strong></li>
                        <li><strong> Your rights relating to Personal Data </strong></li>
                        <li><strong> Marketing communications preferences </strong></li>
                        <li><strong> What Personal Data we collect </strong></li>
                        <li><strong> Our policy on children's privacy </strong></li>
                        <li><strong> How we use your Personal Data and why </strong></li>
                        <li><strong> How we use cookies and other tracking techniques </strong></li>
                        <li><strong> Where we store your Personal Data </strong></li>
                        <li><strong> How we protect your Personal Data </strong></li>
                        <li><strong> How long we store your Personal Data </strong></li>
                        <li><strong> Who we share your Personal Data with </strong></li>
                        <li><strong> Links to other websites </strong></li>
                        <li><strong> Changes to our Privacy & Cookies Policy </strong></li>
                    </ol>

                    <p> Any modifications to this Policy will be posted on this page. </p>


                    <h4 className="text-uppercase weight600 margin-top-20">1) Who We Are and How to Contact Us:</h4>

                    <p> Americas Executions, Inc. (also referred to herein as “AmerX,” “we,” “us,” and “our”) is the
                        Controller (for the purposes of the GDPR) of your Personal Data.
                    </p>

                    <p>
                        AmerX is headquartered at 61 Broadway, Suite 2301, New York, NY 10006. Our phone number is
                        (646)-979-8000. If you have any questions about our practices generally, or about this Policy,
                        please contact us by phone or at ib@amerx.com.
                    </p>

                    <h4 className="text-uppercase weight600 margin-top-20">2) Your Rights Relating to Personal
                        Data: </h4>

                    <p>You have the right under this Policy, and by law if you are within the EU, to: </p>

                    <ul>
                        <li>
                            <strong>Request access to your Personal Data. </strong> If you are within the EU, this
                            enables you to receive a copy
                            of the Personal Data we hold about you, and to check that we are lawfully processing it.

                        </li>
                        <li>
                            <strong>
                                Request correction of the Personal Data that we hold about you. </strong> This enables
                            you to have any
                            incomplete or inaccurate information we hold about you corrected.

                        </li>
                        <li>
                            <strong>
                                Request erasure of your Personal Data.</strong> This enables you to ask us to delete or
                            remove Personal Data
                            where there is no good reason for us continuing to process it. You also have the right if
                            you are
                            within the EU to ask us to delete or remove your Personal Data where you have exercised your
                            right
                            to object to processing (see below).

                        </li>
                        <li>
                            <strong>
                                Object to processing of your Personal Data. </strong> This right exists where we are
                            relying on a legitimate
                            interest as the legal basis for our processing, but there is something about your particular
                            situation which makes you want to object to processing of your Personal Data on this ground.
                            You
                            also have the right to object where we are processing your Personal Data for direct
                            marketing
                            purposes.

                        </li>
                        <li>
                            <strong>
                                Request the restriction of processing of your Personal Data.</strong> This enables you
                            to ask us to suspend
                            the processing of Personal Data about you, for example if you want us to establish its
                            accuracy or
                            the reason for processing it.

                        </li>
                        <li>
                            <strong>
                                Request the transfer of your Personal Data. </strong> If you are within the EU, we will
                            provide to you, or a
                            third party you have chosen, your Personal Data in a structured, commonly-used,
                            machine-readable
                            format. Note that this right only applies to automated information which you initially
                            provided
                            consent for us to use, or where we used the information to perform a contract with you.

                        </li>
                        <li>
                            <strong>

                                Withdraw consent.</strong> This right only exists where we are relying on consent to
                            process your Personal
                            Data ("Consent Withdrawal"). If you withdraw your consent, we may not be able to provide you
                            with
                            access to the certain specific functionalities of our Site. We will advise you if this is
                            the case
                            at the time you withdraw your consent.

                        </li>
                    </ul>


                    <h4 className="text-uppercase weight600 margin-top-20">How to Exercise Your Rights: </h4>

                    <p>If you want to exercise any of the rights described above, please contact us using the contact
                        details in <strong> Who We Are and How to Contact Us. </strong></p>

                    <p>Typically, you will not have to pay a fee to access your Personal Data (or to exercise any of the
                        other rights enumerated above). However, except in relation to Consent Withdrawal, we may charge
                        a
                        reasonable fee if your request is clearly unfounded, repetitive or excessive, or we may refuse
                        to
                        comply with your request under these circumstances. </p>

                    <p><strong>We may need to request specific information from you to help us confirm your identity and
                        ensure
                        your right to access your Personal Data </strong> (or to exercise any of your other rights).
                        This is a
                        security measure to ensure that Personal Data is not disclosed to any person who has no right to
                        receive it. We may also contact you to ask you for further information in relation to a request
                        to
                        speed up our response. </p>

                    <p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer
                        than a month if your request is particularly complex, or if you have made multiple requests. In
                        this
                        case, we will notify you and keep you updated. </p>

                    <h4 className="text-uppercase weight600 margin-top-20">Complaints:</h4>

                    <p>If you would like to submit a complaint regarding this Privacy Policy, or regarding our practices
                        in
                        relation to your Personal Data, please contact us at: ib@amerx.com. We will reply to your
                        complaint
                        as soon as we can. </p>

                    <p>If you feel that your complaint has not been adequately resolved, please note that if you are in
                        the
                        EU, the GDPR gives you the right to contact your local data-protection supervisory authority.
                        For
                        the UK, this authority is the Information Commissioner's Office. </p>

                    <h4 className="text-uppercase weight600 margin-top-20">3) Marketing Communications
                        Preferences: </h4>

                    <p>You can ask us to stop sending you marketing messages, or modify your email preferences at any
                        time,
                        through any of the following methods: </p>

                    <ul>
                        <li>
                            By following the opt-out links on any marketing message sent to you; or
                        </li>
                        <li>
                            Through your account settings on our website, amerx.com; or
                        </li>
                        <li>
                            By contacting us at any time using the contact details in <strong>Who We Are and How to
                            Contact Us. </strong>
                        </li>
                    </ul>


                    <p>
                        Where you opt out of receiving these marketing messages, it will not necessarily terminate
                        contacts
                        relating to purchases or to investments using the Products and Services. </p>

                    <h4 className="text-uppercase weight600 margin-top-20">4) What Personal Data We Collect:</h4>

                    <p>
                        AmerX uses Personal Data we collect to provide the Products and Services; to personalize
                        content; to
                        remember information to help you efficiently access your account; to analyze how the Products
                        and
                        Services are used; to diagnose service or technical problems; to maintain security; to monitor
                        aggregate metrics such as the total number of visitors, traffic, and demographic patterns; to
                        track
                        user content and users as necessary, in order to comply with the Digital Millennium Copyright
                        Act
                        and other applicables laws; and for other reasons, as outlined below. </p>

                    <p>
                        <strong>Information You Directly Provide to Us.</strong> There are many occasions when you
                        provide information that
                        may enable us to identify you personally ("Personal Data") while using the Products and
                        Services.
                        The Personal Data we may collect from you is outlined in the table below. </p>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <th> Category of Personal Data collected</th>
                            <th> What this means</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    Identity Data

                                </td>

                                <td>
                                    First name, surname, maiden name, last name, username or similar identifier, marital
                                    status, title,
                                    date of birth and gender, picture, social security number and/or tax identification
                                    number,
                                    password, copies of ID cards or other forms of identification.
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Contact Data
                                </td>

                                <td>
                                    Your domicile address, mailing address, billing address, email address and telephone
                                    numbers.

                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Financial Data
                                </td>

                                <td>
                                    Your bank account and payment card details, statements about your wealth and
                                    financial
                                    situation.

                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Transaction Data

                                </td>

                                <td>
                                    Any details about payments to and from you and other details of subscriptions and
                                    services you have
                                    purchased from us. Data in respect of your transactions with third parties
                                    (including
                                    your credit
                                    history).
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Investment Data

                                </td>

                                <td>
                                    Information about your investment objectives, investment experience, prior
                                    investments
                                    (on or
                                    offline), syndicates or leads backed, backing amounts and preferences, investment
                                    amounts and
                                    information provided by completing subscription documents for deals closed via the
                                    Products and
                                    Services. Also included is information about your personal investment entities and
                                    their
                                    co-owners
                                    and beneficiaries, and tax-related information in connection with your investments
                                    through the
                                    Products and Services.
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Content Data

                                </td>

                                <td>
                                    Any content you post to the Products and Services not already included in another
                                    category,
                                    including, without limitation, your profiles, questions, preference settings,
                                    answers,
                                    messages,
                                    comments, and other contributions on the Products and Services, and metadata about
                                    them
                                    (such as
                                    when you posted them) (hereinafter also referred to as "Content").
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Marketing and Communications Data

                                </td>

                                <td>
                                    Your preferences regarding receipt of marketing from us and from our affiliated
                                    third
                                    parties, and
                                    your overall communications preferences. If you correspond with us by email or
                                    messaging
                                    through the
                                    Products and Services, we may retain the content of such messages and our responses.
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Technical Data

                                </td>

                                <td>
                                    Internet protocol (IP) address, your login data, browser type and version, time zone
                                    setting and
                                    location, browser plug-in types and versions, operating system and platform and
                                    other
                                    technology on
                                    the devices you use to access this website or use our services.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <p><strong>Personal Data from Third Party Sources.</strong> In addition to the Personal Data that we
                        collect directly
                        from you (as described in the section immediately above this one), we may also collect certain
                        of
                        your Personal Data from third party sources, some of which may not be publicly available.
                        Examples
                        of these sources are broken down in the table below. </p>

                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <th> Third party data source</th>
                            <th> Category(ies) or other types of Personal Data received.</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td> Analytics Providers</td>
                                <td> Technical Data</td>
                            </tr>

                            <tr>
                                <td> Advertisers</td>
                                <td> Technical Data</td>
                            </tr>


                            <tr>
                                <td> Identity Verification and Compliance Service Providers</td>
                                <td> Identity Data <br/>
                                    Contact Data
                                </td>
                            </tr>
                            </tbody>

                        </table>
                    </div>
                    <p>
                        <strong>Aggregated Data. We may also collect, use and share "Aggregated Data" such as
                            statistical or
                            demographic data, for any purpose. </strong> Aggregated Data may be derived from your
                        Personal Data, but
                        once
                        in aggregated form it will not constitute Personal Data for the purposes of the GDPR as this
                        data
                        does not directly or indirectly reveal your identity. However, if we combine or connect
                        Aggregated
                        Data with your Personal Data so that it can directly or indirectly identify you, we treat the
                        combined data as Personal Data, which will be used only in accordance with this Policy and
                        applicable laws. </p>

                    <p><strong>No Special Categories of Personal Data. </strong> We do not collect any "Special
                        Categories of Personal
                        Data"
                        about you (this includes details about your race or ethnicity, religious or philosophical
                        beliefs,
                        sexual orientation, political opinions, trade union membership, information about your health
                        and
                        genetic and biometric data). Nor do we collect any information about criminal convictions or
                        offenses. </p>

                    <h4 className="text-uppercase weight600 margin-top-20">5) Our Policy on Children's Privacy:</h4>

                    <p>Protecting the privacy of young children is especially important to us and under the law. The
                        Products and Services are not intended for children below 16, and AmerX does not knowingly
                        collect
                        or solicit personal information from anyone under the age of 16 or knowingly allow such persons
                        to
                        register with the Products and Services. If you are under the age of 16, you may not submit any
                        personal information through the Site. </p>

                    <p>We encourage parents and legal guardians to monitor their children's Internet usage and to help
                        enforce this Policy by instructing their children never to provide personal information on this
                        Site. If we become aware that we have collected personal information from a child under age 16,
                        we
                        will take steps to remove that information. </p>

                    <h4 className="text-uppercase weight600 margin-top-20">6) How We Use Your Personal Data and
                        Why: </h4>

                    <p>We generally use Personal Data for the following: to deliver and improve our Products and
                        Services;
                        to manage your account and provide you with customer support; to perform research and analysis
                        about
                        use of the Products and Services; to develop, display, and track Content and advertising,
                        tailored
                        to your interests, both on the Products and Services and other sites, including, potentially,
                        providing advertisements for the Products and Services to you when you visit other sites;
                        website
                        and mobile application analytics; to diagnose and fix technology problems; to automatically
                        update
                        the Products and Services on your device; to verify your identify in order to prevent fraud and
                        other unauthorized / illegal activity; to enforce or exercise any rights in our Terms of
                        Service. </p>

                    <p>In respect to each of the purposes for which we use your Personal Data, the GDPR requires us to
                        ensure that we have a legal basis for that use if you are within the EU. The legal bases depend
                        on
                        the Products and Services you use and how you use them. This means we collect and use your
                        Personal
                        Data only where: </p>

                    <ul>
                        <li>
                            We need it to provide you the Products and Services, including to operate the Products and
                            Services,
                            provide customer support and personalized features, and to protect the safety and security
                            of the
                            Products and Services;
                        </li>
                        <li>
                            It satisfies a legitimate interest (which is not overridden by your data protection
                            interests), such
                            as for research and development, to market and promote the Products and Services, or to
                            protect our
                            legal rights and interests; or
                        </li>
                        <li>
                            We need to process your data to comply with a legal or regulatory obligation.
                        </li>
                    </ul>

                    <p>We may also rely on your consent as a legal basis for using your Personal Data where we have
                        expressly sought it for a specific purpose. If we do rely on your consent to a use of your
                        Personal
                        Data, you have the right to change your mind at any time (but this will not affect any
                        processing
                        that has already taken place). We have set out below, in table format, more detailed examples of
                        relevant purposes for which we may use your Personal Data. </p>

                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <th> Purpose</th>
                            <th> Why do we do this</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td> Providing, updating, and maintaining our Products and Services, website and
                                    business
                                </td>
                                <td> To deliver the Products and Services you have requested, including, for example,
                                    registering you as
                                    a user, managing your account and profile, and authenticating you when you log
                                    in. <br/>

                                    For our investment-related services, this also includes, for example, establishing
                                    your
                                    eligibility
                                    to invest on the Site under applicable laws, third-party contractual obligations and
                                    internal
                                    policies, surfacing suitable investment products for you on the Site, and providing
                                    tax-
                                    and other
                                    investment-related reporting for investors.
                                </td>
                            </tr>
                            <tr>
                                <td> Processing investments and payments</td>
                                <td> To close investments and process payment for any purchases, subscriptions or sales
                                    made
                                    on our Site,
                                    to protect against or identify possible money-laundering or other fraudulent
                                    transactions, and
                                    otherwise as needed to manage our business.
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Research and development
                                </td>
                                <td>

                                    To enable us to improve the Products and Services and better understand our users
                                    and
                                    the markets in
                                    which we operate. For example, we may conduct or facilitate research and use
                                    learnings
                                    about how
                                    people use our Products and Services and feedback provided directly to us to
                                    troubleshoot and to
                                    identify trends, usage, activity patterns, opportunities for new features and
                                    improvement of the
                                    Products and Services, and other insights. We also test and analyze certain new
                                    features
                                    with some
                                    users before introducing the feature(s) to all users.
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Communicating with users about the Products and Services
                                </td>
                                <td>
                                    To send communications via email and within the Products and Services; including,
                                    for
                                    example,
                                    responding to your comments, questions and requests, providing customer support, and
                                    sending you
                                    technical notices, product updates, security alerts, and administrative, billing and
                                    account-management-related messages. We may also provide tailored communications
                                    based
                                    on your
                                    activity and interactions with us. For example, we may notify you of investment
                                    opportunities, or
                                    provide information regarding your investments through the Site. These
                                    communications
                                    are part of
                                    the Products and Services, and in most cases, you cannot opt out of them. If an
                                    opt-out
                                    is
                                    available, you will find that option within the communication itself, or in your
                                    account
                                    settings.
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Providing customer support
                                </td>
                                <td>

                                    To resolve technical issues you encounter, to respond to your requests for
                                    assistance,
                                    comments and
                                    questions, to analyze crash information, to repair and improve the Products and
                                    Services, and to
                                    provide other forms of customer support.
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Enhancing security
                                </td>
                                <td>
                                    To keep our website, our Products and Services and associated systems operational
                                    and
                                    secure,
                                    including, for example, verifying accounts and activity, monitoring and
                                    investigating
                                    suspicious or
                                    fraudulent activity, and identifying violations of our terms and policies.
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Marketing, promoting and driving engagement with the Products and Services, and with
                                    third-party
                                    products and services
                                </td>
                                <td>
                                    To send promotional communications that may be of specific interest to you,
                                    including,
                                    for example,
                                    by email and by displaying products, and investment offerings, and other
                                    information, on
                                    our Site or
                                    other companies' websites. These communications may be aimed at driving engagement
                                    and
                                    maximizing
                                    what you get out of the Products and Services, or at promoting third-party products
                                    and
                                    services.
                                    You generally can control whether you receive these communications, as described in
                                    this
                                    Policy
                                    under <strong> Marketing Communications Preferences. </strong>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    To comply with applicable laws, legal processes and regulations and to protect our
                                    legitimate
                                    business interests
                                </td>
                                <td>
                                    As we believe is reasonably necessary to comply with a law, regulation, order,
                                    subpoena,
                                    or rule of
                                    a self-regulatory organization or audit; to protect the safety of any person; to
                                    address
                                    fraud,
                                    security or technical issues; or to protect our legal rights, interests and the
                                    interests of others,
                                    such as, for example, in connection with with the acquisition, merger or sale of
                                    securities or of a
                                    business (e.g., in the process of due diligence).
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                    <h4 className="text-uppercase weight600 margin-top-20">7) How We Use Cookies and Other Tracking
                        techniques:</h4>

                    AmerX may collect non-personally-identifiable information regarding your usage of the Products and
                    Services, including the pages and the materials you viewed, time spent using certain features of the
                    website, and demographic data such as server locations, connection speed, and other information that
                    does not identify you. Like most online services, we also use cookies, log files, clear GIFs,
                    tracking pixels, web beacons, and other technologies that may collect Personal Data, all of whose
                    usage is restricted by the parameters specified in this Policy.

                    <h3>Cookies:</h3>

                    <p><strong>What are cookies? </strong> When you visit the Products and Services, we may send one or
                        more cookies – small
                        data files – to your computer, to uniquely identify your browser and let AmerX help you log in
                        faster and enhance your navigation through the Site. A cookie may convey anonymous information
                        about
                        how you browse the Products and Services to us, so that we can provide you with a more
                        personalized
                        experience. Cookies do not collect personal information about you. A persistent cookie remains
                        on
                        your computer after you close your browser, so that it can be used by your browser during
                        subsequent
                        uses of the Products and Services. Persistent cookies can be removed by following your web
                        browser's
                        directions. A session cookie is temporary and disappears after you close your browser. </p>

                    <p><strong>How can I disable cookies? </strong> You can typically reset your web browser to refuse
                        all cookies, or to
                        notify you when a cookie is being sent. To do so, follow the instructions provided by your
                        browser
                        (usually located within the "settings", "help" "tools" or "edit" facility). Many browsers are
                        set to
                        accept cookies until you change your settings. </p>

                    <p>
                        If you do not accept our cookies, you may experience some inconvenience in your use of our
                        Products
                        and Services, and some features of the Service may not function properly. For example, we may
                        not be
                        able to recognize your computer or mobile device, and you may need to log in every time you
                        visit
                        our Products and Services. </p>

                    <p>
                        For further information about cookies, including how to see what cookies have been set on your
                        computer or mobile device, and how to manage or delete them, you can visit
                        www.allaboutcookies.org
                        or www.youronlinechoices.com. </p>

                    <p><strong>Log Files: </strong> Log file information is automatically reported by your browser each
                        time you access a web
                        page. When you use the Products and Services, our servers automatically record certain
                        information
                        your web browser sends while visiting any website. These server logs may include information
                        such as
                        your web request, Internet Protocol address, browser type, referring / exit pages / URLs, number
                        of
                        clicks, domain names, landing pages, pages viewed, and other browsing information. </p>

                    <p><strong>Clear GIFs Information (Web Beacons): </strong> When you use the Products and Services,
                        we may employ clear
                        GIFs (also known as web beacons or tracking pixels) to anonymously track online usage patterns.
                        No
                        Personally Identifiable Information from your AmerX account is collected using these clear GIFs.
                        In
                        addition, we may also use clear GIFs in HTML-based emails sent to our users, to track which
                        emails
                        are opened by recipients. The information collected is used to enable more accurate reporting
                        and to
                        make AmerX better for our users. </p>

                    <p><strong>Third-Party Products and Services: </strong> AmerX may use third-party services such as
                        Google Analytics,
                        Segment, Fullstory or Amplitude to help understand use of the Products and Services. These
                        third-party services typically collect the information sent by your browser as part of a web
                        page
                        request, including cookies and your IP address. They receive this information and their use of
                        it is
                        governed by their respective privacy policies. You can also prevent the use of Google Analytics
                        relating to your use of our Products and Services by downloading and installing the browser
                        plugin
                        available via this link: http://tools.google.com/dlpage/gaoptout. </p>


                    <p><strong>CalOPPA Disclosures: </strong> We do not currently respond to web browsers' "do not
                        track" signals that
                        provide a method to opt out of collection of information about users' activities on the Products
                        and
                        Services and on other websites. If we do so in the future, we will provide relevant information
                        in
                        this Policy. </p>

                    <h4 className="text-uppercase weight600 margin-top-20">8) Where We Store Your Personal Data: </h4>

                    <p>
                        <strong>The Products and Services are maintained in the United States of
                            America. </strong> Personal Data that you
                        provide us may be stored, processed and accessed by us, by our staff, and by sub-contractors and
                        third parties with whom we share Personal Data in the United States of America, or elsewhere,
                        for
                        the purposes described in this Policy. We may also store Personal Data in locations outside the
                        direct control of AmerX (for instance, on servers or databases co-located with hosting
                        providers).
                        Although we welcome users from all over the world, by accessing the Products and Services and
                        providing us with your Personal Data, you consent to and authorize the export of Personal Data
                        to
                        the United States, and its storage and use as specified in this Policy. <strong> Note that the
                        laws of
                        the
                        United States might not be as comprehensive or protective as laws in the country where you
                        live. </strong>
                    </p>

                    <p>
                        Because the Products and Services are maintained in the United States of America, we do not
                        transfer
                        your Personal Data from the EU to any parties located outside the EU, other than Personal Data
                        relating to investments in the United Kingdom. When information is transferred to us from the EU
                        by
                        any affiliate in connection with your investments in the United Kingdom, we use specific
                        contracts
                        approved by the European Commission, which give Personal Data the same protection it has in the
                        EU.
                        For further details, see
                        <strong>
                            <a
                                href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                European Commission: Model contracts for the transfer of Personal Data to third
                                countries.
                            </a>
                        </strong>
                    </p>

                    <h4 className="text-uppercase weight600 margin-top-20">9) How We Protect Your Personal Data: </h4>

                    <p>
                        AmerX uses industry-standard physical, managerial, and technical safeguards to preserve the
                        integrity and security of your personal information. We limit access to your Personal Data to
                        those
                        employees and other staff who have a business need to have such access. All such people are
                        subject
                        to a contractual duty of confidentiality. We cannot, however, ensure or warrant the security of
                        any
                        information you transmit to AmerX, or guarantee that your information on the Products and
                        Services
                        may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical,
                        managerial, or technical safeguards. </p>

                    <p>We have put in place procedures to deal with any actual or suspected Personal Data breach. Should
                        personal information be compromised by such a breach of security, AmerX will promptly notify
                        those
                        persons whose personal information has been compromised, in accordance with the notification
                        procedures set forth in this Policy, or as otherwise required by applicable law. </p>

                    <p>AmerX cannot ensure that your Personal Data will be protected, controlled or otherwise managed
                        pursuant to this Policy if you share your login and password information with any third party
                        whatsoever, including any third party operating a website or providing other services. </p>

                    <h4 className="text-uppercase weight600 margin-top-20">10) How long we store your Personal
                        Data: </h4>

                    <p>We will retain your information for as long as your account is active, or where the data is
                        reasonably needed for the purposes set out in <strong>How We Use Your Personal Data and
                            Why </strong>, unless you
                        request that we remove your Personal Data as described in <strong>Your Rights Relating to Your
                            Personal
                            Data </strong>. We will only retain your Personal Data for so long as we reasonably need to
                        use it for
                        these
                        purposes, unless a longer retention period is required by law (for example, for regulatory
                        purposes). This may include keeping your Personal Data after you have deactivated your account,
                        for
                        the time needed for us to pursue legitimate business interests, conduct audits, comply with (and
                        demonstrate compliance with) legal obligations, resolve disputes and enforce our
                        agreements. </p>

                    <h4 className="text-uppercase weight600 margin-top-20">11) Who We Share Your Personal Data
                        With </h4>

                    <p>We may share your Personal Data with third parties in the ways that are described in the table
                        below. We consider this information to be a vital part of our relationship with you. </p>

                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <th> Recipients</th>
                            <th> Why we share it</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    Our Affiliates
                                </td>
                                <td>
                                    Our affiliates may access your Personal Data to help us develop, maintain and
                                    provide
                                    our Products
                                    and Services and help manage our customer relationships (including providing
                                    customer
                                    support,
                                    customer liaison, fund advisory services, etc.).
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Service Providers
                                </td>
                                <td>
                                    Our service providers provide us support for our Products and Services, including,
                                    for
                                    example,
                                    website and application development, hosting, maintenance, backup, storage, virtual
                                    infrastructure,
                                    payment processing, analysis, identity verification, background and compliance
                                    reviews,
                                    fund
                                    administration, banking services, and other services for us, which may require them
                                    to
                                    access or use
                                    Personal Data about you.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Professional Advisers
                                </td>
                                <td>
                                    Our lawyers, accountants, bankers, auditors and insurers may need to review your
                                    personal data to
                                    provide consultancy, compliance, banking, legal, insurance, accounting and similar
                                    services.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Legal and Taxing Authorities, Regulators and Participants in Judicial Proceedings
                                </td>
                                <td>
                                    AmerX may disclose your Personal Data if we believe it is reasonably necessary to
                                    comply
                                    with a law,
                                    regulation, order, subpoena, or rule of a self-regulatory organization or audit; to
                                    protect the
                                    safety of any person; to address fraud, security or technical issues; or to protect
                                    our
                                    legal
                                    rights, interests and the interests of others, such as, for example, in connection
                                    with
                                    with the
                                    acquisition, merger or sale of securities or of a business (e.g., in the course of
                                    due
                                    diligence).
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Advertisers
                                </td>
                                <td>
                                    Certain users of the Products and Services may have access to your Personal Data to
                                    enable them to
                                    interact with you and more effectively offer opportunities through the Site that are
                                    targeted
                                    towards your background and preferences. We may also allow third parties, including
                                    ad
                                    servers or ad
                                    networks, to serve advertisements on the Site. Such third parties may be provided
                                    with
                                    access to
                                    your Personal Data to provide advertising tailored to your interests.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Researchers
                                </td>
                                <td>

                                    To enhance the public understanding of patterns and trends in the markets served by
                                    our
                                    Products and
                                    Services, we may provide Personal Data to third parties under confidentiality
                                    obligations, such as
                                    academics or contractors, for research purposes.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    API Users
                                </td>
                                <td>
                                    A limited number of partners have API access to portions of the Site. Examples of
                                    the
                                    most common
                                    API uses are OAuth and AML/accreditation verification of potential investors.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                    <p><strong>SITE PROFILES: </strong> In addition, Personal Data you choose to add to your profile
                        will be available for
                        public viewing on the Site. </p>

                    <p><strong>PERSISTENCE OF DATA AFTER CORPORATE TRANSFER: </strong> As we develop our business, we
                        may buy or sell
                        businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or
                        similar event, we may also transfer your Personal Data as part of the transferred assets without
                        your consent or notice to you. </p>

                    <p><strong>AGGREGATE DATA SHARING: </strong> We may also share non-Personal Data (such as anonymous
                        usage data,
                        referring/exit pages and URLs, platform types, number of clicks, etc.) with interested third
                        parties
                        to help them understand the usage patterns for certain Products and Services, or to conduct
                        independent research based on such anonymous usage data. </p>

                    <p><strong>PASS-ALONG OF REMOVAL REQUESTS: </strong> If you request that we remove your Personal
                        Data as described in
                        Your Rights Relating to Your Personal Data, we will convey that request to any third-party with
                        whom
                        we have shared your data. We are not, however, responsible for revising or removing your
                        Personal
                        Data obtained by any third party who has previously been provided your information by us in
                        accordance with this policy, or any third party to whom you have provided such information
                        (whether
                        by sharing your login and password, or otherwise). </p>

                    <h4 className="text-uppercase weight600 margin-top-20">12) Links to Other Websites: </h4>

                    <p>This Policy applies only to the Products and Services described herein. The Products and Services
                        may contain links to other websites not operated or controlled by AmerX. We are not responsible
                        for
                        the content, accuracy or opinions expressed on other websites, and such websites are not
                        investigated, monitored or checked for accuracy or completeness by us. Please remember that when
                        you
                        use a link to go from the Products and Services to another website, our Privacy and Cookies
                        Policy
                        is no longer in effect. Your browsing and interaction on any other website, including any that
                        are
                        linked to from our Site, is subject to that website's own rules and policies. Such third parties
                        may
                        use their own cookies or other methods to collect information about you. </p>

                    <h4 className="text-uppercase weight600 margin-top-20">13) Changes to Our Privacy and Cookies
                        Policy </h4>

                    <p>
                        We reserve the right, in our sole discretion, to change, modify, add, or remove portions of this
                        Policy at any time. Any changes or updates will be effective immediately upon posting to this
                        page.
                        You should review this Policy regularly for changes. You can determine if changes have been made
                        by
                        checking the Effective Date above. Your continued use of our Site following the posting of any
                        changes to this Policy means that you consent to such changes. </p>
                </div>
            )}
        </BottomScrollListener>
    );
};

export default documentScroll;