import React, {Component} from 'react';

import IssuerService from "../../services/IssuerService";
import MessagePanelComponent from "../../components/MessagePanelOld";
import UserDocumentation from "../../components/UserDocumentation";
import "./IssuerFullEdit.css";
import UserCommentsPanelComponent from "../../components/UserCommentsPanel";
import DealPerIssuerComponent from "../../components/DealPerIssuerComponent";
import Box from "@material-ui/core/Box";
import {CardContent, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import {makeStyles, Theme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from '@material-ui/core/Divider';
import Card from "@material-ui/core/Card";
import {toast} from "react-toastify";
import SelectField from "../../components/Molecules/SelectField";
import MiscService from "../../services/MiscService";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import InvestorService from "../../services/InvestorService";
import InputField from "../../components/Molecules/InputField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import InputPhoneField from "../../components/Molecules/InputPhoneField";
import CurrencyFormatField from "../../components/Molecules/CurrencyFormatField";
import SelectMultipleField from "../../components/Molecules/SelectMultipleField";
import RegTypeService from "../../services/RegTypeService";
import CheckBoxField from "../../components/Molecules/CheckBoxField";
import CheckIcon from '@material-ui/icons/Check';
import TaskService from "../../services/TaskService";
import Badge from "@material-ui/core/Badge";
import ConversationService from "../../services/ConversationService";
import GooglePlaceAutocompleteField from "../../components/Molecules/GooglePlaceAutocompleteField";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
    },
}));


class IssuerFullEdit extends Component {
    classes = () => {
        useStyles()
    }

    constructor(props) {
        super(props);

        this.state = {
            issuer: {
                regTypes: [],
                phone: '',
                accountStatus: ''
            },
            issuerData: {
                companyBusiness: '',
                capitalToRaise: '',
                companyCompetitors: '',
                capitalToDate: '',
                companyFormedYear: '',
                startedRaise: '',
                launchingOffering: ''
            },
            user: {
                firstName: '',
                lastName: '',
            },
            address: {
                country: {
                    id: ''
                },
                state: {
                    id: ''
                },
                city: '',
                streetAddress: '',
                postalCode: ''
            },
            key: 'profile',
            userId: null,
            value: 0,
            countries: [],
            states: [],
            usaCountryId: '',
            regTypes: [],
            dataKycAmlBlock: false,
            inProgress: false,
            years: [],
            apiKey: 'AIzaSyCaB_eJmQ8cOSg26Mx4u5MYEDHBzqtJ640',
            newMessagesCount: 0,
            frozen: true,
            errors: {}
        }
    }

    componentDidMount() {
        const queryString = require('query-string');
        this.initYears()
        this.fetchCountries()
        this.fetchStates()
        this.fetchRegTypes()
        //this.fetchConversation(this.props.match.params.issuerId)
        this.fetchConversation(this.props.userId)

        this.setState({
            //userId: queryString.parse(this.props.location.search).userId
            userId: this.props.userId
        }, () => {
            //this.fetchIssuer(this.props.match.params.issuerId);
            this.fetchIssuer(this.props.userId);
        });
    }

    initYears = () => {
        let years = [];
        const currentYear = new Date().getFullYear()

        for (let i = 1900; i <= currentYear; i++) {
            years.unshift(i);
        }

        this.setState({
            years: years
        })
    }

    fetchCountries = () => {
        MiscService.fetchCountries()
            .then(data => {
                const countries = data.data.map(country => {
                    return {
                        id: country.id,
                        value: country.name
                    }
                })

                const usaCountry = countries.filter(country => country.value === 'United States')[0]
                const usaCountryId = usaCountry ? usaCountry.id : ''

                this.setState({
                    countries: countries,
                    usaCountryId: usaCountryId
                })

            })
            .catch(error => {
                toast.error('Error');
            })
    }

    fetchStates = () => {
        MiscService.fetchStates()
            .then(data => {
                const states = data.data.map(state => {
                    return {
                        id: state.id,
                        value: state.name
                    }
                })

                this.setState({
                    states: states
                })
            })
            .catch(error => {
                toast.error('Error');
            })
    }

    fetchRegTypes = () => {
        RegTypeService.fetch()
            .then(data => {
                const regTypes = data.data.map(type => {
                    return {
                        id: type.id,
                        value: type.name,
                    }
                });

                this.setState({
                    regTypes: regTypes
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    fetchIssuer = (issuerId) => {
        if (this.state.userId) {
            IssuerService.fetchByUserId(issuerId)
                .then(response => {
                    this.setState({
                        ...this.state,
                        issuer: response.data,
                        user: response.data.user,
                        address: {
                            ...response.data.address ? response.data.address : this.state.address,
                            state: response.data.address && response.data.address.state ? response.data.address.state : ''
                        }
                    }, () => this.fetchIssuerData())
                })
                .catch(error => {
                    toast.error('Error');
                })
        } else {
            IssuerService.fetchIssuerById(issuerId)
                .then(response => {
                    this.setState({
                        ...this.state,
                        issuer: response.data,
                        user: response.data.user,
                        address: {
                            ...response.data.address ? response.data.address : this.state.address,
                            state: response.data.address && response.data.address.state ? response.data.address.state : ''
                        }
                    }, () => this.fetchIssuerData())
                })
                .catch(error => {
                    toast.error('Error');
                })
        }
    }

    fetchIssuerData = () => {
        IssuerService.fetchAttributeData(this.state.issuer.id)
            .then(response => {
                this.setState({
                    issuerData: {
                        companyBusiness: response.data.filter(atr => atr.attributeCode === 'companyBusiness')[0]
                            ? response.data.filter(atr => atr.attributeCode === 'companyBusiness')[0].chosenValue : '',
                        capitalToRaise: response.data.filter(atr => atr.attributeCode === 'capitalToRaise')[0]
                            ? response.data.filter(atr => atr.attributeCode === 'capitalToRaise')[0].chosenValue : '',
                        companyCompetitors: response.data.filter(atr => atr.attributeCode === 'companyCompetitors')[0]
                            ? response.data.filter(atr => atr.attributeCode === 'companyCompetitors')[0].chosenValue : '',
                        capitalToDate: response.data.filter(atr => atr.attributeCode === 'capitalToDate')[0]
                            ? response.data.filter(atr => atr.attributeCode === 'capitalToDate')[0].chosenValue : '',
                        companyFormedYear: response.data.filter(atr => atr.attributeCode === 'companyFormedYear')[0]
                            ? response.data.filter(atr => atr.attributeCode === 'companyFormedYear')[0].chosenValue : '',
                        startedRaise: response.data.filter(atr => atr.attributeCode === 'startedRaise')[0]
                            ? response.data.filter(atr => atr.attributeCode === 'startedRaise')[0].chosenValue : '',
                        launchingOffering: response.data.filter(atr => atr.attributeCode === 'launchingOffering')[0]
                            ? response.data.filter(atr => atr.attributeCode === 'launchingOffering')[0].chosenValue : '',
                    },
                })
            })
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            value: newValue
        });
    };

    handleIssuerChange = (value, prop) => {
        this.setState({
            issuer: {
                ...this.state.issuer,
                [prop]: value
            }
        })

        this.validateValue(value, prop)
    }

    handleUserChange = (value, prop) => {
        this.setState({
            user: {
                ...this.state.user,
                [prop]: value
            }
        })

        this.validateValue(value, prop)
    }

    handleAddressChange = (value, prop) => {
        if (prop === 'country' && value.id === this.state.usaCountryId) {
            this.setState({
                address: {
                    ...this.state.address,
                    state: {
                        id: ''
                    }
                }
            })
        }

        this.setState({
            address: {
                ...this.state.address,
                [prop]: value
            }
        })

        this.validateValue(value, prop)
    }

    handleOnChangeAddress = (address) => {
        this.setState({
            address: {
                ...this.state.address,
                streetAddress: address,
            }
        })
    }

    handleSelectedAddress = (place) => {
        let state1 = ''
        let country1 = ''
        let city1 = ''
        let address1 = ''
        let postcode1 = ''

        for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    break;
                }
                case "route": {
                    address1 += component.long_name;
                    break;
                }
                case "postal_code": {
                    postcode1 = `${component.long_name}${postcode1}`;
                    break;
                }
                case "postal_code_suffix": {
                    postcode1 = `${postcode1}-${component.long_name}`;
                    break;
                }
                case "locality":
                    city1 = component.long_name;
                    break;

                case "administrative_area_level_1": {
                    state1 = component.long_name;
                    break;
                }
                case "country":
                    country1 = component.long_name;
                    break;
                default:
            }
        }

        let country = this.state.countries.filter(_country => _country.value === country1)[0]

        let countryId = ''
        let stateId = ''

        if (country) {
            countryId = country.id

            if (countryId === this.state.usaCountryId) {
                let state = this.state.states.filter(state => state.value === state1)[0]

                if (state) {
                    stateId = state.id
                }
            }
        }

        this.setState({
            address: {
                ...this.state.address,
                country: {
                    id: countryId
                },
                state: {
                    id: stateId
                },
                city: city1,
                streetAddress: address1,
                postalCode: postcode1
            }
        })
    }


    handleIssuerDataChange = (value, prop) => {
        this.setState({
            issuerData: {
                ...this.state.issuerData,
                [prop]: value
            }
        })
    }

    handleSubmit = () => {
        if (this.handleValidation()) {
            const request = {
                ...this.state.issuer,
                ...this.state.issuerData,
                address: this.state.address,
                user: this.state.user,
            }

            this.setState({
                inProgress: true
            })

            IssuerService.updateIssuer(request)
                .then(data => {
                    toast.success('Successfully edited!')
                    //this.fetchIssuer(this.props.match.params.issuerId)

                    this.setState({
                        inProgress: false
                    })
                })
                .catch(error => {
                    toast.error('Error');

                    this.setState({
                        inProgress: false
                    })
                })
        } else {
            toast.error('Please fill all the required fields!')
        }
    }

    handleValidation = () => {
        let errors = {}
        let valid = true

        if (!this.state.user.firstName) {
            valid = false
            errors.firstName = 'Cannot be empty'
        }

        if (!this.state.user.lastName) {
            valid = false
            errors.lastName = 'Cannot be empty'
        }

        if (!this.state.issuer.phone) {
            valid = false
            errors.phone = 'Cannot be empty'
        }

        if (!this.state.issuer.companyName) {
            valid = false
            errors.companyName = 'Cannot be empty'
        }

        if (!this.state.address.streetAddress) {
            valid = false
            errors.streetAddress = 'Cannot be empty'
        }

        if (!this.state.address.postalCode) {
            valid = false
            errors.postalCode = 'Cannot be empty'
        }

        if (!this.state.address.country) {
            valid = false
            errors.country = 'Cannot be empty'
        } else {
            if (this.state.address.country === this.state.usaCountryId && !this.state.address.state) {
                valid = false
                errors.state = 'Cannot be empty'
            }
        }

        if (!this.state.address.city) {
            valid = false
            errors.city = 'Cannot be empty'
        }

        if (!this.state.issuerData.companyBusiness) {
            valid = false
            errors.companyBusiness = 'Cannot be empty'
        }

        if (!this.state.issuerData.capitalToRaise) {
            valid = false
            errors.capitalToRaise = 'Cannot be empty'
        }

        this.setState({
            errors: errors
        })

        return valid
    }

    validateValue = (value, field) => {
        if (value || value === '') {
            this.setState({
                errors: {
                    [field]: ''
                }
            })
        }
    }

    handleVerifyBlock = (attr, isVerified) => {
        if (attr === 'KYC_AML') {
            this.setState({
                dataKycAmlBlock: isVerified
            })
        }
    }

    onUnVerifyDocuments = (document, stateToEdit) => {
        let listDocuments = stateToEdit;
        let doc = document.chosenValue.split('.');

        InvestorService.unVerifyDocument(doc[0])
            .then(response => {
                    if (!response.data.documentVerify) {
                        for (let i = 0; i < listDocuments.length; i++) {
                            if (listDocuments[i].chosenValue === document.chosenValue) {
                                listDocuments[i].documentVerify = false;
                            }
                        }
                    } else {
                        toast.error('Error');
                    }
                }
            )
    }

    handleApproved = () => {
        IssuerService.approveIssuer(this.state.issuer.id)
            .then(response => {
                if (this.props.location.state) {
                    TaskService.deleteTask(Number.parseInt(this.props.location.state.taskId))
                        .then(response => {
                        })
                        .catch(error => {
                        })
                }

                toast.success('Approved successfully');
            })
            .catch(error => {

            })
        this.setState({
            accountStatus: 'ACTIVE'
        })
    }

    fetchConversation = (userId) => {
        this.setState({
            inProgress: true
        })

        ConversationService.fetchByUserId(userId)
            .then(response => {
                const newMessagesCount = response.data.messages.filter(message => !message.read).length

                this.setState({
                    messages: response.data.messages,
                    newMessagesCount: newMessagesCount,
                    inProgress: false,
                });
            })
    }

    changeStateFreeze = () => {
        this.setState((prevState, props) => ({
            frozen: !prevState.frozen
        }))
    }


    render() {
        return (
            this.state.inProgress ? (
                <Container maxWidth='lg'>
                    <Grid container
                          justify={"center"}
                          alignItems={"center"}
                          style={{height: '100vh'}}>
                        <Grid item>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                <>
                    <Grid container
                          className={this.classes.root}
                          style={{paddingTop: 80}}>
                        <Grid item lg={12} xs={12}>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="simple tabs example">
                                    <Tab label="Home" {...a11yProps(0)} />
                                    <Tab label="Documentation" {...a11yProps(1)} />
                                    <Tab label={
                                        <Badge badgeContent={this.state.newMessagesCount} variant="dot"
                                               color="primary">
                                            Inbox
                                        </Badge>}
                                         {...a11yProps(2)}/>
                                    {/*<Tab label="Deals and Investments" {...a11yProps(3)} />*/}
                                </Tabs>
                            </AppBar>
                        </Grid>

                        <TabPanel value={this.state.value} index={0}>
                            <Grid spacing={3} container style={{paddingTop: 10}}>
                                <Grid item lg={12}
                                      style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button
                                        variant='contained'
                                        color={this.state.frozen ? 'primary' : 'secondary'}
                                        style={{margin: 15}}
                                        onClick={this.changeStateFreeze}>
                                        {this.state.frozen ? 'Unfreeze' : 'Freeze'} Information Fields
                                    </Button>

                                    {/*<Button*/}
                                    {/*    variant='contained'*/}
                                    {/*    color={"primary"}*/}
                                    {/*    style={{*/}
                                    {/*        margin: 15*/}
                                    {/*    }}*/}
                                    {/*    startIcon={<ClearIcon/>}*/}
                                    {/*    disabled={!!this.state.dataKycAmlBlock}*/}
                                    {/*    onClick={(e) => {*/}
                                    {/*    }}>*/}
                                    {/*    Reject*/}
                                    {/*</Button>*/}

                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        style={{margin: 15}}
                                        startIcon={<CheckIcon/>}
                                        disabled={
                                            this.state.user.accountStatus === 'ACTIVE'
                                            || !this.state.dataKycAmlBlock
                                        }
                                        onClick={this.handleApproved}>
                                        {
                                            this.state.user.accountStatus === 'ACTIVE'
                                                ? 'Approved'
                                                : 'Approve'
                                        }
                                    </Button>
                                </Grid>

                                <Grid item lg={12}>
                                    <Card raised
                                          style={{overflow: 'unset'}}>
                                        <CardContent>
                                            <Grid spacing={3} container>
                                                <Grid item xs={12} lg={12}>
                                                    <Typography variant="h6">
                                                        Contact information
                                                    </Typography>

                                                    <Divider/>
                                                </Grid>
                                            </Grid>

                                            <Grid spacing={3} container>
                                                <Grid item xs={12} md={6} lg={3}>
                                                    <InputField
                                                        title={"First Name"}
                                                        name={'firstName'}
                                                        value={this.state.user.firstName}
                                                        required
                                                        disabled={this.state.frozen}
                                                        onChange={this.handleUserChange}
                                                        hasError={!!this.state.errors.firstName}
                                                        errorMessage={this.state.errors.firstName}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6} lg={3}>
                                                    <InputField
                                                        title={"Last Name"}
                                                        name={'lastName'}
                                                        value={this.state.user.lastName}
                                                        required
                                                        disabled={this.state.frozen}
                                                        onChange={this.handleUserChange}
                                                        hasError={!!this.state.errors.lastName}
                                                        errorMessage={this.state.errors.lastName}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6} lg={3}>
                                                    <InputPhoneField
                                                        title={"Phone"}
                                                        name={'phone'}
                                                        value={this.state.issuer.phone}
                                                        required
                                                        disabled={this.state.frozen}
                                                        onChange={this.handleIssuerChange}
                                                        hasError={!!this.state.errors.phone}
                                                        errorMessage={this.state.errors.phone}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6} lg={3}>
                                                    <InputField
                                                        title={"Email"}
                                                        name={'email'}
                                                        value={this.state.user.email}
                                                        disabled
                                                        onChange={this.handleUserChange}
                                                        hasError={!!this.state.errors.email}
                                                        errorMessage={this.state.errors.email}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Card raised>
                                        <CardContent>
                                            <Grid spacing={3} container>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography variant="h6">
                                                        Company Information
                                                    </Typography>

                                                    <Divider/>
                                                </Grid>
                                            </Grid>

                                            <Grid spacing={3} container>
                                                <Grid item xs={6} lg={4}>
                                                    <InputField
                                                        title={"Company Name"}
                                                        name={'companyName'}
                                                        value={this.state.issuer.companyName}
                                                        required
                                                        disabled={this.state.frozen}
                                                        onChange={this.handleIssuerChange}
                                                        hasError={!!this.state.errors.companyName}
                                                        errorMessage={this.state.errors.companyName}
                                                    />
                                                </Grid>

                                                <Grid item xs={6} lg={4}>
                                                    <GooglePlaceAutocompleteField
                                                        apiKey={this.state.apiKey}
                                                        title={"Street Address"}
                                                        name={'streetAddress'}
                                                        disabled={this.state.frozen}
                                                        defaultValue={this.state.address.streetAddress}
                                                        fillInAddress={this.handleSelectedAddress}
                                                        onChange={this.handleOnChangeAddress}
                                                        hasError={!!this.state.errors.streetAddress}
                                                        errorMessage={this.state.errors.streetAddress}
                                                    />
                                                </Grid>

                                                <Grid item xs={6} lg={4}>
                                                    <InputField
                                                        title={"Postal Code"}
                                                        name={'postalCode'}
                                                        value={this.state.address.postalCode}
                                                        required
                                                        disabled={this.state.frozen}
                                                        onChange={this.handleAddressChange}
                                                        hasError={!!this.state.errors.postalCode}
                                                        errorMessage={this.state.errors.postalCode}
                                                    />
                                                </Grid>

                                                <Grid item xs={6} lg={4}>
                                                    <InputField
                                                        title={"City"}
                                                        name={'city'}
                                                        value={this.state.address.city}
                                                        required
                                                        disabled={this.state.frozen}
                                                        onChange={this.handleAddressChange}
                                                        hasError={!!this.state.errors.city}
                                                        errorMessage={this.state.errors.city}
                                                    />
                                                </Grid>

                                                {this.state.address.country.id === this.state.usaCountryId ? (
                                                    <Grid item xs={6} lg={4}>
                                                        <SelectField
                                                            title={"State"}
                                                            name={"state"}
                                                            value={this.state.address.state.id}
                                                            required
                                                            disabled={this.state.frozen}
                                                            options={this.state.states}
                                                            onChange={this.handleAddressChange}
                                                            hasError={!!this.state.errors.state}
                                                            errorMessage={this.state.errors.state}
                                                        />
                                                    </Grid>
                                                ) : null}

                                                <Grid item xs={6} lg={4}>
                                                    <SelectField
                                                        title={"Country"}
                                                        name={"country"}
                                                        value={this.state.address.country.id}
                                                        required
                                                        disabled={this.state.frozen}
                                                        options={this.state.countries}
                                                        onChange={this.handleAddressChange}
                                                        hasError={!!this.state.errors.country}
                                                        errorMessage={this.state.errors.country}
                                                    />
                                                </Grid>

                                                <Grid item xs={6} lg={4}>
                                                    <InputField
                                                        title={"Industry"}
                                                        name={'companyBusiness'}
                                                        value={this.state.issuerData.companyBusiness}
                                                        required
                                                        disabled={this.state.frozen}
                                                        onChange={this.handleIssuerDataChange}
                                                        hasError={!!this.state.errors.companyBusiness}
                                                        errorMessage={this.state.errors.companyBusiness}
                                                    />
                                                </Grid>

                                                <Grid item xs={6} lg={4}>
                                                    <InputField
                                                        title={"Competitors (if any)"}
                                                        name={'companyCompetitors'}
                                                        value={this.state.issuerData.companyCompetitors}
                                                        disabled={this.state.frozen}
                                                        onChange={this.handleIssuerDataChange}
                                                    />
                                                </Grid>

                                                <Grid item xs={6} lg={4}>
                                                    <SelectField
                                                        title={"Year Company was formed"}
                                                        name={'companyFormedYear'}
                                                        disabled={this.state.frozen}
                                                        value={this.state.issuerData.companyFormedYear}
                                                        options={this.state.years}
                                                        onChange={this.handleIssuerDataChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Card raised>
                                        <CardContent>
                                            <Grid spacing={5} container>
                                                <Grid item xs={12} lg={12}>
                                                    <Typography variant="h6">
                                                        Additional information
                                                    </Typography>

                                                    <Divider/>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} lg={12}>
                                                <Grid spacing={3} container>
                                                    <Grid item xs={6} lg={4}>
                                                        <CurrencyFormatField
                                                            title={"How much capital are looking to raise?"}
                                                            name={"capitalToRaise"}
                                                            prefix={"$ "}
                                                            value={this.state.issuerData.capitalToRaise}
                                                            required
                                                            disabled={this.state.frozen}
                                                            onChange={this.handleIssuerDataChange}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} lg={4}>
                                                        <CurrencyFormatField
                                                            title={"Capital raised to date"}
                                                            name={"capitalToDate"}
                                                            prefix={"$ "}
                                                            disabled={this.state.frozen}
                                                            value={this.state.issuerData.capitalToDate}
                                                            onChange={this.handleIssuerDataChange}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={4} xs={4}>
                                                        <SelectMultipleField
                                                            title={"Type of Offering (Reg Types)"}
                                                            name={"regTypes"}
                                                            disabled={this.state.frozen}
                                                            value={this.state.issuer.regTypes.map(value => value.id)}
                                                            options={this.state.regTypes}
                                                            onChange={this.handleIssuerChange}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={6} xs={6}>
                                                        <InputField
                                                            title={"How close are you to launching the offering?"}
                                                            name={"launchingOffering"}
                                                            value={this.state.issuerData.launchingOffering}
                                                            disabled={this.state.frozen}
                                                            onChange={this.handleIssuerDataChange}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={6} xs={6}>
                                                        <CheckBoxField
                                                            title={"Have you started the raise?"}
                                                            name={"startedRaise"}
                                                            disabled={this.state.frozen}
                                                            options={["Yes", "No"]}
                                                            value={this.state.issuerData.startedRaise}
                                                            onChange={this.handleIssuerDataChange}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item lg={12} xs={12}
                                      style={{textAlign: 'end'}}>
                                    <Button
                                        type={"button"}
                                        color="primary"
                                        variant={"contained"}
                                        onClick={this.handleSubmit}
                                    >
                                        Update
                                    </Button>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Grid spacing={3} container>
                                        <Grid item lg={6} xs={12}>
                                            <Card raised>
                                                <CardContent>
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={9} xs={9}>
                                                            <Typography variant='h6'>
                                                                KYC/AML verification
                                                            </Typography>

                                                            <Divider/>
                                                        </Grid>

                                                        <Grid item lg={3} xs={3}
                                                              style={{textAlign: 'center'}}>

                                                            {
                                                                this.state.dataKycAmlBlock
                                                                    ? <Box>
                                                                        <BeenhereIcon
                                                                            style={{
                                                                                fontSize: 40,
                                                                                color: '#4CAF50',
                                                                                width: '100%'
                                                                            }}/>
                                                                        <Typography
                                                                            variant='caption'>
                                                                            Verified
                                                                        </Typography>
                                                                    </Box>
                                                                    : <Box>
                                                                        <FindInPageIcon
                                                                            style={{
                                                                                fontSize: 40,
                                                                                color: "red",
                                                                                width: '100%'
                                                                            }}/>
                                                                        <Typography variant='caption'>
                                                                            Not Verified (check docs.)
                                                                        </Typography>
                                                                    </Box>
                                                            }
                                                        </Grid>

                                                        <Grid item lg={12} xs={12}>
                                                            {this.state.issuer.id && (
                                                                <UserDocumentation issuerId={this.state.issuer.id}
                                                                                   attributeCode={'KYC_AML'}
                                                                                   onVerifyBlock={this.handleVerifyBlock}
                                                                                   verifyButton={true}
                                                                                   onUnVerifyDocuments={this.onUnVerifyDocuments}/>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        {this.state.user ? (
                                            <Grid item lg={6} xs={12}>
                                                <Card raised>
                                                    <CardContent>
                                                        <UserCommentsPanelComponent user={this.state.user}/>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={this.state.value} index={1}>
                            <Grid container
                                  spacing={3}
                                  justify='center'
                                  alignItems='center'>
                                <Grid item lg={8} xs={12}>
                                    <UserDocumentation issuerId={this.state.issuer.id}
                                                       attributeCode={'OTHER'}
                                                       onVerifyBlock={() => {
                                                       }}/>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        {this.state.user ? (
                            <>
                                <TabPanel value={this.state.value} index={2}>
                                    <Grid spacing={3} container
                                          style={{paddingTop: 10, display: "flex", justifyContent: "center"}}>
                                        <Grid item lg={12} xs={12}>
                                            <Card raised>
                                                <CardContent>
                                                    <MessagePanelComponent
                                                        {...this.props}
                                                        user={this.state.user}/>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value={this.state.value} index={3}>
                                    <Grid spacing={3} container style={{paddingTop: 10}}>
                                        <DealPerIssuerComponent issuerId={this.props.match.params.issuerId}
                                                                user={this.state.user}/>
                                    </Grid>
                                </TabPanel>
                            </>
                        ) : null}
                    </Grid>
                </>
            )

        )
    }
}

export default IssuerFullEdit;