import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import DialogActions from "@material-ui/core/DialogActions";


const CommentsComposeModal = (props) => {
    let handleFormSubmit = (e) => {
        e.preventDefault();
        props.submit({
            'channelId': props.channels[0].id,
            'content': e.target.content.value
        })
        e.target.reset();
        props.close();
    }

    let close = (e) => {
        // e.target.form.reset()
        props.close();
    }

    return (
        <>
            <Dialog
                open={props.show}
                maxWidth="md"
                onClose={close}>
                <form onSubmit={handleFormSubmit}>
                    <DialogTitle id="alert-dialog-title">ADD COMMENT</DialogTitle>
                    <DialogContent style={{overflow: 'hidden'}}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <TextareaAutosize
                                    style={{width: '100%'}}
                                    aria-label="empty textarea"
                                    rowsMin={7}
                                    name={'content'}
                                    required={true}>

                                </TextareaAutosize>
                            </Grid>
                            <Grid item lf={12} xs={12}>
                                <Button variant='contained'
                                        color='primary'
                                        type='submit'>Add Comment
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='outlined'
                            data-dismiss="modal"
                            onClick={close}>Close
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/*<div id="composeMessageModal" className="modal modal-open" role="dialog" style={{*/}
            {/*    transform: props.show ? 'translateY(0vh)' : 'translateY(-100vh)',*/}
            {/*    display: props.show ? 'block' : 'none'*/}
            {/*}}>*/}
            {/*    <div class="modal-dialog modal-lg">*/}
            {/*        <form onSubmit={handleFormSubmit} >*/}
            {/*            <div class="modal-content">*/}
            {/*                <div class="modal-header">*/}
            {/*                    <h4 class="modal-title">Add a comment</h4>*/}
            {/*                </div>*/}
            {/*                <div class="modal-body">*/}

            {/*                    <div class="row">*/}
            {/*                        <div class="col-xs-12 col-sm-10 col-sm-offset-1">*/}
            {/*                            <div class="form-group">*/}

            {/*                            <textarea class="form-control" rows="6" name={'content'} required={true}>*/}
            {/*                            </textarea>*/}

            {/*                            </div>*/}


            {/*                        </div>*/}
            {/*                    </div>*/}


            {/*                </div>*/}
            {/*                <div class="modal-footer text-center">*/}
            {/*                    <button type="button" class="btn btn-primary btn-xl" data-dismiss="modal"*/}
            {/*                            onClick={close}>close*/}
            {/*                    </button>*/}
            {/*                    <button type="submit" class="btn btn-orange btn-xl">Send*/}
            {/*                    </button>*/}
            {/*                </div>*/}

            {/*            </div>*/}
            {/*        </form>*/}

            {/*    </div>*/}
            {/*</div>*/}

        </>
    )
}

export default CommentsComposeModal;