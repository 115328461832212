import React, {useEffect} from 'react';

import {AUTH_TOKEN} from "../../shared/utility";
import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FileViewer from "react-file-viewer";


const DocxPreviewModal = (props) => {

    const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState('');

    useEffect(() => {
        setOpen(!!props.show);
    })

    useEffect(() => {
        if (props.file) {
            let fileToPreview = props.file.split('.');
            setType(fileToPreview[fileToPreview.length - 1])
        }
    }, [props.file])

    const PaperComponent = (props) => {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper {...props} />
            </Draggable>
        );
    }

    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            onClose={props.close}
            open={open}
            disableScrollLock
            disableBackdropClick
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title">

            <DialogTitle
                style={{cursor: 'move'}}
                id="draggable-dialog-title">
                File Preview
            </DialogTitle>

            <DialogContent dividers>
                <FileViewer fileType={type}
                            filePath={
                                props.cms
                                    ? process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/cms/download/' + props.file
                                    + '?access_token=' + localStorage.getItem(AUTH_TOKEN) + '&embedded=true'
                                    : process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/' + props.file
                                    + '?access_token=' + localStorage.getItem(AUTH_TOKEN) + '&embedded=true'
                            }
                />
            </DialogContent>

            <DialogActions>
                <Button
                    type="button"
                    data-dismiss="modal"
                    onClick={props.close}>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DocxPreviewModal;