import React, {Component} from 'react';

import DealService from "../../services/DealService";
import DealStatusService from "../../services/DealStatusService";
import RegTypeService from "../../services/RegTypeService";
import {toast} from "react-toastify";
import InvestmentService from "../../services/InvestmentService";
import CrudTable from "../tables/CrudTable";
import EntityTranslationService from "../../services/EntityTranslationService";
import Dropzone from "react-dropzone";
import DealPaymentTypeService from "../../services/DealPaymentTypeService";
import DocumentService from "../../services/DocumentService";
import PdfPreviewModal from "../modal/PdfPreviewModal";
import ImagePreviewModal from '../modal/ImagePreviewModal';
import DeleteDealPaymentTypeModal from "../modal/DeleteDealPaymentTypeModal";
import PayTypeService from "../../services/PayTypeService";
import DealDataService from '../../services/DealDataService';
import IssuerService from "../../services/IssuerService";
import {Table, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import PublishIcon from "@material-ui/icons/Publish";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ClearIcon from '@material-ui/icons/Clear';
import AddNewPayTypeModal from "../modal/AddNewPayTypeModal";
import Moment from "react-moment";
import Cms from "../cms/Cms";
import SelectMultipleField from "../../components/Molecules/SelectMultipleField";
import SelectField from "../../components/Molecules/SelectField";
import InputField from "../../components/Molecules/InputField";
import CheckBoxField from "../../components/Molecules/CheckBoxField";
import MiscService from "../../services/MiscService";
import {AUTH_TOKEN} from "../../shared/utility";
import SaveIcon from '@material-ui/icons/Save';
import RegistrationService from "../../services/RegistrationStatusService";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>

            {
                value === index && (
                    <Box p={3}>
                        <Typography>
                            {children}
                        </Typography>
                    </Box>
                )
            }
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class OfferingsFullEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dealPaymentTypes: [],
            payType: null,
            offer: {},
            deal: {},
            linkName: '',
            link: '',
            dealLinks: [],
            fileToSave: null,
            documents: [],
            dealDocuments: [],
            documentsToSave: [],
            offeringDocumentsToSave: [],
            key: 'deal-info',
            regTypes: [],
            issuers: [],
            dealStatuses: [],
            countries: [],
            errors: {},

            chosenRegTypes: [],
            offeringName: '',
            issuer: '',
            raisingAmount: '',
            preMoney: '',
            sharePrice: '',
            status: {},
            issuerSignature: 'false',
            autoApprove: 'false',
            paymentACH: 'true',
            excludedCountries: [],
            ipAddressesAtr: {},
            ipAddresses: '',
            individualSteps: [],
            institutionSteps: [],
            chosenIndividualSteps: [],
            chosenInstitutionSteps: [],

            filter: null,
            type: '',
            investmentsUrl: `/rest/investment`,
            showPreview: false,
            fileToPreview: null,
            pages: [],
            value: 0,
            loader: true,
            mainLoader: true,
            noDocuments: false,
            noPayTypeDocuments: false,
            isLink: false,
            isLoading: false,
            isLoadingPayTypeDoc: false,
            showModalAdd: false,
            // documentPayTypeToSave:null,
            investmentsColumnNames: [
                {
                    id: 'id',
                    label: 'ID'
                },
                {
                    id: 'dateCreated',
                    label: 'Investment Submitted',
                    type: 'date',
                    datePattern: "DD.MM.YYYY"
                },
                {
                    id: 'dateApproved',
                    label: 'Investment Approved',
                    type: 'date',
                    datePattern: "DD.MM.YYYY"
                },
                {
                    id: 'dealCode',
                    label: 'Deal code'
                },
                {
                    id: 'investmentStatus',
                    label: 'Status',
                    type: 'translation',
                    translate: EntityTranslationService.translateInvestmentStatus
                },
                {
                    id: 'amount',
                    label: 'Amount',
                    type: 'currency',
                    symbol: '$'
                },
                {
                    id: 'payType',
                    label: 'Pay Type'
                },
                {
                    id: 'firstName',
                    label: 'Investor First Name'
                },
                {
                    id: 'lastName',
                    label: 'Investor Last Name'
                },
                {
                    id: 'email',
                    label: 'E-mail',
                    type: 'link',
                    url: '/investors/edit',
                    paramLink: 'userId',
                    paramValue: 'email'
                }
            ],
            showDeleteModal: false,
            entityToBeDeleted: null,
            showPreviewImage: false
        }
    }

    componentDidMount() {
        this.fetchSteps()
        this.fetchRegTypes()
        this.fetchIssuers()
        this.fetchDealStatuses()
        this.fetchCountries()
        this.fetchDeal(this.props.match.params.dealId);
        // this.loadPages(this.props.match.params.dealId);

    }

    fetchSteps = () => {
        RegistrationService.fetchSteps()
            .then(response => {
                let _individualSteps = response.data
                    .filter(step => step.step.id !== 'STEP_FINISHED')
                    .filter(step => step.userGroup.id === 'USER_INVESTOR_INDIVIDUAL')
                    .map(step => {
                        return {
                            id: step.id,
                            value: step.step.value
                        }
                    })

                let _institutionSteps = response.data
                    .filter(step => step.step.id !== 'STEP_FINISHED')
                    .filter(step => step.userGroup.id === 'USER_INVESTOR_COMPANY')
                    .map(step => {
                        return {
                            id: step.id,
                            value: step.step.value
                        }
                    })

                this.setState({
                    individualSteps: _individualSteps,
                    institutionSteps: _institutionSteps
                })
            })
            .catch(error => {
                toast.error('Error');
            })
    }

    fetchCountries = () => {
        MiscService.fetchCountries()
            .then(data => {
                const countries = data.data.map(country => {
                    return {
                        id: country.id,
                        value: country.name
                    }
                })

                const usaCountry = countries.filter(country => country.value === 'United States')[0]
                const usaCountryId = usaCountry ? usaCountry.id : ''

                this.setState({
                    countries: countries,
                    usaCountryId: usaCountryId
                })

            })
            .catch(error => {
                toast.error('Error');
            })
    }

    fetchRegTypes = () => {
        RegTypeService.fetch().then(data => {
            const _data = data.data.map(type => {
                return {
                    id: type.id,
                    value: type.name,
                }
            });

            this.setState({
                regTypes: _data
            });
        }).catch(error => {
            console.log(error);
        })
    };

    fetchIssuers = () => {
        IssuerService.fetchIssuers()
            .then(response => {
                const _data = response.data.filter(value => (value.user && value.user.accountStatus === 'APPROVED'))
                    .map(value => {
                        return {
                            id: value.id,
                            value: value.companyName ? value.companyName : 'N/A'
                        }
                    })

                this.setState({
                    issuers: _data
                })
            }).catch(error => {
            console.log(error);
        })
    }

    fetchDealStatuses = () => {
        DealStatusService.fetchReal()
            .then(response => {
                this.setState({
                    dealStatuses: response.data
                })
            }).catch(error => {
            console.log(error);
        })
    }

    fetchDocuments(dealId) {
        DealPaymentTypeService.fetchByDealId(dealId)
            .then(response => {
                this.setState({
                    documents: response.data,
                    noPayTypeDocuments: response.data.length === 0
                });
            }).catch(error => {
            console.log(error);
        })
    }

    fetchDeal(dealId) {
        this.setState({
            mainLoader: true
        })

        DealService.fetchDeal(dealId)
            .then(response => {
                // this.fetchDocuments(response.data.id)
                this.fetchDealDocuments(response.data.id)
                this.fetchDealDocumentLinks(response.data.id)
                this.fetchAllowedIPAddresses(response.data.id)

                // const issuer = {
                //     id: response.data.issuer.id,
                //     value: response.data.issuer.pocFullName
                // }
                const chosenRegTypes = response.data.regTypes.map(type => {
                    return {
                        id: type.id,
                        value: type.pocFullName
                    }
                })

                // let individualSteps = ['STEP_CONTACT_INFO', 'STEP_APPROVAL']
                // let institutionSteps = ['STEP_BACKGROUND_INFO', 'STEP_APPROVAL']
                let individualSteps = []
                let institutionSteps = []

                if (response.data.stepGroups && response.data.stepGroups.length > 2) {
                    individualSteps = response.data.stepGroups
                        .filter(step => step.userGroup.id === 'USER_INVESTOR_INDIVIDUAL')
                        .map(step => step.id)

                    institutionSteps = response.data.stepGroups
                        .filter(step => step.userGroup.id === 'USER_INVESTOR_COMPANY')
                        .map(step => step.id)
                }

                this.setState({
                    offer: response.data,
                    deal: response.data,
                    chosenRegTypes: chosenRegTypes,
                    // issuer: issuer,
                    offeringName: response.data.name,
                    raisingAmount: response.data.raiseTarget,
                    preMoney: response.data.preMoney,
                    sharePrice: response.data.sharePrice,
                    status: response.data.dealStatus,
                    issuerSignature: `${response.data.issuerSignature}`,
                    autoApprove: `${response.data.autoApprove}`,
                    paymentACH: `${response.data.paymentACH}`,
                    excludedCountries: response.data.excludedCountries,
                    chosenIndividualSteps: individualSteps,
                    chosenInstitutionSteps: institutionSteps,
                    filter: {
                        code: response.data.code
                    },
                    mainLoader: false
                });
            }).catch(error => {
            console.log(error);
        })
    }

    fetchAllowedIPAddresses = (id) => {
        this.setState({
            loader: true
        })

        DealDataService.fetchByDealAndAttribute({
            dealId: id,
            attributeCode: 'ALLOWED_IP_ADDRESSES'
        })
            .then(response => {
                if (response.data.length !== 0) {
                    this.setState({
                        ipAddressesAtr: response.data[0],
                        ipAddresses: response.data[0].chosenValue,
                        loader: false
                    })
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    onChangeHandler(e) {
        let value = '';

        if (e.target.value === "") {
            value = -1
        } else {
            value = e.target.value
        }

        e.preventDefault();
        let deal = JSON.parse(JSON.stringify(this.state.deal));
        deal[e.target.name] = value;

        this.setState({
            deal: deal
        }, () => {
        });
    }

    submitChanges() {
        if (this.handleValidation()) {
            let deal = JSON.parse(JSON.stringify(this.state.deal));
            deal.name = this.state.offeringName
            deal.issuer = this.state.issuer
            deal.raiseTarget = this.state.raisingAmount
            deal.preMoney = this.state.preMoney
            deal.sharePrice = this.state.sharePrice
            deal.regTypes = this.state.chosenRegTypes
            deal.dealStatus = this.state.status

            this.setState({
                deal: deal
            })

            DealService.updateDeal(deal)
                .then(response => {
                    if (response.data === "") {
                        toast.error('This name already exists');
                    } else {
                        toast.success('Offering edited successfully');
                    }
                }).catch(error => {
                toast.error('Error while editing');
            })

            // this.saveAllowedIps()
        } else {
            toast.error('Check required fields')
        }
    }

    submitSettings() {
        if (true) {
            let request = {
                issuerSignature: this.state.issuerSignature,
                autoApprove: this.state.autoApprove,
                paymentACH: this.state.paymentACH,
                excludedCountries: this.state.excludedCountries,
                allowedIpAddresses: this.state.ipAddresses,
                stepGroups: [
                    ...this.state.chosenIndividualSteps,
                    ...this.state.chosenInstitutionSteps
                ]
                // individualSteps: this.state.chosenIndividualSteps,
                // institutionSteps: this.state.chosenInstitutionSteps
            }

            DealService.updateOfferSettings(this.state.deal.id, request)
                .then(response => {
                    toast.success('Offering edited successfully');
                })
                .catch(error => {
                    toast.error('Error while saving')
                })

            this.saveAllowedIps()
        } else {
            toast.error('Check required fields')
        }
    }

    saveAllowedIps = () => {
        this.pushDealChange({
            id: this.state.ipAddressesAtr.id,
            name: this.state.ipAddressesAtr.name,
            value: this.state.ipAddresses,
            attributeCode: 'ALLOWED_IP_ADDRESSES',
            dealId: this.state.deal.id
        });
    }

    handleValidation() {
        let errors = {}
        let valid = true

        if (!this.state.offeringName) {
            valid = false
            errors.offeringName = 'Cannot be empty'
        }

        if (!this.state.issuer) {
            valid = false
            errors.issuer = 'Cannot be empty'
        }

        // if (!this.state.raisingAmount) {
        //     valid = false
        //     errors.raisingAmount = 'Cannot be empty'
        // }
        //
        // if (this.state.chosenRegTypes.length === 0) {
        //     valid = false
        //     errors.chosenRegTypes = 'Cannot be empty'
        // }
        //
        // if (!this.state.preMoney) {
        //     valid = false
        //     errors.preMoney = 'Cannot be empty'
        // }
        //
        // if (!this.state.sharePrice) {
        //     valid = false
        //     errors.sharePrice = 'Cannot be empty'
        // }

        if (!this.state.status) {
            valid = false
            errors.status = 'Cannot be empty'
        }

        this.setState({
            errors: errors
        })

        return valid
    }

    setDocument(file) {
        DocumentService.saveDocument('deal-payment-type', file).then(response => {
            let fileToSave = {
                'id': response.data.id,
                'filename': response.data.filename,
                'originalFilename': response.data.originalFilename
            }
            this.setState({
                fileToSave: fileToSave
            })
        })
    }

    submitDocumentUpload() {
        this.uploadDocument();
    }

    fetchDealDocuments = (id) => {
        this.setState({
            loader: true
        })
        DealDataService.fetchByDealAndAttribute({
            dealId: id,
            attributeCode: 'DOCUMENT'
        }).then(response => {
            this.setState({
                dealDocuments: response.data,
                noDocuments: response.data.length === 0,
                loader: false
            });
        }).catch(error => {
            console.log(error);
        })
    };

    fetchDealDocumentLinks = (id) => {
        this.setState({
            loader: true
        })

        DealDataService.fetchByDealAndAttribute({
            dealId: id,
            attributeCode: 'LINK'
        })
            .then(response => {
                this.setState({
                    dealLinks: response.data,
                    loader: false
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    uploadDocument() {
        DealPaymentTypeService.save({
            paymentType: JSON.parse(this.state.payType),
            description: this.state.description,
            documentUrl: this.state.fileToSave.filename,
            deal: this.state.deal
        }).then(res => {
            //this.fetchDocuments(this.state.deal.id);
            this.setState({
                fileToSave: null,
                payType: null
            })
        }).catch(err => {
            toast.error('ERROR: Only one file for one Payment Type')
        })
    }

    pushDealChange(body) {
        DealDataService.save(body)
            .then(response => {
                this.fetchDealDocuments(body.dealId);
                this.fetchDealDocumentLinks(body.dealId);
                this.fetchAllowedIPAddresses(body.dealId)
            }).catch(error => {
            console.log(error);
        })
    }

    onFileDelete(id) {
        DealPaymentTypeService.delete(id)
            .then(response => {
                //this.fetchDocuments(this.state.deal.id);
            }).catch(error => {
            console.log(error);
        })
    }

    handleClose = () => {
        this.setState({
            fileToPreview: null,
            showPreview: false
        })
    }

    handleCloseImage = () => {
        this.setState({
            showPreviewImage: false,
            fileToPreview: null
        })
    }

    toggleDeleteModal(entity) {
        if (entity) {
            this.setState({
                entityToBeDeleted: entity
            });
        }

        this.setState((prevState, prevProps) => ({
            showDeleteModal: !prevState.showDeleteModal
        }));
    }

    deleteModalHandler() {
        this.onFileDelete(this.state.entityToBeDeleted.id);
    }

    deleteType() {
        const type = JSON.parse(this.state.payType);
        PayTypeService.deleteType(type.id)
            .then(response => {
                toast.success('The Payment Type successfully deleted');
                this.setState({
                    payType: null,
                })
                this.fetchDealPaymentTypes();
            }).catch(error => {
            toast.error('Cannot delete! Payment method already in use');
        })
    }

    deleteDealData(id) {
        DealDataService.delete(id)
            .then(response => {
                toast.success("The document was successfully deleted");
                this.fetchDealDocuments(this.state.deal.id);
                this.fetchDealDocumentLinks(this.state.deal.id)
            })
            .catch(error => {
                toast.error("An error occurred while deleting the document");
            })
    }

    saveOfferingDocument = (groupId, files, attrCode) => {
        this.setState({isLoading: true});

        DocumentService.saveDocumentMultiple(groupId, files)
            .then(response => {
                let files = response.data.map(file => {
                    return {
                        'id': file.id,
                        'filename': file.filename,
                        'originalFilename': file.originalFilename
                    }
                })
                this.setState(
                    {
                        'offeringDocumentsToSave':
                            [...this.state.offeringDocumentsToSave,
                                ...files
                            ]
                    })
                // this.setState({isLoading: false});
            }).catch(error => {
            this.setState({isLoading: false});
        });
    };

    submitOfferingDocuments = () => {
        this.state.offeringDocumentsToSave.forEach(item => {
            this.pushDealChange({
                name: item.originalFilename,
                value: item.filename,
                attributeCode: 'DOCUMENT',
                dealId: this.state.deal.id
            });
        });

        this.setState({offeringDocumentsToSave: []});
        this.fetchDealDocuments(this.state.deal.id);
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        });
    };

    onFilePreview = (item) => {
        let fileToPreview = '';

        if (item.filename) {
            fileToPreview = item.filename.split('.');

            if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
                this.setState({
                    fileToPreview: item.filename,
                    showPreview: true
                });
            } else {
                this.setState({
                    fileToPreview: item.filename,
                    showPreviewImage: true
                });
            }
        } else {
            fileToPreview = item.chosenValue.split('.');

            if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreview: true
                });
            } else {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreviewImage: true
                });
            }

        }

    };

    previewPayTypeDocument = (item) => {
        let fileToPreview = item.chosenValue ? item.chosenValue.split('.') : item.documentUrl.split('.');

        if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
            this.setState({
                fileToPreview: item.chosenValue ? item.chosenValue : item.documentUrl,
                showPreview: true
            });
        } else {
            this.setState({
                fileToPreview: item.chosenValue ? item.chosenValue : item.documentUrl,
                showPreviewImage: true
            });
        }
    }

    showLinkField = () => {
        this.setState({
            isLink: !this.state.isLink
        })
    }

    addLink = () => {
        if (this.validateLink()) {
            this.pushDealChange({
                name: this.state.linkName,
                value: this.state.link,
                attributeCode: 'LINK',
                dealId: this.state.deal.id
            });

            this.setState({
                linkName: '',
                link: ''
            })
        } else {
            toast.error('Check required fields')
        }
    }

    validateLink = () => {
        let errors = {}
        let valid = true

        if (!this.state.linkName) {
            valid = false
            errors.linkName = 'Cannot be empty'
        }

        if (!this.state.link) {
            valid = false
            errors.link = 'Cannot be empty'
        }

        this.setState({
            errors: errors
        })

        return valid
    }

    startLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        })
    }

    startLoadingPayTypeDoc = () => {
        this.setState({
            isLoadingPayTypeDoc: !this.state.isLoadingPayTypeDoc
        })
    }

    remove(document) {
        let documents = [...this.state.offeringDocumentsToSave];
        let newDocuments = documents.filter(d => {
            return (d.id !== document.id);
        });

        this.setState({offeringDocumentsToSave: newDocuments});
    }

    removePayTypeDoc = () => {
        this.setState({
            fileToSave: null
        })
    }

    showModalAdd = () => {
        this.setState({
            showModalAdd: true
        })
    }

    closeModalAdd = () => {
        this.setState({
            showModalAdd: false
        })
    }

    render() {
        return (
            <>
                {
                    this.state.entityToBeDeleted
                        ? <DeleteDealPaymentTypeModal
                            show={this.state.showDeleteModal}
                            close={(e) => this.toggleDeleteModal()}
                            submit={() => this.deleteModalHandler()}
                            entity={this.state.entityToBeDeleted}/>
                        : null
                }

                <PdfPreviewModal
                    className="modal"
                    show={this.state.showPreview}
                    file={this.state.fileToPreview}
                    close={this.handleClose}/>

                <ImagePreviewModal
                    className="modal"
                    show={this.state.showPreviewImage}
                    file={this.state.fileToPreview}
                    close={this.handleCloseImage}/>

                <AddNewPayTypeModal
                    show={this.state.showModalAdd}
                    close={this.closeModalAdd}
                    submit={this.addType}/>

                <Grid container spacing={3} style={{paddingTop: 80}}>
                    <Grid lg={12} xs={12}>
                        <Grid item lg={12} xs={12}>
                            <AppBar position='static' color="default">
                                <Tabs value={this.state.value}
                                      onChange={this.handleChange}
                                      indicatorColor="primary"
                                      textColor="primary"
                                      variant="fullWidth"
                                      aria-label="simple tabs example">
                                    <Tab label="Information" {...a11yProps(0)} />
                                    <Tab label="Investors" {...a11yProps(1)} />
                                    <Tab label="Documentation" {...a11yProps(2)} />
                                </Tabs>
                            </AppBar>
                        </Grid>

                        <TabPanel index={this.state.value} value={0}>
                            {
                                this.state.mainLoader
                                    ? <Box style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        minHeight: '50vh'
                                    }}>
                                        <CircularProgress/>
                                    </Box>
                                    : <Grid container
                                            spacing={2}
                                            justify='center'
                                            alignItems='center'>
                                        <Grid item lg={12} xs={12}>
                                            <Card raised>
                                                <CardContent>
                                                    <Grid container spacing={2}>
                                                        <Grid item lg={12} xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item lg={8} xs={8}>
                                                                    <Typography variant='h6'>
                                                                        Offering Information
                                                                    </Typography>
                                                                </Grid>

                                                                <Grid item lg={4} xs={4}
                                                                      style={{
                                                                          display: "flex",
                                                                          justifyContent: "flex-end"
                                                                      }}>
                                                                    <Button variant='contained'
                                                                            color='primary'
                                                                            size={'small'}
                                                                            onClick={() => this.submitChanges()}>
                                                                        Save
                                                                    </Button>
                                                                </Grid>

                                                                <Grid item lg={12} xs={12}>
                                                                    <Divider/>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item lg={12} xs={12}>
                                                            <Grid container spacing={3}>
                                                                <Grid item lg={3} xs={6}>
                                                                    <InputField
                                                                        title={'Name'}
                                                                        required={true}
                                                                        value={this.state.offeringName}
                                                                        hasError={this.state.errors.offeringName}
                                                                        errorMessage={this.state.errors.offeringName}
                                                                        onChange={(value) => this.setState({
                                                                            offeringName: value
                                                                        })}
                                                                    />
                                                                </Grid>

                                                                <Grid item lg={3} xs={6}>
                                                                    <SelectMultipleField
                                                                        title={'Reg Types'}
                                                                        value={this.state.chosenRegTypes.map(type => type.id)}
                                                                        options={this.state.regTypes}
                                                                        hasError={this.state.errors.chosenRegTypes}
                                                                        errorMessage={this.state.errors.chosenRegTypes}
                                                                        onChange={(value) => this.setState({
                                                                            chosenRegTypes: value
                                                                        })}
                                                                    />
                                                                </Grid>

                                                                <Grid item lg={3} xs={6}>
                                                                    <InputField
                                                                        title={'Amount to be raised'}
                                                                        value={this.state.raisingAmount}
                                                                        type={'number'}
                                                                        hasError={this.state.errors.raisingAmount}
                                                                        errorMessage={this.state.errors.raisingAmount}
                                                                        onChange={(value) => this.setState({
                                                                            raisingAmount: value
                                                                        })}
                                                                    />
                                                                </Grid>

                                                                <Grid item lg={3} xs={6}>
                                                                    <SelectField
                                                                        title={'Status'}
                                                                        required={true}
                                                                        value={this.state.status.id}
                                                                        options={this.state.dealStatuses}
                                                                        hasError={this.state.errors.status}
                                                                        errorMessage={this.state.errors.status}
                                                                        onChange={(value) => this.setState({
                                                                            status: value
                                                                        })}
                                                                    />
                                                                </Grid>

                                                                <Grid item lg={3} xs={6}>
                                                                    <InputField
                                                                        title={'Pre-Money Valuation'}
                                                                        value={this.state.preMoney}
                                                                        type={'number'}
                                                                        hasError={this.state.errors.preMoney}
                                                                        errorMessage={this.state.errors.preMoney}
                                                                        onChange={(value) => this.setState({
                                                                            preMoney: value
                                                                        })}
                                                                    />
                                                                </Grid>

                                                                <Grid item lg={3} xs={6}>
                                                                    <InputField
                                                                        title={'Share Price'}
                                                                        value={this.state.sharePrice}
                                                                        type={'number'}
                                                                        hasError={this.state.errors.sharePrice}
                                                                        errorMessage={this.state.errors.sharePrice}
                                                                        onChange={(value) => this.setState({
                                                                            sharePrice: value
                                                                        })}
                                                                    />
                                                                </Grid>

                                                                <Grid item lg={3} xs={6}>
                                                                    <SelectField
                                                                        title={'Issuer'}
                                                                        required={true}
                                                                        value={this.state.issuer.id}
                                                                        options={this.state.issuers}
                                                                        hasError={this.state.errors.issuer}
                                                                        errorMessage={this.state.errors.issuer}
                                                                        onChange={(value) => this.setState({
                                                                            issuer: value
                                                                        })}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item lg={12} xs={12}>
                                            <Card raised>
                                                <CardContent>
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={12} xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item lg={8} xs={8}>
                                                                    <Typography variant='h6'>
                                                                        Offering Settings
                                                                    </Typography>
                                                                </Grid>

                                                                <Grid item lg={4} xs={4}
                                                                      style={{
                                                                          display: "flex",
                                                                          justifyContent: "flex-end"
                                                                      }}>
                                                                    <Button variant='contained'
                                                                            color='primary'
                                                                            size={'small'}
                                                                            onClick={() => this.submitSettings()}>
                                                                        Save
                                                                    </Button>
                                                                </Grid>

                                                                <Grid item lg={12} xs={12}>
                                                                    <Divider/>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item lg={12} xs={12}>
                                                            <Grid container spacing={3}>
                                                                <Grid item lg={3} xs={6}>
                                                                    {/*<SelectMultipleField*/}
                                                                    {/*    title={`Excluded countries (count: ${this.state.excludedCountries.length})`}*/}
                                                                    {/*    value={this.state.excludedCountries.map(type => type.id)}*/}
                                                                    {/*    options={this.state.countries}*/}
                                                                    {/*    onChange={(value) => this.setState({*/}
                                                                    {/*        excludedCountries: value*/}
                                                                    {/*    })}*/}
                                                                    {/*/>*/}
                                                                </Grid>

                                                                <Grid item lg={3} xs={6}>
                                                                    <InputField
                                                                        title={'Allowed IP addresses (use "," for multiple IPs)'}
                                                                        multiline
                                                                        value={this.state.ipAddresses}
                                                                        onChange={(value) => this.setState({
                                                                            ipAddresses: value
                                                                        })}
                                                                    />
                                                                </Grid>

                                                                <Grid item lg={3} xs={6}>
                                                                    <SelectMultipleField
                                                                        title={'Display Individual Steps'}
                                                                        value={this.state.chosenIndividualSteps}
                                                                        options={this.state.individualSteps}
                                                                        // requiredItems={['STEP_CONTACT_INFO', 'STEP_APPROVAL']}
                                                                        //hasError={this.state.errors.chosenRegTypes}
                                                                        //errorMessage={this.state.errors.chosenRegTypes}
                                                                        onChange={(value) => this.setState({
                                                                            chosenIndividualSteps: value.map(value => value.id)
                                                                        })}
                                                                    />
                                                                </Grid>

                                                                <Grid item lg={3} xs={6}>
                                                                    <SelectMultipleField
                                                                        title={'Display Institution Steps'}
                                                                        value={this.state.chosenInstitutionSteps}
                                                                        options={this.state.institutionSteps}
                                                                        // hasError={this.state.errors.chosenRegTypes}
                                                                        //errorMessage={this.state.errors.chosenRegTypes}
                                                                        onChange={(value) => this.setState({
                                                                            chosenInstitutionSteps: value.map(value => value.id)
                                                                        })}
                                                                    />
                                                                </Grid>

                                                                <Grid item lg={3} xs={6}>
                                                                    <CheckBoxField
                                                                        title={"Require a signature from Issuer"}
                                                                        name={"issuerSignature"}
                                                                        required
                                                                        options={[
                                                                            {id: 'true', value: "Yes"},
                                                                            {id: 'false', value: "No"}
                                                                        ]}
                                                                        value={this.state.issuerSignature}
                                                                        onChange={(value) => this.setState({
                                                                            issuerSignature: value
                                                                        })}
                                                                    />
                                                                </Grid>

                                                                <Grid item lg={3} xs={6}>
                                                                    <CheckBoxField
                                                                        title={"Auto approve"}
                                                                        name={"autoApprove"}
                                                                        required
                                                                        options={[
                                                                            {id: 'true', value: "Yes"},
                                                                            {id: 'false', value: "No"}
                                                                        ]}
                                                                        value={this.state.autoApprove}
                                                                        onChange={(value) => this.setState({
                                                                            autoApprove: value
                                                                        })}
                                                                    />
                                                                </Grid>

                                                                <Grid item lg={3} xs={6}>
                                                                    <CheckBoxField
                                                                        title={"ACH payment"}
                                                                        name={"paymentACH"}
                                                                        required
                                                                        options={[
                                                                            {id: 'true', value: "Yes"},
                                                                            {id: 'false', value: "No"}
                                                                        ]}
                                                                        value={this.state.paymentACH}
                                                                        onChange={(value) => this.setState({
                                                                            paymentACH: value
                                                                        })}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        <Grid item lg={12} xs={12}>
                                            <Card raised>
                                                <CardContent>
                                                    <Cms dealId={this.props.match.params.dealId}/>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                            }
                        </TabPanel>

                        <TabPanel index={this.state.value} value={1}>
                            <Card raised>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12}>
                                            <Typography variant='h6'>Investors</Typography>
                                        </Grid>

                                        <Divider/>
                                    </Grid>

                                    {
                                        this.state.filter
                                            ? <CrudTable
                                                url={this.state.investmentsUrl}
                                                editModalBody={'investment'}
                                                theadClassName="text-uppercase thead-orange"
                                                sort={''}
                                                pageSize={10}
                                                columns={this.state.investmentsColumnNames}
                                                filter={this.state.filter}
                                                transform={InvestmentService.transform}
                                                canEdit={true}/>
                                            : null
                                    }
                                </CardContent>
                            </Card>
                        </TabPanel>

                        <TabPanel index={this.state.value} value={2}>
                            <Grid container
                                  spacing={3}
                                  justify='center'
                                  alignItems='center'>
                                <Grid item lg={8} xs={8}>
                                    <Card raised>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography variant='h6'>
                                                        Documents
                                                    </Typography>
                                                </Grid>

                                                <Divider/>
                                            </Grid>

                                            <Box mb='10px'
                                                 className="upload__block--title"
                                                 onClick={this.showLinkField}
                                            >
                                                {this.state.isLink ? <RemoveIcon/> : <AddIcon/>}

                                                <Box ml="8px" component="span">
                                                    Add document link (DigiSigner service)
                                                </Box>
                                            </Box>

                                            <Box mb="15px"
                                                 p="25px"
                                                 className={this.state.isLink ? 'upload__view' : 'upload__hidden'}
                                                 style={{background: '#FCFCFC'}}
                                            >
                                                <Grid container spacing={2}
                                                      justify={"space-between"}>
                                                    <Grid item lg={6} xs={12}>
                                                        <InputField
                                                            title={'Document Name'}
                                                            value={this.state.linkName}
                                                            required={true}
                                                            hasError={this.state.errors.linkName}
                                                            errorMessage={this.state.errors.linkName}
                                                            onChange={(value) => this.setState({
                                                                linkName: value
                                                            })}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={6} xs={12}>
                                                        <InputField
                                                            title={'Link'}
                                                            value={this.state.link}
                                                            required={true}
                                                            hasError={this.state.errors.link}
                                                            errorMessage={this.state.errors.link}
                                                            onChange={(value) => this.setState({
                                                                link: value
                                                            })}
                                                        />
                                                    </Grid>

                                                    <Grid item lg={12} xs={12}
                                                          style={{display: "flex", justifyContent: 'flex-end'}}>
                                                        <Button variant='contained'
                                                                color='primary'
                                                                onClick={this.addLink}>
                                                            Add Document Link
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            <Divider/>

                                            <Box mt='10px'
                                                 className="upload__block--title"
                                                 onClick={this.startLoading}
                                            >
                                                {this.state.isLoading ? <RemoveIcon/> : <AddIcon/>}

                                                <Box ml="8px" component="span">
                                                    Add document
                                                </Box>
                                            </Box>

                                            <Dropzone multiple={true}
                                                      accept={'application/pdf,image/png,image/jpeg'}
                                                      onDrop={acceptedFiles => this.saveOfferingDocument('other', acceptedFiles, 'OTHER')}>
                                                {({getRootProps, getInputProps}) => (
                                                    <Box
                                                        mt="15px"
                                                        mb="15px">

                                                        <Box
                                                            mb="15px"
                                                            p="25px"
                                                            className={this.state.isLoading ? 'upload__view' : 'upload__hidden'}
                                                            style={{background: '#FCFCFC'}}>
                                                            <Box>
                                                                <Box>
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        gutterBottom>Only JPG, PNG or PDF files. File
                                                                        must be less than 20 Mb <span
                                                                            className="required__circle">*</span>
                                                                    </Typography>
                                                                </Box>
                                                                {
                                                                    this.state.offeringDocumentsToSave.map(document => {
                                                                            return (
                                                                                <Box
                                                                                    pt="20px"
                                                                                    pb="5px"
                                                                                    style={{borderColor: '#DFDFDF'}}
                                                                                    borderBottom={1}
                                                                                    display="flex"
                                                                                    key={document.id}>

                                                                                    <Box flexGrow={1}>
                                                                                        {document.originalFilename}
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Button
                                                                                            color="primary"
                                                                                            name={'file-01'}
                                                                                            type={"button"}
                                                                                            onClick={() => this.onFilePreview(document)}>
                                                                                            Preview
                                                                                        </Button>

                                                                                        <Button
                                                                                            style={{color: '#B71C1C'}}
                                                                                            name={'file-01'}
                                                                                            type={"button"}
                                                                                            onClick={(e) => this.remove(document)}>
                                                                                            Delete
                                                                                        </Button>
                                                                                    </Box>
                                                                                </Box>
                                                                            )
                                                                        }
                                                                    )
                                                                }

                                                                {
                                                                    this.state.offeringDocumentsToSave.length !== 0
                                                                        ? <Box style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'flex-end',
                                                                            paddingTop: 10
                                                                        }}>

                                                                            <Button variant='contained'
                                                                                    color='primary'
                                                                                    onClick={this.submitOfferingDocuments}>
                                                                                Submit Documents

                                                                            </Button>

                                                                        </Box>
                                                                        : null
                                                                }
                                                            </Box>

                                                            <Box mt="25px" display="flex">
                                                                <Button onClick={this.startLoading}>
                                                                    Cancel
                                                                </Button>

                                                                <Box ml="20px">
                                                                    <input
                                                                        id={'file-01'}
                                                                        type={"file"} {...getInputProps()}
                                                                        className="custom-file-input"
                                                                        accept={'application/pdf,image/png,image/jpeg'}
                                                                        required>
                                                                    </input>

                                                                    <label htmlFor={'file-01'}>
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            endIcon={<PublishIcon/>}
                                                                            component="span">Upload document</Button>
                                                                    </label>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Dropzone>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item lg={8} xs={8}>
                                    <Card raised>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography variant='h6'>
                                                        Uploaded Documents
                                                    </Typography>
                                                </Grid>

                                                <Divider/>

                                                {
                                                    this.state.loader
                                                        ? <Grid item lg={12} xs={12}
                                                                style={{display: "flex", justifyContent: "center"}}>
                                                            <CircularProgress/>
                                                        </Grid>
                                                        : this.state.noDocuments
                                                        ? <Grid item lg={12} xs={12}>
                                                            <Typography>
                                                                No Documents
                                                            </Typography>
                                                        </Grid>
                                                        : <TableContainer>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {/*<TableCell>Document Description</TableCell>*/}
                                                                        <TableCell>
                                                                            Document Name
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Date Uploaded
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>

                                                                {
                                                                    this.state.dealDocuments.map(document => {
                                                                        return (
                                                                            <TableRow>
                                                                                {/*<TableCell>{document.chosenValue}</TableCell>*/}
                                                                                <TableCell>{document.documentOriginalName}</TableCell>
                                                                                <TableCell>{<Moment
                                                                                    format={'LLL'}>{document.dateCreated}</Moment>}</TableCell>
                                                                                <TableCell
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        justifyContent: 'flex-end'
                                                                                    }}>
                                                                                    <Button
                                                                                        onClick={() => this.onFilePreview(document)}
                                                                                        variant='contained'
                                                                                        color='primary'
                                                                                        size='small'
                                                                                        startIcon={<VisibilityIcon/>}>
                                                                                        Preview
                                                                                    </Button>

                                                                                    <Button
                                                                                        onClick={() => window.open(process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/'
                                                                                            + document.chosenValue + '?access_token=' + localStorage.getItem(AUTH_TOKEN)
                                                                                            + '&embedded=false')}
                                                                                        type={"submit"}
                                                                                        variant='contained'
                                                                                        color='primary'
                                                                                        size='small'
                                                                                        style={{marginLeft: 6}}
                                                                                        startIcon={
                                                                                            <SaveIcon/>}>
                                                                                        Download
                                                                                    </Button>

                                                                                    <Button
                                                                                        onClick={() => this.deleteDealData(document.id)}
                                                                                        variant='contained'
                                                                                        size='small'
                                                                                        style={{
                                                                                            backgroundColor: '#CD5C5C',
                                                                                            marginLeft: 8,
                                                                                            color: 'white'
                                                                                        }}
                                                                                        startIcon={<ClearIcon/>}>
                                                                                        Delete
                                                                                    </Button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })
                                                                }
                                                            </Table>
                                                        </TableContainer>
                                                }
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item lg={8} xs={8}>
                                    <Card raised>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography variant='h6'>
                                                        Links to Documents (DigiSigner service)
                                                    </Typography>
                                                </Grid>

                                                <Divider/>

                                                {this.state.loader ? (
                                                    <Grid item lg={12} xs={12}
                                                          style={{display: "flex", justifyContent: "center"}}
                                                    >
                                                        <CircularProgress/>
                                                    </Grid>
                                                ) : this.state.dealLinks.length === 0 ? (
                                                    <Grid item lg={12} xs={12}>
                                                        <Typography>
                                                            No Links
                                                        </Typography>
                                                    </Grid>
                                                ) : (
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        Document Name
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Link
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Date Added
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>

                                                            {
                                                                this.state.dealLinks.map(link => {
                                                                    return (
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                {link.documentOriginalName}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {link.chosenValue}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {<Moment format={'LLL'}>
                                                                                    {link.dateCreated}
                                                                                </Moment>}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'flex-end'
                                                                                }}>
                                                                                {/*<Button*/}
                                                                                {/*    onClick={() => this.onFilePreview(document)}*/}
                                                                                {/*    variant='contained'*/}
                                                                                {/*    color='primary'*/}
                                                                                {/*    size='small'*/}
                                                                                {/*    startIcon={<VisibilityIcon/>}>*/}
                                                                                {/*    Preview*/}
                                                                                {/*</Button>*/}
                                                                                <Button
                                                                                    onClick={() => this.deleteDealData(link.id)}
                                                                                    variant='contained'
                                                                                    size='small'
                                                                                    style={{
                                                                                        backgroundColor: '#CD5C5C',
                                                                                        marginLeft: 8,
                                                                                        color: 'white'
                                                                                    }}
                                                                                    startIcon={<ClearIcon/>}>
                                                                                    Delete
                                                                                </Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })
                                                            }
                                                        </Table>
                                                    </TableContainer>
                                                )}
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default OfferingsFullEdit;