import React from 'react';

const email = (props) => {
    let defaultValue = {
        chosenValue: ''
    };

    const handleOnBlur = (e) => {
        let request = {
            "attributeCode": props.attributeCode,
            "value": e.target.value
        };
        props.onBlurHandler(request);
    };

    return (
        <input
            onBlur={(e) => handleOnBlur(e)}
            defaultValue={props.defaultValue ? props.defaultValue.chosenValue : defaultValue.chosenValue}
            disabled={props.disabled}
            type="email"
            className="input__custom--big--16"/>
    );
};

export default email;