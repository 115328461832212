import React, {Component} from 'react';
import {toast} from "react-toastify";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TextField from "@material-ui/core/TextField";
import TableContainer from '@material-ui/core/TableContainer';
import Paper from "@material-ui/core/Paper";
import {Table} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import InvestorService from "../../services/InvestorService";

class ApproveTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            numberOfPages: 0,
            pageList: [],
            pagePagination: 1,
            order: 'desc',
            orderBy: '',
            pageSize: 10,
            loader: false,
            pendingInvestors: []
        };
    }

    componentDidMount() {
        this.fetchPendingInvestors()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.numberOfPages !== prevState.numberOfPages) {
            let initialPageList = [];
            for (let i = 1; i <= Math.min(5, nextProps.numberOfPages); i++) {
                initialPageList.push(i);
            }
            return {
                numberOfPages: nextProps.numberOfPages,
                pageList: initialPageList
            }
        }
    }

    fetchPendingInvestors() {
        this.setState({
            loader: true,
        });

        InvestorService.fetchPendingInvestors(this.state.orderBy, this.state.order,
            this.state.currentPage - 1, this.state.pageSize, {userAccountStatus: 'WAITING_FOR_APPROVAL'})
            .then(response => {
                let pendingInvestors = InvestorService.transformInvestors(response.data.content);

                this.setState({
                    numberOfPages: response.data.totalPages,
                    pendingInvestors: pendingInvestors,
                    loader: false,
                });
            }).catch(error => {
            console.log(error);
        });
    }

    approveInvestor(email) {
        this.setState({
                loader: true,
            }, () =>
                InvestorService.approveInvestor(email)
                    .then(response => {
                        this.fetchPendingInvestors()
                        this.props.pageChanged()
                        toast.success('Approved successfully');
                    }).catch(error => {
                    toast.error('Approval aborted. The investor has no reg types');

                    this.setState({
                        loader: false,
                    })
                })
        );
    }

    declineInvestor(email) {
        this.setState({
                loader: true,
            }, () =>
                InvestorService.declineInvestor(email)
                    .then(response => {
                        this.fetchPendingInvestors()
                        this.props.pageChanged()
                    }).catch(error => {
                    toast.error(error);

                    this.setState({
                        loader: false,
                    })
                })
        );
    }

    lastPage() {
        if (this.state.currentPage < this.state.numberOfPages) {
            let oldPage = this.state.page

            this.setState((prevState) => ({
                currentPage: this.state.numberOfPages
            }), () => {
                let newPageList = [...this.state.pageList];

                for (let i = oldPage; i <= this.state.totalPages; i++) {
                    newPageList.push(this.state.currentPage);
                    newPageList.shift();

                    this.setState({
                        pageList: newPageList
                    })
                }

                this.fetchPendingInvestors();
            });
        }
    }

    nextPage() {
        if (this.state.currentPage < this.state.numberOfPages) {
            this.setState((prevState) => ({
                currentPage: prevState.currentPage + 1
            }), () => {
                if (this.state.currentPage > this.state.pageList[this.state.pageList.length - 1]) {
                    let newPageList = [...this.state.pageList];
                    newPageList.push(this.state.currentPage);
                    newPageList.shift();

                    this.setState({
                        pageList: newPageList
                    })

                }

                this.fetchPendingInvestors();
            });
        }
    }

    firstPage() {
        if (this.state.currentPage > 1) {
            this.setState((prevState) => ({
                currentPage: 1,
            }), () => {
                this.fetchPendingInvestors();
            });
        }
    }

    previousPage() {
        if (this.state.currentPage > 1) {
            this.setState((prevState) => ({
                currentPage: prevState.currentPage - 1
            }), () => {
                if (this.state.currentPage < this.state.pageList[0]) {
                    let newPageList = [...this.state.pageList];
                    newPageList.unshift(this.state.currentPage);
                    newPageList.pop();

                    this.setState({
                        pageList: newPageList
                    })
                }

                this.fetchPendingInvestors();
            });
        }
    }

    setPage(page) {
        this.setState({
            currentPage: page
        }, () => {
            this.fetchPendingInvestors();
        });

    }

    onChangeGetSpecifiedPage = (e) => {
        this.setState({
            pagePagination: +e.target.value
        });

    }

    currentPaginationPage = () => {
        let paginationPageChecked = this.state.pagePagination;

        if (paginationPageChecked <= this.state.numberOfPages && paginationPageChecked > 0) {
            this.setState((prevState) => ({
                currentPage: paginationPageChecked
            }), () => {
                let newPageList = [];

                for (let i = this.state.currentPage; i <= Math.min(this.state.numberOfPages, this.state.pagePagination + this.state.pageSelectorSize - 1); i++) {
                    newPageList.push(i);

                    this.setState({
                        pageList: newPageList
                    })
                }
                this.fetchPendingInvestors();
            });
        } else {
            toast.error('This page does not exist');
        }
    }

    sortHandler = (value, direction) => {
        this.setState({
            order: direction,
            orderBy: value
        }, () => this.fetchPendingInvestors())
    }

    render() {
        return (
            <>
                <TableContainer component={Paper}>
                    {this.state.loader && (
                        <LinearProgress
                            variant="indeterminate"
                            color="primary"
                        />
                    )}

                    <Table aria-label="simple table">
                        <TableHead style={{background: '#263238'}}>
                            <TableRow>
                                {this.props.columns.map((column, index) =>
                                    <TableCell
                                        key={column.id}
                                        style={{padding: '15px 0px 15px 15px', color: '#FBFCFC'}}
                                        className={index !== 0 ? '' : ''}
                                        sortDirection={this.state.order}
                                    >
                                        <TableSortLabel
                                            style={{color: '#FBFCFC'}}
                                            className={'table'}
                                            active={this.state.orderBy === column}
                                            direction={this.state.order}
                                            onClick={() => this.sortHandler(column.sort, this.state.order === 'asc' ? 'desc' : 'asc')}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    </TableCell>
                                )}

                                <TableCell
                                    key={"approve"}
                                    align="right"
                                    style={{paddingRight: 40, color: '#FBFCFC'}}
                                >
                                    Approve
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        {!this.state.loader && (
                            <TableBody>
                                {this.state.pendingInvestors.map(row => (
                                    <TableRow key={row.email}>
                                        {Object.values(row).map((column, index) => (
                                            <TableCell
                                                key={column + index}
                                                align={"left"}
                                            >
                                                {column}
                                            </TableCell>
                                        ))}

                                        <TableCell align="right">
                                            <Tooltip title="Approve">
                                                <IconButton
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => this.approveInvestor(row.email)}
                                                >
                                                    <CheckIcon/>
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Disapprove">
                                                <IconButton
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => this.declineInvestor(row.email)}>
                                                    <ClearIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>

                <Box width="100%" display="flex" justifyContent="flex-end">
                    <Box mt={2} mb={4} display="flex" alignItems="center">
                        <Button
                            size="small"
                            onClick={() => this.firstPage()}>
                            <FirstPageIcon/>
                        </Button>

                        <Button
                            size="small"
                            onClick={() => this.previousPage()}>
                            <ChevronLeftIcon/>
                        </Button>

                        {
                            this.state.pageList.map(item => {
                                return (
                                    <Box
                                        key={item}
                                        px={2}
                                        onClick={() => this.setPage(item)}
                                    >
                                        <Box
                                            onClick={() => this.setPage(item)}
                                            className={item === this.state.page ? 'paginationActive' : 'paginationPassive'}>
                                            {item}
                                        </Box>
                                    </Box>
                                )
                            })
                        }

                        <Button
                            size="small"
                            onClick={() => this.nextPage()}>
                            <ChevronRightIcon/>
                        </Button>

                        <Button
                            size="small"
                            onClick={() => this.lastPage()}>
                            <LastPageIcon/>
                        </Button>

                        <TextField
                            size="small"
                            variant="outlined"
                            onChange={this.onChangeGetSpecifiedPage}
                            type={"number"}
                            style={{width: 80, margin: 'auto'}}
                            min={0}/>

                        <Box mx={1}>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={this.currentPaginationPage}>Go
                            </Button>
                        </Box>

                        {this.state.flag ?
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => this.editTableHandlerAll()}>
                                Save Status Changes
                            </Button> : null}
                    </Box>
                </Box>
            </>
        );
    }
}


export default ApproveTable;