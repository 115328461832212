import React, {Component} from 'react';
import InviteIssuerModal from '../modal/InviteIssuerModal';
import {toast} from 'react-toastify';
import IssuerService from '../../services/IssuerService';
import CrudTable from '../../containers/tables/CrudTable';
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AddIcon from '@material-ui/icons/Add';
import Divider from "@material-ui/core/Divider";
import SelectField from "../../components/Molecules/SelectField";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputField from "../../components/Molecules/InputField";
import AccountStatusService from "../../services/AccountStatusService";
import CreateUserModal from "../modal/CreateUserModal";
import UserService from "../../services/UserService";
import {withStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";


const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },

    typeIsser: {
        [theme.breakpoints.down("xs")]: {
            marginTop: '-20px'
        },
    },

    tableWidthTabbleCell: {
        [theme.breakpoints.down("xs")]: {
            minWidth: '150px'
        },
    }
});


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class Issuers extends Component {

    state = {
        loader: false,
        issuerUrl: '/rest/issuers',
        invitedIssuerUrl: '/rest/issuers/unvalidated',
        showInviteIssuerModal: false,
        showCreateIssuerModal: false,
        showDeleteIssuerModal: false,
        showInvitedIssuerModal: false,
        showInvitedDeleteIssuerModal: false,
        invitedIssuerColumnNames: [
            {
                id: 'pocFullName',
                label: 'Issuer full Name',
                sort: 'pocFullName'
            },
            {
                id: 'companyName',
                label: 'Company name',
                sort: 'companyName'
            },
            {
                id: 'phone',
                label: 'Phone',
                sort: 'phone'
            },
            {
                id: 'email',
                label: 'E-mail',
                sort: 'email'
            },
            {
                id: 'accountStatus',
                label: 'Account Status',
                sort: ''
            },

        ],
        issuersColumnNames: [
            {
                id: 'pocFullName',
                label: 'Issuer full Name',
                sort: 'pocFullName'
            },
            {
                id: 'companyName',
                label: 'Company name',
                sort: 'companyName'
            },
            {
                id: 'phone',
                label: 'Phone',
                sort: 'phone'
            },
            {
                id: 'pocIssuerEmail',
                label: 'Email',
                sort: 'email'
            },
            {
                id: 'userAccountStatus',
                label: 'Account Status',
                type: 'splitAccountStatus',
                sort: 'userAccountStatus'
            },
        ],
        issuersStatus: 'Registered',
        issuersStatuses: ['Registered', 'Invited'],
        issuersCurrentPage: 0,
        issuers: [],
        issuersPages: 0,
        issuerToBeDeleted: {},
        sizes: [10, 20, 50, 100, 200],
        pageSize: 10,
        sort: '',
        filter: {
            pocFullName: '',
            companyName: '',
            phone: '',
            pocIssuerEmail: '',
            userAccountStatus: ''
        },
        value: 0
    }

    componentDidMount() {
        //this.fetchIssuers(0);
    }

    toggleInviteIssuerModal = () => {
        this.setState((prevState) => ({
            showInviteIssuerModal: !prevState.showInviteIssuerModal
        }));
    }

    toggleCreateIssuerModal = () => {
        this.setState((prevState) => ({
            showCreateIssuerModal: !prevState.showCreateIssuerModal
        }))
    }

    inviteIssuer = (email) => {
        this.setState({
            loader: true
        })

        IssuerService.inviteIssuer({email: email})
            .then(response => {
                toast.success(`Invitation sent by email: ${email}!`);
                this.toggleInviteIssuerModal()

                this.setState({
                    loader: false
                });
            }).catch(error => {
            toast.error('An error occurred, please try other email.');

            this.setState({
                loader: false
            });
        });
    }

    createIssuer = (data) => {
        this.setState({
            loader: true
        })

        UserService.addUserByAdmin(data)
            .then(res => {
                toast.success('New issuer added successfully')

                this.setState({
                    loader: false
                })
            })
            .catch(err => {
                toast.error('Error while adding a new issuer')
                console.log(err);

                this.setState({
                    loader: false
                })
            })
    }

    setIssuerType = (value) => {
        this.setState({
            issuersStatus: value
        })
    }

    onChangeSearchField = (value, field) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [field]: value.id ? value.id : value
            }
        })
    }

    onPageSizeChange = (value) => {
        this.setState({
            pageSize: value
        })
    }

    onSortChange = (value) => {
        this.setState({
            sort: value.id ? value.id : value
        })
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        })
    }


    render() {


        const {classes} = this.props;

        return (
            this.state.loader
                ? <Box style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: '50vh'
                }}>
                    <CircularProgress/>
                </Box>
                : <Grid container spacing={3}
                        style={{marginTop: '70px'}}>
                    <InviteIssuerModal
                        show={this.state.showInviteIssuerModal}
                        close={this.toggleInviteIssuerModal}
                        submit={this.inviteIssuer}/>

                    {this.state.showCreateIssuerModal && (
                        <CreateUserModal
                            userType={"USER_ISSUER"}
                            show={this.state.showCreateIssuerModal}
                            close={this.toggleCreateIssuerModal}
                            submit={this.createIssuer}
                        />
                    )}

                    <Grid item xs={12} lg={12}>
                        <Typography variant="h6">
                            Issuers
                        </Typography>
                        <Divider/>
                    </Grid>

                    <Grid item lg={5} xs={12} sm={12}>
                        <AppBar
                            position="static"
                            color="default"
                        >
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="simple tabs example"
                            >
                                <Tab
                                    style={{padding: 0, fontSize: '12px'}}
                                    label="Registered"
                                    {...a11yProps(0)}
                                />

                                <Tab
                                    style={{padding: 0, fontSize: '12px'}}
                                    // label={"Waiting (" + this.table1.state.data.length + ")"}
                                    label={"Unregistered"}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </AppBar>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <TabPanel
                            value={this.state.value}
                            index={0}
                        >
                            <Grid container spacing={3}>
                                <Grid item md={3} lg={12} xs={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        endIcon={<AddIcon/>}
                                        style={{color: 'white', marginRight: 10}}
                                        onClick={this.toggleInviteIssuerModal}>
                                        Invite Issuer
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="button"
                                        endIcon={<AddIcon/>}
                                        style={{color: 'white'}}
                                        onClick={this.toggleCreateIssuerModal}>
                                        Create Issuer
                                    </Button>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={2} sm={6} xs={12}>
                                            <Typography variant="subtitle2">
                                                Full Name
                                            </Typography>
                                            <InputField
                                                name={'pocFullName'}
                                                value={this.state.filter.pocFullName}
                                                size={"small"}
                                                onChange={this.onChangeSearchField}
                                            />
                                        </Grid>

                                        <Grid item lg={2} sm={6} xs={12}>
                                            <Typography variant="subtitle2">
                                                Company Name
                                            </Typography>
                                            <InputField
                                                name={'companyName'}
                                                value={this.state.filter.companyName}
                                                size={"small"}
                                                onChange={this.onChangeSearchField}
                                            />
                                        </Grid>

                                        <Grid item lg={2} sm={6} xs={12}>
                                            <Typography variant="subtitle2">
                                                Phone
                                            </Typography>
                                            <InputField
                                                name={'phone'}
                                                value={this.state.filter.phone}
                                                size={"small"}
                                                onChange={this.onChangeSearchField}
                                            />
                                        </Grid>

                                        <Grid item lg={2} sm={6} xs={12}>
                                            <Typography variant="subtitle2">
                                                Email
                                            </Typography>
                                            <InputField
                                                name={'pocIssuerEmail'}
                                                value={this.state.filter.pocIssuerEmail}
                                                size={"small"}
                                                onChange={this.onChangeSearchField}
                                            />
                                        </Grid>

                                        <Grid item lg={2} sm={6} xs={12}>
                                            <Typography variant="subtitle2">
                                                Account Status
                                            </Typography>
                                            <SelectField
                                                name={"userAccountStatus"}
                                                value={this.state.filter.userAccountStatus}
                                                emptyLabel={'All'}
                                                size={"small"}
                                                options={AccountStatusService.fetch()}
                                                onChange={this.onChangeSearchField}
                                            />
                                        </Grid>

                                        <Grid item lg={2} sm={6} xs={12}>
                                            <Typography variant="subtitle2">
                                                Show
                                            </Typography>
                                            <SelectField
                                                name={"pageSize"}
                                                value={this.state.pageSize}
                                                size={"small"}
                                                options={this.state.sizes}
                                                onChange={this.onPageSizeChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <CrudTable
                                        {...this.props}
                                        url={this.state.issuerUrl}
                                        theadClassName=""
                                        columns={this.state.issuersColumnNames}
                                        sort={this.state.sort}
                                        pageSize={this.state.pageSize}
                                        filter={this.state.filter}
                                        transform={IssuerService.transformIssuers}
                                        editModalBody="issuer"
                                        canEdit={true}/>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel
                            value={this.state.value}
                            index={1}
                        >
                            <Grid container spacing={3}>
                                <Grid item lg={12} xs={12}>
                                    <CrudTable
                                        {...this.props}
                                        ref={(c) => this.table1 = c}
                                        url={this.state.invitedIssuerUrl}
                                        theadClassName=""
                                        columns={this.state.invitedIssuerColumnNames}
                                        sort={''}
                                        pageSize={10}
                                        transform={IssuerService.transformInvitedIssuers}
                                        editModalBody="unvalidatedIssuer"
                                        canEdit={true}/>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </Grid>
                </Grid>
        )
    }
}

export default withStyles(styles)(Issuers);
