import axios from '../custom-axios/backoffice';

const RegistrationService = {
    fetchSteps: () => {
        return axios.get('/rest/deal/steps')
    },

    fetch: () => {
        return [
            {
                id: 'STEP_BASIC_INFO',
                value: 'Basic Info'
            },
            {
                id: 'STEP_CONTACT_INFO',
                value: 'Contact Info'
            },
            {
                id: 'STEP_ACCREDITATION',
                value: 'Accreditation'
            },
            {
                id: 'STEP_SUITABILITY',
                value: 'Suitability'
            },
            {
                id: 'STEP_INVESTOR_STATUS',
                value: 'Investor Status'
            },
            {
                id: 'STEP_FINAL_SUBMISSION',
                value: 'Acknowledgement & Final Submission'
            },
            {
                id: 'STEP_APPROVAL',
                value: 'Approval'
            },
            {
                id: 'STEP_FINISHED',
                value: 'Finished'
            },
            {
                id: 'STEP_INSTITUTION_INFO',
                value: 'Institution Info'
            },
            {
                id: 'STEP_BACKGROUND_INFO',
                value: 'Background Info'
            },
            {
                id: 'STEP_ISSUER_PROFILE',
                value: 'Issuer Profile'
            },
            {
                id: 'STEP_ISSUER_DOCUMENTATION',
                value: 'Issuer Documentation'
            }
        ]
    },

    fetchInvestorStatus: () => {
        return [
            {
                id: 'STEP_CONTACT_INFO',
                value: 'Contact Info'
            },
            {
                id: 'STEP_DOCUMENTATION',
                value: 'Documentation'
            },
            {
                id: 'STEP_ACCREDITATION',
                value: 'Accreditation'
            },
            {
                id: 'STEP_SUITABILITY',
                value: 'Suitability'
            },
            {
                id: 'STEP_INVESTOR_STATUS',
                value: 'Investor Status'
            },
            {
                id: 'STEP_FINAL_SUBMISSION',
                value: 'Acknowledgement & Final Submission'
            },
            {
                id: 'STEP_APPROVAL',
                value: 'Approval'
            },
            {
                id: 'STEP_FINISHED',
                value: 'Finished'
            },
        ]
    },
    fetchIssuerStatus: () => {
        return [
            // {
            //     id: 'STEP_INSTITUTION_INFO',
            //     name: 'STEP_INSTITUTION_INFO'
            // },
            // {
            //     id: 'STEP_BACKGROUND_INFO',
            //     name: 'STEP_BACKGROUND_INFO'
            // },
            {
                id: 'STEP_ISSUER_PROFILE',
                value: 'Profile'
            },
            {
                id: 'STEP_ISSUER_DOCUMENTATION',
                value: 'Documentation'
            },
            {
                id: 'STEP_APPROVAL',
                value: 'Approval'
            },
            {
                id: 'STEP_FINISHED',
                value: 'Finished'
            },
        ]
    },
    fetchCompanyStatus: () => {
        return [
            {
                id: 'STEP_INSTITUTION_INFO',
                value: 'Institution Info'
            },
            {
                id: 'STEP_BACKGROUND_INFO',
                value: 'Background Info'
            },
            {
                id: 'STEP_FINAL_SUBMISSION',
                value: 'Acknowledgement & Final Submission'
            },
            {
                id: 'STEP_APPROVAL',
                value: 'Approval'
            },
        ]
    }
}

export default RegistrationService;