import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";



const AddNewPayTypeModal = (props) => {

    const [error, setError] = useState(false)
    const [value, setValue] = useState('')

    let handleFormSubmit = (e) => {
        e.preventDefault();
        if (value === '') {
            setError(true)
        } else {
            props.submit(value);
            setError(false)
            setValue('')
            props.close();

        }
    };


    let onChange = (e) => {
        if (value === '') {
            setError(false)
        }
        setValue(e.target.value)
    }

    let close = (e) => {
        setError(false)
        setValue('')
        props.close();

    };


    return (
        <>
            <Dialog open={props.show}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">ADD NEW PAYMENT TYPE</DialogTitle>
                <DialogContent style={{overflow: 'hidden'}}>
                    <Grid container spacing={3}>
                        <Grid item lg={12} xs={12}>
                            <Typography>Please, enter a new payment type</Typography>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <FormControl variant="outlined"
                                         error={error}
                                         fullWidth>
                                <TextField variant='outlined'
                                           error={error}
                                           value={value}
                                           label="New Payment Type"
                                           inputProps={{
                                               id: 'outlined-age-native-simple'
                                           }}
                                           onChange={(e) => onChange(e)}>
                                </TextField>
                                {error?
                                    <FormHelperText>ERROR: You must enter the payment type</FormHelperText> : null}
                            </FormControl>
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <DialogActions>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleFormSubmit}>Add new pay type
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={close}>Cancel
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddNewPayTypeModal;