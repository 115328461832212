import axios from '../custom-axios/backoffice';

const PayTypeService = {
    fetchAll: () => {
        return axios.get('/rest/payment-type');
    },
    saveType: (body) => {
        return axios.post('/rest/payment-type', body);
    },
    deleteType: (id) => {
        return axios.delete(`/rest/payment-type/${id}`);
    }
};

export default PayTypeService;