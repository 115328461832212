import axios from '../custom-axios/backoffice';

const IssuerService = {
    fetchIssuers: () => {
        return axios.get(`/rest/issuers`);
    },

    fetchIssuerById: (id) => {
        return axios.get(`/rest/issuers/${id}`);
    },

    fetchByUserId: (id) => {
        return axios.get(`/rest/issuers/user/${id}`);
    },

    fetchPending: (page) => {
        return axios.get(`/rest/issuers/pending/${page}`);
    },

    inviteIssuer: (request) => {
        return axios.post('/rest/issuers/invite', request);
    },

    updateIssuer: (request) => {
        return axios.put(`/rest/issuers/${request.id}`, request);
    },

    updateGeneralInfo: (request) => {
        return axios.post('/rest/issuers/update/general_info', request);
    },

    updatePOC: (request) => {
        return axios.post('/rest/issuers/update/poc', request);
    },

    approveIssuer: (id) => {
        return axios.post(`/rest/issuers/${id}/approve`);
    },

    transformIssuers: (issuers) => {
        return issuers.map(issuer => {
            return {
                id: issuer.id,
                fullName: issuer.fullName,
                pocFullName: `${issuer.user.firstName} ${issuer.user.lastName}`,
                companyName: issuer.companyName,
                phone: issuer.phone,
                pocIssuerEmail: issuer.user.email,
                userAccountStatus: issuer.user.accountStatus,
                user: issuer.user
            }
        });
    },

    fetchAttributeData: (issuerId) => {
        return axios.get('/rest/issuer_data/' + issuerId);
    },

    saveAttributeData: (request) => {
        return axios.post('/rest/issuer_data', request);
    },

    deleteAttributeData: (request) => {
        return axios.delete('/rest/issuer_data', {data: request});
    },

    transformInvitedIssuers: (issuers) => {
        return issuers.map(issuer => {
            return {
                id: issuer.id,
                pocFullName: `${issuer.unvalidatedUser.firstName} ${issuer.unvalidatedUser.lastName}`,
                phone: issuer.phone,
                email: issuer.unvalidatedUser.email,
                companyName: issuer.companyName,
                accountStatus: issuer.unvalidatedUser.accountStatus
            }
        })
    }
}

export default IssuerService;