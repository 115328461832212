import React, {Component} from 'react';

import './App.css';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {AUTH_TOKEN} from "./shared/utility";
import Aux from './hoc/AuxWrapper';
import Login from './containers/Login';
import TimerLogoutModal from "./containers/modal/TimerLogoutModal";
import {Main} from "./containers/main/Main";

class App extends Component {

    state = {
        showAlert: false,
    };

    constructor(props) {
        super(props);

        this.events = [
            "load",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];

        this.warn = this.warn.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);

        for (let i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }

        if (window.location.pathname !== '/login') {
            this.setTimeout();
        }
    }

    clearTimeout() {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);
    }

    setTimeout() {
        this.warnTimeout = setTimeout(this.warn, 780 * 1000);
    }

    resetTimeout() {
        this.clearTimeout();
        if (window.location.pathname !== '/login') {
            this.setTimeout();
        }
    }

    warn() {
        this.setState({
            showAlert: true
        })
    }

    destroy() {
        this.clearTimeout();

        for (let i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    }

    closeModal = () => {
        this.setState({
            showAlert: false
        })
    };

    render() {

        let routes = (
            <Switch>
                <Route path="/login" component={Login}/>
                <Redirect to="/login"/>
            </Switch>
        );

        let authToken = localStorage.getItem(AUTH_TOKEN);

        if (authToken && authToken !== '')
            routes = (
                <Aux>
                    <Main
                        path={window.location.pathname}
                    />
                </Aux>
            );

        return (
            <Aux>
                {this.state.showAlert ? (
                    <TimerLogoutModal
                        className="modal"
                        close={this.closeModal}
                    />
                ) : null}
                {routes}
            </Aux>
        );
    }
}

export default withRouter(App);
