import React, {useState} from 'react';
import Aux from "../../hoc/AuxWrapper";
import SaveModal from "../../containers/modal/SaveModal";

const SelectWithCheck = (props) => {

    const [isShowing, setIsShowing] = useState(false);
    const [value, setValue] = useState({...props.selectedValue});
    const [options, setOptions] = useState(null);



    const onChangeHandler = (e) => {
            setValue(e.target.value);
            setOptions(e.target.options);
            setIsShowing(!isShowing);
    };

    const changeInput = () => {
        document.getElementById(props.id).value = props.selectedValue['chosenOptionId'];
    };

    const toggleSaveModal = () => {
        setIsShowing(!isShowing);
    };


    const saveChanges = () => {
        let valueOption;
        for (var i = 0; i < options.length; i++) {
            if (options[i].selected) {
                valueOption = options[i].innerHTML.trim();
                break;
            }
        }
        props.onChange({
            attributeCode: props.attributeCode,
            investorAttributeOptionId: value,
            value: valueOption
        });
        setIsShowing(!isShowing);
    };


    return (
        <Aux>
            <SaveModal show={isShowing}
                       close={toggleSaveModal}
                       submit={saveChanges}
                       changeInput={changeInput}
            />
            <select
                disabled={props.options.length === 0 || props.flagDisabled}
                onChange={(e) => {onChangeHandler(e)}}
                className="select__custom--big"
                id={props.id ? props.id : null}
                >
                {props.options.map(option => {
                    return (
                        <option
                            key={option.id}
                            value={option.id}
                            selected={props.selectedValue ? option.id === props.selectedValue['chosenOptionId'] : false}>
                            {option.value}
                        </option>
                    );
                })}

            </select>
        </Aux>
    );
};

export default SelectWithCheck;