import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import {Button, Typography} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import DialogActions from "@material-ui/core/DialogActions";


const MessageComposeModal = (props) => {
    let handleFormSubmit = (e) => {
        e.preventDefault();
        props.submit({
            'channelId': e.target.channelId.value,
            'content': e.target.content.value
        })
        e.target.reset();
        props.close();
    }

    let close = (e) => {
        // e.target.form.reset()
        props.close();
    }

    return (
        <>
            <Dialog
                open={props.show}
                maxWidth="md"
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <form onSubmit={handleFormSubmit}>
                    <DialogTitle id="alert-dialog-title">SEND A MESSAGE</DialogTitle>
                    <DialogContent style={{overflow: 'hidden'}}>
                        <Grid container spacing={3}>
                            <Grid item lg={2} xs={2}>
                                <Typography style={{display: "flex", justifyContent: "center", padding: 'inherit'}}>Send
                                    to</Typography>
                            </Grid>
                            <Grid item lg={9} xs={3}>

                                <select name={'channelId'}
                                        className="input__custom"
                                        data-width="100%"
                                        title="&nbsp;">
                                    {
                                        props.channels.map(ch => {
                                            return (
                                                <option
                                                    value={ch.id}>{ch.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>

                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <TextareaAutosize
                                    style={{width: '100%'}}
                                    aria-label="empty textarea"
                                    rowsMin={7}
                                    name={'content'}
                                    required={true}>

                                </TextareaAutosize>
                            </Grid>
                            <Grid item lf={12} xs={12}>
                                <Button variant='contained'
                                        color='primary'
                                        type='submit'>Send Message
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='outlined'
                            data-dismiss="modal"
                            onClick={close}>Close
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default MessageComposeModal;