import React, {Component} from 'react';
import ImagePreviewModal from "../../containers/modal/ImagePreviewModal";
import PdfPreviewModal from "../../containers/modal/PdfPreviewModal";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {AUTH_TOKEN} from "../../shared/utility";


class CompanyDocuments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPreviewImage: false,
            showPdfPreview: false,
            openBlock: props.open,

        }
    }

    handleClose = (e) => {
        this.setState({
            showPreviewImage: false
        })
    }

    handlePdfClose = (e) => {
        this.setState({
            showPdfPreview: false
        })
    }

    onFilePreview = (e, item) => {
        let fileToPreview = '';
        if (item.filename) {
            fileToPreview = item.filename.split('.');
            if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
                this.setState({
                    fileToPreview: item.filename,
                    showPdfPreview: true
                });
            } else {
                this.setState({
                    fileToPreview: item.filename,
                    showPreviewImage: true
                });
            }

        } else {
            fileToPreview = item.chosenValue.split('.');
            if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPdfPreview: true
                });
            } else {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreviewImage: true
                });
            }
        }
    };

    openBlock = () => {
        this.setState({
            openBlock: !this.state.openBlock
        })
    };


    render() {
        return (
            <>
                <ImagePreviewModal
                    show={this.state.showPreviewImage}
                    file={this.state.fileToPreview}
                    close={this.handleClose}
                />

                <PdfPreviewModal
                    show={this.state.showPdfPreview}
                    file={this.state.fileToPreview}
                    close={this.handlePdfClose}/>

                <Box className="upload__block--title"
                     onClick={this.openBlock}
                >
                    {this.state.openBlock ? <RemoveIcon/> : <AddIcon/>}

                    <Box ml="8px" component="span">
                        {this.props.headText}
                    </Box>
                </Box>


                <Box
                    mb="15px"
                    p="25px"
                    className={this.state.openBlock ? 'upload__view' : 'upload__hidden'}
                    style={{background: '#FCFCFC'}}>

                    <Box>
                        {this.props.currentValue && Array.isArray(this.props.currentValue) ?
                            this.props.currentValue.map(document => {
                                    if (document.chosenValue) {
                                        return (
                                            <Box
                                                pt="20px"
                                                pb="5px"
                                                style={{borderColor: '#DFDFDF'}}
                                                borderBottom={1}
                                                display="flex"
                                                key={document.chosenValue}>

                                                <Box flexGrow={1}>
                                                    {document.documentOriginalName}
                                                </Box>
                                                {/*<label*/}
                                                {/*    className="color-black"> {this.props.labelText ? this.props.headText + ' ' + this.props.labelText : this.props.headText} </label>*/}

                                                <Box>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        name={this.props.fileNumber}
                                                        type={"button"}
                                                        size='small'
                                                        startIcon={<VisibilityIcon/>}
                                                        onClick={(e) => this.onFilePreview(e, document)}>
                                                        Preview
                                                    </Button>

                                                    <Button
                                                        onClick={() => window.open(process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/'
                                                            + document.chosenValue + '?access_token=' + localStorage.getItem(AUTH_TOKEN)
                                                            + '&embedded=false')}
                                                        type={"submit"}
                                                        variant='contained'
                                                        color='primary'
                                                        size='small'
                                                        style={{marginLeft: 6}}
                                                        startIcon={<SaveIcon/>}>
                                                        Download
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )
                                    }

                                    return null
                                }
                            ) : null}
                    </Box>

                    {/*<Box mt="25px" display="flex">*/}

                    {/*    <Button onClick={this.openBlock}>*/}
                    {/*        Cancel*/}
                    {/*    </Button>*/}
                    {/*</Box>*/}
                </Box>

            </>
        )

    }

}

export default CompanyDocuments;