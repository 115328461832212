import styled from "styled-components";

export const Tabs = styled.div`
  background: #fff;
  margin-top: 15px;
  
  @media (max-width: 600px) {
    display: flex;
  }
  
`;

export const Tab = styled.button`
  border: 1px solid #E0E0E0;
  outline: none;
  cursor: pointer;
  position: relative;
  padding: 10px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  margin-right: 15px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  border: ${props => (props.active ? "1px solid #ccc" : "")};
  background-color: ${props => (props.active ? "#DFDFDF" : "white")};

  :hover {
    background-color: white;
  }
  
`;
export const Content = styled.div`
  ${props => (props.active ? "" : "display:none")}
`;
