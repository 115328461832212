import axios from '../custom-axios/backoffice';

const DealDataService = {
    save: (body) => {
        return axios.post('/rest/deal_data', body);
    },
    update: (body) => {
        return axios.put('/rest/deal_data', body);
    },
    fetchByDealAndAttribute: (params) => {
        return axios.get(`/rest/deal_data/deal/${params.dealId}/${params.attributeCode}`);
    },
    delete: (id) => {
        return axios.delete(`/rest/deal_data/${id}`);
    }
};

export default DealDataService;