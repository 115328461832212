import axios from '../custom-axios/backoffice';
import Moment from "react-moment";
import React from "react";

const editText = (value) => {
    let fullText = [];

    let textArray = value.split('_');
    for (let i = 0; i < textArray.length; i++) {
        fullText.push(textArray[i][0] + textArray[i].toLowerCase().slice(1))
    }
    return fullText.join(" ")
}

const InvestorService = {
    fetchPendingInvestors: (sort, direction, page, pageSize, filter) => {
        let filterOpt = [];
        if (filter) {
            filterOpt = Object.keys(filter).map(function (key) {
                if (filter[key] !== undefined && filter[key] !== '')
                    return "&filter%5B" + key + "%5D=" + filter[key] + "";

                return ''
            });
        }
        return axios.get(`/rest/investor/pending?sort=${sort}&direction=${direction}&page=${page}&pageSize=${pageSize}` + filterOpt.join(''));
    },


    fetchAllInvestors: () => {
        return axios.get(`/rest/investor/all`);
    },
    fetchInvestor: () => {
        return axios.get(`/rest/investor`);
    },
    fetchInvestorByUserId: (id) => {
        return axios.get(`/rest/investor/user/${id}`);
    },
    transformInvestors: (investors) => {
        return investors.map(i => {
            return {
                'firstName': i.user.firstName,
                'lastName': i.user.lastName,
                'email': i.user.email,
                'dateRegistrationCompleted': <Moment format={"L LT"}>{i.user.dateRegistrationCompleted}</Moment>,
                'userGroup': i.user.userGroup.id === 'USER_INVESTOR_INDIVIDUAL' ? 'Individual' : 'Institutional',
                'registrationType': i.user.registrationType ? i.user.registrationType.value: '',
                'status': editText(i.user.accountStatus),
                dateCreated:<Moment format={"L LT"}>{i.user.dateCreated}</Moment>
            }
        })
    },

    approveInvestor: (email) => {
        return axios.post(`/rest/investor/approve_investor/${email}`);
    },
    declineInvestor: (email) => {
        return axios.post(`/rest/investor/decline_investor/${email}`);
    },
    fetchInvestorBasicInfo: () => {
        return axios.get("/rest/investor/basic");
    },
    fetchInvestorStatus: () => {
        return axios.get('/rest/registration/status');
    },
    fetchInvestorStatusByUserId: (userId) => {
        return axios.get('/rest/registration/status/user/' + userId);
    },
    fetchInvestorStatusByStep: (step) => {
        return axios.get(`/api/attributes/registration_status/${step}`);
    },
    saveAttributeData: (request) => {
        return axios.post('/rest/investor_data', request);
    },
    deleteAttributeData: (request) => {
        return axios.delete('/rest/investor_data', {data: request});
    },
    deleteMultiAttributeData: (request) => {
        return axios.delete('/rest/investor_data/multi', {data: request});
    },
    updateInvestorInfo: (request) => {
        return axios.post('/rest/investor', request);
    },
    fetchByAttributeCode: (code) => {
        return axios.get('/rest/investor_data/attribute_code/' + code);
    },
    fetchByAttributeCodeAndIssuerId: (code, issuerId) => {
        return axios.get('/rest/issuer_data/attribute_code/' + code + '/issuer/' + issuerId);
    },
    fetchByAttributeCodeAndInvestorId: (code, investorId) => {
        return axios.get('/rest/investor_data/attribute_code/' + code + '/investor/' + investorId);
    },
    fetchByAttributeCodeAndInvestorIdWithRequestBody: (code, investorId) => {
        return axios.post('/rest/investor_data/attribute_code/code/investor/' + investorId, code);
    },
    fetchAllDocumentsByInvestorId: (investorId) => {
        return axios.get('/rest/investor_data/attribute_code/code/investor/' + investorId);
    },

    createAccreditationBlock: (dataForBlock, investorId) => {
        return axios.post('/rest/verification/accreditation_block/' + investorId, dataForBlock);
    },
    createActivationBlock: (data, investorId) => {
        return axios.post('/rest/verification/activation_block/' + investorId, data);
    },

    changeActivationBlock: (data, investorId) => {
        return axios.post('/rest/verification/activation_block/change/' + investorId, data);
    },

    deleteAccreditationBlock: (investorId) => {
        return axios.delete('/rest/verification/accreditation_block/' + investorId);
    },

    findActivationBlock: (investorId) => {
        return axios.get('/rest/verification/activation_block/' + investorId);
    },

    findAccreditationBlock: (investorId) => {
        return axios.get('/rest/verification/accreditation_block/' + investorId);
    },

    pushRegTypes: (iId, vals) => {
        return axios.post('/rest/investor/reg_types', {investorId: iId, regTypes: vals});
    },

    editInvestorInfo: (request) => {
        return axios.put('/rest/investor', request);
    },

    verifyDocument: (uuid) => {
        return axios.post('/rest/documents/' + uuid);
    },

    unVerifyDocument: (uuid) => {
        return axios.post('/rest/documents/unverify/' + uuid);
    }
};

export default InvestorService;