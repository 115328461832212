import React from "react";

import {Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

const SelectField = (props) => {

    const handleChange = (_value) => {
        const selectedValue = props.options.find(value => value.id ? value.id === _value : value === _value)
        props.onChange(selectedValue ? selectedValue : '', props.name)
    }

    return (
        <>
            <Typography>
                {props.title}
                {props.required && <span className="required__circle"> *</span>}
            </Typography>
            <FormControl variant="outlined"
                         fullWidth
                         disabled={props.disabled}
                         size={props.size}
                         error={props.hasError}>
                <Select
                    value={props.value}
                    displayEmpty
                    onChange={event => handleChange(event.target.value)}
                >
                    {props.emptyLabel && (
                        <MenuItem key={props.emptyLabel} value=''>
                            {props.emptyLabel}
                        </MenuItem>
                    )}

                    {props.options.map(option => (
                        <MenuItem key={option.id ? option.id : option}
                                  value={option.id ? option.id : option}>
                            {option.value ? option.value : option}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                    {props.hasError && props.errorMessage}
                </FormHelperText>
            </FormControl>
        </>
    )
}

export default SelectField;