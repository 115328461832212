import React, {Component} from 'react';

import CMSService from "../../services/CMSService";
import ImagePreviewModal from "../modal/ImagePreviewModal";
import {toast} from "react-toastify";
import PdfPreviewModal from "../modal/PdfPreviewModal";
import DealService from "../../services/DealService";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {CardContent, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import InputField from "../../components/Molecules/InputField";
import Dropzone from "react-dropzone";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import PreviewPage from "./PreviewPage";
import {N1ED} from "@edsdk/n1ed-react/lib/cjs";

class CreateCompanyPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mainLoader: false,
            uri: '',
            freeUri: true,
            deal: '',
            dealId: '',
            title: '',
            description: '',
            mainVideoLink: '',
            target: '',
            bodyCode: '',
            elements: [],
            errors: {},
            isLoading: false,
            isUniqueUri: false,
            imagesToSave: [],
            fileToPreview: null,
            showPage: {},
            showPreview: false,
            showPreviewPdf: false,
            showPreviewImage: false,
            edit: false,
            copy: false,
        };
    }

    componentDidMount() {
        const {match: {params}} = this.props;

        if (params.uri) {
            this.setState({
                edit: true
            });

            this.fetchEditData(params.uri)
        } else if (params.copy) {
            this.setState({
                copy: true
            });

            this.fetchCopyData(params.copy)
        } else {
            this.fetchDeal(this.props.match.params.dealId)
        }
    }

    fetchDeal(dealId) {
        this.setState({
            mainLoader: true
        })

        DealService.fetchDeal(dealId)
            .then(response => {
                this.setState({
                    deal: response.data,
                    mainLoader: false
                });
            }).catch(error => {
            toast.error('Error while fetching data');
        })
    }

    fetchCopyData(uri) {
        this.setState({
            mainLoader: true
        })

        CMSService.getPageByUri(uri)
            .then(response => {
                this.setState({
                    title: response.data.title,
                    description: response.data.description,
                    mainVideoLink: response.data.mainVideoLink,
                    dealId: response.data.dealId,
                    bodyCode: response.data.elements[0].text,
                    elements: response.data.elements,
                    mainLoader: false
                })

                this.fetchDeal(response.data.dealId)
            }).catch(error => {
            toast.error('Error while fetching data')
        })
    }

    fetchEditData(uri) {
        this.setState({
            mainLoader: true
        })

        CMSService.getPageByUri(uri)
            .then(response => {
                this.setState({
                    uri: uri,
                    title: response.data.title,
                    description: response.data.description,
                    mainVideoLink: response.data.mainVideoLink,
                    dealId: response.data.dealId,
                    bodyCode: response.data.elements[0].text,
                    elements: response.data.elements
                });

                this.fetchDeal(response.data.dealId)
            }).catch(error => {
            toast.error('Error while fetching data');
        })

        this.fetchImages(uri)
    }

    fetchImages(uri) {
        CMSService.getImagesByUri(uri)
            .then(response => {
                let files = response.data.map(file => {
                    return {
                        'id': file.id,
                        'filename': file.uuid + '.' + file.extension,
                        'originalFilename': file.orginalFilename
                    }
                });

                this.setState(
                    {
                        imagesToSave: [...this.state.imagesToSave, ...files],
                        mainLoader: false
                    });
            }).catch(error => {
            toast.error('Error while fetching data');
        })
    }

    savePage = (event) => {
        event.preventDefault()

        const content = event.target.n1ed.value

        if (this.handleValidation()) {
            if (this.state.edit) {
                CMSService.updatePage(this.generatePage(content))
                    .then(resp => {
                        toast.success('Updated!');
                        this.props.history.push("/offerings/edit/" + resp.data.body.deal.id)
                    })
                    .catch(err => {
                        toast.error('Error while updating')
                    })
            } else {
                CMSService.savePage(this.generatePage(content))
                    .then(resp => {
                        toast.success('Saved!');
                        this.props.history.push("/offerings/edit/" + resp.data.body.deal.id)
                    })
                    .catch(err => {
                        toast.error('Error while saving')
                    })
            }
        } else {
            toast.error('Check required fields')
        }
    };

    handleValidation() {
        let errors = {}
        let valid = true

        if (!this.state.uri) {
            valid = false
            errors.uri = 'Cannot be empty'
        } else if (!this.state.edit && !this.state.isUniqueUri) {
            valid = false
            errors.uri = 'URI already exists'
        }

        if (!this.state.title) {
            valid = false
            errors.title = 'Cannot be empty'
        }

        if (!this.state.description) {
            valid = false
            errors.description = 'Cannot be empty'
        }

        if (this.state.imagesToSave.length === 0 && !this.state.mainVideoLink) {
            valid = false
            errors.contentBox = 'Fill in the video link or/and upload the image'
        }

        this.setState({
            errors: errors
        })

        return valid
    }

    checkUri(uri) {
        CMSService.checkUri(uri)
            .then(response => {
                this.setState({
                    isUniqueUri: response.data,
                });
            })
            .catch(err => {
                toast.error('Error while checking URI!');
            });
    }

    saveImages = (groupId, files, attrCode) => {
        if (!this.state.uri) {
            toast.error('Fill in URI before uploading');
        } else {
            this.setState({
                isLoading: true
            });

            CMSService.saveImagesMultiple(groupId, files, this.state.uri, this.state.edit)
                .then(response => {
                    let files = response.data.map(file => {
                        return {
                            'id': file.id,
                            'filename': file.filename,
                            'originalFilename': file.originalFilename
                        }
                    });

                    this.setState(
                        {
                            imagesToSave:
                                [...this.state.imagesToSave,
                                    ...files
                                ],
                            isLoading: false
                        });
                }).catch(error => {
                toast.error('Error while saving image!');
                this.setState({
                    isLoading: false
                });
            });
        }
    };

    generatePage(content) {
        return {
            'uri': this.state.uri,
            'title': this.state.title,
            'description': this.state.description,
            'mainVideoLink': this.state.mainVideoLink,
            'amountRaised': this.state.deal.raiseTarget,
            'dealId': this.state.deal.id,
            'sharePrice': this.state.deal.sharePrice,
            'preMoney': this.state.deal.preMoney,
            'elements': [{
                'type': 'textarea',
                'position': 1,
                'text': content
            }]
        }
    }

    remove(document) {
        CMSService.removeImageByUuid(document.filename)
            .then(response => {
                let documents = [...this.state.imagesToSave];
                let newDocuments = documents.filter(d => {
                    return (d.id !== document.id);
                });

                this.setState({
                    imagesToSave: newDocuments,
                    somethingChanged: true
                });
            })
            .catch(err => {
                toast.error('Error')
            });
    }

    onFilePreview = (item) => {
        // let fileToPreview;
        // if (item.chosenValue !== undefined) {
        //   fileToPreview = item.chosenValue.split('.');
        // } else fileToPreview = item.filename.split('.');
        // if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
        //   if (item.chosenValue !== undefined) {
        //     this.setState({
        //       fileToPreview: item.chosenValue,
        //       showPreview: true
        //     });
        //   } else {
        //
        //     this.setState({
        //       fileToPreview: item.filename,
        //       showPreview: true
        //     });
        //   }
        // } else {
        //   if (item.chosenValue !== undefined) {
        //     let a = item.filename
        //     debugger
        //     this.setState({
        //       fileToPreview: item.chosenValue,
        //       showPreviewImage: true
        //     });
        //   } else {
        //     this.setState({
        //       fileToPreview: item.filename,
        //       showPreviewImage: true
        //     });
        //   }
        // }


        let fileToPreview;

        if (item.filename) {
            fileToPreview = item.filename.split('.');
            if (fileToPreview[fileToPreview.length - 1] === 'pdf') {

                this.setState({
                    fileToPreview: item.filename,
                    showPreviewPdf: true
                });
            } else {

                this.setState({
                    fileToPreview: item.filename,
                    showPreviewImage: true
                });
            }

        } else {
            fileToPreview = item.chosenValue.split('.');
            if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreviewPdf: true
                });
            } else {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreviewImage: true
                });
            }

        }


        // let fileToPreview;
        // if (item.chosenValue !== undefined) {
        //   fileToPreview = item.chosenValue;
        // } else fileToPreview = item.filename;
        // let splitted = fileToPreview.split('.');
        // if (splitted[splitted.length - 1] === 'pdf') {
        //   this.setState({
        //     fileToPreview: fileToPreview,
        //     showPreviewPdf: true
        //   });
        // } else {
        //   this.setState({
        //     fileToPreview: fileToPreview,
        //     showPreviewImage: true
        //   });
        // }
    };

    togglePreview() {
        this.setState({
            showPreview: false
        })
    }

    showPreview = () => {
        this.setState({
            showPreview: true,
            showPage: this.generatePage()
        })
    };

    handleChange = (value, field) => {
        if (field === 'uri') {
            this.checkUri(value)
        }

        this.setState({
            [field]: value,
        })
    };

    handleEditorChange = (content, editor) => {
        // this.setState({
        //     bodyCode: content,
        // });
    }

    handleCloseImage = () => {
        this.setState({
            showPreviewImage: false
        })
    };

    handleClosePdf = () => {
        this.setState({
            showPreviewPdf: false
        })
    };

    render() {
        return (
            <>
                <PdfPreviewModal
                    className="modal"
                    show={this.state.showPreviewPdf}
                    file={this.state.fileToPreview}
                    close={this.handleClosePdf}
                    cms={true}/>

                <ImagePreviewModal
                    className="modal"
                    show={this.state.showPreviewImage}
                    file={this.state.fileToPreview}
                    close={this.handleCloseImage}
                    cms={true}/>
                <form onSubmit={this.savePage}>
                    <Grid container
                          spacing={3}
                          justify='center'
                          style={{marginTop: '70px', marginBottom: '20px'}}>
                        <Grid item lg={10}>
                            <Card raised>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                onClick={this.showPreview}>
                                                Preview
                                            </Button>

                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                type="submit"
                                                style={{
                                                    color: "white",
                                                    marginLeft: 15
                                                }}
                                                // onClick={this.savePage}
                                            >
                                                {this.state.edit ? 'Update' : 'Create'}
                                            </Button>
                                        </Grid>

                                        <Grid item lg={6}>
                                            <Grid container spacing={3}>
                                                <Grid item lg={12}>
                                                    <InputField
                                                        title={'URL'}
                                                        name={'uri'}
                                                        required={true}
                                                        value={this.state.uri}
                                                        disabled={this.state.edit}
                                                        hasError={this.state.errors.uri}
                                                        errorMessage={this.state.errors.uri}
                                                        onChange={this.handleChange}/>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <InputField
                                                        title={'Title'}
                                                        name={'title'}
                                                        required={true}
                                                        value={this.state.title}
                                                        hasError={this.state.errors.title}
                                                        errorMessage={this.state.errors.title}
                                                        onChange={this.handleChange}/>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <InputField
                                                        title={'Description'}
                                                        name={'description'}
                                                        required={true}
                                                        value={this.state.description}
                                                        multiline={true}
                                                        hasError={this.state.errors.description}
                                                        errorMessage={this.state.errors.description}
                                                        onChange={this.handleChange}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item lg={6}>
                                            <Typography>
                                                Video/image
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                            <div style={{
                                                padding: 10,
                                                border: "solid",
                                                borderRadius: 4,
                                                borderWidth: 1,
                                                borderColor: `${this.state.errors.contentBox
                                                    ? 'red'
                                                    : 'rgba(0, 0, 0, 0.23)'}`
                                            }}>
                                                < InputField
                                                    title={'Main Video Link'}
                                                    name={'mainVideoLink'}
                                                    value={this.state.mainVideoLink}
                                                    onChange={this.handleChange}/>

                                                {this.state.imagesToSave.length === 0 && (
                                                    <Dropzone
                                                        multiple={true}
                                                        accept={'image/png,image/jpeg'}
                                                        onDrop={acceptedFiles => this.saveImages('cms', acceptedFiles, 'CMS')}>
                                                        {
                                                            ({getRootProps, getInputProps}) =>
                                                                <>
                                                                    <Typography style={{marginTop: 25}}>
                                                                        Image
                                                                    </Typography>
                                                                    <div className={'state-id front'}>
                                                                        {
                                                                            this.state.imagesToSave.length === 0
                                                                            && <>
                                                                                <div style={{
                                                                                    border: "solid",
                                                                                    borderRadius: 4,
                                                                                    borderWidth: 1,
                                                                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                                    padding: 3
                                                                                }}
                                                                                     {...getRootProps()}>
                                                                                    <Typography>
                                                                                        Drop image here or click to
                                                                                        Browse
                                                                                    </Typography>
                                                                                    <Typography>
                                                                                        Maximum file size: 20MB
                                                                                    </Typography>
                                                                                </div>

                                                                                <div className="form-group">
                                                                                    <Box pt="15px">
                                                                                        <input
                                                                                            id={'file-0'}
                                                                                            type={"file"} {...getInputProps()}
                                                                                            className=""
                                                                                            accept="image/png,image/jpeg"
                                                                                            multiple
                                                                                            required>
                                                                                        </input>

                                                                                        <label htmlFor={'file-0'}>
                                                                                            <Button
                                                                                                variant="contained"
                                                                                                color={"primary"}
                                                                                                component="span">
                                                                                                Browse
                                                                                            </Button>
                                                                                        </label>

                                                                                    </Box>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </>
                                                        }
                                                    </Dropzone>
                                                )}

                                                {this.state.imagesToSave.length !== 0 && (
                                                    <Grid item lg={12}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Image Description</TableCell>
                                                                    <TableCell>Image Filename</TableCell>
                                                                    <TableCell>Edit</TableCell>
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody>
                                                                {
                                                                    this.state.imagesToSave.map(image => {
                                                                        return (
                                                                            <TableRow key={image.id}>
                                                                                <TableCell>{image.filename}</TableCell>
                                                                                <TableCell>{image.originalFilename}</TableCell>
                                                                                <TableCell>
                                                                                    <Button
                                                                                        color="secondary"
                                                                                        onClick={() => this.onFilePreview(image)}>
                                                                                        Preview
                                                                                    </Button>

                                                                                    <Button
                                                                                        color="secondary"
                                                                                        onClick={(e) => this.remove(image)}>
                                                                                        Delete
                                                                                    </Button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                )}
                                            </div>

                                            {this.state.errors.contentBox && (
                                                <Typography
                                                    style={{fontSize: "small", marginLeft: 14}}
                                                    color={"error"}>
                                                    {this.state.errors.contentBox}
                                                </Typography>
                                            )}

                                        </Grid>

                                        <Grid item lg={12}>
                                            <N1ED
                                                textareaName={'n1ed'}
                                                initialValue={this.state.bodyCode}
                                                apiKey="MW5RDFLT"
                                                // onEditorChange={this.handleEditorChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                            {
                                this.state.showPreview
                                && <PreviewPage
                                    show={this.state.showPreview}
                                    images={this.state.imagesToSave}
                                    bodyCode={this.state.bodyCode}
                                    pageInfo={this.state.showPage}
                                    close={() => this.togglePreview()}>
                                </PreviewPage>
                            }
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
}

export default CreateCompanyPage;