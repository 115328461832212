import React, {useEffect} from 'react';

import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

export default function GooglePlaceAutocompleteField(props) {

    const [value, setValue] = React.useState('');

    let autocomplete = null;

    if (typeof window !== 'undefined') {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${props.apiKey}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }
    }

    useEffect(() => {
        if (props.defaultValue && props.defaultValue.chosenValue) {
            setValue(props.defaultValue.chosenValue)
        } else if (props.defaultValue) {
            setValue(props.defaultValue)
        }

        if (!autocomplete && window.google) {
            autocomplete = new window.google.maps.places.Autocomplete(document.querySelector("#google-place"), {
                fields: ["address_components", "geometry"],
                types: ["address"],
            });

            autocomplete.addListener("place_changed", onSelected);
        }
    }, [props.defaultValue]);


    const onSelected = () => {
        const place = autocomplete.getPlace()
        props.fillInAddress(place)
    }

    const onBlur = (newValue) => {
        props.onChange(newValue)
    }

    return (
        <>
            <Typography>
                {props.title}
                {props.required && <span className="required__circle"> *</span>}
            </Typography>
            <TextField
                id="google-place"
                fullWidth
                type="text"
                variant="outlined"
                value={value}
                disabled={props.disabled}
                error={props.hasError}
                helperText={props.hasError && props.errorMessage}
                onChange={(event, newValue) => {
                    setValue(newValue)
                }}
                onBlur={(event) => onBlur(event.target.value)}
            />
        </>
    )
}