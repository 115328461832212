import React, {Component} from 'react';
import TextInput from '../../../components/input/TextInput';
import Select from '../../../components/input/Select';
import EmailInput from '../../../components/input/Email';
import axios from "../../../custom-axios/backoffice";
import RegTypeService from "../../../services/RegTypeService";
import InvestorService from "../../../services/InvestorService";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import DobUsWithCheck from "../../../components/input/DobUsWithCheck";
import CompanyDocuments from "../../../components/input/CompanyDocuments";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import TextPassword from "../../../components/input/TextPassword";
import Textarea from "../../../components/input/Textarea";
import SelectMultipleField from "../../../components/Molecules/SelectMultipleField";
import InputPhone from "../../../components/input/InputPhone";
import CheckIcon from '@material-ui/icons/Check';
import BeenhereIcon from "@material-ui/icons/Beenhere";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import Moment from "react-moment";
import {toast} from "react-toastify";
import UserDocumentation from "../../../components/UserDocumentation";
import ClearIcon from "@material-ui/icons/Clear";


class InstitutionInfoPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'HOME_STATE_PROVINCE': [],
            'COUNTRY': [],
            'PLEASE_CHOSE_2': [],
            'CONTRACT_HOME_STATE': [],
            'CONTRACT_HOME_COUNTRY': [],
            'MATCH_CHECK': [],
            'COUNTRY_CITIZEN_POINT_OF_CONTACT': [],
            months: [],
            years: [],
            regTypes: [],
            selectedRegTypes: [],
            frozen: true,
            dataKycAmlBlock: false,
            loader: false,
            step: null
        }
    }

    componentDidMount() {
        //this.createDummyInvestor();
        this.fetchRegTypes();
        this.init();
    }

    createDummyInvestor() {
        axios.post('/rest/investor', {
            stateId: '1',
            countryId: '1',
            mailStateId: '1',
            mailCountryId: '1'
        }).then(r => this.init()).catch(e => this.init());
    }

    init() {
        const step = this.props.steps.filter(step => step.step.id === 'STEP_INSTITUTION_INFO')[0]

        this.setState({
            step: step
        })

        this.props.fetchAttributeOptions('MATCH_CHECK').then((data) => {
            this.setState({
                ...this.state,
                'MATCH_CHECK': data.data
            });
        });


        this.props.fetchAttributeOptions('CONTRACT_HOME_COUNTRY').then((data) => {
            this.setState({
                ...this.state,
                'CONTRACT_HOME_COUNTRY': data.data
            });
        });

        this.props.fetchAttributeOptions('CONTRACT_HOME_STATE').then((data) => {
            this.setState({
                ...this.state,
                'CONTRACT_HOME_STATE': data.data
            });
        });

        this.props.fetchAttributeOptions('PLEASE_CHOSE_2').then((data) => {
            this.setState({
                ...this.state,
                'PLEASE_CHOSE_2': data.data
            });
        });

        this.props.fetchAttributeOptions('HOME_STATE_PROVINCE').then((data) => {
            this.setState({
                ...this.state,
                'HOME_STATE_PROVINCE': data.data
            });
        });

        this.props.fetchAttributeOptions('COUNTRY').then((data) => {
            this.setState({
                ...this.state,
                'COUNTRY': data.data
            });
        });

        this.props.fetchAttributeOptions('COUNTRY_CITIZEN_POINT_OF_CONTACT').then((data) => {
            this.setState({
                ...this.state,
                'COUNTRY_CITIZEN_POINT_OF_CONTACT': data.data
            });
        });

        if (this.props.investor && this.props.investor.regTypes) {
            const types = this.props.investor.regTypes.map(o => {
                return {
                    value: o.name,
                    id: o.id,
                }
            })

            this.setState({
                selectedRegTypes: types
            })
        }


        let allYears = [];
        for (let i = new Date().getFullYear() - 105; i <= new Date().getFullYear() - 18; i++) {
            allYears.unshift(i);
        }

        let allMonths = [];
        for (let i = 1; i <= 12; i++) {
            allMonths.push(i);
        }

        this.setState({
            months: allMonths,
            years: allYears
        })
    }

    fetchRegTypes = () => {
        RegTypeService.fetch().then(data => {
            const regTypes = data.data.map(o => {
                return {
                    value: o.name,
                    id: o.id,
                }
            });
            this.setState({
                'regTypes': regTypes
            });
        })
    };

    changeRegTypes = (values) => {
        this.setRegTypes(values.map(o => {
            return {
                value: o.name,
                id: o.id,
            }
        }));
    };

    setRegTypes = (vals) => {
        this.setState({
            selectedRegTypes: vals
        });
    };

    pushRegTypes = () => {
        InvestorService.pushRegTypes(this.props.investor.id, this.state.selectedRegTypes)
            .then(data => {
                window.location.href = '/investors/company/edit/' + this.props.investor.user.id;
            });
    };

    clearRegTypes = () => {
        this.setState({
            selectedRegTypes: []
        }, () => {
            this.pushRegTypes();
        })
    }

    changeStateFreeze = () => {
        this.setState((prevState, props) => ({
            frozen: !prevState.frozen
        }))
    }

    handleVerifyBlock = (attr, isVerified) => {
        if (attr === 'KYC_AML') {
            this.setState({
                dataKycAmlBlock: isVerified
            })
        }
    }

    onUnVerifyDocuments = (document, stateToEdit) => {
        let listDocuments = stateToEdit;
        let doc = document.chosenValue.split('.');

        InvestorService.unVerifyDocument(doc[0])
            .then(response => {
                    if (!response.data.documentVerify) {
                        for (let i = 0; i < listDocuments.length; i++) {
                            if (listDocuments[i].chosenValue === document.chosenValue) {
                                listDocuments[i].documentVerify = false;
                            }

                            if (stateToEdit === this.state.documentsHomeAddressBlock) {
                                this.setState({
                                    documentsHomeAddressBlock: listDocuments,
                                    dataHomeAddressBlock: false
                                })
                            }
                        }
                    } else {
                        toast.error('Error');
                    }
                }
            )
    }


    render() {
        return (
            <>
                {this.props.loader || this.state.loader
                    ? <Box
                        style={{display: "flex", alignItems: "center", justifyContent: "center", minHeight: '50vh'}}>
                        <CircularProgress/>
                    </Box>
                    : <Grid container spacing={3}
                            justify={'space-between'}>
                        <Grid item lg={4} xs={4}>
                            <Typography>
                                {'Date Registration Finished: '}

                                <Moment format={'L LT'}>
                                    {this.props.investor.user.dateRegistrationCompleted}
                                </Moment>
                            </Typography>
                        </Grid>

                        <Grid item lg={6} xs={6}
                              style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                variant='contained'
                                color={this.state.frozen ? 'primary' : 'secondary'}
                                style={{margin: 15}}
                                onClick={this.changeStateFreeze}>
                                {this.state.frozen ? 'Unfreeze' : 'Freeze'} Information Fields
                            </Button>

                            <Button
                                variant='contained'
                                color={"primary"}
                                style={{
                                    margin: 15
                                }}
                                startIcon={<ClearIcon/>}
                                disabled={this.state.step?.status !== 'APPROVED'}
                                onClick={(e) => this.props.rejectStep('STEP_INSTITUTION_INFO')}>
                                Reject
                            </Button>

                            <Button
                                variant='contained'
                                color='secondary'
                                style={{margin: 15}}
                                startIcon={<CheckIcon/>}
                                disabled={this.state.step?.status === 'APPROVED'}
                                onClick={() => this.props.approveStep('STEP_INSTITUTION_INFO')}>
                                {
                                    this.state.step?.status === 'APPROVED'
                                        ? 'Approved'
                                        : 'Approve'
                                }
                            </Button>
                        </Grid>

                        <Grid item lg={6} xs={12}>
                            <Card raised style={{minHeight: '109vh'}}>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12}>
                                            <Typography variant='h6'>
                                                Entity
                                            </Typography>

                                            <Divider/>
                                        </Grid>

                                        <Grid item lg={12} xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        Entity name
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <TextInput
                                                        defaultValue={this.props.investorData['ENTITY_NAME']}
                                                        attributeCode="ENTITY_NAME"
                                                        disabled={this.state.frozen}
                                                        onBlurHandler={this.props.pushInvestorChange}/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        Country
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <Select
                                                        attributeCode="COUNTRY"
                                                        flagDisabled={this.state.frozen}
                                                        selectedValue={this.props.investorData['COUNTRY']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['COUNTRY']}/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        Street address
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <TextInput
                                                        defaultValue={this.props.investorData['HOME_STREET_ADDRESS']}
                                                        disabled={this.state.frozen}
                                                        attributeCode="HOME_STREET_ADDRESS"
                                                        onBlurHandler={this.props.pushInvestorChange}/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        City
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <TextInput
                                                        defaultValue={this.props.investorData['HOME_CITY']}
                                                        attributeCode="HOME_CITY"
                                                        disabled={this.state.frozen}
                                                        onBlurHandler={this.props.pushInvestorChange}
                                                        className="form-control input-lg"/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        State/Province
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <Select
                                                        attributeCode="HOME_STATE_PROVINCE"
                                                        flagDisabled={this.state.frozen}
                                                        selectedValue={this.props.investorData['HOME_STATE_PROVINCE']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['HOME_STATE_PROVINCE']}/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        Postal code
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <TextInput
                                                        defaultValue={this.props.investorData['HOME_POSTAL_CODE']}
                                                        attributeCode="HOME_POSTAL_CODE"
                                                        disabled={this.state.frozen}
                                                        onBlurHandler={this.props.pushInvestorChange}
                                                        className="form-control input-lg"/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        EIN or Foreign Corporation #
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <TextInput
                                                        defaultValue={this.props.investorData['EIN_FOREIGN_CORPORATION_#']}
                                                        attributeCode="EIN_FOREIGN_CORPORATION_#"
                                                        disabled={this.state.frozen}
                                                        onBlurHandler={this.props.pushInvestorChange}
                                                        className="form-control input-lg"/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Grid container spacing={1}>
                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                Reg Types
                                                            </Typography>
                                                        </Grid>
                                                        <Grid lg={6}
                                                              style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                            <Button variant='contained'
                                                                    size='small'
                                                                    color='primary'
                                                                    disabled={this.state.frozen}
                                                                    style={{margin: 2}}
                                                                    onClick={this.pushRegTypes}>
                                                                Save
                                                            </Button>
                                                            <Button variant='contained'
                                                                    size='small'
                                                                    disabled={this.state.frozen}
                                                                    style={{margin: 2}}
                                                                    color='secondary'
                                                                    onClick={() => this.clearRegTypes()}>
                                                                Clear
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <SelectMultipleField
                                                        name={"regTypes"}
                                                        disabled={this.state.frozen}
                                                        value={this.state.selectedRegTypes.map(value => value.id)}
                                                        options={this.state.regTypes}
                                                        onChange={this.changeRegTypes}
                                                    />
                                                </Grid>
                                                <Grid item lg={12} xs={12} style={{paddingTop: 56}}>
                                                    <Typography variant='h6'>
                                                        Entity type
                                                    </Typography>

                                                    <Divider/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        Entity type
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <Select
                                                        attributeCode="PLEASE_CHOSE_2"
                                                        selectedValue={this.props.investorData['PLEASE_CHOSE_2']}
                                                        flagDisabled={this.state.frozen}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['PLEASE_CHOSE_2']}/>
                                                </Grid>

                                                {this.props.investorData['PLEASE_CHOSE_2'] && this.props.investorData['PLEASE_CHOSE_2'].chosenValue === 'Other' ?
                                                    <><Grid item lg={6} xs={6}>
                                                        <Typography>
                                                            Other entity type
                                                        </Typography>
                                                    </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <TextInput
                                                                defaultValue={this.props.investorData['OTHER_ENTITY_TYPE']}
                                                                disabled={this.state.frozen}
                                                                attributeCode="OTHER_ENTITY_TYPE"
                                                                onBlurHandler={this.props.pushInvestorChange}/>
                                                        </Grid>
                                                    </> : null}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item lg={6} xs={12}>
                            <Card raised style={{minHeight: '109vh', overflow: 'unset'}}>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12}>
                                            <Typography variant='h6'>
                                                Point of contact
                                            </Typography>

                                            <Divider/>
                                        </Grid>

                                        <Grid item lg={12} xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        First name
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <TextInput
                                                        defaultValue={this.props.investorData['NAME']}
                                                        disabled={this.state.frozen}
                                                        attributeCode="NAME"
                                                        onBlurHandler={this.props.pushInvestorChange}/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        Last name
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <TextInput
                                                        defaultValue={this.props.investorData['LAST_NAME_COMPANY_POINT_OF_CONTACT']}
                                                        disabled={this.state.frozen}
                                                        attributeCode="LAST_NAME_COMPANY_POINT_OF_CONTACT"
                                                        onBlurHandler={this.props.pushInvestorChange}/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        Title
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <TextInput
                                                        defaultValue={this.props.investorData['TITLE']}
                                                        disabled={this.state.frozen}
                                                        attributeCode="TITLE"
                                                        onBlurHandler={this.props.pushInvestorChange}/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        Country of citizenship
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <Select
                                                        attributeCode="COUNTRY_CITIZEN_POINT_OF_CONTACT"
                                                        flagDisabled={this.state.frozen}
                                                        selectedValue={this.props.investorData['COUNTRY_CITIZEN_POINT_OF_CONTACT']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['COUNTRY_CITIZEN_POINT_OF_CONTACT']}/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        DOB (US Style)
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <DobUsWithCheck
                                                        attributeCode="DOB"
                                                        flagDisabled={this.state.frozen}
                                                        onChangeHandler={this.props.pushInvestorChange}
                                                        defaultValue={this.props.investorData['DOB']}
                                                        months={this.state.months}
                                                        years={this.state.years}/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        SN or ITIN (if any)
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <TextInput
                                                        defaultValue={this.props.investorData['SSN_OR_ITIN']}
                                                        disabled={this.state.frozen}
                                                        attributeCode="SSN_OR_ITIN"
                                                        onBlurHandler={this.props.pushInvestorChange}/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        Work phone number
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <InputPhone
                                                        defaultValue={this.props.investorData['WORK_PHONE_NUMBER']}
                                                        disabled={this.state.frozen}
                                                        attributeCode="WORK_PHONE_NUMBER"
                                                        onCnangeHandler={this.props.pushInvestorChange}
                                                    />
                                                </Grid>
                                                {/*<Grid item lg={6} xs={6}>*/}
                                                {/*    <Typography>Personal email</Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item lg={6} xs={6}>*/}
                                                {/*    <EmailInput*/}
                                                {/*        defaultValue={this.props.investorData['PERSONAL_EMAIL']}*/}
                                                {/*        attributeCode="PERSONAL_EMAIL"*/}
                                                {/*        onBlurHandler={this.props.pushInvestorChange}*/}
                                                {/*        className="form-control input-lg"/>*/}
                                                {/*</Grid>*/}
                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        Work Email
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <EmailInput
                                                        defaultValue={this.props.investorData['WORK_EMAIL']}
                                                        disabled={this.state.frozen}
                                                        attributeCode="WORK_EMAIL"
                                                        onBlurHandler={this.props.pushInvestorChange}/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>Government ID number</Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <TextPassword
                                                        type={'password'}
                                                        id={"passport"}
                                                        flagDisabled={this.state.frozen}
                                                        defaultValue={this.props.investorData['ID_NUMBER_COMPANY']}
                                                        attributeCode="ID_NUMBER_COMPANY"
                                                        onBlurHandler={this.props.pushInvestorChange}/>
                                                </Grid>

                                                <Grid item lg={6} xs={6}>
                                                    <Typography>
                                                        Passport number
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <TextPassword
                                                        type={'password'}
                                                        id={"passport"}
                                                        flagDisabled={this.state.frozen}
                                                        defaultValue={this.props.investorData['PASSPORT_NUMBER_COMPANY']}
                                                        attributeCode='PASSPORT_NUMBER_COMPANY'
                                                        onBlurHandler={this.props.pushInvestorChange}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item lg={6} xs={12}>
                            <Card raised>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12}>
                                            <Typography variant='h6'>
                                                Documentation
                                            </Typography>

                                            <Divider/>
                                        </Grid>

                                        <Grid item lg={12} xs={12}>
                                            <Grid container spacing={3}>
                                                {(this.props.investorData['STATE_ID_FRONT']
                                                    && this.props.investorData['STATE_ID_FRONT'].length !== 0
                                                    && this.props.investorData['STATE_ID_FRONT'][0].chosenValue !== null)
                                                || (this.props.investorData['STATE_ID_3']
                                                    && this.props.investorData['STATE_ID_3'].length !== 0
                                                    && this.props.investorData['STATE_ID_3'][0].chosenValue !== null)
                                                    ? <>
                                                        <Grid item lg={12} xs={12}>
                                                            <CompanyDocuments
                                                                attributeCode={'STATE_ID_FRONT'}
                                                                labelText={'Front'}
                                                                headText={'US Driver’s License or Government Issued ID (FRONT)'}
                                                                currentValue={this.props.investorData['STATE_ID_FRONT']}
                                                                accept={'.jpg .png'}
                                                                documentGroupCode={'state-id-front'}
                                                                onFileSelected={this.props.saveDocument}
                                                                className="state-id front"
                                                                onDocumentDelete={this.props.onDocumentDelete}
                                                                open={true}
                                                                fileNumber="file-1"/>
                                                        </Grid>

                                                        <Grid item lg={12} xs={12}>
                                                            <CompanyDocuments
                                                                attributeCode={'STATE_ID_3'}
                                                                labelText={'Back'}
                                                                headText={'US Driver’s License or Government Issued ID (BACK)'}
                                                                currentValue={this.props.investorData['STATE_ID_3']}
                                                                accept={'.jpg .png'}
                                                                documentGroupCode={'state-id-back'}
                                                                onFileSelected={this.props.saveDocument}
                                                                onDocumentDelete={this.props.onDocumentDelete}
                                                                className="state-id back"
                                                                open={true}
                                                                fileNumber="file-2"/>
                                                        </Grid>
                                                    </>
                                                    : null}

                                                {this.props.investorData['PASSPORT_2']
                                                && this.props.investorData['PASSPORT_2'].length !== 0
                                                && this.props.investorData['PASSPORT_2'][0].chosenValue !== null
                                                    ? <Grid item lg={12} xs={12}>
                                                        <CompanyDocuments
                                                            attributeCode={'PASSPORT_2'}
                                                            headText={'Passport'}
                                                            currentValue={this.props.investorData['PASSPORT_2']}
                                                            accept={'.jpg .png'}
                                                            documentGroupCode={'passport'}
                                                            onFileSelected={this.props.saveDocument}
                                                            onDocumentDelete={this.props.onDocumentDelete}
                                                            className="passport"
                                                            open={true}
                                                            fileNumber="file-3"/>
                                                    </Grid>
                                                    : null}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item lg={6} xs={12}>
                            <Card raised>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12}>
                                            <Typography variant='h6'>
                                                Background Information
                                            </Typography>

                                            <Divider/>
                                        </Grid>

                                        <Grid item lg={12} xs={12}>
                                            <Typography>
                                                Source of funds:
                                            </Typography>
                                            <Textarea
                                                defaultValue={this.props.investorData['STATEMENT_OWN_WORDS']}
                                                attributeCode="STATEMENT_OWN_WORDS"
                                                onBlurHandler={this.props.pushInvestorChange}
                                                className="form-control input-xl"
                                                rows="10"/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item lg={6} xs={6}>
                            <Grid spacing={3} container>
                                <Grid item lg={12} xs={12}>
                                    <Card raised>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item lg={9} xs={9}>
                                                    <Typography variant='h6'>
                                                        KYC/AML verification
                                                    </Typography>

                                                    <Divider/>
                                                </Grid>

                                                <Grid item lg={3} xs={3}
                                                      style={{textAlign: 'center'}}>
                                                    {this.state.dataKycAmlBlock
                                                        ? <Box>
                                                            <BeenhereIcon
                                                                style={{
                                                                    fontSize: 40,
                                                                    color: '#4CAF50',
                                                                    width: '100%'
                                                                }}/>
                                                            <Typography
                                                                variant='caption'>
                                                                Verified
                                                            </Typography>
                                                        </Box>
                                                        : <Box>
                                                            <FindInPageIcon
                                                                style={{
                                                                    fontSize: 40,
                                                                    color: "red",
                                                                    width: '100%'
                                                                }}/>
                                                            <Typography variant='caption'>
                                                                Not Verified (check docs.)
                                                            </Typography>
                                                        </Box>
                                                    }
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    {this.props.investor.id && (
                                                        <UserDocumentation investorId={this.props.investor.id}
                                                                           investor={this.props.investor}
                                                                           attributeCode={'KYC_AML'}
                                                                           onVerifyBlock={this.handleVerifyBlock}
                                                                           verifyButton={true}
                                                                           onUnVerifyDocuments={this.onUnVerifyDocuments}/>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </>
        )
    }
}

export default InstitutionInfoPage;