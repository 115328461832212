import React, {useState} from 'react';

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import {Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import SelectMultile from "../../components/Molecules/SelectMultipleField";
import SelectField from "../../components/Molecules/SelectField";
import InputField from "../../components/Molecules/InputField";
import {toast} from "react-toastify";


const CreateOfferingModal = (props) => {

    const [offeringName, setOfferingName] = useState('')
    const [raisingAmount, setRaisingAmount] = useState('')
    const [issuer, setIssuer] = useState(null)
    const [regTypesChosen, setRegTypesChosen] = useState([])
    const [preMoney, setPreMoney] = useState('')
    const [sharePrice, setSharePrice] = useState('')
    const [errors, setErrors] = useState({})

    const handleValidation = () => {
        let errors = {}
        let valid = true

        if (!offeringName) {
            valid = false
            errors.offeringName = 'Cannot be empty'
        }

        if (!issuer) {
            valid = false
            errors.issuer = 'Cannot be empty'
        }

        // if (!raisingAmount) {
        //     valid = false
        //     errors.raisingAmount = 'Cannot be empty'
        // }
        //
        // if (regTypesChosen.length === 0) {
        //     valid = false
        //     errors.regTypesChosen = 'Cannot be empty'
        // }
        //
        // if (!preMoney) {
        //     valid = false
        //     errors.preMoney = 'Cannot be empty'
        // }
        //
        // if (!sharePrice) {
        //     valid = false
        //     errors.sharePrice = 'Cannot be empty'
        // }

        setErrors(errors)

        return valid
    }

    const handleSubmit = () => {
        if (handleValidation()) {
            const request = {
                name: offeringName,
                issuer: issuer,
                raiseTarget: raisingAmount,
                preMoney: preMoney,
                sharePrice: sharePrice,
                regTypes: regTypesChosen
            };

            props.submit(request);
        } else {
            toast.error('Check required fields')
        }
    };

    const handleClose = () => {
        props.close();
    };


    return (
        <Dialog
            open={props.show}
            maxWidth="md"
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h6">
                    Create new Offering
                </Typography>

                <Divider/>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item lg={6} xs={12}>
                        <InputField
                            title={'Offering Name'}
                            required={true}
                            value={offeringName}
                            hasError={errors.offeringName}
                            errorMessage={errors.offeringName}
                            onChange={setOfferingName}/>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <SelectField
                            title={'Issuer'}
                            required={true}
                            value={issuer ? issuer.id : ''}
                            options={props.issuers}
                            hasError={errors.issuer}
                            errorMessage={errors.issuer}
                            onChange={setIssuer}/>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <InputField
                            title={'Amount to be raised'}
                            value={raisingAmount}
                            hasError={errors.raisingAmount}
                            errorMessage={errors.raisingAmount}
                            type="number"
                            onChange={setRaisingAmount}/>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <InputField
                            title={'Pre-Money Valuation'}
                            value={preMoney}
                            hasError={errors.preMoney}
                            errorMessage={errors.preMoney}
                            type="number"
                            onChange={setPreMoney}/>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <InputField
                            title={'Share Price'}
                            value={sharePrice}
                            hasError={errors.sharePrice}
                            errorMessage={errors.sharePrice}
                            type="number"
                            onChange={setSharePrice}/>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <SelectMultile
                            title={'Reg Types'}
                            value={regTypesChosen.map(type => type.id)}
                            options={props.regTypes}
                            hasError={errors.regTypesChosen}
                            errorMessage={errors.regTypesChosen}
                            onChange={setRegTypesChosen}/>
                    </Grid>
                </Grid>

                <DialogActions>
                    <Button
                        type="button"
                        data-dismiss="modal"
                        onClick={handleClose}>
                        Close
                    </Button>

                    <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        data-dismiss="modal"
                        onClick={handleSubmit}>
                        Create
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default CreateOfferingModal;