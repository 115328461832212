import React, {Component} from 'react';

import Select from '../../../components/input/Select';
import TextInput from "../../../components/input/TextInput";
import MultiCheckbox from "../../../components/input/MultiCheckbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import {Button} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";

class SuitabilityPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'ANNUAL_INCOME': [],
            'TAX_RATE_ID': [],
            'TOTAL_NET_WORTH': [],
            'LIQUIDITY_NEEDS': [],
            'TOTAL_NET_WORTH_2': [],
            'EMPLOYMENT_STATUS_2': [],
            'INDUSTRY_2': [],
            'Stocks': [],
            'ANNUITIES': [],
            'ALT': [],
            'Options': [],
            'SHORT_SALES': [],
            'MARGIN': [],
            'Bonds': [],
            'MUTUAL_FUNDS': [],
            'SF': [],
            'COMMODITIES': [],
            'INVEST_OBJ': [],
            'TIME_H': [],
            'RISK_TOL': [],
            'AVERAGE_INVESTMENT_SIZE': [],
            'SECTORS': [],
            selectedSectors: [],
            step: null
        };
    }

    componentDidMount() {
        this.init();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (Array.isArray(nextProps.investorData['SECTORS']) && this.state.selectedSectors.length === 0) {
            console.log('should not')
            this.setState({
                selectedSectors: nextProps.investorData['SECTORS'].map(s => s.chosenValue)
            }, () => {
                return false;
            })
        }
        console.log('should')
        return true;
    }

    onSectorChange(sector, action) {
        let newSelectedSectors = [...this.state.selectedSectors];
        console.log(sector, action);

        if (action === 'ADD')
            newSelectedSectors.push(sector);
        else if (action === 'REMOVE')
            newSelectedSectors = newSelectedSectors.filter(s => s !== sector);

        this.setState({
            selectedSectors: newSelectedSectors
        })
    }

    init() {
        const step = this.props.steps.filter(step => step.step.id === 'STEP_SUITABILITY')[0]

        this.setState({
            step: step
        })

        this.props.fetchAttributeOptions('SECTORS').then((data) => {
            this.setState({
                ...this.state,
                'SECTORS': data.data
            });
        });

        this.props.fetchAttributeOptions('AVERAGE_INVESTMENT_SIZE').then((data) => {
            this.setState({
                ...this.state,
                'AVERAGE_INVESTMENT_SIZE': data.data
            });
        });

        this.props.fetchAttributeOptions('RISK_TOL').then((data) => {
            this.setState({
                ...this.state,
                'RISK_TOL': data.data
            });
        });

        this.props.fetchAttributeOptions('TIME_H').then((data) => {
            this.setState({
                ...this.state,
                'TIME_H': data.data
            });
        });

        this.props.fetchAttributeOptions('INVEST_OBJ').then((data) => {
            this.setState({
                ...this.state,
                'INVEST_OBJ': data.data
            });
        });

        this.props.fetchAttributeOptions('COMMODITIES').then((data) => {
            this.setState({
                ...this.state,
                'COMMODITIES': data.data
            });
        });

        this.props.fetchAttributeOptions('SF').then((data) => {
            this.setState({
                ...this.state,
                'SF': data.data
            });
        });

        this.props.fetchAttributeOptions('MUTUAL_FUNDS').then((data) => {
            this.setState({
                ...this.state,
                'MUTUAL_FUNDS': data.data
            });
        });

        this.props.fetchAttributeOptions('Bonds').then((data) => {
            this.setState({
                ...this.state,
                'Bonds': data.data
            });
        });

        this.props.fetchAttributeOptions('MARGIN').then((data) => {
            this.setState({
                ...this.state,
                'MARGIN': data.data
            });
        });

        this.props.fetchAttributeOptions('SHORT_SALES').then((data) => {
            this.setState({
                ...this.state,
                'SHORT_SALES': data.data
            });
        });

        this.props.fetchAttributeOptions('ANNUAL_INCOME').then((data) => {
            this.setState({
                ...this.state,
                'ANNUAL_INCOME': data.data
            });
        });

        this.props.fetchAttributeOptions('TAX_RATE_ID').then((data) => {
            this.setState({
                ...this.state,
                'TAX_RATE_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('TOTAL_NET_WORTH').then((data) => {
            this.setState({
                ...this.state,
                'TOTAL_NET_WORTH': data.data
            });
        });

        this.props.fetchAttributeOptions('LIQUIDITY_NEEDS').then((data) => {
            this.setState({
                ...this.state,
                'LIQUIDITY_NEEDS': data.data
            });
        });

        this.props.fetchAttributeOptions('TOTAL_NET_WORTH_2').then((data) => {
            this.setState({
                ...this.state,
                'TOTAL_NET_WORTH_2': data.data
            });
        });

        this.props.fetchAttributeOptions('EMPLOYMENT_STATUS_2').then((data) => {
            this.setState({
                ...this.state,
                'EMPLOYMENT_STATUS_2': data.data
            });
        });

        this.props.fetchAttributeOptions('INDUSTRY_2').then((data) => {
            this.setState({
                ...this.state,
                'INDUSTRY_2': data.data
            });
        });

        this.props.fetchAttributeOptions('Stocks').then((data) => {
            this.setState({
                ...this.state,
                'Stocks': data.data
            });
        });

        this.props.fetchAttributeOptions('ANNUITIES').then((data) => {
            this.setState({
                ...this.state,
                'ANNUITIES': data.data
            });
        });

        this.props.fetchAttributeOptions('ALT').then((data) => {
            this.setState({
                ...this.state,
                'ALT': data.data
            });
        });

        this.props.fetchAttributeOptions('Options').then((data) => {
            this.setState({
                ...this.state,
                'Options': data.data
            });
        });

    }


    render() {
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}
                                  style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    variant='contained'
                                    color={"primary"}
                                    // style={{
                                    //     margin: 15
                                    // }}
                                    startIcon={<ClearIcon/>}
                                    disabled={this.state.step?.status !== 'APPROVED'}
                                    onClick={(e) => this.props.rejectStep('STEP_SUITABILITY')}>
                                    Reject
                                </Button>

                                <Button
                                    variant='contained'
                                    color='secondary'
                                    style={{marginLeft: 15}}
                                    startIcon={<CheckIcon/>}
                                    disabled={this.state.step?.status === 'APPROVED'}
                                    onClick={() => this.props.approveStep('STEP_SUITABILITY')}>
                                    {
                                        this.state.step?.status === 'APPROVED'
                                            ? 'Approved'
                                            : 'Approve'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <form>
                            <Card>
                                <CardContent>
                                    <Grid container>
                                        <Grid item lg={12}>
                                            <Box pb="20px">
                                                <Typography variant="h6">
                                                    Financials
                                                </Typography>
                                                <Divider/>
                                            </Box>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item md={8} lg={4} xs={12}>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom>
                                                        Annual Income from all sources in US $
                                                        <span className="required__circle">*</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <Box>
                                                        <Select
                                                            flagDisabled={true}
                                                            className="select__custom"
                                                            dataLiveSearch="true"
                                                            dataStyle="select__custom"
                                                            dataWidth="100%"
                                                            attributeCode="ANNUAL_INCOME"
                                                            selectedValue={this.props.investorData['ANNUAL_INCOME']}
                                                            onChange={this.props.pushInvestorChange}
                                                            options={this.state['ANNUAL_INCOME']}/>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} lg={4} xs={12}>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom>Total Net Worth in US $
                                                        <span className="required__circle">*</span> </Typography>
                                                </Grid>
                                                <Grid item lg={12} xs={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="TOTAL_NET_WORTH"
                                                        selectedValue={this.props.investorData['TOTAL_NET_WORTH']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['TOTAL_NET_WORTH']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} lg={4} xs={12}>

                                                <Grid item lg={12} xs={12}>

                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom>Employment Status
                                                        <span className="required__circle">*</span>
                                                    </Typography>

                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="EMPLOYMENT_STATUS_2"
                                                        selectedValue={this.props.investorData['EMPLOYMENT_STATUS_2']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['EMPLOYMENT_STATUS_2']}/>

                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid style={{marginTop: '15px', marginBottom: '20px'}} container spacing={3}>
                                            <Grid item md={8} lg={4} xs={12}>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom> Tax Rate
                                                        <span className="required__circle">*</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={12} xs={12}>
                                                            <Select
                                                                flagDisabled={true}
                                                                className="select__custom"
                                                                dataLiveSearch="true"
                                                                dataStyle="input__custom"
                                                                dataWidth="100%"
                                                                attributeCode="TAX_RATE_ID"
                                                                selectedValue={this.props.investorData['TAX_RATE_ID']}
                                                                onChange={this.props.pushInvestorChange}
                                                                options={this.state['TAX_RATE_ID']}/>

                                                            <Box pt="5px">
                                                                <Typography variant="caption" display="block"
                                                                            gutterBottom>
                                                                    Your highest marginal rate (rate on last dollar of
                                                                    taxable
                                                                    income earned) <span
                                                                    className="required__circle">*</span>
                                                                </Typography>
                                                            </Box>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} lg={4} xs={12}>

                                                <Grid item lg={12} xs={12}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom> Average Investment Size
                                                        <span className="required__circle">*</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="AVERAGE_INVESTMENT_SIZE"
                                                        selectedValue={this.props.investorData['AVERAGE_INVESTMENT_SIZE']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['AVERAGE_INVESTMENT_SIZE']}/>

                                                </Grid>
                                            </Grid>
                                            <Grid item md={8} lg={4} xs={12}>
                                                <Grid item lg={12} xs={12}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        gutterBottom>Job Title
                                                        <span className="required__circle">*</span>
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={12} xs={12}>
                                                    <TextInput
                                                        disabled={true}
                                                        defaultValue={this.props.investorData['JOB_TITLE']}
                                                        attributeCode="JOB_TITLE"
                                                        onBlurHandler={this.props.pushInvestorChange}
                                                        className="input__custom--big"/>
                                                </Grid>
                                            </Grid>

                                        </Grid>


                                        <Grid item lg={12}>
                                            <Typography variant="subtitle2">
                                                Sectors <span className="required__circle">*</span>
                                            </Typography>

                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom>Select Sectors that Interest you. </Typography>


                                            <Grid container>

                                                <Grid item md={6} lg={6}>

                                                    {this.state['SECTORS'] ?
                                                        this.state['SECTORS'].slice(0, this.state['SECTORS'].length / 2).map(s => {
                                                            return (
                                                                <div className="checkbox">
                                                                    <MultiCheckbox
                                                                        disabled={true}
                                                                        label={s.value}
                                                                        attributeCode="SECTORS"
                                                                        optionValue={s.value}
                                                                        optionId={s.id}
                                                                        investor={this.props.investor}
                                                                        deleteInvestorData={this.props.deleteMultiInvestorData}
                                                                        pushInvestorChange={this.props.pushInvestorChange}
                                                                        stateChange={(sector, action) => this.onSectorChange(sector, action)}
                                                                        defaultValue={this.props.investorData['SECTORS']}
                                                                        attributes={[]}/>
                                                                </div>
                                                            )
                                                        }) : null}
                                                </Grid>

                                                <Grid item md={6} lg={6}>
                                                    {this.state['SECTORS'] ?
                                                        this.state['SECTORS'].slice(this.state['SECTORS'].length / 2).map(s => {
                                                            return (
                                                                <div className="checkbox">
                                                                    <MultiCheckbox
                                                                        disabled={true}
                                                                        label={s.value}
                                                                        attributeCode="SECTORS"
                                                                        optionValue={s.value}
                                                                        optionId={s.id}
                                                                        investor={this.props.investor}
                                                                        deleteInvestorData={this.props.deleteMultiInvestorData}
                                                                        pushInvestorChange={this.props.pushInvestorChange}
                                                                        stateChange={(sector, action) => this.onSectorChange(sector, action)}
                                                                        defaultValue={this.props.investorData['SECTORS']}
                                                                        attributes={[]}/>
                                                                </div>
                                                            )
                                                        }) : null}
                                                </Grid>

                                            </Grid>

                                            {this.state.selectedSectors.find(s => s === 'Other') ?
                                                <Grid item lg={8} xs={12} md={6}>
                                                    <Box pt="30px">
                                                        <Divider/>

                                                        <Box pt="30px">

                                                            <Typography variant="subtitle2">
                                                                Other sector
                                                            </Typography>

                                                            <TextInput
                                                                desabled={true}
                                                                defaultValue={this.props.investorData['OTHER_SECTOR']}
                                                                attributeCode="OTHER_SECTOR"
                                                                onBlurHandler={this.props.pushInvestorChange}
                                                                className="input__custom--big"/>
                                                        </Box>
                                                    </Box> </Grid> : null}

                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>

                            <Card style={{marginTop: '30px'}}>
                                <CardContent>
                                    <Grid container>
                                        <Grid item lg={12}>
                                            <Box pb="20px">
                                                <Typography variant="h6">
                                                    Investment Experience
                                                </Typography>
                                                <Divider/>
                                            </Box>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item lg={12}>
                                                <Typography>
                                                    How many years experience do you have investing
                                                    in the following areas? <span className="required__circle">*</span>
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Stocks <span className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>
                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="Stocks"
                                                        selectedValue={this.props.investorData['Stocks']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['Stocks']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Annuities <span className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="ANNUITIES"
                                                        selectedValue={this.props.investorData['ANNUITIES']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['ANNUITIES']}/>
                                                </Grid>

                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Alternative <span className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="ALT"
                                                        selectedValue={this.props.investorData['ALT']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['ALT']}/>

                                                    <Box>
                                                        <Typography variant="caption">May
                                                            include structured products, hedge funds, etc.</Typography>
                                                    </Box>

                                                </Grid>
                                            </Grid>

                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Options <span className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="Options"
                                                        selectedValue={this.props.investorData['Options']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['Options']}/>
                                                </Grid>

                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Sales <span className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="SHORT_SALES"
                                                        selectedValue={this.props.investorData['SHORT_SALES']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['SHORT_SALES']}/>
                                                </Grid>

                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Margin <span className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="MARGIN"
                                                        selectedValue={this.props.investorData['MARGIN']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['MARGIN']}/>
                                                </Grid>

                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Bonds <span className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="Bonds"
                                                        selectedValue={this.props.investorData['Bonds']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['Bonds']}/>
                                                </Grid>

                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Mutual Funds <span className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="MUTUAL_FUNDS"
                                                        selectedValue={this.props.investorData['MUTUAL_FUNDS']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['MUTUAL_FUNDS']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Securities Futures <span className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="SF"
                                                        selectedValue={this.props.investorData['SF']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['SF']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>
                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Commodities/Futures <span className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="COMMODITIES"
                                                        selectedValue={this.props.investorData['COMMODITIES']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['COMMODITIES']}/>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                            <Card style={{marginTop: '30px'}}>
                                <CardContent>
                                    <Grid container>
                                        <Grid item lg={12}>
                                            <Box pb="10px">
                                                <Typography variant="h6">
                                                    Investment profile
                                                </Typography>
                                                <Divider/>
                                            </Box>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item md={8} xs={12} lg={4}>

                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Investment Objectives <span
                                                        className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="INVEST_OBJ"
                                                        selectedValue={this.props.investorData['INVEST_OBJ']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['INVEST_OBJ']}/>
                                                </Grid>


                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>

                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Time Horizon <span className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="TIME_H"
                                                        selectedValue={this.props.investorData['TIME_H']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['TIME_H']}/>
                                                </Grid>
                                            </Grid>

                                            <Grid item md={8} xs={12} lg={4}>

                                                <Grid item lg={12}>
                                                    <Box pt="21px"> <Typography variant="subtitle2">
                                                        Risk Tolerance <span className="required__circle">*</span>
                                                    </Typography></Box>
                                                </Grid>

                                                <Grid item lg={12}>
                                                    <Select
                                                        flagDisabled={true}
                                                        className="select__custom"
                                                        dataLiveSearch="true"
                                                        dataStyle="select__custom"
                                                        dataWidth="100%"
                                                        attributeCode="RISK_TOL"
                                                        selectedValue={this.props.investorData['RISK_TOL']}
                                                        onChange={this.props.pushInvestorChange}
                                                        options={this.state['RISK_TOL']}/>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                        </form>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default SuitabilityPage;