import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import axios from "../../custom-axios/dms-app";
import ImagePreviewModal from "../../containers/modal/ImagePreviewModal";
import Button from "@material-ui/core/Button";
import PublishIcon from '@material-ui/icons/Publish';


class InvestmentUploadDocument extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPreview: false
        }
    }

    openFile = (e) => {
        e.stopPropagation();
        axios.get("/v1/api/document/download/" + this.props.currentValue.chosenValue, {responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.props.currentValue.chosenValue);
                document.body.appendChild(link);
                link.click();
            });
    };
    onDocumentDelete = (e) => {
        this.props.onDocumentDelete(this.props.documentGroupCode, this.props.attributeCode)
    }

    handleClose = (e) => {
        this.setState({
            showPreview: false
        })
    }

    onFilePreview = (e, item) => {
        this.setState({
            fileToPreview: item.chosenValue,
            showPreview: true
        });
    }

    render() {
        return (
            <>
                <ImagePreviewModal
                    className="modal"
                    show={this.state.showPreview}
                    file={this.state.fileToPreview}
                    close={this.handleClose}
                />
                {
                    (this.props.currentValue && this.props.currentValue.chosenValue ?
                            (
                                <div>
                                    <button name={this.props.fileNumber} type={"button"}
                                            onClick={(e) => this.onFilePreview(e, this.props.currentValue)}
                                            className={"btn btn-border-white btn-block"}>Preview
                                    </button>
                                    <button name={this.props.fileNumber} type={"button"}
                                            onClick={(e) => this.onDocumentDelete(e)}
                                            className={"btn btn-border-white btn-block"}>Remove
                                    </button>
                                </div>
                            )
                            :
                            (
                                <Dropzone
                                    onDrop={acceptedFiles => this.props.onFileSelected(this.props.documentGroupCode, acceptedFiles)}
                                >
                                    {({getRootProps, getInputProps}) => (

                                        <div className={this.props.wrapperClass}>
                                            <input id={this.props.fileNumber} type={"file"} {...getInputProps()}
                                                   style={{display: 'none'}}
                                                   accept="{this.props.accept}"
                                                   required>
                                            </input>


                                            {this.props.currentValue && this.props.currentValue.chosenValue ?
                                                <label htmlFor={this.props.fileNumber}>
                                                    <a
                                                        href={process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/' + this.props.currentValue.chosenValue}>
                                                        {this.props.currentValue.chosenValue}</a>
                                                </label> :

                                                <label htmlFor={this.props.fileNumber}>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        startIcon={<PublishIcon/>}
                                                        component="span">
                                                        Upload
                                                    </Button>
                                                </label>
                                            }
                                        </div>

                                    )}
                                </Dropzone>)
                    )
                }
            </>
        )
    }


}

export default InvestmentUploadDocument;