import React from 'react';

import PhoneInput from "react-phone-input-2";
import {Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import "react-phone-input-2/lib/bootstrap.css";

const InputPhoneField = ({
                             title, name, value, required, disabled, onChange, onBlur, hasError = false, errorMessage
                         }) => {

    return (
        <>
            <Typography>
                {title}
                {required && <span className="required__circle"> *</span>}
            </Typography>
            <FormControl
                fullWidth
                error={hasError}
            >
                <PhoneInput
                    value={value}
                    country={"us"}
                    placeholder={''}
                    inputClass={"tel-input"}
                    disabled={disabled}
                    containerClass={hasError ? "tel-input-container error_border" : "tel-input-container"}
                    onBlur={onBlur}
                    onChange={(value) => onChange(value, name)}
                />

                <FormHelperText>
                    {hasError && errorMessage}
                </FormHelperText>
            </FormControl>
        </>
    )
}

export default InputPhoneField;