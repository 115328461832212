import React from 'react';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import Box from "@material-ui/core/Box";


export const IssuersGraph = ({usersData}) => {

    const data = [
        {
            name: 'Waiting for Approval:', issuers: usersData.issuersWaiting,
        },

        {
            name: 'Pending', issuers: usersData.issuersPending,
        },

        {
            name: 'Pending KYC/AML:', issuers: usersData.issuersPendingKyc,
        },

        {
            name: 'Active:', issuers: usersData.issuersActive,
        },

        {
            name: 'Blocked:', issuers: usersData.issuersBlocked,
        },
        {
            name: 'Removed:', issuers: usersData.issuersRemoved,
        },
        {
            name: 'On Registration:', issuers: usersData.issuersOnRegistration,
        },
    ];

    return (
        <Box pt="20px">
            <ResponsiveContainer height={400}>
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5, right: 0, left: 0, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis
                        dataKey="name"
                        style={{fontSize: 12}}/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Bar
                        dataKey="issuers"
                        fill="#032F66"/>

                </BarChart>
            </ResponsiveContainer>
        </Box>
    )
}