import React, {Component} from 'react';
import CrudTable from '../../containers/tables/CrudTable';
import UserService from "../../services/UserService";
import CreateUserModal from '../modal/CreateUserModal';
import {toast} from "react-toastify";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AddIcon from '@material-ui/icons/Add';
import Box from "@material-ui/core/Box";
import {makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ApproveTable from "../tables/ApproveTable";
import InvestorService from "../../services/InvestorService";
import PropTypes from 'prop-types';
import SelectField from "../../components/Molecules/SelectField";
import AccountStatusService from "../../services/AccountStatusService";
import InputField from "../../components/Molecules/InputField";
import UserGroupService from "../../services/UserGroupService";
import RegistrationService from "../../services/RegistrationStatusService";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
    },

    custom: {
        padding: 0
    }
}));


class Users extends Component {
    classes = () => {
        useStyles()
    }

    constructor(props) {
        super(props);
        this.state = {
            code: null,
            url: '/rest/users',
            pageSize: 10,
            sizes: [10, 20, 50, 100, 200],
            show: false,
            value: 0,
            filter: {
                fullName: '',
                email: this.props.match.params.email || '',
                accountStatus: '',
                userGroup: this.props.match.params.type || '',
                registrationStatus: ''
            },
            registrationStatuses: [],
            pendingInvestorsColumnNames: [
                {
                    id: 'firstName',
                    label: 'First name',
                    sort: 'firstName',
                },
                {
                    id: 'lastName',
                    label: 'Last name',
                    sort: 'lastName',
                },
                {
                    id: 'email',
                    label: 'Email',
                    sort: 'email',
                    width: 250
                },
                {
                    id: 'email',
                    label: 'Date main completed',
                },
                {
                    id: 'email',
                    label: 'Investor Type',
                },
                {
                    id: 'email',
                    label: 'Registration Type',
                },
                {
                    id: 'email',
                    label: 'Status',
                },
                {
                    id: 'email',
                    label: 'Date Created',
                },
            ],
            pendingInvestorsCurrentPage: 0,
            pendingInvestors: [],
            pendingInvestorsPages: 0,
            filterByEmailForInvestors: {email: ''},
            loaderApprovalTable: true,
            columnNames: [
                {
                    id: 'fullName',
                    label: 'Full Name',
                    sort: 'firstName',
                    type: 'editLink'
                },
                {
                    id: 'email',
                    label: 'Email',
                    sort: 'email',
                    width: 250
                },
                {
                    id: 'offers',
                    label: 'Offers',
                    type: 'list'
                },
                {
                    id: 'userGroup',
                    label: 'User Type',
                    type: 'splitUserGroup',
                    sort: 'userGroup'
                },
                // {
                //     id: 'registrationStatus',
                //     label: 'Registration Status',
                //     type: 'dropdownRegistrationStatus',
                //     sort: 'registrationStatus'
                // },
                {
                    id: 'accountStatus',
                    label: 'Account Status',
                    type: 'dropdownAccountStatus',
                    sort: 'accountStatus'
                },
                {
                    id: 'registrationType',
                    label: 'Registration Type',
                    type: 'registrationType',
                    sort: 'registrationType'
                },

                {
                    id: 'dateCreated',
                    label: 'Date Created',
                    type: 'date',
                    datePattern: 'L LT',
                    sort: 'dateCreated'
                },
            ],
            investmentsCurrentPage: 0,
            investments: [],
            investmentsPages: 0,
        };
    }

    componentDidMount() {
        this.fetchPendingInvestors(0, '', 'desc', this.state.pageSize)
        this.fetchRegistrationStatuses()
        const {match: {params}} = this.props;
        this.setState({
            code: params.code,
        });
    }

    fetchPendingInvestors(page, sortBy, order, pageSize) {
        // this.setState({
        //     loaderApprovalTable: true,
        // });

        InvestorService.fetchPendingInvestors(sortBy ? sortBy : '', order, page, pageSize, {userAccountStatus: 'WAITING_FOR_APPROVAL'})
            .then(response => {
                let pendingInvestors = InvestorService.transformInvestors(response.data.content);

                this.setState({
                    pendingInvestorsPages: response.data.totalPages,
                    pendingInvestors: pendingInvestors,
                    pendingInvestorsCurrentPage: page,
                    loaderApprovalTable: false,
                });
            }).catch(error => {
            console.log(error);
        });
    }

    fetchRegistrationStatuses() {
        this.setState({
            registrationStatuses: RegistrationService.fetch()
        })
    }

    onChangeEmailInvestorsTable = (e) => {
        this.setState({
            filterByEmailForInvestors: {
                ...this.state.filterByEmailForInvestors,
                'email': e.target.value,
            },
            page: 1

        }, () => {
            this.fetchPendingInvestors(this.state.pendingInvestorsCurrentPage, '', 'desc', 10);
        });

    };

    onChangeSearchField = (value, field) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [field]: value.id ? value.id : value
            }
        })

        if (field === 'userGroup') this.changeRegistrationStatuses(value)
    }

    changeRegistrationStatuses(value) {
        switch (value.id) {
            case 'USER_INVESTOR_INDIVIDUAL':
                this.setState({
                    registrationStatuses: RegistrationService.fetchInvestorStatus()
                })

                break
            case 'USER_ISSUER':
                this.setState({
                    registrationStatuses: RegistrationService.fetchIssuerStatus()
                })

                break
            case 'USER_INVESTOR_COMPANY':
                this.setState({
                    registrationStatuses: RegistrationService.fetchCompanyStatus()
                })

                break
            case 'USER_ADMIN':
                this.setState({
                    registrationStatuses: []
                })

                break
            default:
                this.fetchRegistrationStatuses()
        }
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        })
    }

    onChange = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                'userGroup': e.target.value,
            },
        })
    }

    onPageSizeChange = (value) => {
        this.setState({
            pageSize: value
        });
    };

    toggleModal() {
        this.setState((state, props) => {
            return {
                ...state,
                show: !state.show
            };
        });
    }

    onNewUser(data) {
        UserService.addUserByAdmin(data)
            .then(res => {
                this.toggleModal()
                toast.success('New user added successfully');
                this.onChangeSearchField('', 'userType')
            })
            .catch(err => {
                toast.error('User with this email already exists');
            })
    }

    exportUsers() {
        UserService.exportUsers()
    }

    deleteHandler = (user, isFull) => {
        if (isFull) {
            UserService.deleteFullUser(user.id)
                .then(response => {
                    this.table.fetchData()
                })
                .catch(error => {

                })
        } else {
            UserService.deleteUser(user.id)
                .then(response => {
                    this.table.fetchData()
                })
                .catch(error => {

                })
        }
    }


    render() {
        return (
            <Grid container spacing={2}
                  style={{paddingTop: 80}}>
                <Grid item lg={5} xs={12} sm={12}>
                    <AppBar
                        position="static"
                        color="default"
                    >
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="simple tabs example"
                        >
                            <Tab
                                style={{padding: 0, fontSize: '12px'}}
                                label="All Users"
                                {...a11yProps(0)}
                            />

                            <Tab
                                style={{padding: 0, fontSize: '12px'}}
                                label={this.state.pendingInvestors.length !== 0
                                    ? "Waiting For Approval Users (" + this.state.pendingInvestors.length + ")"
                                    : "Waiting For Approval Users"}
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </AppBar>
                </Grid>

                <Grid item lg={12} xs={12} sm={12}>
                    <TabPanel
                        value={this.state.value}
                        index={0}>
                        {this.state.show && (
                            <CreateUserModal
                                className="modal"
                                submit={(data) => this.onNewUser(data)}
                                show={this.state.show}
                                close={() => this.toggleModal()}
                            />
                        )}

                        <Grid container spacing={2}>
                            <Grid item lg={12} xs={12} sm={12}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    endIcon={<AddIcon/>}
                                    style={{color: 'white'}}
                                    onClick={() => this.toggleModal()}>
                                    Add new user
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<ImportExportIcon/>}
                                    style={{marginLeft: '10px'}}
                                    onClick={() => this.exportUsers()}>
                                    Export
                                </Button>
                            </Grid>

                            <Grid item lg={12} xs={12} sm={6}>
                                <Grid container spacing={2}>
                                    <Grid item lg={2} sm={6} xs={12}>
                                        <Typography variant="subtitle2">
                                            Full Name
                                        </Typography>
                                        <InputField
                                            name={'fullName'}
                                            value={this.state.filter.fullName}
                                            size={"small"}
                                            onChange={this.onChangeSearchField}
                                        />
                                    </Grid>

                                    <Grid item lg={2} sm={6} xs={12}>
                                        <Typography variant="subtitle2">
                                            Email
                                        </Typography>
                                        <InputField
                                            name={'email'}
                                            value={this.state.filter.email}
                                            size={"small"}
                                            onChange={this.onChangeSearchField}
                                        />
                                    </Grid>

                                    <Grid item lg={1} sm={6} xs={12}>
                                        <Typography variant="subtitle2">
                                            User Type
                                        </Typography>
                                        <SelectField
                                            name={'userGroup'}
                                            value={this.state.filter.userGroup}
                                            emptyLabel={'All'}
                                            size={"small"}
                                            options={UserGroupService.fetch()}
                                            onChange={this.onChangeSearchField}
                                        />
                                    </Grid>

                                    <Grid item lg={2} sm={6} xs={12}>
                                        <Typography variant="subtitle2">
                                            Registration Status
                                        </Typography>
                                        <SelectField
                                            name={"registrationStatus"}
                                            value={this.state.filter.registrationStatus}
                                            emptyLabel={'All'}
                                            size={"small"}
                                            options={this.state.registrationStatuses}
                                            onChange={this.onChangeSearchField}
                                        />
                                    </Grid>

                                    <Grid item lg={2} sm={6} xs={12}>
                                        <Typography variant="subtitle2">
                                            Account Status
                                        </Typography>
                                        <SelectField
                                            name={"accountStatus"}
                                            value={this.state.filter.accountStatus}
                                            emptyLabel={'All'}
                                            size={"small"}
                                            options={AccountStatusService.fetch()}
                                            onChange={this.onChangeSearchField}
                                        />
                                    </Grid>

                                    <Grid item lg={1} sm={6} xs={12}>
                                        <Typography variant="subtitle2">
                                            Show
                                        </Typography>
                                        <SelectField
                                            name={"pageSize"}
                                            value={this.state.pageSize}
                                            options={this.state.sizes}
                                            size={"small"}
                                            onChange={this.onPageSizeChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item lg={12}>
                                <CrudTable
                                    {...this.props}
                                    ref={(c) => this.table = c}
                                    url={this.state.url}
                                    theadClassName=""
                                    columns={this.state.columnNames}
                                    canEdit={true}
                                    sort={this.state.sort}
                                    pageSize={this.state.pageSize}
                                    editModalBody={'user'}
                                    filter={this.state.filter}
                                    flag={true}
                                    transform={UserService.transform}
                                    loader={this.state.loader}
                                    flagCheck={true}
                                    update={() => this.fetchPendingInvestors(0, '', 'desc', 10)}
                                    deleteHandler={this.deleteHandler}
                                    user={true}/>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Grid>

                <Grid item lg={12} xs={12} sm={12}>
                    <TabPanel
                        value={this.state.value}
                        index={1}>
                        <Grid container spacing={3}>
                            {/*<Grid item lg={12} xs={12} sm={6}>*/}
                            {/*    <Grid spacing={3} container>*/}
                            {/*        <Grid item lg={3} xs={12}>*/}
                            {/*            <Typography variant="subtitle2">*/}
                            {/*                Email*/}
                            {/*            </Typography>*/}
                            {/*            <input*/}
                            {/*                defaultValue={this.state.filter.email}*/}
                            {/*                onChange={this.onChangeEmailInvestorsTable}*/}
                            {/*                className={"input__custom"}*/}
                            {/*                type={"text"}/>*/}
                            {/*        </Grid>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}

                            <Grid item lg={12} xs={12}>
                                <ApproveTable
                                    {...this.props}
                                    loader={this.state.loaderApprovalTable}
                                    columns={this.state.pendingInvestorsColumnNames}
                                    rows={this.state.pendingInvestors}
                                    numberOfPages={this.state.pendingInvestorsPages}
                                    pageChanged={() => this.fetchPendingInvestors(0, '', 'desc', 10)}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Grid>
            </Grid>
        )
    }
}

export default Users;