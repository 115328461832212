import React from 'react';

import ReactDOM from 'react-dom'
import App from './App';
import * as serviceWorker from './serviceWorker';

import './assets/css/bootstrap.css';
import './index.css';
import './assets/fontawesome/css/fontawesome-all.css';
import './assets/css/dropzone.css';
import './assets/css/custom-radio-checkbox.css';
import './assets/css/bootstrap-select.min.css';
import './assets/css/bootstrap-datetimepicker.css'
import './assets/css/backoffice.css';
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import Aux from './hoc/AuxWrapper';
import 'react-toastify/dist/ReactToastify.css';
import "react-image-gallery/styles/css/image-gallery.css";
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';

const rootElement = document.getElementById('root');


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#032F66',
        },
        secondary: {
            main: '#4CAF50',
            white: '#fff',
        },
        default: {
            main: '#FAFAFA'
        }

    },
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Aux>
            <ToastContainer autoClose={3000}/>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Aux>
    </ThemeProvider>,
    rootElement);

// If you want your main to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
