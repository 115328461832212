import React from 'react';

const RadioInput = (props) => {

    const onChangeHandler = (value) => {
        const option = props.options.find(o => o.value === value);
        props.onChange({
            attributeCode: props.attributeCode,
            value: value,
            investorAttributeOptionId: option.id
        });
    };

    return (
        <div>
            <div className="radio radio-inline">
                <input type="radio"
                       name={props.name}
                       id="yes"
                       value="yes"
                       disabled={props.disabled?props.disabled:false}
                       checked={props.defaultValue && props.defaultValue.chosenValue ? props.defaultValue.chosenValue === "yes" : false}
                       onChange={(e) => onChangeHandler(e.currentTarget.value)}/>
                <label htmlFor="yes">
                    Yes
                </label>
            </div>
            <div className="radio radio-inline">
                <input type="radio"
                       name={props.name}
                       id="no"
                       value="no"
                       disabled={props.disabled?props.disabled:false}
                       checked={props.defaultValue && props.defaultValue.chosenValue ? props.defaultValue.chosenValue === "no" : false}
                       onChange={(e) => onChangeHandler(e.currentTarget.value)}/>
                <label htmlFor="no">
                    No
                </label>
            </div>
        </div>
    );
};

export default RadioInput;