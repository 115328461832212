import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {CardContent, Divider, Table} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import PdfPreviewModal from "../../modal/PdfPreviewModal";
import ImagePreviewModal from "../../modal/ImagePreviewModal";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import UnVerifyDocumentsModal1 from "../../modal/UnVerifyDocumentsModal1";
import UnVerifyDocumentsModal2 from "../../modal/UnVerifyDocumentsModal2";
import PassportVerificationModal from "../../modal/PassportVerificationModal";
import GovIdVerificationModal from "../../modal/GovIdVerificationModal";
import HomeAddressVerifyModal from "../../modal/HomeAddressVerifyModal";
import DeletePassportModal from "../../modal/DeletePassportModal";
import DeleteGovIdModal from "../../modal/DeleteGovIdModal";
import HomeAddressUnVerifyModal from "../../modal/HomeAddressUnVerifyModal";
import Box from "@material-ui/core/Box";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import WarningIcon from "@material-ui/icons/Warning";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import TextPassword from "../../../components/input/TextPassword";
import SelectWithCheck from "../../../components/input/SelectWithCheck";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {AUTH_TOKEN} from "../../../shared/utility";
import SaveIcon from "@material-ui/icons/Save";
import UserDocumentation from "../../../components/UserDocumentation";
import InvestorService from "../../../services/InvestorService";
import {toast} from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Text from "../../../components/input/Text";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

class Documentation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'COUNTRY_CITIZEN': [],
            'STATE_PROVINCE_ID': [],
            'COUNTRY_ID': [],
            'MAILING_COUNTRY_ID': [],
            'MAILING_STATE_PROVINCE_ID': [],
            'SAME_MAILING_ADDRESS_ID': [],
            mandatoryFields: ['MAILING_STATE_PROVINCE_ID', 'STATE_PROVINCE_ID', 'SAME_MAILING_ADDRESS_ID'],
            months: [],
            years: [],
            regTypes: [],
            selectedRegTypes: [],
            typePassport: 'password',
            wordingPassport: 'Show',
            typeGovId: 'password',
            wordingGovId: 'Show',
            typeSsn: 'password',
            wordingSsn: 'Show',
            accountStatus: '',
            documentsHomeAddressBlock: [],
            documentsPassportBlock: [],
            documentsGovIdBlock: [],
            showPreview: false,
            showPdfPreview: false,
            userVerifiedHomeAddressBlock: "",
            verificationDateHomeAddressBlock: "",
            userVerifiedPassportBlock: "",
            verificationDatePassportBlock: "",
            userVerifiedGovIdBlock: "",
            verificationDateGovIdBlock: "",
            dataHomeAddressBlock: false,
            dataPassportBlock: false,
            dataGovIdBlock: false,
            dataKycAmlBlock: false,
            checkActivationBlock: false,
            freezeInputs: true,
            wordingFreeze: "Unfreeze",
            isShowing1: false,
            isShowing2: false,
            unVerifyPassport: false,
            unVerifyGovId: false,
            unVerifyHomeAddress: false,
            showingPassport: false,
            showingGovId: false,
            showingHomeAddress: false,
            showingDeletePassport: false,
            showingDeleteGovId: false,
            showingDeleteHomeAddress: false,
            expirationDatePassport: '',
            expirationDateGovId: '',
            expirationDateHomeAddress: '',
            expirationDatePassportChecked: false,
            expirationDateGovIdChecked: false,
            expirationDateHomeAddressChecked: false,
            verificationDatePassportChecked: false,
            verificationDateGovIdChecked: false,
            verificationDateHomeAddressChecked: false,
            loader: false,
            allowRegType: '',
            'regA': false,
            'regA+': false,
            'regCF': false,
            'regD(506B)': false,
            'regD(506C)': false,
            'regD': true,
            step: null
        }
    }

    componentDidMount() {
        const step = this.props.steps.filter(step => step.step.id === 'STEP_DOCUMENTATION')[0]

        this.setState({
            step: step
        })
        // document.body.setAttribute('class', 'dashboard-body');
        this.fetchDocumentsHomeAddress();
        this.fetchDocumentsPassport();
        this.fetchDocumentsGovId();
    }

    fetchDocumentsHomeAddress = () => {
        if (this.props.issuerId) {
            InvestorService.fetchByAttributeCodeAndIssuerId('FINANCIAL_STATEMENT_', this.props.issuerId)
                .then(data => {
                    this.setState({documentsHomeAddressBlock: data.data});
                });
        } else if (this.props.investorId) {
            InvestorService.fetchByAttributeCodeAndInvestorId('FINANCIAL_STATEMENT_', this.props.investorId)
                .then(data => {
                    this.setState({documentsHomeAddressBlock: data.data}, () => {
                        this.checkVerifyDocumentsFirstTime(this.state.documentsHomeAddressBlock)
                        this.checkBlocksVerified()
                    });
                });
        }
    };

    fetchDocumentsPassport = () => {
        this.setState({
            loader: true
        })

        if (this.props.issuerId) {
            InvestorService.fetchByAttributeCodeAndIssuerId('PASSPORT', this.props.issuerId)
                .then(data => {
                    this.setState({documentsPassportBlock: data.data});
                });
        } else if (this.props.investorId) {
            InvestorService.fetchByAttributeCodeAndInvestorId('PASSPORT', this.props.investorId)
                .then(data => {
                    this.setState({documentsPassportBlock: data.data}, () => {
                        this.checkVerifyDocumentsFirstTime(this.state.documentsPassportBlock)
                        this.checkBlocksVerified()
                    });
                });
        }
    };

    fetchDocumentsGovId = () => {
        if (this.props.issuerId) {
            InvestorService.fetchByAttributeCodeAndIssuerId('STATE_ID_BACK', this.props.issuerId)
                .then(data => {
                    this.setState({documentsGovIdBlock: data.data});
                });
        } else if (this.props.investorId) {
            let documentsList = [];
            InvestorService.fetchByAttributeCodeAndInvestorId('STATE_ID_4', this.props.investorId)
                .then(data1 => {

                    data1.data.forEach(doc1 => {
                        documentsList.push(doc1)
                    })
                });

            InvestorService.fetchByAttributeCodeAndInvestorId('STATE_ID_BACK', this.props.investorId)
                .then(data2 => {
                    data2.data.forEach(doc2 => {
                        documentsList.push(doc2)
                    });

                    this.setState({documentsGovIdBlock: documentsList}, () => {
                        this.checkVerifyDocumentsFirstTime(this.state.documentsGovIdBlock)
                    });
                });
        }
    };

    checkBlocksVerified = () => {
        if (this.props.investor.activationBlockList && this.props.investor.activationBlockList.length !== 0) {
            this.props.investor.activationBlockList.forEach(block => {
                if (block.blockName === "BLOCK_PASSPORT") {
                    this.setState({
                        userVerifiedPassportBlock: block.userNameThatVerified,
                        verificationDatePassportBlock: block.verificationDate,
                        expirationDatePassport: block.expirationDate
                    }, () => {
                        if (block.expirationDate !== '') {
                            this.checkExpirationDate(block.expirationDate, "BLOCK_PASSPORT");
                        }
                        this.checkVerificationDate(block.verificationDate, "BLOCK_PASSPORT")
                    });

                }
                if (block.blockName === "BLOCK_HOME_ADDRESS") {
                    this.setState({
                        userVerifiedHomeAddressBlock: block.userNameThatVerified,
                        verificationDateHomeAddressBlock: block.verificationDate,
                        expirationDateHomeAddress: block.expirationDate
                    }, () => {
                        this.checkExpirationDate(block.expirationDate, "BLOCK_HOME_ADDRESS");
                        this.checkVerificationDate(block.verificationDate, "BLOCK_HOME_ADDRESS")

                    });
                }
                if (block.blockName === "BLOCK_GOV_ID") {
                    this.setState({
                        userVerifiedGovIdBlock: block.userNameThatVerified,
                        verificationDateGovIdBlock: block.verificationDate,
                        expirationDateGovId: block.expirationDate
                    }, () => {
                        if (block.expirationDate !== '') {
                            this.checkExpirationDate(block.expirationDate, "BLOCK_GOV_ID");
                        }
                        this.checkVerificationDate(block.verificationDate, "BLOCK_GOV_ID")
                    });
                }
            })
        }
    };

    onFilePreview = (e, item) => {
        e.stopPropagation();
        /*this.setState({
            fileToPreview: item.chosenValue,
            showPreview: true
        });*/
        let fileToPreview = item.chosenValue.split('.');

        if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
            this.setState({
                fileToPreview: item.chosenValue,
                showPdfPreview: true
            });
        } else {
            this.setState({
                fileToPreview: item.chosenValue,
                showPreview: true
            });
        }
    };

    handleClose = (e) => {
        this.setState({
            showPreview: false
        })
    };

    handlePdfClose = (e) => {
        this.setState({
            showPdfPreview: false
        })
    };

    onVerifyDocuments = (document, stateToEdit) => {
        let listDocuments = stateToEdit;
        let doc = document.chosenValue.split('.');

        InvestorService.verifyDocument(doc[0])
            .then(response => {
                    if (response.data.documentVerify) {
                        for (let i = 0; i < listDocuments.length; i++) {
                            if (listDocuments[i].chosenValue === document.chosenValue) {
                                listDocuments[i].documentVerify = true;
                            }

                            if (stateToEdit === this.state.documentsHomeAddressBlock) {
                                this.setState({
                                    documentsHomeAddressBlock: listDocuments

                                }, () => {
                                    this.checkVerifiedOrNoListDocuments(listDocuments)
                                })
                            }

                            if (stateToEdit === this.state.documentsPassportBlock) {
                                this.setState({
                                    documentsPassportBlock: listDocuments
                                }, () => {
                                    this.checkVerifiedOrNoListDocuments(listDocuments)
                                })
                            }

                            if (stateToEdit === this.state.documentsGovIdBlock) {
                                this.setState({
                                    documentsGovIdBlock: listDocuments
                                }, () => {
                                    this.checkVerifiedOrNoListDocuments(listDocuments)
                                })
                            }
                        }

                        //toast.success('Successfully verified');
                    } else {
                        toast.error('Error verification');
                    }
                }
            )
    };

    onUnVerifyDocuments = (document, stateToEdit) => {
        let listDocuments = stateToEdit;
        let doc = document.chosenValue.split('.');

        InvestorService.unVerifyDocument(doc[0])
            .then(response => {
                    if (!response.data.documentVerify) {
                        for (let i = 0; i < listDocuments.length; i++) {
                            if (listDocuments[i].chosenValue === document.chosenValue) {
                                listDocuments[i].documentVerify = false;
                            }

                            if (stateToEdit === this.state.documentsHomeAddressBlock) {
                                this.setState({
                                    documentsHomeAddressBlock: listDocuments

                                }, () => {
                                    this.checkVerifiedOrNoListDocuments(listDocuments)
                                })
                            }

                            if (stateToEdit === this.state.documentsPassportBlock) {
                                this.setState({
                                    documentsPassportBlock: listDocuments
                                }, () => {
                                    this.checkVerifiedOrNoListDocuments(listDocuments)
                                })
                            }

                            if (stateToEdit === this.state.documentsGovIdBlock) {
                                this.setState({
                                    documentsGovIdBlock: listDocuments
                                }, () => {
                                    this.checkVerifiedOrNoListDocuments(listDocuments)
                                })
                            }
                        }
                    } else {
                        toast.error('Error');
                    }
                }
            )
    }

    checkVerifyDocumentsFirstTime = (documents) => {
        let currentVerifiedDocuments = 0;
        let listDocuments = documents;

        for (let i = 0; i < listDocuments.length; i++) {
            if (listDocuments[i].documentVerify === true) {
                currentVerifiedDocuments++
            }
        }
        if (currentVerifiedDocuments === listDocuments.length && currentVerifiedDocuments !== 0) {
            if (documents === this.state.documentsHomeAddressBlock) {
                this.setState({
                    dataHomeAddressBlock: true
                })
            }

            if (documents === this.state.documentsPassportBlock) {
                this.setState({
                    dataPassportBlock: true
                })
            }

            if (documents === this.state.documentsGovIdBlock) {
                this.setState({
                    dataGovIdBlock: true
                })
            }
        }
        this.setState({
            loader: false
        })
    };

    checkVerifiedOrNoListDocuments = (documents) => {
        let currentVerifiedDocuments = 0;
        let listDocuments = documents;

        for (let i = 0; i < listDocuments.length; i++) {
            if (listDocuments[i].documentVerify === true) {
                currentVerifiedDocuments++
            }
        }

        if (currentVerifiedDocuments === listDocuments.length && currentVerifiedDocuments !== 0) {
            if (documents === this.state.documentsHomeAddressBlock) {
                this.setState({
                    dataHomeAddressBlock: true
                })
            }

            if (documents === this.state.documentsPassportBlock) {
                this.setState({
                    dataPassportBlock: true
                }, () => {
                    if (this.state.expirationDatePassport !== '') {
                        this.createActivationBlock("BLOCK_PASSPORT");
                    }

                })
            }

            if (documents === this.state.documentsGovIdBlock) {
                this.setState({
                    dataGovIdBlock: true
                }, () => {
                    if (this.state.expirationDateGovId !== '') {
                        this.createActivationBlock("BLOCK_GOV_ID");
                    }

                })
            }
        }
    };

    createVerificationDate() {
        let now = new Date();
        let options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timezone: 'UTC',
            hour: 'numeric',
            minute: 'numeric',
        };
        return now.toLocaleDateString("en-US", options);
    }

    createActivationBlock = (block) => {
        let date = this.createVerificationDate();
        let firstAndLastUserName = this.props.userThatVerified.firstName + " " + this.props.userThatVerified.lastName;
        let investorId = this.props.investorId;
        let blockName = "";
        let expirationDate = "";

        if (block === "BLOCK_HOME_ADDRESS") {
            blockName = "BLOCK_HOME_ADDRESS";
            expirationDate = this.state.expirationDateHomeAddress;
            this.setState({
                userVerifiedHomeAddressBlock: firstAndLastUserName,
                verificationDateHomeAddressBlock: date,
                verificationDateHomeAddressChecked: true
            });
        }

        if (block === "BLOCK_PASSPORT") {
            blockName = "BLOCK_PASSPORT";
            expirationDate = this.state.expirationDatePassport;
            this.setState({
                userVerifiedPassportBlock: firstAndLastUserName,
                verificationDatePassportBlock: date,
                verificationDatePassportChecked: true
            });
        }

        if (block === "BLOCK_GOV_ID") {
            blockName = "BLOCK_GOV_ID";
            expirationDate = this.state.expirationDateGovId;
            this.setState({
                userVerifiedGovIdBlock: firstAndLastUserName,
                verificationDateGovIdBlock: date,
                verificationDateGovIdChecked: true
            });
        }

        let data = {
            date: date,
            userName: firstAndLastUserName,
            blockName: blockName,
            expirationDate: expirationDate,
        };

        InvestorService.createActivationBlock(data, investorId)
            .then(response => {
                this.props.fetchInvestor(this.props.userId);
                this.fetchDocumentsHomeAddress();
                this.fetchDocumentsPassport();
                this.fetchDocumentsGovId();
            })
            .catch(error => {
            })
    };

    showModal = () => {
        this.setState({
            isShowing1: !this.state.isShowing1
        });
    };

    handleOnUnVerify = (e, block) => {
        e.preventDefault();
        if (block === "passport") {
            this.setState({
                    unVerifyPassport: true
                }, () => {
                    this.showModal()
                }
            );
        }
        if (block === "govId") {
            this.setState({
                unVerifyGovId: true
            }, () => {
                this.showModal()
            })
        }
        if (block === "homeAddress") {
            this.setState({
                unVerifyHomeAddress: true
            }, () => {
                this.showModal()
            })
        }
    };

    handleVerifyBlock = (attr, isVerified) => {
        if (attr === 'KYC_AML') {
            this.setState({
                dataKycAmlBlock: isVerified
            })
        }
    }

    toggleSaveModal1 = () => {
        this.setState({
            unVerifyPassport: false,
            unVerifyGovId: false,
            unVerifyHomeAddress: false
        });
        this.setState({
            isShowing1: !this.state.isShowing1
        })
    };

    toggleSaveModal2 = () => {
        this.setState({
            unVerifyPassport: false,
            unVerifyGovId: false,
            unVerifyHomeAddress: false
        });
        this.setState({
            isShowing2: !this.state.isShowing2
        })
    };

    closeForSubmit = () => {
        this.setState({
            isShowing1: !this.state.isShowing1
        })
    };

    openModal = () => {
        this.setState({
            isShowing2: !this.state.isShowing2
        })
    };

    showDateModal = (e, blockName) => {
        e.preventDefault();

        if (blockName === "BLOCK_HOME_ADDRESS") {
            this.setState({
                showingHomeAddress: true
            })
        }

        if (blockName === "BLOCK_GOV_ID") {
            this.setState({
                showingGovId: true
            })
        }

        if (blockName === "BLOCK_PASSPORT") {
            this.setState({
                showingPassport: true
            })
        }
    };

    closeDateModal = (blockName) => {
        if (blockName === "BLOCK_HOME_ADDRESS") {
            this.setState({
                showingHomeAddress: false
            })
        }

        if (blockName === "BLOCK_GOV_ID") {
            this.setState({
                showingGovId: false
            })
        }

        if (blockName === "BLOCK_PASSPORT") {
            this.setState({
                showingPassport: false
            })
        }
    };

    openDeleteDateModel = (e, blockName) => {
        e.preventDefault();

        if (blockName === "BLOCK_HOME_ADDRESS") {
            this.setState({
                showingDeleteHomeAddress: true
            })
        }

        if (blockName === "BLOCK_GOV_ID") {
            this.setState({
                showingDeleteGovId: true
            })
        }

        if (blockName === "BLOCK_PASSPORT") {
            this.setState({
                showingDeletePassport: true
            })
        }
    };

    closeDeleteDateModal = (blockName) => {
        if (blockName === "BLOCK_HOME_ADDRESS") {
            this.setState({
                showingDeleteHomeAddress: false
            })
        }

        if (blockName === "BLOCK_GOV_ID") {
            this.setState({
                showingDeleteGovId: false
            })
        }

        if (blockName === "BLOCK_PASSPORT") {
            this.setState({
                showingDeletePassport: false
            })
        }
    };

    saveChanges = () => {
        let investorId = this.props.investorId;

        if (this.state.unVerifyPassport) {
            let listDocuments = this.state.documentsPassportBlock;
            for (let i = 0; i < listDocuments.length; i++) {
                let doc = listDocuments[i].chosenValue.split('.');
                InvestorService.unVerifyDocument(doc[0]).then(response => {
                });
                listDocuments[i].documentVerify = false;
            }
            this.setState({
                documentsPassportBlock: listDocuments
            });

            let data = {
                blockName: "BLOCK_PASSPORT",
                date: "",
                flag: "verification"
            };

            InvestorService.changeActivationBlock(data, investorId).then(() => {
                this.setState({
                    dataPassportBlock: false,
                    unVerifyPassport: false
                });
            });

        }

        if (this.state.unVerifyGovId) {
            let listDocuments = this.state.documentsGovIdBlock;
            for (let i = 0; i < listDocuments.length; i++) {
                let doc = listDocuments[i].chosenValue.split('.');
                InvestorService.unVerifyDocument(doc[0]).then(response => {
                });
                listDocuments[i].documentVerify = false;
            }
            this.setState({
                documentsGovIdBlock: listDocuments
            })

            let data = {
                blockName: "BLOCK_GOV_ID",
                date: "",
                flag: "verification"
            };

            InvestorService.changeActivationBlock(data, investorId)
                .then(response => {
                    this.setState({
                        dataGovIdBlock: false,
                        unVerifyGovId: false
                    });
                });

        }

        if (this.state.unVerifyHomeAddress) {
            let listDocuments = this.state.documentsHomeAddressBlock;
            for (let i = 0; i < listDocuments.length; i++) {
                let doc = listDocuments[i].chosenValue.split('.');
                InvestorService.unVerifyDocument(doc[0]).then(response => {
                });
                listDocuments[i].documentVerify = false;
            }
            this.setState({
                documentsHomeAddressBlock: listDocuments
            });
            let data = {
                blockName: "BLOCK_HOME_ADDRESS",
                date: "",
                flag: "verification"
            };

            InvestorService.changeActivationBlock(data, investorId);
            this.setState({
                dataHomeAddressBlock: false,
                unVerifyHomeAddress: false
            });
        }
    };

    saveExpirationDateChanges = (date, blockName) => {
        this.checkExpirationDate(date, blockName);

        if (blockName === "BLOCK_PASSPORT") {
            this.setState({
                expirationDatePassport: date
            }, () => {
                this.createActivationBlock(blockName)
            });
        }

        if (blockName === "BLOCK_GOV_ID") {
            this.setState({
                expirationDateGovId: date
            }, () => {
                this.createActivationBlock(blockName)
            });
        }

        if (blockName === "BLOCK_HOME_ADDRESS") {
            this.state.documentsHomeAddressBlock.forEach(value => {
                this.onVerifyDocuments(value, this.state.documentsHomeAddressBlock)
            })

            this.setState({
                expirationDateHomeAddress: date
            }, () => {
                this.createActivationBlock(blockName)
            });
        }
    };

    compareDate = (e) => {
        let date = new Date(e);
        let dateUtc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        dateUtc.setHours(0, 0, 0, 0);
        let now = new Date();
        let nowUtc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        nowUtc.setHours(0, 0, 0, 0)

        return dateUtc >= nowUtc
    };

    checkExpirationDate = (e, blockName) => {
        if (blockName === "BLOCK_HOME_ADDRESS") {
            if (e === "1") {
                this.setState({
                    expirationDateHomeAddressChecked: true
                })
            } else {
                this.setState({
                    expirationDateHomeAddressChecked: false
                })
            }
        } else {
            if (this.compareDate(e)) {
                if (blockName === "BLOCK_PASSPORT") {
                    this.setState({
                        expirationDatePassportChecked: true
                    })
                }

                if (blockName === "BLOCK_GOV_ID") {
                    this.setState({
                        expirationDateGovIdChecked: true
                    })
                }
            } else {
                if (blockName === "BLOCK_PASSPORT") {
                    this.setState({
                        expirationDatePassportChecked: false
                    })
                }

                if (blockName === "BLOCK_GOV_ID") {
                    this.setState({
                        expirationDateGovIdChecked: false
                    })
                }
            }
        }
    };

    checkVerificationDate = (e, blockName) => {
        let date = new Date(e);
        let dateUtc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        dateUtc.setHours(0, 0, 0, 0);
        dateUtc.setFullYear(dateUtc.getFullYear() + 2);
        let now = new Date();
        let nowUtc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        nowUtc.setHours(0, 0, 0, 0);

        if (dateUtc >= nowUtc) {
            if (blockName === "BLOCK_PASSPORT") {
                this.setState({
                    verificationDatePassportChecked: true
                })
            }

            if (blockName === "BLOCK_GOV_ID") {
                this.setState({
                    verificationDateGovIdChecked: true
                })
            }

            if (blockName === "BLOCK_HOME_ADDRESS") {
                this.setState({
                    verificationDateHomeAddressChecked: true
                })
            }
        } else {
            if (blockName === "BLOCK_PASSPORT") {
                this.setState({
                    verificationDatePassportChecked: false,
                })
            }

            if (blockName === "BLOCK_GOV_ID") {
                this.setState({
                    verificationDateGovIdChecked: false,
                })
            }

            if (blockName === "BLOCK_HOME_ADDRESS") {
                this.setState({
                    verificationDateHomeAddressChecked: false,
                })
            }
        }

    };

    saveDateChanges = (blockName, date) => {
        if (blockName === "BLOCK_HOME_ADDRESS") {
            this.state.documentsHomeAddressBlock.forEach(value => {
                this.onUnVerifyDocuments(value, this.state.documentsHomeAddressBlock)
            })

            this.setState({
                expirationDateHomeAddress: date,
                expirationDateHomeAddressChecked: false,
                verificationDateHomeAddressBlock: date,
                verificationDateHomeAddressChecked: false
            })
        }

        if (blockName === "BLOCK_GOV_ID") {
            this.setState({
                expirationDateGovId: date
            })
        }

        if (blockName === "BLOCK_PASSPORT") {
            this.setState({
                expirationDatePassport: date
            })
        }

        let data = {
            blockName: blockName,
            date: date,
            flag: "expiration"
        };

        this.checkExpirationDate(date, blockName);
        InvestorService.changeActivationBlock(data, this.props.investorId)
    };

    handleChange = (event) => {
        const _selectedRegTypes = this.state.selectedRegTypes ? this.state.selectedRegTypes : []
        const type = this.state.regTypes.find((element, index, array) => element.id.toString() === event.target.value)

        if (event.target.checked) {
            _selectedRegTypes.push(type)
        } else {
            const index = _selectedRegTypes.map(value => value.id).indexOf(type.id)
            _selectedRegTypes.splice(index, 1)
        }

        this.setState({selectedRegTypes: _selectedRegTypes}, () => this.pushRegTypes());
    };

    handleVerifiedBoxOnChange = (event, blockName) => {
        if (event.target.checked) {
            this.showDateModal(event, blockName)
        } else {
            this.openDeleteDateModel(event, blockName)
        }
    }

    render() {
        return (
            <>
                <PdfPreviewModal
                    className="modal"
                    show={this.state.showPdfPreview}
                    file={this.state.fileToPreview}
                    close={this.handlePdfClose}/>

                <ImagePreviewModal
                    className="modal"
                    show={this.state.showPreview}
                    file={this.state.fileToPreview}
                    close={this.handleClose}
                />

                <UnVerifyDocumentsModal1
                    show={this.state.isShowing1}
                    close={this.toggleSaveModal1}
                    closeForSubmit={this.closeForSubmit}
                    submit={this.openModal}
                />

                <UnVerifyDocumentsModal2
                    show={this.state.isShowing2}
                    close={this.toggleSaveModal2}
                    submit={this.saveChanges}
                />

                <PassportVerificationModal
                    show={this.state.showingPassport}
                    close={this.closeDateModal}
                    submit={this.saveExpirationDateChanges}
                    blockName={"BLOCK_PASSPORT"}
                    verifyDate={this.compareDate}
                />

                <GovIdVerificationModal
                    show={this.state.showingGovId}
                    close={this.closeDateModal}
                    submit={this.saveExpirationDateChanges}
                    blockName={"BLOCK_GOV_ID"}
                    verifyDate={this.compareDate}
                />

                <HomeAddressVerifyModal
                    show={this.state.showingHomeAddress}
                    close={this.closeDateModal}
                    submit={this.saveExpirationDateChanges}
                    blockName={"BLOCK_HOME_ADDRESS"}
                />

                <DeletePassportModal
                    show={this.state.showingDeletePassport}
                    close={this.closeDeleteDateModal}
                    submit={this.saveDateChanges}
                    blockName={"BLOCK_PASSPORT"}
                />

                <DeleteGovIdModal
                    show={this.state.showingDeleteGovId}
                    close={this.closeDeleteDateModal}
                    submit={this.saveDateChanges}
                    blockName={"BLOCK_GOV_ID"}
                />

                <HomeAddressUnVerifyModal
                    show={this.state.showingDeleteHomeAddress}
                    close={this.closeDeleteDateModal}
                    submit={this.saveDateChanges}
                    blockName={"BLOCK_HOME_ADDRESS"}
                />

                {
                    this.props.loader
                        // || this.state.loader
                        ? <Box style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: '50vh'
                        }}>
                            <CircularProgress/>
                        </Box>
                        :
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} xs={12}
                                          style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button
                                            variant='contained'
                                            color={"primary"}
                                            // style={{
                                            //     margin: 15
                                            // }}
                                            startIcon={<ClearIcon/>}
                                            disabled={this.state.step?.status !== 'APPROVED'}
                                            onClick={(e) => this.props.rejectStep('STEP_DOCUMENTATION')}>
                                            Reject
                                        </Button>

                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            style={{marginLeft: 15}}
                                            startIcon={<CheckIcon/>}
                                            disabled={this.state.step?.status === 'APPROVED'}
                                            onClick={() => this.props.approveStep('STEP_DOCUMENTATION')}>
                                            {
                                                this.state.step?.status === 'APPROVED'
                                                    ? 'Approved'
                                                    : 'Approve'
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item lg={6} xs={12}>
                                <Card raised>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={9} xs={9}>
                                                <Typography variant='h6'>
                                                    Passport Information:
                                                </Typography>

                                                <Divider/>

                                                {
                                                    this.state.dataPassportBlock
                                                    && this.state.expirationDatePassportChecked
                                                    && this.state.verificationDatePassportChecked
                                                        ? <Button variant='contained'
                                                                  style={{marginTop: 8}}
                                                                  color='primary'
                                                                  size='small'
                                                                  startIcon={
                                                                      <ClearIcon/>}
                                                                  onClick={(e) => this.handleOnUnVerify(e, "passport")}>
                                                            Cancel
                                                            Verification
                                                        </Button>
                                                        : null
                                                }
                                            </Grid>


                                            {this.state.documentsPassportBlock.length !== 0
                                                ? (
                                                    <>
                                                        <Grid item lg={3} xs={3}
                                                              style={{textAlign: 'center'}}>

                                                            {
                                                                this.state.dataPassportBlock
                                                                && this.state.expirationDatePassportChecked
                                                                && this.state.verificationDatePassportChecked
                                                                    ? <Box>
                                                                        <BeenhereIcon
                                                                            style={{
                                                                                fontSize: 40,
                                                                                color: '#4CAF50',
                                                                                width: '100%'
                                                                            }}/>
                                                                        <Typography
                                                                            variant='caption'>
                                                                            Verified
                                                                        </Typography>
                                                                    </Box>

                                                                    : this.state.documentsPassportBlock.length === 0
                                                                    ? <Box>
                                                                        <WarningIcon
                                                                            style={{
                                                                                fontSize: 40,
                                                                                color: "red",
                                                                                width: '100%'
                                                                            }}/>
                                                                        <Typography variant='caption'>
                                                                            Not Verified (no docs.)
                                                                        </Typography>
                                                                    </Box>
                                                                    : <Box>
                                                                        <FindInPageIcon
                                                                            style={{
                                                                                fontSize: 40,
                                                                                color: "red",
                                                                                width: '100%'
                                                                            }}/>
                                                                        <Typography variant='caption'>
                                                                            Not Verified (check docs.)
                                                                        </Typography>
                                                                    </Box>
                                                            }
                                                        </Grid>

                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                Passport Number *
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <TextPassword
                                                                type={this.state.typePassport}
                                                                id={"passport"}
                                                                defaultValue={this.props.investorData['PASSPORT_NUMBER']}
                                                                attributeCode="PASSPORT_NUMBER"
                                                                onBlurHandler={this.props.pushInvestorChange}
                                                                flagDisabled={this.state.freezeInputs}/>
                                                        </Grid>

                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                Country of Citizenship *
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={6} cs={6}>
                                                            <SelectWithCheck
                                                                id={"countryCitizen"}
                                                                dataLiveSearch="true"
                                                                dataStyle="form-control input-lg"
                                                                dataWidth="100%"
                                                                attributeCode="COUNTRY_CITIZEN"
                                                                selectedValue={this.props.investorData['COUNTRY_CITIZEN']}
                                                                onChange={this.props.pushInvestorChange}
                                                                options={this.state['COUNTRY_CITIZEN']}
                                                                flagDisabled={true}/>
                                                        </Grid>

                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                Expiration Date *
                                                            </Typography>
                                                            <Button
                                                                variant='contained'
                                                                size='small'
                                                                startIcon={
                                                                    <PlaylistAddCheckIcon/>}
                                                                disabled={this.state.documentsPassportBlock.length === 0}
                                                                onClick={(e) => this.showDateModal(e, "BLOCK_PASSPORT")}>Set
                                                            </Button>

                                                            {
                                                                this.state.expirationDatePassport
                                                                && this.state.documentsPassportBlock.length !== 0
                                                                    ? <Button
                                                                        variant='contained'
                                                                        style={{marginLeft: 5}}
                                                                        size='small'
                                                                        startIcon={
                                                                            <ClearIcon/>}
                                                                        onClick={(e) => this.openDeleteDateModel(e, "BLOCK_PASSPORT")}>
                                                                        Void
                                                                    </Button>
                                                                    : null
                                                            }
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                InputProps={{readOnly: true}}
                                                                variant="outlined"
                                                                value={this.state.expirationDatePassport}/>
                                                        </Grid>

                                                        <Grid item lg={12} xs={12}>
                                                            <Card>
                                                                <CardContent>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item lg={12} xs={12}>
                                                                            <Typography variant='h6'>
                                                                                Uploaded Documents
                                                                            </Typography>

                                                                            <Divider/>
                                                                        </Grid>

                                                                        {
                                                                            this.state.documentsPassportBlock.length === 0
                                                                                ?
                                                                                <Grid item lg={12} xs={12}>
                                                                                    <Typography>
                                                                                        No Documents
                                                                                    </Typography>
                                                                                </Grid>
                                                                                : <TableContainer>
                                                                                    <Table>
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                {/*<TableCell>Document Description</TableCell>*/}
                                                                                                <TableCell>
                                                                                                    Document
                                                                                                    Name
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    Date
                                                                                                    Uploaded
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    width={257}/>
                                                                                            </TableRow>
                                                                                        </TableHead>

                                                                                        {
                                                                                            this.state.documentsPassportBlock.map(document => {
                                                                                                return (
                                                                                                    <TableRow>
                                                                                                        {/*<TableCell>{document.chosenValue}</TableCell>*/}
                                                                                                        <TableCell>
                                                                                                            {document.documentOriginalName}
                                                                                                        </TableCell>
                                                                                                        <TableCell>
                                                                                                            {document.dateCreated}
                                                                                                        </TableCell>
                                                                                                        <TableCell>
                                                                                                            <div>
                                                                                                                <Button
                                                                                                                    onClick={(e) => this.onFilePreview(e, document)}
                                                                                                                    variant='contained'
                                                                                                                    color='primary'
                                                                                                                    size='small'
                                                                                                                    startIcon={
                                                                                                                        <VisibilityIcon/>}>
                                                                                                                    Preview
                                                                                                                </Button>

                                                                                                                <Button
                                                                                                                    onClick={() => window.open(process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/'
                                                                                                                        + document.chosenValue + '?access_token=' + localStorage.getItem(AUTH_TOKEN)
                                                                                                                        + '&embedded=false')}
                                                                                                                    type={"submit"}
                                                                                                                    variant='contained'
                                                                                                                    color='primary'
                                                                                                                    size='small'
                                                                                                                    style={{marginLeft: 6}}
                                                                                                                    startIcon={
                                                                                                                        <SaveIcon/>}>
                                                                                                                    Download
                                                                                                                </Button>
                                                                                                            </div>

                                                                                                            <div>
                                                                                                                {document.documentVerify ?
                                                                                                                    <Button
                                                                                                                        fullWidth
                                                                                                                        variant='contained'
                                                                                                                        color='primary'
                                                                                                                        size='small'
                                                                                                                        style={{marginTop: 6}}
                                                                                                                        startIcon={
                                                                                                                            <ClearIcon/>}
                                                                                                                        onClick={(e) => this.onUnVerifyDocuments(document, this.state.documentsPassportBlock)}
                                                                                                                    >
                                                                                                                        Unverify
                                                                                                                    </Button> :
                                                                                                                    <Button
                                                                                                                        fullWidth
                                                                                                                        variant='contained'
                                                                                                                        color='secondary'
                                                                                                                        style={{marginTop: 6}}
                                                                                                                        size='small'
                                                                                                                        startIcon={
                                                                                                                            <CheckIcon/>}
                                                                                                                        onClick={(e) => this.onVerifyDocuments(document, this.state.documentsPassportBlock)}>Verify
                                                                                                                    </Button>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                )
                                                                                            })}
                                                                                    </Table>
                                                                                </TableContainer>
                                                                        }
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>

                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                User that Verified:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                {
                                                                    this.state.userVerifiedPassportBlock !== ""
                                                                    && this.state.documentsPassportBlock.length !== 0
                                                                    && this.state.dataPassportBlock
                                                                    && this.state.expirationDatePassportChecked
                                                                        ? this.state.userVerifiedPassportBlock
                                                                        : 'N/A'
                                                                }
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                Verification Date:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                {
                                                                    this.state.verificationDatePassportBlock !== ""
                                                                    && this.state.documentsPassportBlock.length !== 0
                                                                    && this.state.dataPassportBlock
                                                                    && this.state.expirationDatePassportChecked
                                                                        ? this.state.verificationDatePassportBlock
                                                                        : 'N/A'
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <Grid item lg={12} xs={12}>
                                                        <Typography>
                                                            No Documents
                                                        </Typography>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item lg={6} xs={12}>
                                <Card raised>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={9} xs={9}>
                                                <Typography variant='h6'>
                                                    Driver's License or Government
                                                    Issued ID Information:
                                                </Typography>

                                                <Divider/>

                                                {
                                                    this.state.dataGovIdBlock
                                                    && this.state.expirationDateGovIdChecked
                                                    && this.state.verificationDateGovIdChecked
                                                        ? <Button variant='contained'
                                                                  style={{marginTop: 8}}
                                                                  color='primary'
                                                                  size='small'
                                                                  startIcon={<ClearIcon/>}
                                                                  onClick={(e) => this.handleOnUnVerify(e, "govId")}>
                                                            Cancel Verification
                                                        </Button>
                                                        : null
                                                }
                                            </Grid>

                                            {
                                                this.state.documentsGovIdBlock.length !== 0
                                                    ? (
                                                        <>
                                                            <Grid item
                                                                  lg={3}
                                                                  xs={3}
                                                                  style={{textAlign: 'center'}}>

                                                                {
                                                                    this.state.dataGovIdBlock
                                                                    && this.state.expirationDateGovIdChecked
                                                                    && this.state.verificationDateGovIdChecked
                                                                        ? <Box>
                                                                            <BeenhereIcon
                                                                                style={{
                                                                                    fontSize: 40,
                                                                                    color: '#4CAF50',
                                                                                    width: '100%'
                                                                                }}/>
                                                                            <Typography
                                                                                variant='caption'>
                                                                                Verified
                                                                            </Typography>
                                                                        </Box>
                                                                        : this.state.documentsGovIdBlock.length === 0
                                                                        ? <Box>
                                                                            <WarningIcon
                                                                                style={{
                                                                                    fontSize: 40,
                                                                                    color: "red",
                                                                                    width: '100%'
                                                                                }}/>
                                                                            <Typography variant='caption'>
                                                                                Not Verified (no docs.)
                                                                            </Typography>
                                                                        </Box>
                                                                        : <Box>
                                                                            <FindInPageIcon
                                                                                style={{
                                                                                    fontSize: 40,
                                                                                    color: "red",
                                                                                    width: '100%'
                                                                                }}/>
                                                                            <Typography variant='caption'>Not
                                                                                Verified
                                                                                (check
                                                                                docs.)
                                                                            </Typography>
                                                                        </Box>}
                                                            </Grid>

                                                            <Grid item lg={6} xs={6}>
                                                                <Typography>
                                                                    Gorvenment ID Number *
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item lg={6} xs={6}>
                                                                <TextPassword type={this.state.typeGovId}
                                                                              id={"govId"}
                                                                              defaultValue={this.props.investorData['ID_NUMBER']}
                                                                              attributeCode="ID_NUMBER"
                                                                              onBlurHandler={this.props.pushInvestorChange}
                                                                              flagDisabled={this.state.freezeInputs}/>
                                                            </Grid>

                                                            <Grid item lg={6} xs={6}>
                                                                <Typography>Expiration Date *</Typography>
                                                                <Button
                                                                    variant='contained'
                                                                    size='small'
                                                                    startIcon={
                                                                        <PlaylistAddCheckIcon/>}
                                                                    disabled={this.state.documentsGovIdBlock.length === 0}
                                                                    onClick={(e) => this.showDateModal(e, "BLOCK_GOV_ID")}>Set
                                                                </Button>
                                                                {this.state.expirationDateGovId && this.state.documentsGovIdBlock.length !== 0 ?
                                                                    <Button
                                                                        variant='contained'
                                                                        style={{marginLeft: 5}}
                                                                        size='small'
                                                                        startIcon={
                                                                            <ClearIcon/>}
                                                                        onClick={(e) => this.openDeleteDateModel(e, "BLOCK_GOV_ID")}>Void
                                                                    </Button> : null}
                                                            </Grid>
                                                            <Grid item lg={6} xs={6}>
                                                                <TextField
                                                                    fullWidth
                                                                    InputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                    variant="outlined"
                                                                    value={this.state.expirationDateGovId}/>
                                                            </Grid>
                                                            <Grid item lg={12} xs={12}>
                                                                <Card>
                                                                    <CardContent>
                                                                        <Grid container spacing={3}>
                                                                            <Grid item lg={12} xs={12}>
                                                                                <Typography variant='h6'>
                                                                                    Uploaded Documents
                                                                                </Typography>

                                                                                <Divider/>
                                                                            </Grid>

                                                                            {this.state.documentsGovIdBlock.length === 0 ?
                                                                                <Grid item lg={12} xs={12}>
                                                                                    <Typography>
                                                                                        No Documents
                                                                                    </Typography>
                                                                                </Grid>
                                                                                : <TableContainer>
                                                                                    <Table>
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                {/*<TableCell>Document Description</TableCell>*/}
                                                                                                <TableCell>Document
                                                                                                    Name</TableCell>
                                                                                                <TableCell>Date
                                                                                                    Uploaded</TableCell>
                                                                                                <TableCell
                                                                                                    width={257}/>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        {this.state.documentsGovIdBlock.map(document => {
                                                                                            return (
                                                                                                <TableRow>
                                                                                                    {/*<TableCell>{document.chosenValue}</TableCell>*/}
                                                                                                    <TableCell>{document.documentOriginalName}</TableCell>
                                                                                                    <TableCell>{document.dateCreated}</TableCell>
                                                                                                    <TableCell>
                                                                                                        <div>
                                                                                                            <Button
                                                                                                                onClick={(e) => this.onFilePreview(e, document)}
                                                                                                                variant='contained'
                                                                                                                color='primary'
                                                                                                                size='small'
                                                                                                                startIcon={
                                                                                                                    <VisibilityIcon/>}>
                                                                                                                Preview
                                                                                                            </Button>

                                                                                                            <Button
                                                                                                                onClick={() => window.open(process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/'
                                                                                                                    + document.chosenValue + '?access_token=' + localStorage.getItem(AUTH_TOKEN)
                                                                                                                    + '&embedded=false')}
                                                                                                                type={"submit"}
                                                                                                                variant='contained'
                                                                                                                color='primary'
                                                                                                                size='small'
                                                                                                                style={{marginLeft: 6}}
                                                                                                                startIcon={
                                                                                                                    <SaveIcon/>}>
                                                                                                                Download
                                                                                                            </Button>
                                                                                                        </div>

                                                                                                        <div>
                                                                                                            {document.documentVerify ?
                                                                                                                <Button
                                                                                                                    fullWidth
                                                                                                                    variant='contained'
                                                                                                                    color='primary'
                                                                                                                    size='small'
                                                                                                                    style={{marginTop: 6}}
                                                                                                                    startIcon={
                                                                                                                        <ClearIcon/>}
                                                                                                                    onClick={(e) => this.onUnVerifyDocuments(document, this.state.documentsGovIdBlock)}
                                                                                                                >
                                                                                                                    Unverify
                                                                                                                </Button> :
                                                                                                                <Button
                                                                                                                    fullWidth
                                                                                                                    variant='contained'
                                                                                                                    color='secondary'
                                                                                                                    style={{marginTop: 6}}
                                                                                                                    size='small'
                                                                                                                    startIcon={
                                                                                                                        <CheckIcon/>}
                                                                                                                    onClick={(e) => this.onVerifyDocuments(document, this.state.documentsGovIdBlock)}>
                                                                                                                    Verify
                                                                                                                </Button>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            )
                                                                                        })}
                                                                                    </Table>
                                                                                </TableContainer>}
                                                                        </Grid>
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>

                                                            <Grid item lg={6} xs={6}>
                                                                <Typography>
                                                                    User that Verified:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item lg={6} xs={6}>
                                                                <Typography>{this.state.userVerifiedGovIdBlock !== "" && this.state.documentsGovIdBlock.length !== 0
                                                                && this.state.dataGovIdBlock && this.state.expirationDateGovIdChecked ?
                                                                    this.state.userVerifiedGovIdBlock : 'N/A'}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item lg={6} xs={6}>
                                                                <Typography>Verification
                                                                    Date:</Typography>
                                                            </Grid>
                                                            <Grid item lg={6} xs={6}>
                                                                <Typography>{this.state.verificationDateGovIdBlock !== "" && this.state.documentsGovIdBlock.length !== 0
                                                                && this.state.dataGovIdBlock && this.state.expirationDateGovIdChecked ?
                                                                    this.state.verificationDateGovIdBlock : 'N/A'}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <Grid item lg={12} xs={12}>
                                                            <Typography>
                                                                No Documents
                                                            </Typography>
                                                        </Grid>
                                                    )
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item lg={6} xs={12}>
                                <Card raised>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={12} xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={12} xs={12}>
                                                        <Typography variant='h6'>
                                                            Bill or Financial Statement
                                                        </Typography>

                                                        <Divider/>
                                                    </Grid>

                                                    {
                                                        this.state.documentsHomeAddressBlock.length === 0
                                                            ? <Grid item lg={12} xs={12}>
                                                                <Typography>
                                                                    No Documents
                                                                </Typography>
                                                            </Grid>
                                                            : <TableContainer>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            {/*<TableCell>Document Description</TableCell>*/}
                                                                            <TableCell>
                                                                                Document Name
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Date Uploaded
                                                                            </TableCell>
                                                                            <TableCell width={257}/> </TableRow>
                                                                    </TableHead>
                                                                    {this.state.documentsHomeAddressBlock.map(document => {
                                                                        return (
                                                                            <TableRow>
                                                                                {/*<TableCell>{document.chosenValue}</TableCell>*/}
                                                                                <TableCell>
                                                                                    {document.documentOriginalName}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {document.dateCreated}
                                                                                </TableCell>
                                                                                <TableCell align={"right"}>
                                                                                    <div>
                                                                                        <Button
                                                                                            onClick={(e) => this.onFilePreview(e, document)}
                                                                                            variant='contained'
                                                                                            color='primary'
                                                                                            size='small'
                                                                                            startIcon={
                                                                                                <VisibilityIcon/>}>
                                                                                            Preview
                                                                                        </Button>

                                                                                        <Button
                                                                                            onClick={() => window.open(process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/'
                                                                                                + document.chosenValue + '?access_token=' + localStorage.getItem(AUTH_TOKEN)
                                                                                                + '&embedded=false')}
                                                                                            type={"submit"}
                                                                                            variant='contained'
                                                                                            color='primary'
                                                                                            size='small'
                                                                                            style={{marginLeft: 6}}
                                                                                            startIcon={<SaveIcon/>}>
                                                                                            Download
                                                                                        </Button>
                                                                                    </div>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                </Table>
                                                            </TableContainer>
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Grid item lg={12} xs={12}>
                                                <Card>
                                                    <CardContent>
                                                        <Grid container spacing={3}>
                                                            <Grid item lg={9} xs={9}>
                                                                <Typography variant='h6'>
                                                                    Home Address Information:
                                                                </Typography>

                                                                <Divider/>
                                                            </Grid>
                                                            <Grid item
                                                                  lg={3}
                                                                  xs={3}
                                                                  style={{textAlign: 'center'}}>

                                                                {
                                                                    this.state.dataHomeAddressBlock
                                                                    && this.state.expirationDateHomeAddressChecked
                                                                    && this.state.verificationDateHomeAddressChecked
                                                                        ? <Box>
                                                                            <BeenhereIcon
                                                                                style={{
                                                                                    fontSize: 40,
                                                                                    color: '#4CAF50',
                                                                                    width: '100%'
                                                                                }}/>
                                                                            <Typography
                                                                                variant='caption'>
                                                                                Verified
                                                                            </Typography>
                                                                        </Box>
                                                                        : this.state.documentsHomeAddressBlock.length === 0
                                                                        ? <Box>
                                                                            <WarningIcon
                                                                                style={{
                                                                                    fontSize: 40,
                                                                                    color: "red",
                                                                                    width: '100%'
                                                                                }}/>
                                                                            <Typography variant='caption'>
                                                                                Not Verified (no docs.)
                                                                            </Typography>
                                                                        </Box>
                                                                        : <Box>
                                                                            <FindInPageIcon
                                                                                style={{
                                                                                    fontSize: 40,
                                                                                    color: "red",
                                                                                    width: '100%'
                                                                                }}/>
                                                                            <Typography variant='caption'>
                                                                                Not Verified (check docs.)
                                                                            </Typography>
                                                                        </Box>
                                                                }

                                                            </Grid>
                                                            <Grid item lg={6} xs={6}>
                                                                <Typography>
                                                                    Street Address *
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item lg={6} xs={6}>
                                                                <Text
                                                                    defaultValue={this.props.investorData['STREET_ADDRESS_ID']}
                                                                    id={"streetAddress"}
                                                                    attributeCode="STREET_ADDRESS_ID"
                                                                    onBlurHandler={this.props.pushInvestorChange}
                                                                    flagDisabled={this.state.freezeInputs}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={6} xs={6}>
                                                                <Typography>
                                                                    City *
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item lg={6} xs={6}>
                                                                <Text
                                                                    defaultValue={this.props.investorData['CITY_ID']}
                                                                    id={"city"}
                                                                    attributeCode="CITY_ID"
                                                                    onBlurHandler={this.props.pushInvestorChange}
                                                                    flagDisabled={this.state.freezeInputs}/>
                                                            </Grid>
                                                            <Grid item lg={6} xs={6}>
                                                                <Typography>
                                                                    State (US only)
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item lg={6} xs={6}>
                                                                <SelectWithCheck
                                                                    id={"stateSelect"}
                                                                    dataLiveSearch="true"
                                                                    dataStyle="form-control input-lg"
                                                                    dataWidth="100%"
                                                                    attributeCode="STATE_PROVINCE_ID"
                                                                    selectedValue={this.props.investorData['STATE_PROVINCE_ID']}
                                                                    onChange={this.props.pushInvestorChange}
                                                                    options={
                                                                        this.props.investorData['COUNTRY_ID']
                                                                        && this.props.investorData['COUNTRY_ID'].chosenValue === 'United States'
                                                                            ? this.state['STATE_PROVINCE_ID']
                                                                            : []
                                                                    }
                                                                    flagDisabled={this.state.freezeInputs}/>
                                                            </Grid>

                                                            <Grid item lg={6} xs={6}>
                                                                <Typography>
                                                                    Postal Code *
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item lg={6} xs={6}>
                                                                <Text
                                                                    defaultValue={this.props.investorData['POSTAL_CODE_ID']}
                                                                    id={"postalCode"}
                                                                    attributeCode="POSTAL_CODE_ID"
                                                                    onBlurHandler={this.props.pushInvestorChange}
                                                                    className="form-control input-lg"
                                                                    flagDisabled={this.state.freezeInputs}/>
                                                            </Grid>

                                                            <Grid item lg={6} xs={6}>
                                                                <Typography>
                                                                    Country *
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item lg={6} xs={6}>
                                                                <SelectWithCheck
                                                                    className="form-control input-lg"
                                                                    id={"countrySelect"}
                                                                    dataLiveSearch="true"
                                                                    dataStyle="form-control input-lg"
                                                                    dataWidth="100%"
                                                                    attributeCode="COUNTRY_ID"
                                                                    selectedValue={this.props.investorData['COUNTRY_ID']}
                                                                    onChange={this.props.pushInvestorChange}
                                                                    options={this.state['COUNTRY_ID']}
                                                                    flagDisabled={true}/>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            <Grid item lg={12} xs={12}>
                                                <FormControl component="fieldset">
                                                    <FormGroup style={{display: 'flex'}}>
                                                        <FormControlLabel
                                                            label="Documents are verified and not older than 90 days. (Address is verified)"
                                                            control={
                                                                <Checkbox
                                                                    disabled={this.state.documentsHomeAddressBlock.length === 0}
                                                                    checked={this.state.expirationDateHomeAddressChecked}
                                                                    onChange={(e) => this.handleVerifiedBoxOnChange(e, 'BLOCK_HOME_ADDRESS')}
                                                                    name="documentVerified"/>
                                                            }
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item lg={6} xs={6}>
                                                <Typography>
                                                    User that Verified:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>
                                                    {
                                                        this.state.userVerifiedHomeAddressBlock !== ""
                                                        && this.state.documentsHomeAddressBlock.length !== 0
                                                        && this.state.dataHomeAddressBlock
                                                        && this.state.expirationDateHomeAddressChecked
                                                            ? this.state.userVerifiedHomeAddressBlock
                                                            : 'N/A'
                                                    }
                                                </Typography>
                                            </Grid>

                                            <Grid item lg={6} xs={6}>
                                                <Typography>
                                                    Verification Date:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <Typography>
                                                    {
                                                        this.state.verificationDateHomeAddressBlock !== ""
                                                        && this.state.documentsHomeAddressBlock.length !== 0
                                                        && this.state.dataHomeAddressBlock
                                                        && this.state.expirationDateHomeAddressChecked
                                                            ? this.state.verificationDateHomeAddressBlock
                                                            : 'N/A'
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item lg={6} xs={12}>
                                <Card raised>
                                    <CardContent>
                                        <Grid container spacing={3}>
                                            <Grid item lg={9} xs={9}>
                                                <Typography variant='h6'>
                                                    KYC/AML verification
                                                </Typography>

                                                <Divider/>
                                            </Grid>

                                            <Grid item lg={3} xs={3}
                                                  style={{textAlign: 'center'}}>
                                                {this.state.dataKycAmlBlock
                                                    ? <Box>
                                                        <BeenhereIcon
                                                            style={{
                                                                fontSize: 40,
                                                                color: '#4CAF50',
                                                                width: '100%'
                                                            }}/>
                                                        <Typography
                                                            variant='caption'>
                                                            Verified
                                                        </Typography>
                                                    </Box>
                                                    // : this.state.documents.length === 0
                                                    // ? <Box>
                                                    //     <WarningIcon
                                                    //         style={{
                                                    //             fontSize: 40,
                                                    //             color: "red",
                                                    //             width: '100%'
                                                    //         }}/>
                                                    //     <Typography variant='caption'>
                                                    //         Not Verified (no docs.)
                                                    //     </Typography>
                                                    // </Box>
                                                    : <Box>
                                                        <FindInPageIcon
                                                            style={{
                                                                fontSize: 40,
                                                                color: "red",
                                                                width: '100%'
                                                            }}/>
                                                        <Typography variant='caption'>
                                                            Not Verified (check docs.)
                                                        </Typography>
                                                    </Box>
                                                }
                                            </Grid>

                                            <Grid item lg={12} xs={12}>
                                                <UserDocumentation investorId={this.props.investorId}
                                                                   investor={this.props.investor}
                                                                   attributeCode={'KYC_AML'}
                                                                   onVerifyBlock={this.handleVerifyBlock}
                                                                   verifyButton={true}
                                                                   onUnVerifyDocuments={this.onUnVerifyDocuments}/>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                }
            </>
        )
    }
}

export default Documentation;