import React from 'react';
import Aux from '../../hoc/AuxWrapper';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const checkbox = (props) => {
    let checkboxOptionId;
    let checkboxOptionValue;
    const onChangeHandler = (checked) => {
        if (!checked) {
            props.attributes.forEach(attr => {
                props.deleteInvestorData({attributeCode: attr.code})
            });
            checkboxOptionValue = "false";
            checkboxOptionId = props.options.find(o => o.value === checkboxOptionValue).id;
        } else {
            props.attributes.forEach(attr => {
                var request;
                if (attr.data.attributeType === 'TEXT_INPUT') {
                    request = {
                        attributeCode: attr.code,
                        value: attr.data.chosenValue
                    };
                } else {
                    const option = attr.chosenData.find(option => option.description === attr.data.chosenValue);
                    if (option) {
                        request = {
                            attributeCode: attr.code,
                            investorAttributeOptionId: option.id,
                            value: option.description
                        };
                    }
                }


                if (request)
                    props.pushInvestorChange(request);

            });

            checkboxOptionValue = "true";
            checkboxOptionId = props.options.find(o => o.value === checkboxOptionValue).id;
        }

        props.pushInvestorChange({
            attributeCode: props.attributeCode,
            value: checkboxOptionValue,
            investorAttributeOptionId: checkboxOptionId
        });

    };


    return (
        <Aux>
            <FormControlLabel
                control={<Checkbox
                    disabled={props.disabled ? props.disabled : false}
                    color="primary"
                    id={props.attributeCode}
                    className="same-mailing-address"
                    onChange={(e) => onChangeHandler(e.target.checked)}
                    checked={props.defaultValue ? props.defaultValue.chosenValue === 'true' : false}
                    type="checkbox"/>}
                label={props.label}
            />
        </Aux>
    );
};

export default checkbox;