import axios from '../custom-axios/backoffice';

const RegTypeReportService = {
    getByIssuerId: (issuerId) => {
        return axios.get("/rest/regtype_report/issuer/" +issuerId);
    },
    getByDealId: (dealId) => {
        return axios.get("/rest/regtype_report/deal/"+dealId);
    }
}

export default RegTypeReportService;