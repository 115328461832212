import React, {Component} from 'react';
//import Date from '../../components/input/Date';
import Aux from '../../../hoc/AuxWrapper'
import RadioInput from "../../../components/input/RadioInput";
import PdfPreviewModal from "../../modal/PdfPreviewModal";
import ImagePreviewModal from "../../modal/ImagePreviewModal";
import InvestorService from "../../../services/InvestorService";
import AccreditationSaveModal1 from "../../modal/AccreditationSaveModal1";
import AccreditationDeleteModal from "../../modal/AccreditationDeleteModal";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Button, Table} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Box from "@material-ui/core/Box";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import WarningIcon from "@material-ui/icons/Warning";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import VisibilityIcon from "@material-ui/icons/Visibility";
import styled from "styled-components";
import DoneIcon from '@material-ui/icons/Done';
import CheckIcon from "@material-ui/icons/Check";


const IconSuccess = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const IconOrr = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #F3F3F3;
  border-style: solid;
  border-color: #032F66;
  border-width: 2px;
`;

class AccreditationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MEET_EITHER_OF_THESE: [],
            SPOUSE_TOG_MEET: [],
            UNSURE_INDIVIDUAL_JOINT: [],
            nonMandatoryFields: ['SPOUSE_TOG_MEET', 'LETTER_ACCRD', 'UNSURE_INDIVIDUAL_JOINT'],
            showPreview: false,
            showPdfPreview: false,
            dataHomeAddressBlock: true,
            standard: '',
            showing1: false,
            showing2: false,
            showingDelete: false,
            userThatVerified: '',
            verificationDate: '',
            expirationDate: '',
            verifyCitizenship: false,
            verifyCountry: false,
            verifyExpirationDate: false,
            showingPassport1: false,
            showingPassport2: false,
            investorId: -1,
            step: null
        };
    }

    componentDidMount() {
        this.init();

        const step = this.props.steps.filter(step => step.step.id === 'STEP_ACCREDITATION')[0]

        this.setState({
            step: step
        })
        // this.fetchDocumentsHomeAddress();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.investorId !== this.state.investorId && this.props.investorId !== undefined) {
            this.setState({
                investorId: prevProps.investorId
            })
            this.fetchActivationBlock(prevProps.investorId);
            this.fetchAccreditationBlock(prevProps.investorId);
        }
    }


    fetchActivationBlock = (investorId) => {
        this.checkStandard();
        InvestorService.findActivationBlock(investorId).then(response => {
            let dataHomeAddressBlock = false;
            if (response.data.expirationDate === "1" && response.data.verificationDate !== "") {
                dataHomeAddressBlock = true
            }
            this.setState({
                dataHomeAddressBlock: dataHomeAddressBlock
            })
        });
    };

    verifyDate = (e) => {
        let date = new Date(e);
        let dateUtc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        dateUtc.setHours(0, 0, 0, 0);
        let now = new Date();
        let nowUtc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        nowUtc.setHours(0, 0, 0, 0);
        return dateUtc >= nowUtc;
    };

    onVerify = (data) => {
        let verifyCitizenship, verifyCountry, verifyDate = false;
        if (data.citizenship1 === data.citizenship2) {
            verifyCitizenship = true;
        }
        if (data.homeAddress1 === data.homeAddress2) {
            verifyCountry = true;
        }
        verifyDate = this.verifyDate(data.expirationDate);
        if (verifyDate === false) {
            this.deleteAccreditationBlock();
        }
        this.setState({
            verifyCitizenship: verifyCitizenship,
            verifyCountry: verifyCountry,
            verifyExpirationDate: verifyDate
        });
    };

    fetchAccreditationBlock = (investorId) => {
        InvestorService.findAccreditationBlock(investorId).then(response => {
            if (response.data) {
                this.onVerify(response.data);
                this.setState({
                    userThatVerified: response.data.userThatVerified,
                    verificationDate: response.data.verificationDate,
                    expirationDate: response.data.expirationDate
                })
            } else {
                this.setState({
                    userThatVerified: "",
                    verificationDate: "",
                    expirationDate: ""
                })
            }
        });
    };

    init() {
        this.props.fetchAttributeOptions('MEET_EITHER_OF_THESE').then((data) => {
            this.setState({
                ...this.state,
                'MEET_EITHER_OF_THESE': data.data
            });
        });

        this.props.fetchAttributeOptions('SPOUSE_TOG_MEET').then((data) => {
            this.setState({
                ...this.state,
                'SPOUSE_TOG_MEET': data.data
            });
        });

        this.props.fetchAttributeOptions('UNSURE_INDIVIDUAL_JOINT').then((data) => {
            this.setState({
                ...this.state,
                'UNSURE_INDIVIDUAL_JOINT': data.data
            });
        });
    }


    onFilePreview = (e, item) => {
        e.stopPropagation();

        let fileToPreview = item.chosenValue.split('.');

        if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
            this.setState({
                fileToPreview: item.chosenValue,
                showPdfPreview: true
            });
        } else {
            this.setState({
                fileToPreview: item.chosenValue,
                showPreview: true
            });
        }
    };

    handleClose = (e) => {
        this.setState({
            showPreview: false
        })
    };

    handlePdfClose = (e) => {
        this.setState({
            showPdfPreview: false
        })
    };

    checkStandard = () => {
        let standard = '';
        if (this.props.investorData['COUNTRY_ID'] && this.props.investorData['COUNTRY_ID'].chosenValue && this.props.investorData['COUNTRY_CITIZEN'] && this.props.investorData['COUNTRY_CITIZEN'].chosenValue) {
            if (this.props.investorData['COUNTRY_CITIZEN'].chosenValue === 'United States' || this.props.investorData['COUNTRY_ID'].chosenValue === 'United States') {
                standard = 'United States';
            } else {
                standard = this.props.investorData['COUNTRY_ID'].chosenValue;
            }
            this.setState({
                standard: standard
            })
        } else {
            this.setState({
                standard: ''
            })
        }
    };

    showModal = (e) => {
        e.preventDefault();
        this.setState({
            showing1: true
        })
    };

    closeModal = (e) => {
        this.setState({
            showing1: false
        })
    };


    deleteAccreditationBlock = () => {
        InvestorService.deleteAccreditationBlock(this.props.investorId).then(response => {
            this.setState({
                userThatVerified: "",
                verificationDate: "",
                expirationDate: ""
            })

        })
    };

    openDeleteModal = (e) => {
        e.preventDefault();
        this.setState({
            showingDelete: true
        })
    };

    closeDeleteModal = (e) => {
        this.setState({
            showingDelete: false
        })
    };

    createVerificationDate() {
        let now = new Date();
        let options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timezone: 'UTC',
            hour: 'numeric',
            minute: 'numeric',
        };
        return now.toLocaleDateString("en-US", options);
    }

    saveChanges = (date, standard) => {
        let verificationDate = this.createVerificationDate();
        let userName = this.props.userThatVerified.firstName + " " + this.props.userThatVerified.lastName;

        let dataForBlock = {
            userName: userName,
            date: verificationDate,
            expirationDate: date,
            standard: standard,
            homeAddress: this.props.investorData['COUNTRY_ID'].chosenValue,
            citizenship: this.props.investorData['COUNTRY_CITIZEN'].chosenValue,
            regType: this.props.allowedRegType
        };

        this.setState({
            userThatVerified: userName,
            verificationDate: verificationDate,
            expirationDate: date,
        });
        InvestorService.createAccreditationBlock(dataForBlock, this.props.investorId).then(() => {
            this.fetchAccreditationBlock(this.props.investorId);
        });
    };

    render() {
        return (
            <>
                <PdfPreviewModal
                    className="modal"
                    show={this.state.showPdfPreview}
                    file={this.state.fileToPreview}
                    close={this.handlePdfClose}/>
                <ImagePreviewModal
                    className="modal"
                    show={this.state.showPreview}
                    file={this.state.fileToPreview}
                    close={this.handleClose}
                />
                <AccreditationSaveModal1
                    show={this.state.showing1}
                    close={this.closeModal}
                    standard={this.state.standard}
                    submit={this.saveChanges}
                    verifyDate={this.verifyDate}
                />
                <AccreditationDeleteModal
                    show={this.state.showingDelete}
                    close={this.closeDeleteModal}
                    submit={this.deleteAccreditationBlock}
                />

                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} xs={12}
                                  style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    variant='contained'
                                    color={"primary"}
                                    // style={{
                                    //     margin: 15
                                    // }}
                                    startIcon={<ClearIcon/>}
                                    disabled={this.state.step?.status !== 'APPROVED'}
                                    onClick={(e) => this.props.rejectStep('STEP_ACCREDITATION')}>
                                    Reject
                                </Button>

                                <Button
                                    variant='contained'
                                    color='secondary'
                                    style={{marginLeft: 15}}
                                    startIcon={<CheckIcon/>}
                                    disabled={this.state.step?.status === 'APPROVED'}
                                    onClick={() => this.props.approveStep('STEP_ACCREDITATION')}>
                                    {
                                        this.state.step?.status === 'APPROVED'
                                            ? 'Approved'
                                            : 'Approve'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item lg={9} xs={9}>
                                        <Typography variant='h6'>
                                            Accreditation information submitted by user matches
                                            uploaded accreditation documents:
                                        </Typography>

                                        <Divider/>

                                        {this.state.dataPassportBlock && this.state.expirationDatePassportChecked && this.state.verificationDatePassportChecked ?
                                            <Button variant='contained'
                                                    style={{marginTop: 8}}
                                                    color='primary'
                                                    size='small'
                                                    startIcon={
                                                        <ClearIcon/>}
                                                    onClick={(e) => this.handleOnUnVerify(e, "passport")}>Cancel
                                                Verification
                                            </Button>
                                            : null}
                                    </Grid>
                                    <Grid item
                                          lg={3}
                                          xs={3}
                                          style={{textAlign: 'center'}}>

                                        {this.state.userThatVerified !== '' && this.state.verificationDate !== ''
                                        && this.state.verifyCitizenship && this.state.verifyCountry && this.state.verifyExpirationDate && this.state.dataHomeAddressBlock ?
                                            <Box>
                                                <BeenhereIcon
                                                    style={{
                                                        fontSize: 40,
                                                        color: '#4CAF50',
                                                        width: '100%'
                                                    }}/>
                                                <Typography variant='caption'>Verify</Typography>
                                            </Box>

                                            : !this.props.accreditDocuments ?
                                                <Box>
                                                    <WarningIcon
                                                        style={{
                                                            fontSize: 40,
                                                            color: "red",
                                                            width: '100%'
                                                        }}/>
                                                    <Typography variant='caption'>Not Verified (no
                                                        docs.)</Typography>
                                                </Box>
                                                :
                                                <Box>
                                                    <FindInPageIcon
                                                        style={{
                                                            fontSize: 40,
                                                            color: "red",
                                                            width: '100%'
                                                        }}/>
                                                    <Typography variant='caption'>
                                                        Not Verified (check data)
                                                    </Typography>
                                                </Box>
                                        }
                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <Typography>
                                            Accreditation Expiration Date
                                        </Typography>

                                        <Button
                                            variant='contained'
                                            size='small'
                                            disabled={!(this.state.dataHomeAddressBlock && this.props.accreditDocuments)}
                                            startIcon={
                                                <PlaylistAddCheckIcon/>}
                                            // disabled={!this.state.dataHomeAddressBlock}
                                            onClick={(e) => this.showModal(e)}>Set
                                        </Button>

                                        {this.state.expirationDate ?
                                            <Button
                                                variant='contained'
                                                style={{marginLeft: 5}}
                                                size='small'
                                                startIcon={
                                                    <ClearIcon/>}
                                                onClick={(e) => this.openDeleteModal(e)}>Void
                                            </Button> : null}
                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <TextField
                                            fullWidth
                                            InputProps={{readOnly: true}}
                                            variant="outlined"
                                            value={this.state.expirationDate}/>
                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <Typography>
                                            Country of Citizenship
                                        </Typography>
                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <Typography>
                                            {this.props.investorData['COUNTRY_CITIZEN'] && this.props.investorData['COUNTRY_CITIZEN'].chosenValue ?
                                                this.props.investorData['COUNTRY_CITIZEN'].chosenValue : ''}
                                        </Typography>
                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <Typography>
                                            Home Address Country
                                        </Typography>

                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <Typography>
                                            {this.props.investorData['COUNTRY_ID'] && this.props.investorData['COUNTRY_ID'].chosenValue ? this.props.investorData['COUNTRY_ID'].chosenValue : ''}</Typography>
                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <Typography>
                                            Relevant Accreditation Standard
                                        </Typography>
                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <Typography>
                                            {this.state.standard}
                                        </Typography>
                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <Typography>
                                            Verification of Home Address Documents
                                        </Typography>
                                    </Grid>

                                    <Grid item lg={6} xs={6}>
                                        <Typography>
                                            {this.state.dataHomeAddressBlock ? "Yes" : "No"}
                                        </Typography>
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <Card>
                                            <CardContent>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={12} xs={12}>
                                                        <Typography variant='h6'>
                                                            Uploaded Accreditation Documents
                                                        </Typography>

                                                        <Divider/>
                                                    </Grid>

                                                    {this.props.documents.length === 0 ? (
                                                        <Grid item lg={12} xs={12}>
                                                            <Typography>
                                                                No Documents
                                                            </Typography>
                                                        </Grid>
                                                    ) : (
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Document Name</TableCell>
                                                                        <TableCell>Date Uploaded</TableCell>
                                                                    </TableRow>
                                                                </TableHead>

                                                                {this.props.documents.map(document => {
                                                                    if (document.attributeCode === "LETTER_ACCRD") {
                                                                        return (
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    {document.documentOriginalName}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {document.dateCreated}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Button
                                                                                        onClick={(e) => this.onFilePreview(e, document)}
                                                                                        variant='contained'
                                                                                        color='primary'
                                                                                        size='small'
                                                                                        startIcon={
                                                                                            <VisibilityIcon/>
                                                                                        }>
                                                                                        Preview
                                                                                    </Button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }

                                                                    return null
                                                                })}
                                                            </Table>
                                                        </TableContainer>
                                                    )}
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <Typography>User that Verified:</Typography>
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <Typography>   {this.state.userThatVerified !== '' && this.state.verificationDate !== ''
                                        && this.state.verifyCitizenship && this.state.verifyCountry && this.state.verifyExpirationDate && this.state.dataHomeAddressBlock ?
                                            this.state.userThatVerified :
                                            'N/A'}</Typography>
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <Typography>Verification
                                            Date:</Typography>
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <Typography>{this.state.userThatVerified !== '' && this.state.verificationDate !== ''
                                        && this.state.verifyCitizenship && this.state.verifyCountry && this.state.verifyExpirationDate && this.state.dataHomeAddressBlock ?
                                            this.state.verificationDate : 'N/A'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Card>
                            <CardContent>
                                <form>
                                    <Box>
                                        <Box pb={3.5}>
                                            <Typography variant="h6">
                                                To be accredited as an Individual, you must satisfy
                                                one of these two conditions:
                                            </Typography>
                                            <Divider/>
                                        </Box>

                                        <Box>
                                            <Grid container>
                                                <Grid item lg={5}>
                                                    <IconSuccess>
                                                        <DoneIcon style={{fontSize: '55px', color: '#4CAF50'}}/>
                                                    </IconSuccess>

                                                    <Typography variant="subtitle2">
                                                        My personal Liquid Net Worth (excluding the value of my primary
                                                        residence)
                                                        is over $1,000,000 USD.
                                                    </Typography>
                                                </Grid>

                                                <Grid item lg={2}>
                                                    <Box display="flex" justifyContent="center">
                                                        <IconOrr>
                                                            <Typography variant="subtitle2">OR</Typography>
                                                        </IconOrr>
                                                    </Box>
                                                </Grid>

                                                <Grid item lg={5}>
                                                    <IconSuccess>
                                                        <DoneIcon style={{fontSize: '55px', color: '#4CAF50'}}/>
                                                    </IconSuccess>

                                                    <Typography variant="subtitle2">
                                                        My income was in excess of $200,000 USD in each of the the past
                                                        2
                                                        calendar years and I reasonably expect to reach the same income
                                                        level
                                                        in the current year.
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </Box>

                                        <Box mt={4} mb={1}>
                                            <Typography variant="subtitle2">Do you meet either of these conditions?
                                                <span className="required__circle"> *</span>
                                            </Typography>
                                        </Box>

                                        <RadioInput
                                            name="MEET_EITHER_OF_THESE"
                                            attributeCode="MEET_EITHER_OF_THESE"
                                            defaultValue={this.props.investorData['MEET_EITHER_OF_THESE']}
                                            options={this.state['MEET_EITHER_OF_THESE']}
                                            deleteInvestorData={this.props.deleteInvestorData}
                                            onChange={this.props.pushInvestorChange}
                                            disabled={true}/>

                                        <Box py={5}>
                                            <Divider/>
                                        </Box>
                                    </Box>

                                    {(this.props.investorData['MEET_EITHER_OF_THESE'] && this.props.investorData['MEET_EITHER_OF_THESE'].chosenValue === 'no') ?
                                        <Box>
                                            <Box pb={3.5}>
                                                <Typography variant="h6">
                                                    To be accredited as a Joint investor with your Spouse, you and your
                                                    Spouse together must satisfy one of these two conditions:
                                                </Typography>
                                                <Divider/>
                                            </Box>

                                            <Box>
                                                <Grid container>
                                                    <Grid item lg={4}>

                                                        <IconSuccess>
                                                            <DoneIcon style={{fontSize: '55px', color: '#4CAF50'}}/>
                                                        </IconSuccess>

                                                        <Typography variant="subtitle2">
                                                            My Joint Liquid Net Worth with my Spouse
                                                            (excluding the value of our primary residence) is over
                                                            $1,000,000 USD.
                                                        </Typography>

                                                    </Grid>

                                                    <Grid item lg={2}>
                                                        <Box display="flex" justifyContent="center">
                                                            <IconOrr>
                                                                <Typography variant="subtitle2">OR</Typography>
                                                            </IconOrr>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item lg={4}>
                                                        <IconSuccess>
                                                            <DoneIcon style={{fontSize: '55px', color: '#4CAF50'}}/>
                                                        </IconSuccess>

                                                        <Typography variant="subtitle2">
                                                            My Joint Income with my Spouse was in excess of $300,000
                                                            USD in each of the past 2 calendar years and we reasonably
                                                            expect
                                                            to reach the same income level in the current year
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Box>


                                            <Box mt={4} mb={1}>
                                                <Typography variant="subtitle2">
                                                    Do you and your Spouse together meet either of these conditions?
                                                    (answer is mandatory only if you answered "No" on the previous
                                                    question)
                                                    <span className="required__circle"> *</span>
                                                </Typography>
                                            </Box>

                                            <RadioInput
                                                name="SPOUSE_TOG_MEET"
                                                attributeCode="SPOUSE_TOG_MEET"
                                                defaultValue={this.props.investorData['SPOUSE_TOG_MEET']}
                                                options={this.state['SPOUSE_TOG_MEET']}
                                                deleteInvestorData={this.props.deleteInvestorData}
                                                onChange={this.props.pushInvestorChange}
                                                disabled={true}/>

                                            <Box py={5}>
                                                <Divider/>
                                            </Box>
                                        </Box>
                                        : null}

                                    {(this.props.investorData['MEET_EITHER_OF_THESE'] && this.props.investorData['MEET_EITHER_OF_THESE'].chosenValue === 'no') &&
                                    (this.props.investorData['SPOUSE_TOG_MEET'] && this.props.investorData['SPOUSE_TOG_MEET'].chosenValue === 'no') ?
                                        <Aux>
                                            <Box>
                                                <Box mb={1}>
                                                    <Typography variant="subtitle2">
                                                        Are you unsure of your Individual or Joint accreditation
                                                        status and would prefer someone on our team
                                                        reach out to you?
                                                    </Typography>
                                                </Box>

                                                <RadioInput
                                                    name="UNSURE_INDIVIDUAL_JOINT"
                                                    attributeCode="UNSURE_INDIVIDUAL_JOINT"
                                                    defaultValue={this.props.investorData['UNSURE_INDIVIDUAL_JOINT']}
                                                    options={this.state['UNSURE_INDIVIDUAL_JOINT']}
                                                    onChange={this.props.pushInvestorChange}
                                                    disabled={true}/>
                                            </Box>

                                            {this.props.investorData['UNSURE_INDIVIDUAL_JOINT'] && this.props.investorData['UNSURE_INDIVIDUAL_JOINT'].chosenValue && this.props.investorData['UNSURE_INDIVIDUAL_JOINT'].chosenValue === "yes" ?

                                                <Box mt={4}>
                                                    <Typography variant="subtitle2">
                                                        Our Accreditation Team will reach out to you within the next 3
                                                        business days.
                                                    </Typography>
                                                </Box>

                                                : null}

                                            <Box py={5}>
                                                <Divider/>
                                            </Box>

                                        </Aux> : null}

                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


                {/*<div>*/}
                {/*    /!*<div>*!/*/}
                {/*    /!*    {this.state.userThatVerified !== '' && this.state.verificationDate !== '' &&*!/*/}
                {/*    /!*    this.state.verifyCitizenship && this.state.verifyCountry && this.state.verifyExpirationDate ?*!/*/}
                {/*    /!*        <label style={{fontSize: "22px"}}><u>ACCREDITED</u></label> :*!/*/}
                {/*    /!*        <label style={{fontSize: "22px"}}><u>NOT ACCREDITED YET</u></label>}*!/*/}
                {/*    /!*</div>*!/*/}
                {/*    <div className="row">*/}
                {/*        <div>*/}
                {/*            <div className="card padding-bottom-40">*/}

                {/*                <form className="form-horizontal">*/}
                {/*                    <div style={{float: "right"}}>*/}
                {/*                        /!*{this.props.allowedRegType?this.props.allowedRegType:null}*!/*/}
                {/*                        {this.state.userThatVerified !== '' && this.state.verificationDate !== ''*/}
                {/*                        && this.state.verifyCitizenship && this.state.verifyCountry && this.state.verifyExpirationDate && this.state.dataHomeAddressBlock ?*/}
                {/*                            <label style={{color: "green"}}>ACCREDITED</label> :*/}
                {/*                            <label style={{color: "red"}}>NOT ACCREDITED</label>}*/}
                {/*                        {this.state.userThatVerified !== '' && this.state.verificationDate !== ''*/}
                {/*                        && this.state.verifyCitizenship && this.state.verifyCountry && this.state.verifyExpirationDate && this.state.dataHomeAddressBlock ?*/}
                {/*                            <div>*/}
                {/*                                <div style={{display: "flex"}}>*/}
                {/*                                    <text>User that Verified:</text>*/}
                {/*                                    <text style={{*/}
                {/*                                        color: "green",*/}
                {/*                                        paddingLeft: 5*/}
                {/*                                    }}>{this.state.userThatVerified}</text>*/}
                {/*                                </div>*/}
                {/*                                <div style={{display: "flex"}}>*/}
                {/*                                    <text>Verification Date:</text>*/}
                {/*                                    <text style={{*/}
                {/*                                        color: "green",*/}
                {/*                                        paddingLeft: 10*/}
                {/*                                    }}>{this.state.verificationDate}</text>*/}
                {/*                                </div>*/}
                {/*                            </div> : null}*/}
                {/*                    </div>*/}
                {/*                    <div className="inline-block">*/}
                {/*                        <div className="form-group">*/}
                {/*                            <label*/}
                {/*                                className="col-xs-12 col-sm-4 control-label width-35 control-label-lg">Accreditation*/}
                {/*                                Expiration Date</label>*/}
                {/*                            <div className="col-sm-16">*/}
                {/*                                <input*/}
                {/*                                    className="form-control input-ln"*/}
                {/*                                    readOnly={true}*/}
                {/*                                    defaultValue={this.state.expirationDate}/>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group">*/}
                {/*                            <label*/}
                {/*                                className="col-xs-12 col-sm-4 control-label width-35 control-label-lg">Country*/}
                {/*                                of Citizenship</label>*/}
                {/*                            <div className="col-sm-15">*/}
                {/*                                <text>{this.props.investorData['COUNTRY_CITIZEN'] && this.props.investorData['COUNTRY_CITIZEN'].chosenValue ? this.props.investorData['COUNTRY_CITIZEN'].chosenValue : ''}</text>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group">*/}
                {/*                            <label*/}
                {/*                                className="col-xs-12 col-sm-4 control-label width-35 control-label-lg">Home*/}
                {/*                                Address Country</label>*/}
                {/*                            <div className="col-sm-15">*/}
                {/*                                <text>{this.props.investorData['COUNTRY_ID'] && this.props.investorData['COUNTRY_ID'].chosenValue ? this.props.investorData['COUNTRY_ID'].chosenValue : ''}</text>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group">*/}
                {/*                            <label*/}
                {/*                                className="col-xs-12 col-sm-4 control-label width-35 control-label-lg">Relevant*/}
                {/*                                Accreditation Standard</label>*/}
                {/*                            <div className="col-sm-15">*/}
                {/*                                <label><u>{this.state.standard}</u></label>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className="form-group">*/}
                {/*                            <label*/}
                {/*                                className="col-xs-12 col-sm-4 control-label width-35 control-label-lg">*/}
                {/*                                Verification of Home Address Documents</label>*/}
                {/*                            <div className="col-sm-15">*/}
                {/*                                <text> {this.state.dataHomeAddressBlock ? "Yes" : "No"}</text>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="form-group">*/}
                {/*                        <button className={"btn btn-orange btn-lg btn-pagination"}*/}
                {/*                                disabled={!(this.state.dataHomeAddressBlock && this.props.accreditDocuments)}*/}
                {/*                                onClick={this.showModal}>Update Accreditation*/}
                {/*                        </button>*/}
                {/*                        {this.state.userThatVerified !== '' && this.state.verificationDate !== '' ?*/}
                {/*                            <button className={"btn btn-orange btn-lg btn-pagination"}*/}
                {/*                                    onClick={this.openDeleteModal}>Void Accreditation*/}
                {/*                            </button> : null}*/}
                {/*                    </div>*/}

                {/*                    <div>*/}
                {/*                        <h4 style={{padding: 5, textAlign: "center"}}>Accreditation documents:</h4>*/}
                {/*                    </div>*/}
                {/*                    <div className="table">*/}
                {/*                        <table className="table table-resposive">*/}
                {/*                            <thead>*/}
                {/*                            <tr>*/}
                {/*                                <th>Document Description</th>*/}
                {/*                                <th>Document Filename</th>*/}
                {/*                                <th>Date Uploaded</th>*/}
                {/*                                <th>Actions</th>*/}
                {/*                            </tr>*/}
                {/*                            </thead>*/}
                {/*                            <tbody>*/}
                {/*                            {*/}
                {/*                                this.props.documents.map(document => {*/}
                {/*                                    if (document.attributeCode === "LETTER_ACCRD") {*/}
                {/*                                        return (*/}
                {/*                                            <>*/}
                {/*                                                <tr key={document.id}>*/}
                {/*                                                    <td>{document.chosenValue}</td>*/}
                {/*                                                    <td>{document.documentOriginalName}</td>*/}
                {/*                                                    <td>{document.dateCreated}</td>*/}
                {/*                                                    <td style={{display: "contents"}}>*/}
                {/*                                                        <button*/}
                {/*                                                            className={"btn btn-info basic-info-1"}*/}
                {/*                                                            type={"button"}*/}
                {/*                                                            onClick={(e) => this.onFilePreview(e, document)}>Preview*/}
                {/*                                                        </button>*/}
                {/*                                                    </td>*/}
                {/*                                                </tr>*/}
                {/*                                            </>*/}
                {/*                                        )*/}
                {/*                                    }*/}
                {/*                                })*/}
                {/*                            }*/}
                {/*                            </tbody>*/}
                {/*                        </table>*/}
                {/*                    </div>*/}
                {/*                    <hr className="margin-top-50 margin-bottom-50 dark-blue"/>*/}

                {/*                    <p className="h4 margin-bottom-50">To be accredited as an Individual, you must*/}
                {/*                        satisfy*/}
                {/*                        one of these two conditions:</p>*/}

                {/*                    <ul className="list-big-checkmarks">*/}
                {/*                        <li>My personal Liquid Net Worth (excluding the value of my primary residence)*/}
                {/*                            is*/}
                {/*                            over $1,000,000 USD.*/}
                {/*                        </li>*/}
                {/*                        <li className="or"><span>or</span></li>*/}
                {/*                        <li>My income was in excess of $200,000 USD in each of the the past 2 calendar*/}
                {/*                            years*/}
                {/*                            and I reasonably expect to reach the same income level in the current year.*/}
                {/*                        </li>*/}
                {/*                    </ul>*/}

                {/*                    <div className="form-group margin-top-60">*/}
                {/*                        <div className="col-xs-12">*/}
                {/*                            <p className="weight600">Do you meet either of these conditions? *</p>*/}
                {/*                        </div>*/}
                {/*                        <div className="col-xs-12">*/}
                {/*                            <RadioInput*/}
                {/*                                name="MEET_EITHER_OF_THESE"*/}
                {/*                                attributeCode="MEET_EITHER_OF_THESE"*/}
                {/*                                defaultValue={this.props.investorData['MEET_EITHER_OF_THESE']}*/}
                {/*                                options={this.state['MEET_EITHER_OF_THESE']}*/}
                {/*                                onChange={this.props.pushInvestorChange}*/}
                {/*                                disabled={true}/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <hr className="margin-top-50 margin-bottom-50 dark-blue"/>*/}

                {/*                    <p className="h4 margin-bottom-50">To be accredited as a Joint investor with your*/}
                {/*                        Spouse, you and your Spouse together must satisfy one of these two*/}
                {/*                        conditions:</p>*/}

                {/*                    <ul className="list-big-checkmarks">*/}
                {/*                        <li>My Joint Liquid Net Worth with mySpouse (excluding the value of our primary*/}
                {/*                            residence) is over $1,000,000 USD.*/}
                {/*                        </li>*/}
                {/*                        <li className="or"><span>or</span></li>*/}
                {/*                        <li>My Joint Income with my Spouse was in excess of $300,000 USD in each of the*/}
                {/*                            past 2 calendar years and we reasonably expect to reach the same income*/}
                {/*                            level in the current year*/}
                {/*                        </li>*/}
                {/*                    </ul>*/}

                {/*                    <div className="form-group margin-top-60">*/}
                {/*                        <div className="col-xs-12">*/}
                {/*                            <p className="weight600">Do you and your Spouse together meet either of*/}
                {/*                                these*/}
                {/*                                conditions? (answer is mandatory only if you answered "No" on the*/}
                {/*                                previous question)</p>*/}
                {/*                        </div>*/}
                {/*                        <div className="col-xs-12">*/}
                {/*                            <RadioInput*/}
                {/*                                name="SPOUSE_TOG_MEET"*/}
                {/*                                attributeCode="SPOUSE_TOG_MEET"*/}
                {/*                                defaultValue={this.props.investorData['SPOUSE_TOG_MEET']}*/}
                {/*                                options={this.state['SPOUSE_TOG_MEET']}*/}
                {/*                                onChange={this.props.pushInvestorChange}*/}
                {/*                                disabled={true}/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                    <hr className="margin-top-50 margin-bottom-50 dark-blue"/>*/}

                {/*                    {(this.props.investorData['MEET_EITHER_OF_THESE'] && this.props.investorData['MEET_EITHER_OF_THESE'].chosenValue === 'no') &&*/}
                {/*                    (this.props.investorData['SPOUSE_TOG_MEET'] && this.props.investorData['SPOUSE_TOG_MEET'].chosenValue === 'no') ?*/}
                {/*                        <Aux>*/}
                {/*                            <div className="form-group margin-top-60">*/}
                {/*                                <div className="col-xs-12">*/}
                {/*                                    <p className="weight600">Are you unsure of your Individual or Joint*/}
                {/*                                        accreditation status and would prefer someone on our team reach*/}
                {/*                                        out*/}
                {/*                                        to*/}
                {/*                                        you? *</p>*/}
                {/*                                </div>*/}
                {/*                                <div className="col-xs-12">*/}
                {/*                                    <RadioInput*/}
                {/*                                        name="UNSURE_INDIVIDUAL_JOINT"*/}
                {/*                                        attributeCode="UNSURE_INDIVIDUAL_JOINT"*/}
                {/*                                        defaultValue={this.props.investorData['UNSURE_INDIVIDUAL_JOINT']}*/}
                {/*                                        options={this.state['UNSURE_INDIVIDUAL_JOINT']}*/}
                {/*                                        onChange={this.props.pushInvestorChange}/>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}

                {/*                            <hr className="margin-top-50 margin-bottom-50 dark-blue"/>*/}
                {/*                        </Aux> : null}*/}

                {/*                    /!*{(this.props.investorData['COUNTRY_CITIZEN'] && this.props.investorData['COUNTRY_CITIZEN'].chosenValue == 'United States')*!/*/}
                {/*                    /!*|| (this.props.investorData['COUNTRY_ID'] && this.props.investorData['COUNTRY_ID'].chosenValue == 'United States') ?*!/*/}
                {/*                    /!*    <div className="form-group margin-top-40 margin-bottom-40">*!/*/}
                {/*                    /!*        <div className="col-xs-12">*!/*/}
                {/*                    /!*            <p className="weight600 margin-bottom-20">Please upload your most recent*!/*/}
                {/*                    /!*                unexpired*!/*/}
                {/*                    /!*                Letter*!/*/}
                {/*                    /!*                of Accreditation from a CPA, Attorney or Broker/Dealer if you have*!/*/}
                {/*                    /!*                one:</p>*!/*/}
                {/*                    /!*        </div>*!/*/}

                {/*                    /!*        <FileUploadComponent onFileSelected={this.props.saveDocument}*!/*/}
                {/*                    /!*                    fileNumber="file-1"*!/*/}
                {/*                    /!*                    currentValue={this.props.investorData['LETTER_ACCRD']}*!/*/}
                {/*                    /!*                    onDocumentDelete={this.props.onDocumentDelete}*!/*/}
                {/*                    /!*                    documentGroupCode="letter-of-accreditation"*!/*/}
                {/*                    /!*                    attributeCode="LETTER_ACCRD"*!/*/}
                {/*                    /!*                    wrapperClass="col-xs-12 col-sm-3 col-lg-2 text-right"/>*!/*/}
                {/*                    /!*    </div> : null}*!/*/}


                {/*                </form>*/}


                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    }
}

export default AccreditationPage;