import React, {Component} from 'react';
import Aux from '../../hoc/AuxWrapper';
import SaveModal from "../../containers/modal/SaveModal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";

class DobUsWithCheck extends Component {

    constructor(props) {
        super(props);
        this.state = {
            month: ' ',
            day: ' ',
            year: ' ',
            daysInMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
            days: [],
            initialDatesSet: false,
            error: false,
            isShowing: false,
            firstValueOfDay: 0,
            firstValueOfMonth: 0,
            firstValueOfYear: 0,
            errorDay: false,
            errorMonth: false,
            errorYear: false
        }
    }

    componentDidMount(){
        if (this.props.defaultValue && this.props.defaultValue.chosenValue) {
            this.initDob(this.props.defaultValue.chosenValue);
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.defaultValue && this.props.defaultValue.chosenValue && !prevProps.defaultValue) || (prevProps.defaultValue && prevProps.defaultValue.chosenValue && !this.state.initialDatesSet)) {
           this.initDob(this.props.defaultValue.chosenValue);
        }
    }

    initDob=(dob)=>{
        let date = new Date(dob);
        this.setState({
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
            firstValueOfYear: date.getFullYear(),
            firstValueOfMonth: date.getMonth() + 1,
            firstValueOfDay: date.getDate(),
            initialDatesSet: true
        });
        this.updateDays(date.getMonth() + 1);
    }

    styles = {
        formControl: {
            display: 'flex',
            margin: 'auto',
        },
    }


    leapYear(year) {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    }

    monthChanged(e) {
        if (this.state.month !== e.target.value) {
            this.setState({
                month: parseInt(e.target.value)
            }, () => {
                if (!this.checkError()) {
                    this.dateChanged()
                }
            })
        }
    }

    yearChanged(e) {
        this.setState({
            year: parseInt(e.target.value)
        }, () => {
            if (!this.checkError()) {
                let newDaysInMonth = [...this.state.daysInMonth];
                if (this.leapYear(this.state.year)) {
                    newDaysInMonth[1] = 29;
                    this.setState({
                        daysInMonth: newDaysInMonth,
                    }, () => this.dateChanged())
                } else if (this.state.daysInMonth[1] === 29) {
                    newDaysInMonth[1] = 28;
                    this.setState({
                        daysInMonth: newDaysInMonth,
                        day: this.state.day === 29 ? 1 : this.state.day,
                    }, () => this.dateChanged())
                } else this.dateChanged();
            }
        })
    }

    dayChanged(e) {
        if (e.target.value !== this.state.day) {
            this.setState({
                day: parseInt(e.target.value),

            }, () => {
                if (!this.checkError()) {
                    this.setState({
                        isShowing: true
                    })

                }
            })
        }

    }

    dateChanged() {
        if (this.state.daysInMonth[this.state.month - 1] !== this.state.days.length) {
            this.updateDays(this.state.month);
            this.setState({
                isShowing: true
            })
        } else {
            this.setState({
                isShowing: true
            })
        }
    }

    updateDays(month) {
        let newDays = [];
        for (let i = 1; i <= this.state.daysInMonth[month - 1]; i++) {
            newDays.push(i);
        }
        this.setState({
            days: newDays,
        });

    }

    checkError = () => {
        let dob = new Date(this.state.year, this.state.month - 1, this.state.day, 0, 0, 0, 0);

        let errorDay = false;
        let errorMonth = false;
        let errorYear = false;
        let now = new Date();
        if (dob.getFullYear() + 18 > now.getFullYear()) {
            errorYear = true;
        } else if (dob.getFullYear() + 18 === now.getFullYear()) {
            if (dob.getMonth() > now.getMonth()) {
                errorMonth = true;
            } else if (dob.getMonth() === now.getMonth()) {
                errorDay = dob.getDate() > now.getDate()
            }
        }
        this.setState({
            errorDay: errorDay,
            errorMonth: errorMonth,
            errorYear: errorYear
        })

        return (errorDay|errorMonth|errorYear);
    }

    submitDate = () => {
        let dob = new Date(this.state.year, this.state.month - 1, this.state.day, 0, 0, 0, 0);
        if (!this.checkError())
            this.props.onChangeHandler({
                attributeCode: this.props.attributeCode,
                value: dob
            });
    }

    closeModal = () => {
        this.setState({
            isShowing: false,
            day: this.state.firstValueOfDay,
            month: this.state.firstValueOfMonth,
            year: this.state.firstValueOfYear,
            days: this.state.days
        })
    };

    closeModalAfterSubmit = () => {
        this.setState({
            isShowing: false
        })
    }

    render() {
        return (
            <Aux>
                <SaveModal show={this.state.isShowing}
                           close={this.closeModal}
                           submit={this.submitDate}
                           closeAfterSubmit={this.closeModalAfterSubmit}
                />
                <Grid container spacing={3}>
                    <Grid item xs={4} spacing={3}>
                        <FormControl variant="outlined"
                                     fullWidth
                                     disabled={this.props.flagDisabled}
                                     error={this.state.errorMonth}>
                            <InputLabel htmlFor="outlined-age-native-simple">Month</InputLabel>
                            <Select native
                                    value={this.state.day !== ' ' ? this.state.month : ''}
                                    onChange={(e) => this.monthChanged(e)}
                                    label="Month"
                                    inputProps={{
                                        id: 'outlined-age-native-simple'
                                    }}>
                                <option value="" disabled selected={' ' === this.state.month}></option>
                                {this.props.months.map(m => {
                                    return (
                                        <option selected={m === this.state.month}
                                                value={m}>{m} </option>
                                    );
                                })}
                            </Select>
                            {this.state.errorMonth?
                            <FormHelperText>You must be over 18 years old</FormHelperText>:null}
                        </FormControl>
                    </Grid>


                    <Grid item xs={4} spacing={3}>

                        <FormControl variant="outlined"
                                     fullWidth
                                     disabled={this.props.flagDisabled}
                                     error={this.state.errorDay}>
                            <InputLabel htmlFor="outlined-age-native-simple">Day</InputLabel>
                            <Select native
                                    value={this.state.day}
                                    onChange={(e) => this.dayChanged(e)}
                                    label="Day"
                                    inputProps={{
                                        id: 'outlined-age-native-simple',
                                    }}>
                                <option value="" disabled selected={' ' === this.state.day}></option>
                                {this.state.days.map(d => {
                                    return (
                                        <option selected={d === this.state.day}
                                                value={d}> {d} </option>
                                    );
                                })}
                            </Select>
                            {this.state.errorDay?
                            <FormHelperText>You must be over 18 years old</FormHelperText>:null}
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} spacing={3}>

                        <FormControl variant="outlined"
                                     fullWidth
                                     error={this.state.errorYear}
                                     disabled={this.props.flagDisabled}>
                            <InputLabel htmlFor="outlined-age-native-simple">Year</InputLabel>
                            <Select native
                                    value={this.state.day !== ' ' ? this.state.year : ''}
                                    onChange={(e) => this.yearChanged(e)}
                                    label="Year"
                                    inputProps={{
                                        id: 'outlined-age-native-simple'
                                    }}>
                                <option value="" disabled selected={' ' === this.state.year}></option>
                                {this.props.years.map(y => {
                                    return (
                                        <option selected={y === this.state.year} value={y}> {y} </option>
                                    );
                                })}
                            </Select>
                            {this.state.errorYear?
                            <FormHelperText>You must be over 18 years old</FormHelperText>:null}
                        </FormControl>
                    </Grid>
                    {this.state.error?
                        <Typography variant='subtitle1' style={{color: "red", margin: "auto"}}>You must be over 18 years
                            old</Typography> : null}


                </Grid>
            </Aux>
        );
    }
}


export default DobUsWithCheck;