import React from 'react';

const textarea = (props) => {
    let defaultValue = {
        chosenValue: ''
    };

    const handleOnBlur = (e) => {
        let request = {
            "attributeCode": props.attributeCode,
            "value": e.target.value
        };
        props.onBlurHandler(request);
    };

    return (
        <textarea
            style={{fontSize:14}}
            readOnly={props.readOnly?props.readOnly:false}
            key={props.defaultValue ? props.defaultValue.chosenValue : defaultValue.chosenValue}
            onBlur={(e) => handleOnBlur(e)}
            defaultValue={props.defaultValue ? props.defaultValue.chosenValue : defaultValue.chosenValue}
            className={props.className}
            rows={props.rows}/>
    );
};

export default textarea;