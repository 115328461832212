import axios from '../custom-axios/backoffice';

const DealService = {

    fetchDeals: () => {
        return axios.get('/rest/deal/offers');
    },

    fetchDealsPerIssuer: (issuerId) => {
        return axios.get(`/rest/deal/issuer/${issuerId}`);
    },

    fetchDeal: (dealId) => {
        return axios.get(`/rest/deal/offers/${dealId}`)
    },

    createOffering: (request) => {
        return axios.post('/rest/deal', request);
    },

    updateDeal: (request) => {
        return axios.put('/rest/deal', request);
    },

    updateOfferSettings: (offerId, request) => {
        return axios.put(`/rest/deal/${offerId}/settings`, request);
    },

    updateDealStatus: (id, status) => {
        return axios.put(`/rest/deal/${id}/status`, status);
    }
}

export default DealService;