import React, {useEffect, useState} from 'react';
import UserRoleService from "../../services/UserGroupService";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import SelectUI from '@material-ui/core/Select';
import Divider from "@material-ui/core/Divider";


const CreateUserModal = (props) => {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [userGroup, setUseGroup] = useState('')
    const [errorFirstName, setErrorFirstName] = useState(false)
    const [errorLastName, setErrorLastName] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorUserGroup, setErrorUserGroup] = useState(false)
    const [errorEmailValid, setErrorEmailValid] = useState(false)

    const errorValidText = 'Email is not valid'
    const errorText = 'This field is required'

    let userInfo = JSON.parse(localStorage.getItem('userInfo'))

    useEffect(() => {
        if (props.userType) {
            setUseGroup(props.userType)
        }
    }, []);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (firstName !== '' && lastName !== '' && email !== '' && userGroup !== '' && !errorEmail) {
            props.submit({
                firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
                lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
                email: email,
                userGroup: userGroup,
                registrationType: 'ADMIN'
            });

            //clearState();
            //props.close();
        } else {
            if (firstName === '') {
                setErrorFirstName(true)
            }
            if (lastName === '') {
                setErrorLastName(true)
            }
            if (email === '') {
                setErrorEmail(true)
            }
            if (userGroup === '') {
                setErrorUserGroup(true)
            }
        }
    };

    let validEmail = (email) => {
        let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(email);
    }

    let onChange = (e, fieldName) => {
        let value = e.target.value

        switch (fieldName) {
            case 'firstName':
                setFirstName(value)
                break
            case 'lastName':
                setLastName(value)
                break
            case 'email':
                setEmail(value)
                if (validEmail(value)) {
                    setErrorEmailValid(false)
                } else {
                    if (value === '' && errorEmailValid) {
                        setErrorEmail(true)
                    }
                    setErrorEmailValid(true)
                }
                break
            case  'userGroup':
                setUseGroup(value)
                break
            default:
        }

        if (value !== '' && fieldName === 'firstName') {
            setErrorFirstName(false)
        }
        if (value !== '' && fieldName === 'lastName') {
            setErrorLastName(false)
        }
        if (value !== '' && fieldName === 'email') {
            setErrorEmail(false)
        }
        if (value !== '' && fieldName === 'userGroup') {
            setErrorUserGroup(false)
        }
    }

    let clearState = () => {
        setErrorFirstName(false)
        setErrorLastName(false)
        setErrorEmail(false)
        setErrorUserGroup(false)
        setErrorEmailValid(false)

        setFirstName('')
        setLastName('')
        setEmail('')
        setUseGroup('')
    }

    let close = (e) => {
        clearState()
        props.close();
    };

    return (
        <>
            <Dialog open={props.show}>
                <DialogTitle id="alert-dialog-title">
                    ADD NEW USER
                    <Divider/>
                </DialogTitle>

                <DialogContent style={{overflow: 'hidden', paddingTop: 24}}>
                    <Grid container spacing={3}>
                        <Grid item lg={4} xs={4}>
                            <Typography>
                                First name
                                <span className="required__circle"> *</span>
                            </Typography>
                        </Grid>
                        <Grid item lg={8} xs={8}>
                            <FormControl variant="outlined"
                                         error={errorFirstName}
                                         fullWidth>
                                <TextField variant='outlined'
                                           error={errorFirstName}
                                           required
                                           value={firstName}
                                           helperText={errorFirstName ? errorText : ''}
                                           onChange={(e) => onChange(e, 'firstName')}>
                                </TextField>
                            </FormControl>
                        </Grid>

                        <Grid item lg={4} xs={4}>
                            <Typography>
                                Last name
                                <span className="required__circle"> *</span>
                            </Typography>
                        </Grid>
                        <Grid item lg={8} xs={8}>
                            <FormControl variant="outlined"
                                         error={errorLastName}
                                         fullWidth>
                                <TextField variant='outlined'
                                           required
                                           error={errorLastName}
                                           value={lastName}
                                           helperText={errorLastName ? errorText : ''}
                                           onChange={(e) => onChange(e, 'lastName')}>
                                </TextField>
                            </FormControl>
                        </Grid>

                        <Grid item lg={4} xs={4}>
                            <Typography>
                                Email
                                <span className="required__circle"> *</span>
                            </Typography>
                        </Grid>
                        <Grid item lg={8} xs={8}>
                            <FormControl variant="outlined"
                                         error={errorEmail}
                                         fullWidth>
                                <TextField variant='outlined'
                                           required
                                           error={errorEmail ? errorEmail : errorEmailValid}
                                           value={email}
                                           helperText={errorEmail ? errorText : errorEmailValid ? errorValidText : ''}
                                           onChange={(e) => onChange(e, 'email')}>
                                </TextField>
                            </FormControl>
                        </Grid>

                        <Grid item lg={4} xs={4}>
                            <Typography>
                                User type
                                <span className="required__circle"> *</span>
                            </Typography>
                        </Grid>
                        <Grid item lg={8} xs={8}>
                            <FormControl variant="outlined"
                                         required
                                         error={errorUserGroup}
                                         disabled={!!props.userType}
                                         fullWidth>
                                <SelectUI
                                    native
                                    fullWidth
                                    onChange={(e) => onChange(e, 'userGroup')}
                                    value={userGroup}
                                    data-width="100%">
                                    <option/>

                                    {UserRoleService.fetch()
                                        .filter(userGroup => (userInfo.userGroup.id === 'USER_SUPER_ADMIN')
                                            || (userGroup.id !== 'USER_SUPER_ADMIN' && userInfo.userGroup.id !== 'USER_SUPER_ADMIN'))
                                        .map(userGroup => {
                                            return (
                                                <option key={userGroup.id}
                                                        value={userGroup.id}>
                                                    {userGroup.value}
                                                </option>
                                            )
                                        })
                                    }
                                </SelectUI>
                                {errorUserGroup ?
                                    <FormHelperText>This field is required</FormHelperText> : null}
                            </FormControl>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                            <DialogActions>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleFormSubmit}>
                                    Add new user
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={close}>Cancel
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreateUserModal;