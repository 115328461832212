import React, {Component} from 'react';
import Select from '../../components/Select';
import RegTypeService from "../../services/RegTypeService";
import {NavLink, withRouter} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

class OfferingEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            regTypes: [],
            selectedRegTypes: []
        }

    }

    componentDidMount() {
        this.props.history.push("/offerings/edit/" + this.props.offer.id)
        this.fetchRegTypes();
    }

    onChangeHandler = (e) => {
        this.props.onChange(e.target.name, e.target.value);
    };

    fetchRegTypes = () => {
        RegTypeService.fetch().then(data => {
            const regTypes = data.data.map(o => {
                return {
                    label: o.name,
                    value: o.id,
                }
            });
            this.setState({
                'regTypes': regTypes
            });
        })
    };

    render() {
        return (

            <>
                <div className="row">
                    <Grid container>

                        <Grid col={4}>

                            <Typography variant="subtitle2">Code</Typography>

                            <TextField
                                defaultValue={this.props.offer.code}
                                type="text"
                                variant="outlined"
                                name={'code'}
                                onChange={(e) => this.onChangeHandler(e)}
                                required={true}/>

                        </Grid>

                        <Grid col={4}>
                            <Typography variant="subtitle2">Name</Typography>

                            <TextField
                                defaultValue={this.props.offer.name}
                                type="text"
                                name={'name'}
                                onChange={(e) => this.onChangeHandler(e)}
                                required={true}/>

                        </Grid>


                        <Grid col={4}>
                            <Typography variant="subtitle2">Full Name</Typography>

                            <TextField
                                defaultValue={this.props.offer.fullName}
                                type="text"
                                name={'fullName'}
                                onChange={(e) => this.onChangeHandler(e)}
                                required={true}/>
                        </Grid>
                    </Grid>

                    <div className="col-xs-12 col-md-4">
                        <div className=" margin-top-10">

                            <label>Short Description</label>

                            <TextField
                                defaultValue={this.props.offer.shortDescription}
                                type="text"
                                name={'shortDescription'}
                                onChange={(e) => this.onChangeHandler(e)}
                                required={true}/>
                        </div>
                    </div>

                    <div className="col-xs-12 col-md-4">

                        <div className="margin-top-10">
                            <Typography variant="subtitle2">Full Description</Typography>

                            <textarea
                                defaultValue={this.props.offer.description}
                                type="text"
                                name={'description'}
                                onChange={(e) => this.onChangeHandler(e)}
                                required={true}/>
                        </div>
                    </div>


                    <div className="col-xs-12 col-md-4">
                        <Typography variant="subtitle2">Raise Goal Amount</Typography>

                        <TextField
                            defaultValue={this.props.offer.securityTokenOffering}
                            type="number"
                            name={'securityTokenOffering'}
                            className="form-control input-lg"
                            onChange={(e) => this.onChangeHandler(e)}
                            required={true}/>
                    </div>

                    <div className="row margin-top-10">
                        <div className="col-xs-12 col-md-4">

                            <div className="margin-top-10">

                                <label className="weight400 text-upper control-label control-label-lg">Image URL</label>

                                <textarea
                                    defaultValue={this.props.offer.imageUrl}
                                    type="text"
                                    name={'imageUrl'}
                                    className="form-control input-lg"
                                    onChange={(e) => this.onChangeHandler(e)}
                                    required={true}/>
                            </div>


                        </div>
                        <div className="col-xs-12 col-md-4">

                            <div
                                className=" margin-top-10">
                                <Typography>Issuer {this.props.offer.issuer_id}</Typography>

                                <Select
                                    name={'issuer'}
                                    colName={'fullName'}
                                    url={'/rest/issuers'}
                                    defaultValue={this.props.offer.issuer_id}
                                    className={'input-lg'}/>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-4">
                            <Typography variant="subtitle2">Status</Typography>

                            <Select
                                name={'dealStatus'}
                                data={[]}
                                className={'input-lg'}
                                defaultValue={this.props.offer.dealStatus}
                                onChange={(e) => this.onChangeHandler(e)}/>
                        </div>
                    </div>
                    {/*<div className="row margin-top-10">
                        <div className="col-xs-12 col-md-4">

                            <div className="margin-top-10">
                                <label className="weight400 text-upper control-label control-label-lg">Status</label>
                                <ReactMultiSelectCheckboxes onChange={this.changeRegTypes}
                                                            options={this.state.regTypes}/>
                                <ul>
                                    {
                                        this.props.offer && this.props.offer.regTypes && this.props.offer.regTypes.map(v => {
                                            return (
                                                <li>{v.name}</li>
                                            )
                                        })
                                    }
                                </ul>

                            </div>
                        </div>
                    </div>*/}

                    <div className="col-xs-12 margin-top-20">
                        <div className="row">
                            <div className="col-xs-12 col-md-4 col-md-offset-4">
                                <NavLink to={"/offerings/edit/" + this.props.offer.id}
                                         className={"btn btn-success btn-xl btn-block"}>Full Edit</NavLink>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }

}

export default withRouter(OfferingEdit);