import React, {Component} from 'react';
import OfferingService from "../../services/OfferingService";
import CrudTable from "../tables/CrudTable";
import CreateOfferingModal from "../modal/CreateOfferingModal";
import {toast} from "react-toastify";
import DealStatusService from "../../services/DealStatusService";
import RegTypeService from "../../services/RegTypeService";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import IssuerService from "../../services/IssuerService";
import Divider from "@material-ui/core/Divider";
import DealService from "../../services/DealService";
import SelectField from "../../components/Molecules/SelectField";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import InputField from "../../components/Molecules/InputField";
import TableHead from "@material-ui/core/TableHead";
import {withStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },

    typeIsser: {
        [theme.breakpoints.down("xs")]: {
            marginTop: '-20px'
        },
    }
});

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class Offerings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offeringUrl: '/rest/deal/offers',
            showIssuerModal: false,
            showDeleteIssuerModal: false,
            dealStatuses: [],
            defaultFilter: '',
            sizes: [10, 20, 50, 100, 200],
            pageSize: 10,
            sort: '',
            filter: {
                name: '',
                pocFullName: '',
                raiseTarget: '',
                amtRaised: '',
                dealStatus: ''
            },
            pocFullName: '',
            regTypes: [],
            regTypesChosen: [],
            dealsColumnNames: [
                {
                    id: 'name',
                    label: 'Name',
                    type: 'editLink',
                    sort: 'name'
                },
                // {
                //     id: 'dateCreated',
                //     label: 'DATE CREATED',
                //     type: 'date',
                //     datePattern: "DD.MM.YYYY"
                // },
                {
                    id: 'companyName',
                    label: 'Issuer',
                    sort: 'companyName'
                },
                {
                    id: 'shortDescription',
                    label: 'Short Description',
                    visible: false
                },
                {
                    id: 'raiseTarget',
                    label: 'Raise Goal',
                    type: 'currency',
                    symbol: '$',
                    sort: 'raiseTarget'
                },
                {
                    id: 'preMoney',
                    label: 'PreMoney',
                    type: 'currency',
                    symbol: '$',
                    sort: 'preMoney'
                },
                {
                    id: 'dealStatus',
                    label: 'Offering Status',
                    type: 'splitDealStatus',
                    sort: 'dealStatus'
                }
            ],
            dealsUsersColumnNames: [
                {
                    id: 'name',
                    label: 'Offer Name',
                    type: 'editLink',
                    sort: 'name'
                },
                {
                    id: 'userName',
                    label: 'Investor',
                    type: 'link',
                    url: '/users',
                    paramLink: 'userId',
                    paramValue: 'userName'
                }
            ],
            issuersCurrentPage: 0,
            issuers: [],
            issuersPages: 0,
            issuerToBeDeleted: {},
            value: 0
        };
    }

    componentDidMount() {
        this.fetchRegTypes()
        this.fetchDealStatus()
        this.fetchIssuers()
    }

    fetchDealStatus() {
        DealStatusService.fetchReal()
            .then(response => {
                this.setState({
                    dealStatuses: response.data
                })
            }).catch(error => {
            console.log(error);
        })
    }

    fetchRegTypes = () => {
        RegTypeService.fetch().then(data => {
            const _data = data.data.map(type => {
                return {
                    id: type.id,
                    value: type.name,
                }
            });

            this.setState({
                regTypes: _data
            });
        }).catch(error => {
            console.log(error);
        })
    };

    fetchIssuers = () => {
        IssuerService.fetchIssuers()
            .then(data => {
                const _data = data.data.filter(value => (value.user && value.user.accountStatus === 'APPROVED'))
                    .map(value => {
                        return {
                            id: value.id,
                            value: value.companyName ? value.companyName : 'N/A'
                        }
                    })

                this.setState({
                    issuers: _data
                })
            }).catch(error => {
            console.log(error);
        })
    }

    createOffering = (request) => {
        DealService.createOffering(request)
            .then((data) => {
                if (data.data === '') {
                    toast.error('This name is already exists')
                } else {
                    toast.success(`Deal with name ${request.name} created`);
                    this.toggleOfferingModal();
                    this.table.fetchData();
                }
            });
    };

    toggleOfferingModal = () => {
        this.setState((prevState) => ({
            showIssuerModal: !prevState.showIssuerModal
        }));
    };

    changeDealStatusHandler = (status) => {
        this.setState({
            filter: {
                dealStatus: status
            }
        })
    }

    onChangeSearchField = (value, field) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [field]: value.id ? value.id : value
            }
        })
    }

    onPageSizeChange = (value) => {
        this.setState({
            pageSize: value
        })
    }

    onSortChange = (value) => {
        this.setState({
            sort: value.id ? value.id : value
        })
    }

    deleteHandler = (offer) => {
        DealService.updateDealStatus(offer.id, {status: 'DELETED'})
            .then(response => {
                this.table.fetchData()
            })
            .catch(error => {
            })
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        })
    }


    render() {

        const {classes} = this.props;

        return (
            <Grid style={{marginTop: '70px'}} container spacing={3}>
                <CreateOfferingModal
                    show={this.state.showIssuerModal}
                    close={this.toggleOfferingModal}
                    submit={this.createOffering}
                    regTypes={this.state.regTypes}
                    issuers={this.state.issuers}/>

                <Grid item xs={12} sm={12}>
                    <Typography variant="h6">
                        Offerings
                    </Typography>

                    <Divider/>
                </Grid>

                <Grid item lg={5} xs={12} sm={12}>
                    <AppBar
                        position="static"
                        color="default"
                    >
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="simple tabs example"
                        >
                            <Tab
                                style={{padding: 0, fontSize: '12px'}}
                                label="All Offerings"
                                {...a11yProps(0)}
                            />

                            <Tab
                                style={{padding: 0, fontSize: '12px'}}
                                label={"Pending Investors"}
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </AppBar>
                </Grid>


                <TabPanel
                    value={this.state.value}
                    index={0}
                >
                    <Grid container spacing={3}>
                        <Grid item lg={12} xs={12}>
                            <Box display="flex">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{color: 'white'}}
                                    endIcon={<AddIcon/>}
                                    onClick={() => this.toggleOfferingModal()}>
                                    Create Offering
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                            <div className="container__responsible--table">
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{minWidth: '250px'}}>
                                                <Typography variant="subtitle2">
                                                    Name
                                                </Typography>
                                                <InputField
                                                    name={'name'}
                                                    value={this.state.filter.name}
                                                    size={"small"}
                                                    onChange={this.onChangeSearchField}
                                                />
                                            </TableCell>
                                            <TableCell style={{minWidth: '250px'}}>
                                                <Typography variant="subtitle2">
                                                    Raise Goal
                                                </Typography>
                                                <InputField
                                                    name={'raiseTarget'}
                                                    value={this.state.filter.raiseTarget}
                                                    size={"small"}
                                                    onChange={this.onChangeSearchField}
                                                />
                                            </TableCell>
                                            {/*<TableCell>*/}
                                            {/*    <Typography variant="subtitle2">*/}
                                            {/*        AMT Raised*/}
                                            {/*    </Typography>*/}
                                            {/*    <Input*/}
                                            {/*        name={'amtRaised'}*/}
                                            {/*        value={this.state.filter.amtRaised}*/}
                                            {/*        size={"small"}*/}
                                            {/*        onChange={this.onChangeSearchField}*/}
                                            {/*    />*/}
                                            {/*</TableCell>*/}
                                            <TableCell style={{minWidth: '250px'}}>
                                                <Typography variant="subtitle2">
                                                    Deal Status
                                                </Typography>
                                                <SelectField
                                                    name={"dealStatus"}
                                                    value={this.state.filter.dealStatus}
                                                    emptyLabel={'All'}
                                                    size={"small"}
                                                    options={this.state.dealStatuses}
                                                    onChange={this.onChangeSearchField}
                                                />
                                            </TableCell>
                                            <TableCell style={{minWidth: '250px'}}>
                                                <Typography variant="subtitle2">
                                                    Show
                                                </Typography>
                                                <SelectField
                                                    name={"pageSize"}
                                                    value={this.state.pageSize}
                                                    size={"small"}
                                                    options={this.state.sizes}
                                                    onChange={this.onPageSizeChange}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </div>

                            <CrudTable
                                url={this.state.offeringUrl}
                                ref={(c) => this.table = c}
                                theadClassName=""
                                columns={this.state.dealsColumnNames}
                                filter={this.state.filter}
                                sort={this.state.sort}
                                pageSize={this.state.pageSize}
                                transform={OfferingService.transformDeal}
                                entityName={"Offering"}
                                editModalBody="offering"
                                deleteHandler={this.deleteHandler}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel
                    value={this.state.value}
                    index={1}
                >
                    <Grid container spacing={3}>
                        <CrudTable
                            url={this.state.offeringUrl}
                            ref={(c) => this.table = c}
                            theadClassName=""
                            columns={this.state.dealsUsersColumnNames}
                            filter={this.state.filter}
                            sort={this.state.sort}
                            pageSize={this.state.pageSize}
                            transform={OfferingService.transformDealUser}
                            entityName={"Offering"}
                            editModalBody="offering"
                        />
                    </Grid>
                </TabPanel>
            </Grid>
        );
    }
}

export default withStyles(styles)(Offerings);