import React, {Component} from 'react';
import DealService from "../services/DealService";
import InvestorsIssuerPage from "./InvestorsIssuerPage";
import {Grid, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

class DealPerIssuerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deals: [],
            show: false,
            channel: {}
        }

    }

    componentDidMount() {
        this.fetchDealsPerIssuer();
    }

    componentDidUpdate(prevProps) {
        if (this.props.user.id !== prevProps.user.id) {
            this.fetchDealsPerIssuer(this.props.issuerId);
        }
    }

    fetchDealsPerIssuer = () => {
        if (this.props.user.id) {
            DealService.fetchDealsPerIssuer(this.props.issuerId).then(data => {
                this.setState({
                    deals: data.data
                })
            });
        }

    }

    onChangeHandler = (e) => {
        let dealId = null;
        if (e.target.value !== "All") {
            dealId = e.target.value;
        }
        this.setState((state, e) => {

            return {
                ...state,
                dealId: dealId
            };
        })
    }

    render() {
        return (
            <>
                <Grid container spacing={3} style={{paddingTop: 10}}>
                    <Grid item lg={3} xs={6}>
                        <Typography>Deals</Typography>
                        <select name={'dealPerId'}
                                className='input__custom'
                                onChange={(e) => this.onChangeHandler(e)}>
                            <option>All</option>
                            {this.state.deals.map(option => {
                                return (
                                    <option
                                        key={option.id}
                                        value={option.id}>
                                        {option.name}
                                    </option>)
                            })}</select>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Card raised>
                            <CardContent>
                                <InvestorsIssuerPage issuerId={this.props.issuerId} dealId={this.state.dealId}/>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default DealPerIssuerComponent;