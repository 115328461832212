import React, {Component} from 'react';
import CrudTable from '../../containers/tables/CrudTable';
import InvestmentService from "../../services/InvestmentService";
import DealService from '../../services/DealService';
import CreateInvestmentModalNew from '../modal/CreateInvestmentModalNew'
import {toast} from "react-toastify";
import InvestorService from "../../services/InvestorService";
import UserService from "../../services/UserService";
import PayTypeService from "../../services/PayTypeService";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SelectField from "../../components/Molecules/SelectField";
import InputField from "../../components/Molecules/InputField";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import OfferingInfoModal from "../modal/OfferingInfoModal";


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
})


class Investments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            code: null,
            investmentsUrl: `/rest/investment/investments`,
            filter: {
                investorName: '',
                investmentStatus: '',
                dealName: '',
            },
            sizes: [10, 20, 50, 100, 200],
            pageSize: 10,
            sort: '',
            deals: [],
            selectedDeal: null,
            investor: {},
            user: {},
            show: false,
            showOfferInfo: false,
            investmentsColumnNames: [
                {
                    id: 'dateCreated',
                    label: 'Investment Submitted',
                    type: 'date',
                    datePattern: "L LT",
                    sort: 'dateCreated'
                },

                {
                    id: 'dateApproved',
                    label: 'Investment Approved',
                    type: 'date',
                    datePattern: "DD.MM.YYYY",
                    sort: 'dateApproved'
                },

                {
                    id: 'dealName',
                    label: 'Offering Name',
                    // type: 'offerLink',
                    sort: 'dealName',
                    type: 'link',
                    url: '/offerings/edit',
                    paramLink:'dealId',
                    paramValue:'dealName'
                },
                {
                    id: 'investmentStatus',
                    label: 'Status',
                    // type: 'translation',
                    //translate: EntityTranslationService.translateInvestmentStatus,
                    type: 'splitInvestmentStatus',
                    sort: 'investmentStatus'
                },
                {
                    id: 'amount',
                    label: 'Actual Amount',
                    type: 'currency',
                    symbol: '$ ',
                    sort: 'amount'
                },
                {
                    id: 'fullName',
                    label: 'Investor Name',
                    sort: 'investorName'
                },
            ],
            investmentsCurrentPage: 0,
            investments: [],
            investmentsPages: 0,
            investmentStatuses: [],
            payTypes: []
        };
    }

    componentDidMount() {
        this.fetchDeals();
        this.fetchUser();
        //this.fetchPayTypes();
        this.fetchInvestmentStatuses();
    }

    fetchDeals() {
        DealService.fetchDeals()
            .then(res => {
                this.setState({
                    deals: res.data
                })
            }).catch(err => {
            console.log(err);
        })
    }

    fetchUser() {
        UserService.fetchCurrentUser()
            .then(res => {
                this.setState({
                    user: res.data
                })
            }).catch(err => {
            console.log(err);
        })
    }

    fetchInvestor() {
        InvestorService.fetchInvestorByUserId(this.state.user.id)
            .then(res => {
                this.setState({
                    investor: res.data
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    fetchInvestmentStatuses() {
        InvestmentService.fetchInvestmentStatuses()
            .then(response => {
                this.setState({
                    investmentStatuses: response.data
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    fetchPayTypes() {
        PayTypeService.fetchAll()
            .then(response => {
                this.setState({
                    payTypes: response.data
                })
            }).catch(error => {
            console.log(error);
        })
    }

    onNewInvestment = (data) => {
        InvestmentService.createInvestment(data).then((res) => {
            toast.success('Investment request is created!');
            window.location.href = "/investors/all";
        })
    }

    toggleModal() {
        this.setState((state, props) => {
            return {
                ...state,
                show: !state.show
            };
        });
    }

    onChangeSearchField = (value, field) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [field]: value.id ? value.id : value
            }
        })
    }

    onPageSizeChange = (value) => {
        this.setState({
            pageSize: value
        })
    }

    onSortChange = (value) => {
        this.setState({
            sort: value.id ? value.id : value
        })
    }

    handleOnLinkClick = (investment) => {
        const deal = this.state.deals.filter(deal => deal.id === investment.dealId)[0]

        if (deal) {
            this.setState({
                selectedDeal: deal,
                showOfferInfo: true
            })
        }
    }

    deleteHandler = (investment) => {
        InvestmentService.updateInvestment("DELETED", investment.id)
            .then(response => {
                this.table.fetchData();
            })
            .catch(error => {
            })
    }

    render() {
        const {classes} = this.props;

        return (
            <>
                <Grid container spacing={2}
                      style={{marginTop: '70px'}}>
                    <Grid item lg={12}>
                        <Typography variant="h6">
                            Investments
                        </Typography>

                        <Divider/>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <div className="container__responsible--table">
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{minWidth: '250px'}}>
                                            <Typography variant="subtitle2">
                                                Investor Name
                                            </Typography>
                                            <InputField
                                                name={'investorName'}
                                                value={this.state.filter.investorName}
                                                size={"small"}
                                                onChange={this.onChangeSearchField}
                                            />
                                        </TableCell>
                                        <TableCell style={{minWidth: '250px'}}>
                                            <Typography variant="subtitle2">
                                                Offer Name
                                            </Typography>
                                            <InputField
                                                name={'dealName'}
                                                value={this.state.filter.dealName}
                                                size={"small"}
                                                onChange={this.onChangeSearchField}
                                            />
                                        </TableCell>
                                        <TableCell style={{minWidth: '250px'}}>
                                            <Typography variant="subtitle2">
                                                Status
                                            </Typography>
                                            <SelectField
                                                name={"investmentStatus"}
                                                value={this.state.filter.investmentStatus}
                                                emptyLabel={'All'}
                                                size={"small"}
                                                options={this.state.investmentStatuses}
                                                onChange={this.onChangeSearchField}
                                            />
                                        </TableCell>
                                        <TableCell style={{minWidth: '250px'}}>
                                            <Typography variant="subtitle2">
                                                Show
                                            </Typography>
                                            <SelectField
                                                name={"pageSize"}
                                                value={this.state.pageSize}
                                                size={"small"}
                                                options={this.state.sizes}
                                                onChange={this.onPageSizeChange}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </div>
                    </Grid>

                    <Grid item lg={12}>
                        <CrudTable
                            {...this.props}
                            url={this.state.investmentsUrl}
                            ref={(c) => this.table = c}
                            editModalBody={'investment'}
                            filter={this.state.filter}
                            sort={this.state.sort}
                            pageSize={this.state.pageSize}
                            columns={this.state.investmentsColumnNames}
                            transform={InvestmentService.transform}
                            canEdit={true}
                            canManage={true}
                            deleteHandler={this.deleteHandler}
                            onLinkClick={this.handleOnLinkClick}
                            payTypes={this.state.payTypes}/>
                    </Grid>
                </Grid>

                {this.state.show && (
                    <CreateInvestmentModalNew
                        className="modal"
                        submit={(data) => this.onNewInvestment(data)}
                        show={this.state.show}
                        deals={this.state.deals}
                        payTypes={this.state.payTypes}
                        close={() => this.toggleModal()}>
                    </CreateInvestmentModalNew>
                )}

                {this.state.selectedDeal && this.state.showOfferInfo && (
                    <OfferingInfoModal
                        show={this.state.showOfferInfo}
                        offer={this.state.selectedDeal}
                        close={() => this.setState({
                            showOfferInfo: false
                        })}>
                    </OfferingInfoModal>
                )}
            </>
        )
    }
}

export default withStyles(styles)(Investments)