import React, {Component} from 'react';
import InvestorService from '../../services/InvestorService';
import BasicInfoPage from "./edit/BasicInfoPage";
import DocumentService from "../../services/DocumentService";
import AttributeService from "../../services/AttributeService";
import {toast} from 'react-toastify';
import AccreditationPage from "./edit/AccreditationPage";
import SuitabilityPage from "./edit/SuitabilityPage";
import InvestorStatusPage from "./edit/InvestorStatusPage";
import FinalSubmissionPage from "./edit/FinalSubmissionPage";
import UserCommentsPanelComponent from "../../components/UserCommentsPanel";
import AuditLogService from "../../services/AuditLogService";
import CrudTable from "../tables/CrudTable";
import './edit/InvestorEdit.css';
import EntityTranslationService from "../../services/EntityTranslationService";
import InvestmentService from "../../services/InvestmentService";
import UserService from "../../services/UserService";
import Box from "@material-ui/core/Box";
import {Card, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import {makeStyles, Theme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import MessagePanelComponentOld from "../../components/MessagePanelOld";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Documentation from "./edit/Documentation";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
    },
}));


class InvestorEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            investor: {user: {}},
            user: {},
            id: null,
            investorData: {},
            key: 'basic-info',
            apiUrl: '/rest/auditlog',
            offeringUrl: '/rest/investment',
            documents: [],
            userThatVerifiedBlock: {},
            accreditationDocuments: false,
            allowedRegType: "",
            value: 0,
            loader: false,
            steps: [],
            filterAudit: {
                username: ''
            },
            filterInvestment: {
                'investor.id': -1
            },
            auditLogColNames: [
                {id: 'id', label: 'ID'},
                {id: 'date', label: 'date'},
                {id: 'action', label: 'action'}, {id: 'username', label: 'username'},
                {id: 'entityClass', label: 'entityClass'},
                {id: 'entityId', label: 'entity id'},
                {id: 'oldValue', label: 'Old Value'},
                {id: 'newValue', label: 'New Value'}],
            investmentsColumnNames: [
                {
                    id: 'dateCreated',
                    label: 'Investment Submitted',
                    type: 'date',
                    datePattern: "DD.MM.YYYY"
                },
                {
                    id: 'dateApproved',
                    label: 'Investment Approved',
                    type: 'date',
                    datePattern: "DD.MM.YYYY"
                },
                {
                    id: 'dealCode',
                    label: 'Deal code'
                },
                {
                    id: 'investmentStatus',
                    label: 'Status',
                    type: 'translation',
                    translate: EntityTranslationService.translateInvestmentStatus
                },
                {
                    id: 'amount',
                    label: 'Amount',
                    type: 'currency',
                    symbol: '$'
                },
                {
                    id: 'payType',
                    label: 'Pay Type'
                },
                {
                    id: 'firstName',
                    label: 'Investor First Name'
                },
                {
                    id: 'lastName',
                    label: 'Investor Last Name'
                },
                {
                    id: 'email',
                    label: 'E-mail',
                    type: 'link',
                    url: '/investors/edit',
                    paramLink: 'userId',
                    paramValue: 'email'
                }
            ],
            dealsColumnNames: [
                {
                    id: 'code',
                    label: 'Code',
                    type: 'link',
                    url: '/investors',
                    paramLink: 'code',
                    paramValue: 'code'
                },
                {
                    id: 'name',
                    label: 'Short Name'
                },
                {
                    id: 'dateCreated',
                    label: 'DATE CREATED'
                },
                {
                    id: 'fullName',
                    label: 'Full Name'
                },
                {
                    id: 'issuer_id',
                    label: 'Issuer ID'
                },
                {
                    id: 'issuerEmail',
                    label: 'Issuer Email'
                },
                {
                    id: 'issuerFullname',
                    label: 'Issuer Fullname'
                },
                {
                    id: 'shortDescription',
                    label: 'Short Description',
                    visible: false
                },
                {
                    id: 'securityTokenOffering',
                    label: 'Raise Goal'
                },
                {
                    id: 'imageUrl',
                    label: 'Image',
                    type: 'image'
                }, {
                    id: 'amtRaised',
                    label: 'AMT Raised'
                }, {
                    id: 'percentGoal',
                    label: '% of goal'
                }, {
                    id: 'numInvestors',
                    label: '# of investors'
                }, {
                    id: 'usdPerInvestor',
                    label: 'USD/Investors'
                }, {
                    id: 'dealStatus',
                    label: 'Deal Status'
                }
            ]
        }
    }


    componentDidMount() {
        const {match: {params}} = this.props

        this.setState({
            id: params.id,
            //user: this.props.location.state.data,
            filterAudit: {
                // username: this.props.user.email
            },
        }, () => {
            this.fetchInvestor(params.id);
        });

        this.fetchInvestorStatus(params.id);
        this.fetchUserThatVerifiedBlock();
        this.fetchUserSteps(params.id)
    }

    classes = () => {
        useStyles()
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        });
    };

    fetchUserThatVerifiedBlock() {
        UserService.fetchCurrentUser()
            .then(response => {
                this.setState({
                    userThatVerifiedBlock: response.data
                })
            }).catch(error => {
            console.log(error);
        })
    }

    onBlurHandler(request) {
        let newInvestor = JSON.parse(JSON.stringify(this.state.investor));
        newInvestor[request.attributeCode] = request.value;
        this.setState({
            investor: newInvestor
        });
    }

    pushInvestorChange = (request) => {
        request.investorId = this.state.investor.id;
        return InvestorService.saveAttributeData(request).then((data) => {
            let investorData = this.state.investorData;
            investorData[data.data.attributeCode] = data.data;

            this.setState({
                ...this.state,
                investorData: investorData
            })
        })

    };

    fetchInvestor = (id) => {
        InvestorService.fetchInvestorByUserId(id)
            .then(response => {
                this.setState({
                    investor: response.data,
                    user: response.data.user,
                    filterInvestment: {
                        'investor.id': response.data.id
                    },
                }, () => {
                    this.fetchAllDocuments();
                });
            }).catch(error => {
            console.log(error);
        });
    };

    fetchAllDocuments = () => {
        if (this.state.investor.id) {
            InvestorService.fetchAllDocumentsByInvestorId(this.state.investor.id).then(data => {
                this.setState({documents: data.data}, () => {
                    data.data.forEach(doc => {
                        if (doc.attributeCode === "LETTER_ACCRD") {
                            this.setState({
                                accreditationDocuments: true
                            })
                        }
                    })
                })
            })
        }
    }

    updateInvestorInfo = (request) => {
        InvestorService.updateInvestorInfo(request)
            .then(response => {
                this.setState({
                    investor: response.data
                });
                toast.success('Saved!')
            }).catch(error => {
            toast.error(error)
        });
    };

    deleteInvestorData = (request) => {
        InvestorService.deleteAttributeData(request)
            .then(response => {
                let investorData = this.state.investorData;
                investorData[request.attributeCode] = {};
                this.setState({
                    ...this.state,
                    investorData: investorData
                });
            }).catch(error => {
            console.log(error);
        });
    };

    deleteMultiInvestorData = (request) => {
        InvestorService.deleteMultiAttributeData(request)
            .then(response => {
                this.fetchInvestorStatus(request.userId);
            }).catch(error => {
            console.log(error);
        });
    };

    onDocumentDelete = (groupId, attrCode) => {
        this.deleteInvestorData({
            attributeCode: attrCode
        });
    };

    saveDocument = (groupId, file, attrCode) => {
        DocumentService.saveDocument(groupId, file)
            .then(response => {
                this.pushInvestorChange({
                    value: response.data.filename,
                    attributeCode: attrCode,
                    documentId: response.data.id
                });
            }).catch(error => {
            console.log(error);
        });
    };

    fetchInvestorStatus = (id) => {
        this.setState({
            loader: true
        })

        InvestorService.fetchInvestorStatusByUserId(id)
            .then(response => {
                this.setState({
                    investorData: response.data,
                    loader: false
                });
            })
            .catch(error => {
                console.log(error);
            })
    };

    fetchUserSteps = (id) => {
        this.setState({
            loader: true
        })

        UserService.fetchUserSteps(id)
            .then(response => {
                this.setState({
                    steps: response.data,
                    loader: false
                });
            })
            .catch(error => {
                console.log(error);
            })
    };

    approveUserStep = (step) => {
        UserService.updateUserStepStatus(this.state.investor.user.id,
            {
                step: step,
                status: 'APPROVED'
            })
            .then(response => {
                toast.success('Approved successfully');
                this.fetchUserSteps(this.state.investor.user.id)
            })
            .catch(error => {
                console.log(error);
            });
    }

    rejectUserStep = (step) => {
        UserService.updateUserStepStatus(this.state.investor.user.id,
            {
                step: step,
                status: 'PENDING_APPROVAL'
            })
            .then(response => {
                this.fetchUserSteps(this.state.investor.user.id)
                toast.success('Rejected successfully');
            })
            .catch(error => {
                console.log(error);
            });
    }

    fetchAttributeOptions = (code) => {
        return AttributeService.getAttributeOptions(code);
    };

    approveInvestor = () => {
        InvestorService.approveInvestor(this.state.investor.user.email)
            .then(response => {
                toast.success('Approved successfully');
            })
            .catch(error => {
                console.log(error);
            });
    }

    setRegType = (e) => {
        if (e !== this.state.allowedRegType) {
            this.setState({
                allowedRegType: e
            })
        }
    };

    render() {
        return (
            this.state.loader
                ? <Box style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: '50vh'
                }}>
                    <CircularProgress/>
                </Box>
                : <Grid container style={{paddingTop: 80}}>
                    <Grid item lg={12} xs={12}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="simple tabs example">
                                <Tab label="Contact Info" {...a11yProps(0)} />
                                <Tab label="Documentation" {...a11yProps(1)} />
                                <Tab label="Accreditation" {...a11yProps(2)} />
                                <Tab label="Suitability" {...a11yProps(2)} />
                                <Tab label="Investor Status" {...a11yProps(4)} />
                                <Tab label="Disclosures" {...a11yProps(5)} />
                                <Tab label="Investments" {...a11yProps(6)} />
                                <Tab label="Audit Log" {...a11yProps(7)} />
                                <Tab label="Messages/Comments" {...a11yProps(8)} />
                            </Tabs>
                        </AppBar>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <TabPanel value={this.state.value} index={0}>
                            <BasicInfoPage pushInvestorChange={this.pushInvestorChange}
                                           investor={this.state.investor}
                                           updateInvestorInfo={this.updateInvestorInfo}
                                           deleteInvestorData={this.deleteInvestorData}
                                           investorData={this.state.investorData}
                                           fetchAttributeOptions={this.fetchAttributeOptions}
                                           fetchInvestorStatusByStep={(step) => this.fetchInvestorStatusByStep(step)}
                                           investorAttributeOptions={this.investorAttributeOptions}
                                           validate={this.validate}
                                           investorId={this.state.investor.id}
                                           key={this.state.key}
                                           userThatVerified={this.state.userThatVerifiedBlock}
                                           userId={this.state.id}
                                           fetchInvestor={this.fetchInvestor}
                                           approveInvestor={this.approveInvestor}
                                           setRegtype={this.setRegType}
                                           allowedRegTyp={this.state.allowedRegType}
                                           loader={this.state.loader}
                                           steps={this.state.steps}
                                           approveStep={this.approveUserStep}
                                           rejectStep={this.rejectUserStep}
                            />
                        </TabPanel>

                        <TabPanel value={this.state.value} index={1}>
                            <Documentation
                                pushInvestorChange={this.pushInvestorChange}
                                investor={this.state.investor}
                                updateInvestorInfo={this.updateInvestorInfo}
                                deleteInvestorData={this.deleteInvestorData}
                                investorData={this.state.investorData}
                                fetchAttributeOptions={this.fetchAttributeOptions}
                                fetchInvestorStatusByStep={(step) => this.fetchInvestorStatusByStep(step)}
                                investorAttributeOptions={this.investorAttributeOptions}
                                validate={this.validate}
                                investorId={this.state.investor.id}
                                key={this.state.key}
                                userThatVerified={this.state.userThatVerifiedBlock}
                                userId={this.state.id}
                                fetchInvestor={this.fetchInvestor}
                                approveInvestor={this.approveInvestor}
                                setRegtype={this.setRegType}
                                allowedRegTyp={this.state.allowedRegType}
                                loader={this.state.loader}
                                steps={this.state.steps}
                                approveStep={this.approveUserStep}
                                rejectStep={this.rejectUserStep}
                            />
                        </TabPanel>

                        <TabPanel value={this.state.value} index={2}>
                            <AccreditationPage pushInvestorChange={this.pushInvestorChange}
                                               updateInvestorInfo={this.updateInvestorInfo}
                                               deleteInvestorData={this.deleteInvestorData}
                                               onDocumentDelete={this.onDocumentDelete}
                                               investorData={this.state.investorData}
                                               saveDocument={this.saveDocument}
                                               fetchAttributeOptions={this.fetchAttributeOptions}
                                               fetchInvestorStatusByStep={(step) => this.fetchInvestorStatusByStep(step)}
                                               investorAttributeOptions={this.investorAttributeOptions}
                                               documents={this.state.documents}
                                               investorId={this.state.investor.id}
                                               userThatVerified={this.state.userThatVerifiedBlock}
                                               accreditDocuments={this.state.accreditationDocuments}
                                               allowedRegType={this.state.allowedRegType}
                                               steps={this.state.steps}
                                               approveStep={this.approveUserStep}
                                               rejectStep={this.rejectUserStep}
                            />
                        </TabPanel>

                        <TabPanel value={this.state.value} index={3}>
                            <SuitabilityPage pushInvestorChange={this.pushInvestorChange}
                                             updateInvestorInfo={this.updateInvestorInfo}
                                             deleteInvestorData={this.deleteInvestorData}
                                             deleteMultiInvestorData={this.deleteMultiInvestorData}
                                             onDocumentDelete={this.onDocumentDelete}
                                             investorData={this.state.investorData}
                                             investor={this.state.investor}
                                             saveDocument={this.saveDocument}
                                             fetchAttributeOptions={this.fetchAttributeOptions}
                                             fetchInvestorStatusByStep={(step) => this.fetchInvestorStatusByStep(step)}
                                             investorAttributeOptions={this.investorAttributeOptions}
                                             steps={this.state.steps}
                                             approveStep={this.approveUserStep}
                                             rejectStep={this.rejectUserStep}
                            />
                        </TabPanel>

                        <TabPanel value={this.state.value} index={4}>
                            <InvestorStatusPage
                                pushInvestorChange={this.pushInvestorChange}
                                updateInvestorInfo={this.updateInvestorInfo}
                                deleteInvestorData={this.deleteInvestorData}
                                onDocumentDelete={this.onDocumentDelete}
                                investorData={this.state.investorData}
                                saveDocument={this.saveDocument}
                                fetchAttributeOptions={this.fetchAttributeOptions}
                                fetchInvestorStatusByStep={(step) => this.fetchInvestorStatusByStep(step)}
                                investorAttributeOptions={this.investorAttributeOptions}
                                steps={this.state.steps}
                                approveStep={this.approveUserStep}
                                rejectStep={this.rejectUserStep}
                            />
                        </TabPanel>

                        <TabPanel value={this.state.value} index={5}>
                            <FinalSubmissionPage
                                pushInvestorChange={this.pushInvestorChange}
                                updateInvestorInfo={this.updateInvestorInfo}
                                deleteInvestorData={this.deleteInvestorData}
                                onDocumentDelete={this.onDocumentDelete}
                                investorData={this.state.investorData}
                                saveDocument={this.saveDocument}
                                fetchAttributeOptions={this.fetchAttributeOptions}
                                fetchInvestorStatusByStep={(step) => this.fetchInvestorStatusByStep(step)}
                                investorAttributeOptions={this.investorAttributeOptions}
                                user={this.state.user}
                                steps={this.state.steps}
                                approveStep={this.approveUserStep}
                                rejectStep={this.rejectUserStep}
                            />
                        </TabPanel>

                        <TabPanel value={this.state.value} index={6}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12}>
                                            <Typography variant='h6'>
                                                Investments
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={12} xs={12}>
                                            {this.state.filterInvestment['investor.id'] !== -1 ?
                                                <CrudTable url={this.state.offeringUrl}
                                                    //ref={(c) => this.table = c}
                                                           theadClassName="text-uppercase thead-orange"
                                                           columns={this.state.investmentsColumnNames}
                                                           filter={this.state.filterInvestment}
                                                           sort={''}
                                                           pageSize={10}
                                                           transform={InvestmentService.transform}
                                                           entityName={"Investment"}
                                                           editModalBody="investment"
                                                           canEdit={true}/>
                                                : null}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </TabPanel>

                        <TabPanel value={this.state.value} index={7}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12}>
                                            <Typography variant='h6'>Audit Log</Typography>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <CrudTable url={this.state.apiUrl}
                                                       filter={this.state.filterAudit}
                                                       sort={''}
                                                       pageSize={10}
                                                       theadClassName="text-uppercase thead-orange"
                                                       columns={this.state.auditLogColNames}
                                                       transform={AuditLogService.transform}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </TabPanel>

                        <TabPanel value={this.state.value} index={8}>
                            <Grid container spacing={3} style={{paddingTop: 10}}>
                                <Grid item lg={6} xs={6}>
                                    <Card>
                                        <CardContent>
                                            <MessagePanelComponentOld
                                                {...this.props}
                                                user={this.state.user}/>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={6} xs={6}>
                                    <Card>
                                        <CardContent>
                                            <UserCommentsPanelComponent user={this.state.user}/>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </Grid>
                </Grid>
        );
    }
}

export default InvestorEdit;