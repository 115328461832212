import React, {Component} from 'react';

import Text from '../../../components/input/Text';
import RegTypeService from "../../../services/RegTypeService";
import InvestorService from "../../../services/InvestorService";
import {toast} from 'react-toastify';
import ImagePreviewModal from "../../modal/ImagePreviewModal";
import PdfPreviewModal from "../../modal/PdfPreviewModal";
import DobUsWithCheck from "../../../components/input/DobUsWithCheck";
import UnVerifyDocumentsModal1 from "../../modal/UnVerifyDocumentsModal1";
import UnVerifyDocumentsModal2 from "../../modal/UnVerifyDocumentsModal2";
import PassportVerificationModal from "../../modal/PassportVerificationModal";
import GovIdVerificationModal from "../../modal/GovIdVerificationModal";
import HomeAddressVerifyModal from "../../modal/HomeAddressVerifyModal";
import DeletePassportModal from "../../modal/DeletePassportModal";
import DeleteGovIdModal from "../../modal/DeleteGovIdModal";
import HomeAddressUnVerifyModal from "../../modal/HomeAddressUnVerifyModal";
import {Button} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import TextPassword from "../../../components/input/TextPassword";
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CircularProgress from "@material-ui/core/CircularProgress";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Moment from "react-moment";
import InputPhoneField from "../../../components/Molecules/InputPhoneField";

export default class BasicInfoPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'COUNTRY_CITIZEN': [],
            'STATE_PROVINCE_ID': [],
            'COUNTRY_ID': [],
            'MAILING_COUNTRY_ID': [],
            'MAILING_STATE_PROVINCE_ID': [],
            'SAME_MAILING_ADDRESS_ID': [],
            mandatoryFields: ['MAILING_STATE_PROVINCE_ID', 'STATE_PROVINCE_ID', 'SAME_MAILING_ADDRESS_ID'],
            months: [],
            years: [],
            regTypes: [],
            selectedRegTypes: [],
            typePassport: 'password',
            wordingPassport: 'Show',
            typeGovId: 'password',
            wordingGovId: 'Show',
            typeSsn: 'password',
            wordingSsn: 'Show',
            accountStatus: '',
            documentsHomeAddressBlock: [],
            documentsPassportBlock: [],
            documentsGovIdBlock: [],
            showPreview: false,
            showPdfPreview: false,
            userVerifiedHomeAddressBlock: "",
            verificationDateHomeAddressBlock: "",
            userVerifiedPassportBlock: "",
            verificationDatePassportBlock: "",
            userVerifiedGovIdBlock: "",
            verificationDateGovIdBlock: "",
            dataHomeAddressBlock: false,
            dataPassportBlock: false,
            dataGovIdBlock: false,
            dataKycAmlBlock: false,
            checkActivationBlock: false,
            freezeInputs: true,
            wordingFreeze: "Unfreeze",
            isShowing1: false,
            isShowing2: false,
            unVerifyPassport: false,
            unVerifyGovId: false,
            unVerifyHomeAddress: false,
            showingPassport: false,
            showingGovId: false,
            showingHomeAddress: false,
            showingDeletePassport: false,
            showingDeleteGovId: false,
            showingDeleteHomeAddress: false,
            expirationDatePassport: '',
            expirationDateGovId: '',
            expirationDateHomeAddress: '',
            expirationDatePassportChecked: false,
            expirationDateGovIdChecked: false,
            expirationDateHomeAddressChecked: false,
            verificationDatePassportChecked: false,
            verificationDateGovIdChecked: false,
            verificationDateHomeAddressChecked: false,
            loader: false,
            allowRegType: '',
            'regA': false,
            'regA+': false,
            'regCF': false,
            'regD(506B)': false,
            'regD(506C)': false,
            'regD': true,
            step: null
        }
    }


    componentDidMount() {
        //this.createDummyInvestor();
        this.fetchRegTypes();

        this.init();
        this.fetchDocumentsHomeAddress();
        this.fetchDocumentsPassport();
        this.fetchDocumentsGovId();
    }

    init() {
        const step = this.props.steps.filter(step => step.step.id === 'STEP_CONTACT_INFO')[0]

        this.setState({
            step: step
        })

        this.props.fetchAttributeOptions('STATE_PROVINCE_ID').then((data) => {
            this.setState({
                ...this.state,
                'STATE_PROVINCE_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('COUNTRY_ID').then((data) => {
            this.setState({
                ...this.state,
                'COUNTRY_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('MAILING_COUNTRY_ID').then((data) => {
            this.setState({
                ...this.state,
                'MAILING_COUNTRY_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('MAILING_STATE_PROVINCE_ID').then((data) => {
            this.setState({
                ...this.state,
                'MAILING_STATE_PROVINCE_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('SAME_MAILING_ADDRESS_ID').then((data) => {
            this.setState({
                ...this.state,
                'SAME_MAILING_ADDRESS_ID': data.data
            });
        });

        this.props.fetchAttributeOptions('COUNTRY_CITIZEN').then((data) => {
            this.setState({
                ...this.state,
                'COUNTRY_CITIZEN': data.data
            });
        });

        this.setState({
            selectedRegTypes: this.props.investor.regTypes
        })

        this.setState({
            accountStatus: this.props.investor.user.accountStatus
        })

        let allYears = [];
        for (let i = new Date().getFullYear() - 105; i <= new Date().getFullYear(); i++) {
            allYears.unshift(i);
        }

        let allMonths = [];
        for (let i = 1; i <= 12; i++) {
            allMonths.push(i);
        }

        this.setState({
            months: allMonths,
            years: allYears
        })

    }

    fetchRegTypes = () => {
        RegTypeService.fetch().then(data => {
            this.setState({
                regTypes: data.data
            });
        })
    };

    nextStep(e) {
        e.preventDefault();
        this.props.fetchInvestorStatusByStep('STEP_CONTACT_INFO')
            .then(response => {
                let newMandatoryFields = [...this.state.mandatoryFields];
                if (this.props.investorData['COUNTRY_ID'].chosenValue === 'United States') {
                    newMandatoryFields = newMandatoryFields.filter(f => f !== 'STATE_PROVINCE_ID');
                }
                if (this.props.investorData['MAILING_COUNTRY_ID'].chosenValue === 'United States') {
                    newMandatoryFields = newMandatoryFields.filter(f => f !== 'MAILING_STATE_PROVINCE_ID');
                }
                if (this.props.validate(this.props.investorData, response.data, newMandatoryFields)) {

                    this.props.updateInvestorInfo({
                        firstName: this.props.investorData['FIRST_NAME_ON_ID'].chosenValue,
                        lastName: this.props.investorData['LAST_NAME_ON_ID'].chosenValue,
                        streetAddress: this.props.investorData['STREET_ADDRESS_ID'].chosenValue,
                        city: this.props.investorData['CITY_ID'].chosenValue,
                        postalCode: this.props.investorData['POSTAL_CODE_ID'].chosenValue,
                        mailStreetAddress: this.props.investorData['MAILING_STREET_ADDRESSS_ID'].chosenValue,
                        mailCity: this.props.investorData['MAILING_CITY_ID'].chosenValue,
                        mailPostalCode: this.props.investorData['MAILING_POSTAL_CODE_ID'].chosenValue,
                        stateId: 1,
                        countryId: 1,
                        mailStateId: 1,
                        mailCountryId: 1,
                        /*
                        alternativeEmail: this.props.investorData['CITY_ID'].chosenValue,
                        phoneNumber: this.props.investorData['CITY_ID'].chosenValue,
                        alternativePhoneNumb: this.props.investorData['CITY_ID'].chosenValue,*/
                    });

                    this.props.setRegistrationStatus('STEP_CONTACT_INFO')
                        .then(res => {
                            localStorage.setItem('userInfo', JSON.stringify(res.data));
                            this.props.history.push('/contact-info');
                        }).catch(err => {
                    });
                } else alert('Please fill the required fields');
            }).catch(error => {
            console.log(error);
        });
    }

    changeStateForShowPassport = () => {
        let oldState = this.state.typePassport;
        let isTextOrHide = (oldState === 'password');
        let newState = (isTextOrHide) ? 'text' : 'password';
        let newWord = (isTextOrHide) ? 'Hide' : 'Show';
        this.setState({
            typePassport: newState,
            wordingPassport: newWord
        })
    };

    changeShowingSsn = () => {
        let oldState = this.state.typeSsn;
        let isTextOrHide = (oldState === 'password');
        let newState = (isTextOrHide) ? 'text' : 'password';
        let newWord = (isTextOrHide) ? 'Hide' : 'Show';
        this.setState({
            typeSsn: newState,
            wordingSsn: newWord
        })
    };
    changeStateForShowGovId = () => {
        let oldState = this.state.typeGovId;
        let isTextOrHide = (oldState === 'password');
        let newState = (isTextOrHide) ? 'text' : 'password';
        let newWord = (isTextOrHide) ? 'Hide' : 'Show';
        this.setState({
            typeGovId: newState,
            wordingGovId: newWord
        })
    };

    changeStateFreeze = () => {
        let oldState = this.state.freezeInputs;
        let isTextOrHide = (oldState === false);
        let newState = (isTextOrHide);
        let newWord = (isTextOrHide) ? 'Unfreeze' : 'Freeze';
        this.setState({
            freezeInputs: newState,
            wordingFreeze: newWord
        });
    };

    fetchDocumentsHomeAddress = () => {
        if (this.props.issuerId) {
            InvestorService.fetchByAttributeCodeAndIssuerId('FINANCIAL_STATEMENT_', this.props.issuerId)
                .then(data => {
                    this.setState({documentsHomeAddressBlock: data.data});
                });
        } else if (this.props.investorId) {
            InvestorService.fetchByAttributeCodeAndInvestorId('FINANCIAL_STATEMENT_', this.props.investorId)
                .then(data => {
                    this.setState({documentsHomeAddressBlock: data.data}, () => {
                        this.checkVerifyDocumentsFirstTime(this.state.documentsHomeAddressBlock)
                        this.checkBlocksVerified()
                    });
                });
        }
    };

    fetchDocumentsPassport = () => {
        this.setState({
            loader: true
        })

        if (this.props.issuerId) {
            InvestorService.fetchByAttributeCodeAndIssuerId('PASSPORT', this.props.issuerId)
                .then(data => {
                    this.setState({documentsPassportBlock: data.data});
                });
        } else if (this.props.investorId) {
            InvestorService.fetchByAttributeCodeAndInvestorId('PASSPORT', this.props.investorId)
                .then(data => {
                    this.setState({documentsPassportBlock: data.data}, () => {
                        this.checkVerifyDocumentsFirstTime(this.state.documentsPassportBlock)
                        this.checkBlocksVerified()
                    });
                });
        }
    };

    fetchDocumentsGovId = () => {
        if (this.props.issuerId) {
            InvestorService.fetchByAttributeCodeAndIssuerId('STATE_ID_BACK', this.props.issuerId)
                .then(data => {
                    this.setState({documentsGovIdBlock: data.data});
                });
        } else if (this.props.investorId) {
            let documentsList = [];
            InvestorService.fetchByAttributeCodeAndInvestorId('STATE_ID_4', this.props.investorId)
                .then(data1 => {

                    data1.data.forEach(doc1 => {
                        documentsList.push(doc1)
                    })
                });

            InvestorService.fetchByAttributeCodeAndInvestorId('STATE_ID_BACK', this.props.investorId)
                .then(data2 => {
                    data2.data.forEach(doc2 => {
                        documentsList.push(doc2)
                    });

                    this.setState({documentsGovIdBlock: documentsList}, () => {
                        this.checkVerifyDocumentsFirstTime(this.state.documentsGovIdBlock)
                    });
                });
        }
    };

    checkBlocksVerified = () => {
        if (this.props.investor.activationBlockList && this.props.investor.activationBlockList.length !== 0) {
            this.props.investor.activationBlockList.forEach(block => {
                if (block.blockName === "BLOCK_PASSPORT") {
                    this.setState({
                        userVerifiedPassportBlock: block.userNameThatVerified,
                        verificationDatePassportBlock: block.verificationDate,
                        expirationDatePassport: block.expirationDate
                    }, () => {
                        if (block.expirationDate !== '') {
                            this.checkExpirationDate(block.expirationDate, "BLOCK_PASSPORT");
                        }
                        this.checkVerificationDate(block.verificationDate, "BLOCK_PASSPORT")
                    });

                }
                if (block.blockName === "BLOCK_HOME_ADDRESS") {
                    this.setState({
                        userVerifiedHomeAddressBlock: block.userNameThatVerified,
                        verificationDateHomeAddressBlock: block.verificationDate,
                        expirationDateHomeAddress: block.expirationDate
                    }, () => {
                        this.checkExpirationDate(block.expirationDate, "BLOCK_HOME_ADDRESS");
                        this.checkVerificationDate(block.verificationDate, "BLOCK_HOME_ADDRESS")

                    });
                }
                if (block.blockName === "BLOCK_GOV_ID") {
                    this.setState({
                        userVerifiedGovIdBlock: block.userNameThatVerified,
                        verificationDateGovIdBlock: block.verificationDate,
                        expirationDateGovId: block.expirationDate
                    }, () => {
                        if (block.expirationDate !== '') {
                            this.checkExpirationDate(block.expirationDate, "BLOCK_GOV_ID");
                        }
                        this.checkVerificationDate(block.verificationDate, "BLOCK_GOV_ID")
                    });
                }
            })
        }
    };

    onFilePreview = (e, item) => {
        e.stopPropagation();
        /*this.setState({
            fileToPreview: item.chosenValue,
            showPreview: true
        });*/
        let fileToPreview = item.chosenValue.split('.');

        if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
            this.setState({
                fileToPreview: item.chosenValue,
                showPdfPreview: true
            });
        } else {
            this.setState({
                fileToPreview: item.chosenValue,
                showPreview: true
            });
        }
    };

    handleClose = (e) => {
        this.setState({
            showPreview: false
        })
    };

    handlePdfClose = (e) => {
        this.setState({
            showPdfPreview: false
        })
    };

    onVerifyDocuments = (document, stateToEdit) => {
        let listDocuments = stateToEdit;
        let doc = document.chosenValue.split('.');

        InvestorService.verifyDocument(doc[0])
            .then(response => {
                    if (response.data.documentVerify) {
                        for (let i = 0; i < listDocuments.length; i++) {
                            if (listDocuments[i].chosenValue === document.chosenValue) {
                                listDocuments[i].documentVerify = true;
                            }

                            if (stateToEdit === this.state.documentsHomeAddressBlock) {
                                this.setState({
                                    documentsHomeAddressBlock: listDocuments

                                }, () => {
                                    this.checkVerifiedOrNoListDocuments(listDocuments)
                                })
                            }

                            if (stateToEdit === this.state.documentsPassportBlock) {
                                this.setState({
                                    documentsPassportBlock: listDocuments
                                }, () => {
                                    this.checkVerifiedOrNoListDocuments(listDocuments)
                                })
                            }

                            if (stateToEdit === this.state.documentsGovIdBlock) {
                                this.setState({
                                    documentsGovIdBlock: listDocuments
                                }, () => {
                                    this.checkVerifiedOrNoListDocuments(listDocuments)
                                })
                            }
                        }

                        //toast.success('Successfully verified');
                    } else {
                        toast.error('Error verification');
                    }
                }
            )
    };

    onUnVerifyDocuments = (document, stateToEdit) => {
        let listDocuments = stateToEdit;
        let doc = document.chosenValue.split('.');

        InvestorService.unVerifyDocument(doc[0])
            .then(response => {
                    if (!response.data.documentVerify) {
                        for (let i = 0; i < listDocuments.length; i++) {
                            if (listDocuments[i].chosenValue === document.chosenValue) {
                                listDocuments[i].documentVerify = false;
                            }

                            if (stateToEdit === this.state.documentsHomeAddressBlock) {
                                this.setState({
                                    documentsHomeAddressBlock: listDocuments

                                }, () => {
                                    this.checkVerifiedOrNoListDocuments(listDocuments)
                                })
                            }

                            if (stateToEdit === this.state.documentsPassportBlock) {
                                this.setState({
                                    documentsPassportBlock: listDocuments
                                }, () => {
                                    this.checkVerifiedOrNoListDocuments(listDocuments)
                                })
                            }

                            if (stateToEdit === this.state.documentsGovIdBlock) {
                                this.setState({
                                    documentsGovIdBlock: listDocuments
                                }, () => {
                                    this.checkVerifiedOrNoListDocuments(listDocuments)
                                })
                            }
                        }
                    } else {
                        toast.error('Error');
                    }
                }
            )
    }

    checkVerifyDocumentsFirstTime = (documents) => {
        let currentVerifiedDocuments = 0;
        let listDocuments = documents;

        for (let i = 0; i < listDocuments.length; i++) {
            if (listDocuments[i].documentVerify === true) {
                currentVerifiedDocuments++
            }
        }
        if (currentVerifiedDocuments === listDocuments.length && currentVerifiedDocuments !== 0) {
            if (documents === this.state.documentsHomeAddressBlock) {
                this.setState({
                    dataHomeAddressBlock: true
                })
            }

            if (documents === this.state.documentsPassportBlock) {
                this.setState({
                    dataPassportBlock: true
                })
            }

            if (documents === this.state.documentsGovIdBlock) {
                this.setState({
                    dataGovIdBlock: true
                })
            }
        }
        this.setState({
            loader: false
        })
    };

    checkVerifiedOrNoListDocuments = (documents) => {
        let currentVerifiedDocuments = 0;
        let listDocuments = documents;

        for (let i = 0; i < listDocuments.length; i++) {
            if (listDocuments[i].documentVerify === true) {
                currentVerifiedDocuments++
            }
        }

        if (currentVerifiedDocuments === listDocuments.length && currentVerifiedDocuments !== 0) {
            if (documents === this.state.documentsHomeAddressBlock) {
                this.setState({
                    dataHomeAddressBlock: true
                })
            }

            if (documents === this.state.documentsPassportBlock) {
                this.setState({
                    dataPassportBlock: true
                }, () => {
                    if (this.state.expirationDatePassport !== '') {
                        this.createActivationBlock("BLOCK_PASSPORT");
                    }

                })
            }

            if (documents === this.state.documentsGovIdBlock) {
                this.setState({
                    dataGovIdBlock: true
                }, () => {
                    if (this.state.expirationDateGovId !== '') {
                        this.createActivationBlock("BLOCK_GOV_ID");
                    }

                })
            }
        }
    };

    createVerificationDate() {
        let now = new Date();
        let options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timezone: 'UTC',
            hour: 'numeric',
            minute: 'numeric',
        };
        return now.toLocaleDateString("en-US", options);
    }

    createActivationBlock = (block) => {
        let date = this.createVerificationDate();
        let firstAndLastUserName = this.props.userThatVerified.firstName + " " + this.props.userThatVerified.lastName;
        let investorId = this.props.investorId;
        let blockName = "";
        let expirationDate = "";

        if (block === "BLOCK_HOME_ADDRESS") {
            blockName = "BLOCK_HOME_ADDRESS";
            expirationDate = this.state.expirationDateHomeAddress;
            this.setState({
                userVerifiedHomeAddressBlock: firstAndLastUserName,
                verificationDateHomeAddressBlock: date,
                verificationDateHomeAddressChecked: true
            });
        }

        if (block === "BLOCK_PASSPORT") {
            blockName = "BLOCK_PASSPORT";
            expirationDate = this.state.expirationDatePassport;
            this.setState({
                userVerifiedPassportBlock: firstAndLastUserName,
                verificationDatePassportBlock: date,
                verificationDatePassportChecked: true
            });
        }

        if (block === "BLOCK_GOV_ID") {
            blockName = "BLOCK_GOV_ID";
            expirationDate = this.state.expirationDateGovId;
            this.setState({
                userVerifiedGovIdBlock: firstAndLastUserName,
                verificationDateGovIdBlock: date,
                verificationDateGovIdChecked: true
            });
        }

        let data = {
            date: date,
            userName: firstAndLastUserName,
            blockName: blockName,
            expirationDate: expirationDate,
        };

        InvestorService.createActivationBlock(data, investorId)
            .then(response => {
                this.props.fetchInvestor(this.props.userId);
                this.fetchDocumentsHomeAddress();
                this.fetchDocumentsPassport();
                this.fetchDocumentsGovId();
            })
            .catch(error => {
            })
    };

    showModal = () => {
        this.setState({
            isShowing1: !this.state.isShowing1
        });
    };

    handleOnUnVerify = (e, block) => {
        e.preventDefault();
        if (block === "passport") {
            this.setState({
                    unVerifyPassport: true
                }, () => {
                    this.showModal()
                }
            );
        }
        if (block === "govId") {
            this.setState({
                unVerifyGovId: true
            }, () => {
                this.showModal()
            })
        }
        if (block === "homeAddress") {
            this.setState({
                unVerifyHomeAddress: true
            }, () => {
                this.showModal()
            })
        }
    };

    handleVerifyBlock = (attr, isVerified) => {
        if (attr === 'KYC_AML') {
            this.setState({
                dataKycAmlBlock: isVerified
            })
        }
    }

    toggleSaveModal1 = () => {
        this.setState({
            unVerifyPassport: false,
            unVerifyGovId: false,
            unVerifyHomeAddress: false
        });
        this.setState({
            isShowing1: !this.state.isShowing1
        })
    };

    toggleSaveModal2 = () => {
        this.setState({
            unVerifyPassport: false,
            unVerifyGovId: false,
            unVerifyHomeAddress: false
        });
        this.setState({
            isShowing2: !this.state.isShowing2
        })
    };

    closeForSubmit = () => {
        this.setState({
            isShowing1: !this.state.isShowing1
        })
    };

    openModal = () => {
        this.setState({
            isShowing2: !this.state.isShowing2
        })
    };

    showDateModal = (e, blockName) => {
        e.preventDefault();

        if (blockName === "BLOCK_HOME_ADDRESS") {
            this.setState({
                showingHomeAddress: true
            })
        }

        if (blockName === "BLOCK_GOV_ID") {
            this.setState({
                showingGovId: true
            })
        }

        if (blockName === "BLOCK_PASSPORT") {
            this.setState({
                showingPassport: true
            })
        }
    };

    closeDateModal = (blockName) => {
        if (blockName === "BLOCK_HOME_ADDRESS") {
            this.setState({
                showingHomeAddress: false
            })
        }

        if (blockName === "BLOCK_GOV_ID") {
            this.setState({
                showingGovId: false
            })
        }

        if (blockName === "BLOCK_PASSPORT") {
            this.setState({
                showingPassport: false
            })
        }
    };

    openDeleteDateModel = (e, blockName) => {
        e.preventDefault();

        if (blockName === "BLOCK_HOME_ADDRESS") {
            this.setState({
                showingDeleteHomeAddress: true
            })
        }

        if (blockName === "BLOCK_GOV_ID") {
            this.setState({
                showingDeleteGovId: true
            })
        }

        if (blockName === "BLOCK_PASSPORT") {
            this.setState({
                showingDeletePassport: true
            })
        }
    };

    closeDeleteDateModal = (blockName) => {
        if (blockName === "BLOCK_HOME_ADDRESS") {
            this.setState({
                showingDeleteHomeAddress: false
            })
        }

        if (blockName === "BLOCK_GOV_ID") {
            this.setState({
                showingDeleteGovId: false
            })
        }

        if (blockName === "BLOCK_PASSPORT") {
            this.setState({
                showingDeletePassport: false
            })
        }
    };

    saveChanges = () => {
        let investorId = this.props.investorId;

        if (this.state.unVerifyPassport) {
            let listDocuments = this.state.documentsPassportBlock;
            for (let i = 0; i < listDocuments.length; i++) {
                let doc = listDocuments[i].chosenValue.split('.');
                InvestorService.unVerifyDocument(doc[0]).then(response => {
                });
                listDocuments[i].documentVerify = false;
            }
            this.setState({
                documentsPassportBlock: listDocuments
            });

            let data = {
                blockName: "BLOCK_PASSPORT",
                date: "",
                flag: "verification"
            };

            InvestorService.changeActivationBlock(data, investorId).then(() => {
                this.setState({
                    dataPassportBlock: false,
                    unVerifyPassport: false
                });
            });

        }

        if (this.state.unVerifyGovId) {
            let listDocuments = this.state.documentsGovIdBlock;
            for (let i = 0; i < listDocuments.length; i++) {
                let doc = listDocuments[i].chosenValue.split('.');
                InvestorService.unVerifyDocument(doc[0]).then(response => {
                });
                listDocuments[i].documentVerify = false;
            }
            this.setState({
                documentsGovIdBlock: listDocuments
            })

            let data = {
                blockName: "BLOCK_GOV_ID",
                date: "",
                flag: "verification"
            };

            InvestorService.changeActivationBlock(data, investorId)
                .then(response => {
                    this.setState({
                        dataGovIdBlock: false,
                        unVerifyGovId: false
                    });
                });

        }

        if (this.state.unVerifyHomeAddress) {
            let listDocuments = this.state.documentsHomeAddressBlock;
            for (let i = 0; i < listDocuments.length; i++) {
                let doc = listDocuments[i].chosenValue.split('.');
                InvestorService.unVerifyDocument(doc[0]).then(response => {
                });
                listDocuments[i].documentVerify = false;
            }
            this.setState({
                documentsHomeAddressBlock: listDocuments
            });
            let data = {
                blockName: "BLOCK_HOME_ADDRESS",
                date: "",
                flag: "verification"
            };

            InvestorService.changeActivationBlock(data, investorId);
            this.setState({
                dataHomeAddressBlock: false,
                unVerifyHomeAddress: false
            });
        }
    };

    saveExpirationDateChanges = (date, blockName) => {
        this.checkExpirationDate(date, blockName);

        if (blockName === "BLOCK_PASSPORT") {
            this.setState({
                expirationDatePassport: date
            }, () => {
                this.createActivationBlock(blockName)
            });
        }

        if (blockName === "BLOCK_GOV_ID") {
            this.setState({
                expirationDateGovId: date
            }, () => {
                this.createActivationBlock(blockName)
            });
        }

        if (blockName === "BLOCK_HOME_ADDRESS") {
            this.state.documentsHomeAddressBlock.forEach(value => {
                this.onVerifyDocuments(value, this.state.documentsHomeAddressBlock)
            })

            this.setState({
                expirationDateHomeAddress: date
            }, () => {
                this.createActivationBlock(blockName)
            });
        }
    };

    compareDate = (e) => {
        let date = new Date(e);
        let dateUtc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        dateUtc.setHours(0, 0, 0, 0);
        let now = new Date();
        let nowUtc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        nowUtc.setHours(0, 0, 0, 0)

        return dateUtc >= nowUtc
    };

    checkExpirationDate = (e, blockName) => {
        if (blockName === "BLOCK_HOME_ADDRESS") {
            if (e === "1") {
                this.setState({
                    expirationDateHomeAddressChecked: true
                })
            } else {
                this.setState({
                    expirationDateHomeAddressChecked: false
                })
            }
        } else {
            if (this.compareDate(e)) {
                if (blockName === "BLOCK_PASSPORT") {
                    this.setState({
                        expirationDatePassportChecked: true
                    })
                }

                if (blockName === "BLOCK_GOV_ID") {
                    this.setState({
                        expirationDateGovIdChecked: true
                    })
                }
            } else {
                if (blockName === "BLOCK_PASSPORT") {
                    this.setState({
                        expirationDatePassportChecked: false
                    })
                }

                if (blockName === "BLOCK_GOV_ID") {
                    this.setState({
                        expirationDateGovIdChecked: false
                    })
                }
            }
        }
    };

    checkVerificationDate = (e, blockName) => {
        let date = new Date(e);
        let dateUtc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        dateUtc.setHours(0, 0, 0, 0);
        dateUtc.setFullYear(dateUtc.getFullYear() + 2);
        let now = new Date();
        let nowUtc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        nowUtc.setHours(0, 0, 0, 0);

        if (dateUtc >= nowUtc) {
            if (blockName === "BLOCK_PASSPORT") {
                this.setState({
                    verificationDatePassportChecked: true
                })
            }

            if (blockName === "BLOCK_GOV_ID") {
                this.setState({
                    verificationDateGovIdChecked: true
                })
            }

            if (blockName === "BLOCK_HOME_ADDRESS") {
                this.setState({
                    verificationDateHomeAddressChecked: true
                })
            }
        } else {
            if (blockName === "BLOCK_PASSPORT") {
                this.setState({
                    verificationDatePassportChecked: false,
                })
            }

            if (blockName === "BLOCK_GOV_ID") {
                this.setState({
                    verificationDateGovIdChecked: false,
                })
            }

            if (blockName === "BLOCK_HOME_ADDRESS") {
                this.setState({
                    verificationDateHomeAddressChecked: false,
                })
            }
        }

    };

    saveDateChanges = (blockName, date) => {
        if (blockName === "BLOCK_HOME_ADDRESS") {
            this.state.documentsHomeAddressBlock.forEach(value => {
                this.onUnVerifyDocuments(value, this.state.documentsHomeAddressBlock)
            })

            this.setState({
                expirationDateHomeAddress: date,
                expirationDateHomeAddressChecked: false,
                verificationDateHomeAddressBlock: date,
                verificationDateHomeAddressChecked: false
            })
        }

        if (blockName === "BLOCK_GOV_ID") {
            this.setState({
                expirationDateGovId: date
            })
        }

        if (blockName === "BLOCK_PASSPORT") {
            this.setState({
                expirationDatePassport: date
            })
        }

        let data = {
            blockName: blockName,
            date: date,
            flag: "expiration"
        };

        this.checkExpirationDate(date, blockName);
        InvestorService.changeActivationBlock(data, this.props.investorId)
    };

    handleChange = (event) => {
        const _selectedRegTypes = this.state.selectedRegTypes ? this.state.selectedRegTypes : []
        const type = this.state.regTypes.find((element, index, array) => element.id.toString() === event.target.value)

        if (event.target.checked) {
            _selectedRegTypes.push(type)
        } else {
            const index = _selectedRegTypes.map(value => value.id).indexOf(type.id)
            _selectedRegTypes.splice(index, 1)
        }

        this.setState({selectedRegTypes: _selectedRegTypes}, () => this.pushRegTypes());
    };

    handleVerifiedBoxOnChange = (event, blockName) => {
        if (event.target.checked) {
            this.showDateModal(event, blockName)
        } else {
            this.openDeleteDateModel(event, blockName)
        }
    }

    pushRegTypes = () => {
        InvestorService.pushRegTypes(this.props.investor.id, this.state.selectedRegTypes)
            .then(data => {
                toast.success("Updated!");
            });
    };

    handleApproved = () => {
        this.props.approveStep('STEP_CONTACT_INFO')

        // this.props.approveInvestor(this.props.investor.user.email)
        // this.setState({
        //     accountStatus: 'ACTIVE'
        // })
    }

    render() {
        return (
            <>
                <PdfPreviewModal
                    className="modal"
                    show={this.state.showPdfPreview}
                    file={this.state.fileToPreview}
                    close={this.handlePdfClose}/>

                <ImagePreviewModal
                    className="modal"
                    show={this.state.showPreview}
                    file={this.state.fileToPreview}
                    close={this.handleClose}
                />

                <UnVerifyDocumentsModal1
                    show={this.state.isShowing1}
                    close={this.toggleSaveModal1}
                    closeForSubmit={this.closeForSubmit}
                    submit={this.openModal}
                />

                <UnVerifyDocumentsModal2
                    show={this.state.isShowing2}
                    close={this.toggleSaveModal2}
                    submit={this.saveChanges}
                />

                <PassportVerificationModal
                    show={this.state.showingPassport}
                    close={this.closeDateModal}
                    submit={this.saveExpirationDateChanges}
                    blockName={"BLOCK_PASSPORT"}
                    verifyDate={this.compareDate}
                />

                <GovIdVerificationModal
                    show={this.state.showingGovId}
                    close={this.closeDateModal}
                    submit={this.saveExpirationDateChanges}
                    blockName={"BLOCK_GOV_ID"}
                    verifyDate={this.compareDate}
                />

                <HomeAddressVerifyModal
                    show={this.state.showingHomeAddress}
                    close={this.closeDateModal}
                    submit={this.saveExpirationDateChanges}
                    blockName={"BLOCK_HOME_ADDRESS"}
                />

                <DeletePassportModal
                    show={this.state.showingDeletePassport}
                    close={this.closeDeleteDateModal}
                    submit={this.saveDateChanges}
                    blockName={"BLOCK_PASSPORT"}
                />

                <DeleteGovIdModal
                    show={this.state.showingDeleteGovId}
                    close={this.closeDeleteDateModal}
                    submit={this.saveDateChanges}
                    blockName={"BLOCK_GOV_ID"}
                />

                <HomeAddressUnVerifyModal
                    show={this.state.showingDeleteHomeAddress}
                    close={this.closeDeleteDateModal}
                    submit={this.saveDateChanges}
                    blockName={"BLOCK_HOME_ADDRESS"}
                />

                {
                    this.props.loader
                        // || this.state.loader
                        ? <Box style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: '50vh'
                        }}>
                            <CircularProgress/>
                        </Box>
                        : <Grid item lg={12}>
                            <Grid container spacing={3}>
                                <Grid item lg={12} xs={12}>
                                    <Card raised>
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item lg={9} xs={9}>
                                                    <Grid container spacing={5}>
                                                        <Grid item lg={2} xs={2} style={{margin: 'auto'}}>
                                                            <Typography>
                                                                Allowed reg types:
                                                            </Typography>

                                                            <Divider/>
                                                        </Grid>

                                                        <Grid item lg={10} xs={10}>
                                                            <FormControl component="fieldset"
                                                                         disabled={false
                                                                             // !this.state.dataHomeAddressBlock
                                                                             // || !this.state.dataKycAmlBlock
                                                                             // || !(this.state.dataGovIdBlock
                                                                             //     || this.state.dataPassportBlock)
                                                                         }>
                                                                <FormGroup style={{display: 'inline'}}>
                                                                    {
                                                                        this.state.regTypes.map(type => {
                                                                            return <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={
                                                                                            this.state.selectedRegTypes
                                                                                            && this.state.selectedRegTypes
                                                                                                .some(node => {
                                                                                                    return node.id === type.id
                                                                                                })
                                                                                        }
                                                                                        onChange={(e) => this.handleChange(e)}
                                                                                        value={type.id}
                                                                                        name={type.name}/>
                                                                                }
                                                                                label={type.name}
                                                                            />
                                                                        })
                                                                    }
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item lg={3} xs={3}
                                                      style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    <Button
                                                        variant='contained'
                                                        color={"primary"}
                                                        style={{
                                                            margin: 15
                                                        }}
                                                        startIcon={<ClearIcon/>}
                                                        disabled={
                                                            this.state.step?.status !== 'APPROVED' ||
                                                            (this.state.selectedRegTypes
                                                                && this.state.selectedRegTypes.length === 0)
                                                            // || !this.state.dataHomeAddressBlock
                                                            // || !this.state.dataKycAmlBlock
                                                            // || !(this.state.dataGovIdBlock
                                                            //     || this.state.dataPassportBlock)
                                                        }
                                                        onClick={(e) => this.props.rejectStep('STEP_CONTACT_INFO')}>
                                                        Reject
                                                    </Button>

                                                    <Button
                                                        variant='contained'
                                                        color='secondary'
                                                        style={{margin: 15}}
                                                        startIcon={<CheckIcon/>}
                                                        disabled={
                                                            this.state.step?.status === 'APPROVED'
                                                            || (this.state.selectedRegTypes
                                                                && this.state.selectedRegTypes.length === 0)
                                                            // || !this.state.dataHomeAddressBlock
                                                            // || !this.state.dataKycAmlBlock
                                                            // || !(this.state.dataGovIdBlock
                                                            //     || this.state.dataPassportBlock)
                                                        }
                                                        onClick={() => this.handleApproved()}>
                                                        {
                                                            this.state.step?.status === 'APPROVED'
                                                                ? 'Approved'
                                                                : 'Approve'
                                                        }
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <Grid container
                                          justify={"space-between"}>
                                        <Grid item lg={4} xs={4}>
                                            <Typography>
                                                {'Date Registration Finished: '}

                                                <Moment
                                                    format={'L LT'}>{this.props.investor.user.dateRegistrationCompleted}
                                                </Moment>
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={4} xs={4}
                                              style={{textAlign: 'end'}}>
                                            <Button
                                                variant='contained'
                                                color={this.state.wordingFreeze === 'Freeze' ? 'secondary' : 'primary'}
                                                onClick={this.changeStateFreeze}>
                                                {this.state.wordingFreeze} Information Fields
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item lg={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12}>
                                            <Card raised
                                                  style={{overflow: 'unset'}}>
                                                <CardContent>
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={12} xs={12}>
                                                            <Typography variant='h6'>
                                                                Main Information
                                                            </Typography>
                                                            <Divider/>
                                                        </Grid>

                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                First Name *
                                                            </Typography>
                                                            <Typography variant="caption">Legal Name as it appears
                                                                on your
                                                                ID</Typography>
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <Text
                                                                defaultValue={this.props.investor.user.firstName}
                                                                id={"firstName"}
                                                                attributeCode="FIRST_NAME_ON_ID"
                                                                onBlurHandler={this.props.pushInvestorChange}
                                                                flagDisabled={this.state.freezeInputs}
                                                            />
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>Last Name *</Typography>
                                                            <Typography variant="caption">
                                                                Legal Name as it appears on your ID
                                                            </Typography>

                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <Text
                                                                defaultValue={this.props.investor.user.lastName}
                                                                id={"lastName"}
                                                                attributeCode="LAST_NAME_ON_ID"
                                                                onBlurHandler={this.props.pushInvestorChange}
                                                                className="form-control input-lg"
                                                                flagDisabled={this.state.freezeInputs}
                                                            />
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                DOB (US Style) *
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <DobUsWithCheck attributeCode="DOB_ID"
                                                                            onChangeHandler={this.props.pushInvestorChange}
                                                                            defaultValue={this.props.investorData['DOB_ID']}
                                                                            months={this.state.months}
                                                                            years={this.state.years}
                                                                            flagDisabled={this.state.freezeInputs}/>
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                US Social Security Number
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <TextPassword
                                                                defaultValue={this.props.investorData['SSN']}
                                                                type={this.state.typeSsn}
                                                                id={"ssn"}
                                                                attributeCode="SSN"
                                                                onBlurHandler={this.props.pushInvestorChange}
                                                                flagDisabled={this.state.freezeInputs}
                                                            />
                                                        </Grid>

                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>
                                                                Phone Number
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <InputPhoneField
                                                                name={'phone'}
                                                                value={this.props.investor.user.phoneNumber}
                                                                disabled={this.state.freezeInputs}
                                                            />
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <Typography>Email</Typography>
                                                        </Grid>
                                                        <Grid item lg={6} xs={6}>
                                                            <Text
                                                                defaultValue={this.props.investor.user.email}
                                                                id={"lastName"}
                                                                flagDisabled={this.state.freezeInputs}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                }
            </>
        )
    }
}