import React, {useEffect, useState} from "react";

import UserService from "../../services/UserService";
import IssuerFullEdit from "../Issuers/IssuerFullEdit";
import InvestorEdit from "../Investors/InvestorEdit";
import InvestorCompanyEdit from "../Investors/InvestorCompanyEdit";
import AdminEdit from "../admin/AdminEdit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const UserDetails = (props) => {

    const [user, setUser] = useState()

    useEffect(() => {
        fetchUser(props.match.params.id)
    }, [])

    const fetchUser = (id) => {
        UserService.fetchUser(id)
            .then(response => {
                setUser(response.data)
            })
            .catch(error => {

            })
    }

    const getPage = () => {
        switch (user && user.userGroup && user.userGroup.id) {
            case 'USER_ADMIN':
            case 'USER_SUPER_ADMIN':
                return (
                    <AdminEdit
                        {...props}
                        userId={user.id}
                    />
                )
            case 'USER_ISSUER':
                //props.history.push("/issuers/edit/" + user.id + "?userId=true")
                return (
                    <IssuerFullEdit
                        {...props}
                        userId={user.id}
                    />
                )
            case 'USER_INVESTOR_COMPANY':
                //props.history.push("/investors/company/edit/" + user.id)

                return (
                    <InvestorCompanyEdit
                        {...props}
                        userId={user.id}
                    />
                )
            case 'USER_INVESTOR_INDIVIDUAL':
                //props.history.push({pathname: "/investors/individual/edit/" + user.id, state: {data: user}})
                return (
                    <InvestorEdit
                        {...props}
                        userId={user.id}
                    />
                )
            default:
                return (
                    <Grid container style={{paddingTop: 80}}>
                        <Grid item lg={12} xs={12}>
                            <Typography variant={"h6"}>
                                User didn't select User Type: Company or Individual
                            </Typography>
                        </Grid>
                    </Grid>
                )
        }
    }


    return (
        getPage()
    )
}

export default UserDetails