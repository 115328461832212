import React, {useEffect, useState} from 'react';

import {CardContent, Grid, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import {Cell, Pie, PieChart, ResponsiveContainer} from 'recharts';
import {makeStyles} from "@material-ui/styles";


const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '16px',
        textTransform: 'uppercase'
    },

}));


const COLORS = ['#032F66', '#486890', '#91A4BC'];

// const RADIAN = Math.PI / 180;

// const renderCustomizedLabel = ({
//                                    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
//                                }) => {
//     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//     const x = cx + radius * Math.cos(-midAngle * RADIAN);
//     const y = cy + radius * Math.sin(-midAngle * RADIAN);
//
//     return (
//         <text
//             x={x}
//             y={y}
//             fill="white"
//             textAnchor={x > cx ? 'start' : 'end'}
//             dominantBaseline="central">
//
//             {`${(percent * 100).toFixed(0)}%`}
//
//         </text>
//     );
// };

export const OfferingStatusDashboard = (props) => {

    const [data, setData] = useState([])

    useEffect(() => {
        setData(
            [
                {name: 'Group A', value: props.dealsData.dealsPending},
                {name: 'Group B', value: props.dealsData.dealsActive},
                {name: 'Group C', value: props.dealsData.dealsFinished},
            ]
        )
    }, [props.dealsData])


    const classes = useStyles();
    const dataCount = [
        {
            name: 'Pending',
            count: props.dealsData.dealsPending ? props.dealsData.dealsPending : 0,
            color: '#032F66'
        },

        {
            name: 'Active',
            count: props.dealsData.dealsActive ? props.dealsData.dealsActive : 0,
            color: '#486890'
        },

        {
            name: 'Finished',
            count: props.dealsData.dealsFinished ? props.dealsData.dealsFinished : 0,
            color: '#91A4BC'
        },
    ]


    return (
        <Card raised
              style={{height: 500}}>
            <CardContent>
                <Typography
                    className={classes.header}
                    variant="h6">
                    Offering Status
                </Typography>

                <div style={{height: 350}}>
                    <ResponsiveContainer>
                        <PieChart
                            width={100}
                            height={100}
                        >
                            <Pie
                                data={data}
                                labelLine={true}
                                outerRadius={150}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`}
                                              fill={COLORS[index % COLORS.length]}
                                        />
                                    )
                                )}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>

                <Grid container>
                    {dataCount.map((item) => {
                        return (
                            <Grid key={item.name}
                                  item xs={4} lg={4}>
                                <Box style={{
                                    width: 25,
                                    height: 25,
                                    WebkitBorderRadius: 12.5,
                                    MozBorderRadius: 12.5,
                                    borderRadius: 12.5,
                                    backgroundColor: item.color,
                                    margin: 'auto'
                                }}>
                                </Box>
                                <Box style={{textAlign: 'center'}}>
                                    <Typography variant="h5">
                                        {item.count}
                                    </Typography>
                                </Box>

                                <Box style={{
                                    color: '#546E7A',
                                    fontSize: '12px',
                                    textAlign: 'center',
                                    textTransform: 'uppercase'
                                }}>
                                    {item.name}
                                </Box>
                            </Grid>
                        )
                    })}
                </Grid>
            </CardContent>
        </Card>
    )

}