import axios from '../custom-axios/backoffice';

const SettingService = {
    transform: (requests) => {
        return requests.map(request => {
            return {
                id: request.id ? request.id : '',
                name: request.name ? request.name : '',
                settingGroup: request.settingGroup ? request.settingGroup.name : '',
                ips: request.settingData.filter(data => data.attribute.code === 'ALLOWED_IP_ADDRESSES')[0],
            }
        })
    },

    createSetting: (request) => {
        return axios.post('/api/settings', request)
    },

    updateDataSetting: (request) => {
        return axios.put('/api/settings/data', request)
    },

    fetchSettings: (sort, page, pageSize, filter) => {
        let filterOpt = [];

        if (filter) {
            filterOpt = Object.keys(filter).map(function (key) {
                if (filter[key] !== undefined && filter[key] !== '') return '&' + key + '=' + filter[key]

                return ''
            })
        }

        return axios.get(`/api/settings?sort=${sort}&page=${page}&pageSize=${pageSize}` + filterOpt.join(''));
    },
}

export default SettingService