import React from 'react';

const select = (props) => {
    const onChangeHandler = (attributeCode, investorAttributeOptionId, options) => {
        let value;
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                value = options[i].innerHTML.trim();
                break;
            }
        }
        props.onChange({
            attributeCode: attributeCode,
            investorAttributeOptionId: investorAttributeOptionId,
            value: value
        });
    }


    return (
        <select
            disabled={props.options.length === 0 || props.flagDisabled ? props.flagDisabled : false}
            onChange={(e) => {
                onChangeHandler(props.attributeCode, e.target.value, e.target.options)
            }}
            className="select__custom--big"
            data-live-search={props.dataLiveSearch}
            data-style={props.dataStyle}
            data-width={props.dataWidth}
        >
            {props.options.map(option => {
                return (
                    <option
                        key={option.id}
                        value={option.id}
                        selected={props.selectedValue ? option.id === props.selectedValue['chosenOptionId'] : false}>
                        {option.value}
                    </option>
                );
            })}

        </select>
    );
};

export default select;