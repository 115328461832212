import React, {Component} from 'react';
import "./css/IssuersDashboard.css"
import InvestmentService from "../services/InvestmentService";
import RegTypeReportService from "../services/RegTypeReportService";
import Grid from "@material-ui/core/Grid";
import {TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import Moment from "react-moment";
import LinearProgress from "@material-ui/core/LinearProgress";

class InvestorsIssuerPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            investments: [],
            regTypeReport: [],
            loader: true,
            columnNames: [
                {
                    id: 'dateCreated',
                    label: 'Investment Submitted Date',
                    type: 'date',
                    datePattern: "L LT"
                },
                {
                    id: 'dateApproved',
                    label: 'Investment Approved Date',
                    type: 'date',
                    datePattern: "L LT"
                },
                {
                    id: 'dealCode',
                    label: 'Deal Code'
                },
                {
                    id: 'status',
                    label: 'Deal Status',
                    type: 'splitDealStatus'
                },
                {
                    id: 'investmentAmount',
                    label: 'Investment Amount'
                },
                {
                    id: 'payType',
                    label: 'Pay Type'
                },
                {
                    id: 'investorFirstName',
                    label: 'Investor First Name'
                },
                {
                    id: 'investorLastName',
                    label: 'Last Name'
                },
                {
                    id: 'investorEmail',
                    label: 'Investor Email'
                },
            ]

        }
    }

    componentDidMount() {
        document.body.setAttribute('class', 'dashboard-body');
        this.loadData();

    }

    loadData = () => {
        if (!this.props.dealId) {
            this.loadInvestments();
            this.loadRegTypeReport();
        } else {
            this.loadRegTypeReportPerDeal(this.props.dealId);
            this.loadInvestmentsPerDeal(this.props.dealId);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.dealId !== prevProps.dealId) {
            this.loadData();
        }
    }

    loadInvestments = () => {

        this.setState({
            loader: true
        })
        InvestmentService.fetchInvestmentsPerIssuer(this.props.issuerId).then((response) => {
            this.setState({
                investments: response.data,
                loader: false
            });
        })
    }

    loadRegTypeReport = () => {
        RegTypeReportService.getByIssuerId(this.props.issuerId).then(response => {
            let res = new Map();
            response.data.forEach(item => {
                if (res.has(item.name)) {
                    res.get(item.name).push(item);
                } else {
                    res.set(item.name, [item]);
                }
            });
            this.setState({regTypeReport: res})
        })
    }

    loadRegTypeReportPerDeal = (dealId) => {
        RegTypeReportService.getByDealId(dealId).then(response => {
            let res = new Map();
            response.data.forEach(item => {
                if (res.has(item.name)) {
                    res.get(item.name).push(item);
                } else {
                    res.set(item.name, [item]);
                }
            });
            this.setState({regTypeReport: res})
        })
    }

    loadInvestmentsPerDeal = (dealId) => {
        this.setState({
            loader: true
        })
        InvestmentService.fetchInvestmentsPerDeal(dealId).then((response) => {
            this.setState({
                investments: response.data,
                loader: false
            });
        })
    }
    editText = (value) => {
        let fullText = [];

        let textArray = value.split('_');
        for (let i = 0; i < textArray.length; i++) {
            fullText.push(textArray[i][0] + textArray[i].toLowerCase().slice(1))
        }
        return fullText.join(" ")
    }


    generateColumn(col, row) {
        switch (col.type) {
            case 'splitDealStatus':
                return <>{row[col.id] && <span>{this.editText(row.status)}</span>}</>;
            case 'date':
                return <>{!row[col.id]}
                    {row[col.id] && <Moment format={col.datePattern}>{row[col.id]}</Moment>}</>;
            default:
        }

        return (row[col.id]);
    }

    loadChartDataPerIssuer = () => {

    }

    render() {
        // let regTypes = Array.from(this.state.regTypeReport).map((value, key) => {
        //     return (
        //         <>
        //             <RegTypeComponent name={value[0]} items={value[1]}/>
        //         </>
        //     )
        // })
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {this.state.columnNames.map(col => {
                                            return (<TableCell>{col.label}</TableCell>)
                                        })}
                                    </TableRow>
                                </TableHead>
                                {this.state.loader ?
                                    <LinearProgress
                                        variant="indeterminate"
                                        color="primary"
                                        style={{display: 'table-caption'}}
                                    />
                                    :
                                    <TableBody>
                                        {
                                            this.state.investments.map((row) =>
                                                (
                                                    <TableRow>
                                                        {this.state.columnNames.map(col => {
                                                            return (
                                                                <TableCell>
                                                                    {this.generateColumn(col, row)}
                                                                </TableCell>
                                                            )

                                                        })}
                                                    </TableRow>
                                                )
                                            )
                                        }
                                    </TableBody>}
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default InvestorsIssuerPage;