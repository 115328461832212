import React, {Component} from 'react';
import InvestorService from "../../services/InvestorService";
import CrudService from "../../services/CrudService";
import validationRegTypes from "../../components/input/ValidationRegTypes";
import ConfirmNewInvestmentModal from "./ConfirmNewInvestmentModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";

class CreateInvestmentModalNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            investors: [],
            investor: "",
            investorId: '',
            userId: '',
            page: 1,
            pagePagination: 1,
            totalPages: 0,
            maxPageListSize: 5,
            pageList: [],
            pageSize: 10,
            county: '',
            dob: '',
            regTypes: [],
            allowedRegTypes: [],
            deals: [],
            dealId: '',
            regTypeId: '',
            payTypeId: '',
            amount: '',
            currentDealId: 0
        };
    }

    componentDidMount() {
        this.fetchInvestors();
    };

    fetchInvestors = () => {
        CrudService.fetch("rest/investor", "", this.state.page - 1, this.state.pageSize, {}, true)
            .then(response => {
                    this.setState({
                        investors: response.data.content
                    });
                    if (this.state.totalPages !== response.data.totalPages) {
                        this.setState({
                            // data: this.props.transform(response.data.content),
                            totalPages: response.data.totalPages,

                        }, () => {
                            this.generatePageList();
                        });
                    }
                }
            )
    };

    onClickSelect = (e) => {
        let listElement = document.querySelector('.select--element--custom--list-wrap');
        listElement.classList.toggle('active')
    };

    onClickDeal = (e) => {
        let dealId = +e.target.value;
        let deals = this.props.deals;
        let regTypes = [];
        for (let i = 0; i < deals.length; i++) {
            if (deals[i].id === dealId) {
                if (deals[i].regTypes.length === 0) {
                    alert("This offering has no Reg Types.");
                }
                regTypes = deals[i].regTypes;

                this.setState({
                    dealId: dealId
                });
                break
            }
        }
        this.setState({
            regTypes: regTypes,
            currentDealId: dealId
        });
    };

    onClickUser = (e) => {
        if (e.target.value === '') {
            this.setState({
                investor: '',
                userId: '',
                allowedRegTypes: [],
                investorId: '',
                dob: ''
            })
        } else {
            let investor = this.state.investors.find(i => i.id === +e.target.value)
            this.setState({
                investorId: investor.id,
                userId: investor.user.id,
                investor: investor
            });
            if (investor.accreditationBlock) {
                if (investor.accreditationBlock.regType) {
                    let allowedRegTypes = [];
                    this.state.regTypes.forEach(reg => {
                        if (reg.name.indexOf(investor.accreditationBlock.regType) !== -1) {
                            allowedRegTypes.push(reg);
                        }
                    });
                    this.setState({
                        allowedRegTypes: allowedRegTypes
                    });
                    if (allowedRegTypes.length !== 0) {
                        InvestorService.fetchByAttributeCodeAndInvestorId('DOB_ID', investor.id).then(res => {
                            let dob;
                            if (res.data[0] && res.data[0].chosenValue) {
                                dob = this.changeDob(res.data[0].chosenValue);
                            } else {
                                dob = "";
                            }
                            this.setState({
                                dob: dob
                            })
                        });
                        InvestorService.fetchByAttributeCodeAndInvestorId('COUNTRY_ID', investor.id).then(res => {
                            let country;
                            if (res.data[0] && res.data[0].chosenValue) {
                                country = res.data[0].chosenValue
                            } else {
                                country = ""
                            }
                            this.setState({
                                country: country
                            })
                        });
                    } else {
                        this.setState({
                            investor: '',
                            userId: '',
                            allowedRegTypes: []
                        }, () => {
                            // this.fetchInvestors();
                        });
                        validationRegTypes("User is not qualified for this investment.\nREASON: the User does not qualify for the Offering's Reg Type.\nPlease check their profile.")
                    }
                } else {
                    validationRegTypes("User is not qualified for this investment.\nREASON: the User does not qualify for the Offering's Reg Type.\nPlease check their profile.")
                }
            } else {
                this.setState({
                    investor: '',
                    userId: '',
                    allowedRegTypes: []
                }, () => {
                    // this.fetchInvestors();
                });
                validationRegTypes("User is not qualified for this investment.\nREASON: the User is not accredited. Please check their profile.")
            }
        }
    };

    onClickRegType = (e) => {
        this.setState({
            regTypeId: +e.target.value
        })
    };


    changeDob = (e) => {
        let date = new Date(e);
        let options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return date.toLocaleDateString(options);
    };

    // createDate() {
    //     let now = new Date();
    //     let options = {
    //         year: 'numeric',
    //         month: 'long',
    //         day: 'numeric',
    //         timezone: 'UTC',
    //         hour: 'numeric',
    //         minute: 'numeric',
    //     };
    //     return now.toLocaleDateString("en-US", options);
    // }


    handleFormSubmit = () => {
        this.props.submit({
            'dealId': this.state.dealId,
            'investorId': this.state.investorId,
            'regTypeId': this.state.regTypeId,
            'paymentTypeId': this.state.payTypeId,
            'amount': this.state.amount,
            'country': this.state.country
        });
        this.setState({
            investor: '',
            userId: '',
            page: 1,
            allowedRegTypes: [],
            currentDealId: 0,
            regTypes: [],
            amount: ''
        }, () => {
            this.fetchInvestors();
        });
        this.props.close();
    };

    onClose = () => {
        this.setState({
            investor: '',
            userId: '',
            page: 1,
            allowedRegTypes: [],
            currentDealId: 0,
            regTypes: [],
            amount: ""
        }, () => {
            this.fetchInvestors();
        });
        this.props.close();
    };


    onChangeMoney = (e) => {
        let value = e.target.value;
        let input1;
        let input2 = "";
        if (value.split(".")[1] || value.split(".")[1] === "") {
            input1 = value.split(".")[0];
            if (value.split(".")[1]) {
                let newInput2 = value.split(".")[1].replace(/[\D\s_]+/g, "");
                if (newInput2.length > 8) {
                    input2 = "." + newInput2.slice(0, -1)

                } else {
                    input2 = "." + newInput2;
                }
            } else {
                input2 = ".";
            }
        } else {
            input1 = value
        }
        let newInput1 = input1.replace(/[\D\s_]+/g, "");
        newInput1 = newInput1 ? parseInt(newInput1, 10) : 0;
        let newInput1Final;
        if (newInput1 === 0) {
            newInput1Final = '';
        } else {
            newInput1Final = newInput1.toLocaleString("en-US", {maximumFractionDigits: 8});
        }
        let newMoney = newInput1Final + input2;


        // let doc = document.getElementById('amount');
        // doc.value = newMoney;
        this.setState({
            amount: newMoney
        });
    };

    generatePageList() {
        let initialPageList = [];
        for (let i = 1; i <= Math.min(this.state.totalPages, this.state.maxPageListSize); i++) {
            initialPageList.push(i);
        }
        this.setState({
                pageList: initialPageList
            }, () => {
            }
        )
    }

    firstPage() {
        if (this.state.page > 1) {
            this.setState((prevState) => ({
                page: 1,
            }), () => {
                this.generatePageList();
                this.fetchInvestors();
            });
        }
    }

    lastPage() {
        if (this.state.page < this.state.totalPages) {
            let oldPage = this.state.page;
            this.setState((prevState) => ({
                page: this.state.totalPages
            }), () => {
                let newPageList = [...this.state.pageList];
                for (let i = oldPage; i <= this.state.totalPages; i++) {
                    newPageList.push(i);
                    newPageList.shift();
                    this.setState({
                        pageList: newPageList
                    })
                }
                this.fetchInvestors();
            });
        }
    }

    nextPage() {
        if (this.state.page < this.state.totalPages) {
            this.setState((prevState) => ({
                page: prevState.page + 1
            }), () => {
                if (this.state.page > this.state.pageList[this.state.pageList.length - 1]) {
                    let newPageList = [...this.state.pageList];
                    newPageList.push(this.state.page);
                    newPageList.shift();
                    this.setState({
                        pageList: newPageList
                    })
                }
                this.fetchInvestors();
            });
        }
    }

    previousPage() {
        if (this.state.page > 1) {
            this.setState((prevState) => ({
                page: prevState.page - 1
            }), () => {
                if (this.state.page < this.state.pageList[0]) {
                    let newPageList = [...this.state.pageList];
                    newPageList.unshift(this.state.page);
                    newPageList.pop();
                    this.setState({
                        pageList: newPageList
                    })
                }
                this.fetchInvestors();
            });
        }
    }

    setPage(page) {
        this.setState({
            page: page
        }, () => {
            this.fetchInvestors()
        });

    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({
            payTypeId: +e.target.payType.value,
            // amount: e.target.amount.value
        }, () => {
            this.setState({
                showModal: true
            })
        })
    };

    closeModal = () => {
        this.setState({
            showModal: false
        })

    };

    render() {
        return (
            <>
                <ConfirmNewInvestmentModal
                    show={this.state.showModal}
                    close={this.closeModal}
                    submit={this.handleFormSubmit}
                    data={this.state.data}/>


                <Dialog
                    open={this.props.show}
                    onClose={this.onClose}
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">


                    <form onSubmit={(e) => this.showModal(e)}>

                        <DialogTitle id="alert-dialog-title">ADD NEW INVESTMENT</DialogTitle>

                        <DialogContent style={{overflow: 'hidden'}}>
                            <Grid container spacing={3}>
                                <Grid item lg={4} xs={4}>
                                    <Typography>Offering</Typography>
                                </Grid>
                                <Grid item lg={8} xs={8}>
                                    <select
                                        className="select__custom--big"
                                        name={'deal'}
                                        required={true}
                                        onChange={(e) => {
                                            this.onClickDeal(e)
                                        }}>

                                        <option selected>
                                        </option>
                                        {
                                            this.props.deals.map(d => {
                                                return (<option
                                                    selected={this.state.currentDealId === d.id}
                                                    key={d.name}
                                                    value={d.id}>{d.name} </option>)
                                            })
                                        }

                                    </select>
                                </Grid>
                            </Grid>

                            {this.state.regTypes.length !== 0 ?
                                <Grid container spacing={3}>
                                    <Grid item lg={4} xs={4}>
                                        <Typography>User</Typography>
                                    </Grid>
                                    <Grid item lg={8} xs={8}>
                                        <select
                                            className="select__custom--big"
                                            onChange={(e) =>
                                                this.onClickUser(e)
                                            }>
                                            <option/>
                                            {this.state.investors.map(investor => {
                                                return (<option key={investor.id}
                                                                value={investor.id}
                                                > {investor.user.firstName + " " + investor.user.lastName} </option>)
                                            })}
                                        </select>
                                    </Grid>
                                    <Grid lg={12} xs={12}>
                                        <Box width="100%" display="flex" justifyContent="center">
                                            <Box mb={2} display="flex" alignItems="center">
                                                <Button
                                                    size="small"
                                                    onClick={() => this.firstPage()}>
                                                    <FirstPageIcon/>
                                                </Button>

                                                <Button
                                                    size="small"
                                                    onClick={() => this.previousPage()}>
                                                    <ChevronLeftIcon/>
                                                </Button>

                                                {
                                                    this.state.pageList.map(item => {
                                                        return (
                                                            <Box px={2} onClick={() => this.setPage(item)}>
                                                                <Box
                                                                    onClick={() => this.setPage(item)}
                                                                    className={item === this.state.page ? 'paginationActive' : 'paginationPassive'}>{item}</Box>
                                                            </Box>
                                                        );
                                                    })
                                                }

                                                <Button
                                                    size="small"
                                                    onClick={() => this.nextPage()}>
                                                    <ChevronRightIcon/>
                                                </Button>

                                                <Button
                                                    size="small"
                                                    onClick={() => this.lastPage()}>
                                                    <LastPageIcon/>
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid> : null}

                            {this.state.regTypes.length !== 0 && this.state.allowedRegTypes.length !== 0 ?
                                <Grid container spacing={3}>
                                    <Grid item lg={12} xs={12}>
                                        <Divider/>
                                        <Typography>{this.state.investor !== "" ? this.state.investor.user.firstName + " " + this.state.investor.user.lastName : null}</Typography>
                                        <Typography>DOB {this.state.dob}</Typography>
                                        <Typography>{this.state.country}</Typography>
                                        <Typography>Reg Types
                                            Allowed: {this.state.investor && this.state.investor.accreditationBlock ? this.state.investor.accreditationBlock.regType : null}</Typography>
                                        <Divider/>
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <Typography>Investment Reg Type</Typography>
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <select className="select__custom--big"
                                                name={'deal'}
                                                required={true}
                                                onChange={(e) => {
                                                    this.onClickRegType(e)
                                                }}>
                                            <option/>
                                            {
                                                this.state.allowedRegTypes.map(r => {
                                                    return (<option key={r.id}
                                                                    value={r.id}> {r.name} </option>)
                                                })
                                            }
                                        </select>
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <Typography>Currency</Typography>
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <select className="select__custom--big"
                                                name={'payType'}
                                                required={true}>
                                            <option/>
                                            {this.props.payTypes.map(t => {
                                                return (
                                                    <option key={t.id}
                                                            value={t.id}> {t.type} </option>);
                                            })}
                                            {/*<option value={'WIRE_TRANSFER'}>Wire transfer</option>
                                        <option value={'BLOCKCHAIN'}>Blockchain</option>*/}
                                        </select>
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <Typography>Amount</Typography>
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <input name={'amount'}
                                               id={'amount'}
                                               type="text"
                                               style={{fontSize: 16}}
                                               className="input__custom--big"
                                               required={true}
                                               value={this.state.amount}
                                               onChange={(e) => this.onChangeMoney(e)}
                                        />
                                    </Grid>

                                </Grid> : null}


                            {/*<select*/}
                            {/*    className="select__custom--big"*/}
                            {/*    name={'deal'}*/}
                            {/*    required={true}*/}
                            {/*    onChange={(e) => {*/}
                            {/*        this.onClickDeal(e)*/}
                            {/*    }}>*/}

                            {/*    <option selected>*/}
                            {/*    </option>*/}
                            {/*    {*/}
                            {/*        this.props.deals.map(d => {*/}
                            {/*            return (<option*/}
                            {/*                selected={this.state.currentDealId === d.id}*/}
                            {/*                key={d.name}*/}
                            {/*                value={d.id}>{d.name} </option>)*/}
                            {/*        })*/}
                            {/*    }*/}

                            {/*</select>*/}


                            {/*{this.state.regTypes.length !== 0 ?*/}
                            {/*    <div>*/}

                            {/*<Typography variant="subtitle2">User</Typography>*/}


                            {/*<div className="select__custom--big"*/}
                            {/*     onClick={(e) => {*/}
                            {/*         this.onClickSelect(e)*/}
                            {/*     }}>{this.state.investor ? this.state.investor.user.firstName + " " + this.state.investor.user.lastName : null}</div>*/}

                            {/*<div*/}
                            {/*    className="select--element--custom--list-wrap select--element--style">*/}
                            {/*    <ul className="select--element--custom--list padding-inline-start">*/}
                            {/*        {*/}
                            {/*            (this.state.investors ? this.state.investors.map(invest => {*/}
                            {/*                return (<option key={invest.id}*/}
                            {/*                                value={invest.user.id}*/}
                            {/*                                className="block-flight padding-top-10"*/}
                            {/*                    // onClick={(e) => {*/}
                            {/*                    //     this.onClickUser(e)*/}
                            {/*                    // }}*/}
                            {/*                > {invest.user.firstName + " " + invest.user.lastName} </option>)*/}
                            {/*            }) : null)*/}
                            {/*        }*/}
                            {/*    </ul>*/}

                            {/*    <div className="wrap__custom--btn">*/}
                            {/*        /!*<button className="custom--btn">Prev</button>*!/*/}
                            {/*        /!*<button className="custom--btn">Next</button>*!/*/}
                            {/*        <div className="text-center">*/}

                            {/*            <nav aria-label="Page navigation">*/}

                            {/*                <ul className="pagination">*/}

                            {/*                    <li onClick={() => this.firstPage()}>*/}

                            {/*                        <a aria-label="Previous"*/}
                            {/*                           className="color-grey">*/}
                            {/*        <span aria-hidden="true">*/}
                            {/*          <i className="fal fa-chevron-double-left"> </i>*/}
                            {/*        </span>*/}
                            {/*                        </a>*/}
                            {/*                    </li>*/}
                            {/*                    <li onClick={() => this.previousPage()}>*/}
                            {/*                        <a aria-label="Previous" className="color-grey">*/}
                            {/*          <span aria-hidden="true">*/}
                            {/*            <i className="fal fa-chevron-left"></i>*/}
                            {/*          </span>*/}
                            {/*                        </a>*/}
                            {/*                    </li>*/}
                            {/*                    {*/}
                            {/*                        this.state.pageList.map(item => {*/}
                            {/*                            return (*/}
                            {/*                                <li onClick={() => this.setPage(item)}>*/}
                            {/*                                    <a className={item === this.state.page ? 'active' : ''}>{item}</a>*/}
                            {/*                                </li>*/}
                            {/*                            );*/}
                            {/*                        })*/}
                            {/*                    }*/}

                            {/*                    <li onClick={() => this.nextPage()}>*/}
                            {/*                        <a aria-label="Next"*/}
                            {/*                           className="color-grey">*/}
                            {/*                                                    <span aria-hidden="true">*/}
                            {/*                                                        <i*/}
                            {/*                                                            className="fal fa-chevron-right"> </i>*/}
                            {/*                                                    </span>*/}
                            {/*                        </a>*/}
                            {/*                    </li>*/}
                            {/*                    <li onClick={() => this.lastPage()}>*/}
                            {/*                        <a aria-label="Next"*/}
                            {/*                           className="color-grey">*/}
                            {/*                                                    <span aria-hidden="true">*/}
                            {/*                                                        <i*/}
                            {/*                                                            className="fal fa-chevron-double-right"> </i></span>*/}
                            {/*                        </a>*/}
                            {/*                    </li>*/}
                            {/*                </ul>*/}
                            {/*            </nav>*/}
                            {/*        </div>*/}
                            {/*        /!*<div className='text-center padding-bottom-5'>*!/*/}
                            {/*        /!*    <input*!/*/}
                            {/*        /!*        onChange={this.onChangeGetSpecifiedPage}*!/*/}
                            {/*        /!*        className={"form-control-pagination"}*!/*/}
                            {/*        /!*        type={"number"}/>*!/*/}
                            {/*        /!*    <button*!/*/}
                            {/*        /!*        className={"btn btn-orange btn-pagination"}*!/*/}
                            {/*        /!*        onClick={this.currentPaginationPage}*!/*/}
                            {/*        /!*    >Go*!/*/}
                            {/*        /!*    </button>*!/*/}
                            {/*        /!*</div>*!/*/}

                            {/*    </div>*/}
                            {/*</div>*/}


                            {/*    {this.state.allowedRegTypes.length !== 0 ?*/}
                            {/*        <div>*/}
                            {/*            <div className="inline-block">*/}
                            {/*                <label*/}
                            {/*                    className="weight400 col-xs-12 col-sm-4">{this.state.investor !== "" ? this.state.investor.user.firstName + " " + this.state.investor.user.lastName : null}</label>*/}
                            {/*            </div>*/}
                            {/*            <div className="inline-block">*/}
                            {/*                <label*/}
                            {/*                    className="weight400 col-xs-12 col-sm-4">DOB {this.state.dob}</label>*/}
                            {/*            </div>*/}
                            {/*            <div className="inline-block">*/}
                            {/*                <label*/}
                            {/*                    className="weight400 col-xs-12 col-sm-4">{this.state.country}</label>*/}
                            {/*            </div>*/}
                            {/*            <div className="inline-block">*/}
                            {/*                <label*/}
                            {/*                    className="weight400 col-xs-12 col-sm-4 width-35">Reg*/}
                            {/*                    Types*/}
                            {/*                    Allowed: {this.state.investor && this.state.investor.accreditationBlock ? this.state.investor.accreditationBlock.regType : null}*/}
                            {/*                </label>*/}
                            {/*            </div>*/}

                            {/*            <div className="inline-block margin-top-20">*/}
                            {/*                <label*/}
                            {/*                    className="weight400 col-xs-12 col-sm-4 width-35 text-uppercase">Investment*/}
                            {/*                    Reg Type</label>*/}
                            {/*                <div className="col-sm-15 padding-top-0 padding-right-20">*/}
                            {/*                    <select className="form-control input-lg"*/}
                            {/*                            name={'deal'}*/}
                            {/*                            required={true}*/}
                            {/*                            onChange={(e) => {*/}
                            {/*                                this.onClickRegType(e)*/}
                            {/*                            }}>*/}
                            {/*                        <option></option>*/}
                            {/*                        {*/}
                            {/*                            this.state.allowedRegTypes.map(r => {*/}
                            {/*                                return (<option key={r.id}*/}
                            {/*                                                value={r.id}> {r.name} </option>)*/}
                            {/*                            })*/}
                            {/*                        }*/}
                            {/*                    </select>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="inline-block">*/}
                            {/*                <label*/}
                            {/*                    className="weight400 col-xs-12 col-sm-4 width-35 text-uppercase">Currency</label>*/}
                            {/*                <div className="col-sm-15 padding-top-0 padding-right-20">*/}
                            {/*                    <select className="form-control input-lg"*/}
                            {/*                            name={'payType'}*/}
                            {/*                            required={true}>*/}
                            {/*                        <option></option>*/}
                            {/*                        {this.props.payTypes.map(t => {*/}
                            {/*                            return (*/}
                            {/*                                <option key={t.id}*/}
                            {/*                                        value={t.id}> {t.type} </option>);*/}
                            {/*                        })}*/}
                            {/*                        /!*<option value={'WIRE_TRANSFER'}>Wire transfer</option>*/}
                            {/*        <option value={'BLOCKCHAIN'}>Blockchain</option>*!/*/}
                            {/*                    </select>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*            <div className="inline-block">*/}
                            {/*                <label*/}
                            {/*                    className="weight400 col-xs-12 col-sm-4 width-35 text-uppercase">Amount</label>*/}
                            {/*                <div className="col-sm-15 padding-top-0 padding-right-20">*/}
                            {/*                    <input name={'amount'}*/}
                            {/*                           id={'amount'}*/}
                            {/*                           type="text"*/}
                            {/*                           className="form-control input-lg text-capitalize"*/}
                            {/*                           required={true}*/}
                            {/*                           value={this.state.amount}*/}
                            {/*                           onChange={(e) => this.onChangeMoney(e)}*/}
                            {/*                    />*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div> : null}*/}

                            {/*</div>*/}
                            {/*: null}*/}


                            {/*<div className="row">*/}

                            {/*<div*/}
                            {/*    className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 margin-top-20">*/}
                            {/*    <label className="weight400 text-upper control-label control-label-lg">Investor*/}
                            {/*        First Name*/}
                            {/*    </label>*/}
                            {/*    <input type="text" name={'firstName'}*/}
                            {/*           className="form-control input-lg text-capitalize" required={true}/>*/}
                            {/*</div>*/}


                            {/*<div*/}
                            {/*    className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 margin-top-20">*/}
                            {/*    <label className="weight400 text-upper control-label control-label-lg">Investor*/}
                            {/*        Last Name</label>*/}
                            {/*    <input type="text" name={'lastName'}*/}
                            {/*           className="form-control input-lg text-capitalize" required={true}/>*/}
                            {/*</div>*/}


                            {/*<div*/}
                            {/*    className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 margin-top-20">*/}
                            {/*    <label className="weight400 text-upper control-label control-label-lg">Investor*/}
                            {/*        E-mail*/}
                            {/*    </label>*/}
                            {/*    <input type="email" name={'email'} className="form-control input-lg"*/}
                            {/*           required={true}/>*/}
                            {/*</div>*/}

                            {/*<div*/}
                            {/*    className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 margin-top-20">*/}
                            {/*    <label className="weight400 text-upper control-label control-label-lg">Choose*/}
                            {/*        deal</label>*/}
                            {/*    <select className="form-control input-lg" name={'deal'} required={true}>*/}
                            {/*        <option></option>*/}
                            {/*        {*/}
                            {/*            props.deals.map(d => {*/}
                            {/*                return (<option key={d.id} value={d.id}> {d.code} </option>)*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*    </select>*/}
                            {/*</div>*/}

                            {/*<div*/}
                            {/*    className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 margin-top-20">*/}
                            {/*    <label className="weight400 text-upper control-label control-label-lg">Enter*/}
                            {/*        Amount</label>*/}
                            {/*    <input type="text" name={'amount'} className="form-control input-lg"*/}
                            {/*           required={true}/>*/}
                            {/*</div>*/}

                            {/*<div*/}
                            {/*    className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2  margin-top-20">*/}
                            {/*    <label className="weight400 text-upper control-label control-label-lg">How would*/}
                            {/*        you*/}
                            {/*        like to*/}
                            {/*        invest?</label>*/}
                            {/*    <select className="form-control input-lg" name={'payType'} required={true}>*/}
                            {/*        <option></option>*/}
                            {/*        {props.payTypes.map(t => {*/}
                            {/*            return (<option key={t.id} value={t.id}> {t.type} </option>);*/}
                            {/*        })}*/}
                            {/*        /!*<option value={'WIRE_TRANSFER'}>Wire transfer</option>*/}
                            {/*    <option value={'BLOCKCHAIN'}>Blockchain</option>*!/*/}
                            {/*    </select>*/}
                            {/*</div>*/}

                            {/*<div className="form-group">*/}
                            {/*    <div className="col-sm-12 margin-top-30 text-center">*/}
                            {/*        <div className="checkbox">*/}
                            {/*            <input type="checkbox" id="checkbox7" required={true}/>*/}
                            {/*            <label htmlFor="checkbox7">*/}
                            {/*                I acknowledge that I have double checked the amount entered*/}
                            {/*                above.*/}
                            {/*            </label>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*</div>*/}

                        </DialogContent>
                        <DialogActions>
                            {this.state.regTypes.length !== 0 && this.state.allowedRegTypes.length !== 0 ?
                                <Button type="submit"
                                        variant='contained'
                                        color='primary'>Submit
                                </Button> : null}
                            <Button
                                color="default"
                                variant="container"
                                type="button"
                                onClick={this.onClose}
                                data-dismiss="modal">Close
                            </Button>
                        </DialogActions>
                    </form>

                </Dialog>

            </>
        )
    }
}


export default CreateInvestmentModalNew;