const AccountStatusService = {
    fetch: () => {
        return [
            {
                id: 'USER',
                value: 'User'
            },
            {
                id: 'PENDING_KYC_AML',
                value: 'Pending KYC/AML'
            },
            {
                id: 'WAITING_FOR_APPROVAL',
                value: 'Waiting for approval'
            },
            {
                id: 'APPROVED',
                value: 'Approved'
            },
            {
                id: 'BLOCKED',
                value: 'Blocked'
            },
            {
                id: 'DELETED',
                value: 'Deleted'
            },
        ]
    }
}

export default AccountStatusService;