import React, {Component} from 'react';

import Checkbox from '../../../components/input/Checkbox';
import InvestorService from "../../../services/InvestorService";

class InvestorStatusPageCustom extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const {match: {params}} = this.props;
        this.fetchInvestorStatus(params.id)
    }

    fetchInvestorStatus = (id) => {
        InvestorService.fetchInvestorStatusByUserId(id)
            .then(response => {
                this.setState({
                    investorData: response.data
                });
            })
            .catch(error => {
                console.log(error);
            })

    };


    render() {
        return (
            <section className="margin-investor-status">
                <div>
                    <div>
                        <div>
                            <div className="card padding-bottom-40">
                                <p>Check all applicable boxes and provide an explanation in the space provided if you or
                                    if an immediate family member (spouse, brother, sister, parent, child,
                                    mother-in-law, father-in-law, sister-in-law, brother-in-law, daughter-in-law,
                                    son-in-law), or any other person who supports you or who you support to a material
                                    extent, or an additional account holder, is a described person below:</p>

                                <form className="form-horizontal">


                                    <div className="form-group text-left margin-top-30 margin-bottom-40">
                                        <div className="col-sm-12 margin-bottom-30">
                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    defaultValue={this.state.investorData ? this.state.investorData['A_MEMBER_OR_EMP'] : null}
                                                    label="A member or employee of any securities, options or commodities
                                                    Exchange or other Self-Regulatory Organization including a
                                                    registered securities association, registered clearing
                                                    organization or the Municipal Securities Rulemaking Board."
                                                    attributes={[]}/>

                                            </div>
                                        </div>

                                        <div className="col-sm-12 margin-bottom-30">
                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    defaultValue={this.state.investorData ? this.state.investorData['EMPL_BROKER'] : null}
                                                    label="An employee of a broker/dealer or other member of the Financial
                                                    Industry Regulatory Authority (FINRA)."
                                                    attributes={[]}/>

                                            </div>
                                        </div>

                                        <div className="col-sm-12 margin-bottom-30">
                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    defaultValue={this.state.investorData ? this.state.investorData['SENIOR_OFFICER_BANK'] : null}
                                                    label="A senior officer of a bank, savings and loan institution,
                                                    insurance company, investment company, or investment advisory
                                                    firm, or other financial institution."
                                                    attributes={[]}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 margin-bottom-30">
                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    defaultValue={this.state.investorData ? this.state.investorData['EMPLOYEE_INVESTMENT'] : null}
                                                    label="An employee of an investment advisor."
                                                    attributes={[]}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 margin-bottom-30">
                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    defaultValue={this.state.investorData ? this.state.investorData['EMPLOYEE_STATE'] : null}
                                                    label="An employee of a state or federal securities regulator."
                                                    attributes={[]}/>

                                            </div>
                                        </div>

                                        <div className="col-sm-12 margin-bottom-30">
                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    defaultValue={this.state.investorData ? this.state.investorData['PERSON_SEC'] : null}
                                                    label="A person in the securities department of any of the entities
                                                    listed above, or a person in a position to influence, or whose
                                                    activities directly or indirectly involve, or are related to, the
                                                    function of buying or selling securities for any such entites."
                                                    attributes={[]}/>

                                            </div>
                                        </div>

                                        <div className="col-sm-12 margin-bottom-30">
                                            <div className="checkbox">
                                                <Checkbox
                                                    disabled={true}
                                                    defaultValue={this.state.investorData ? this.state.investorData['PERSON_CONTR'] : null}
                                                    options={this.state['PERSON_CONTR']}
                                                    label="A person who has contributed to the equity or capital of a
                                                    broker/dealer, directly or indirectly, in an amount that exceeds
                                                    10% of the broker/dealer's equity or capital."
                                                    attributes={[]}/>

                                            </div>
                                        </div>
                                    </div>

                                    <p className="weight600 margin-bottom-20">If you checked any of the above, please
                                        explain in detail: *</p>

                                    <textarea
                                        readOnly={true}
                                        defaultValue={this.state.investorData ? this.state.investorData['IF_CHECKED_ANY'].chosenValue : null}
                                        className="form-control input-xl"
                                        rows="5"/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default InvestorStatusPageCustom;