import React, {Component} from 'react';
import CrudTable from '../../containers/tables/CrudTable';
import UserService from "../../services/UserService";
import Grid from "@material-ui/core/Grid";
import InputField from "../../components/Molecules/InputField";
import Typography from "@material-ui/core/Typography";
import SelectField from "../../components/Molecules/SelectField";
import Button from "@material-ui/core/Button";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import CheckIcon from '@material-ui/icons/Check';
import {toast} from "react-toastify";
import UserGroupService from "../../services/UserGroupService";
import RequestStatusService from "../../services/RequestStatusService";
import RequestService from "../../services/RequestService";

class UserRequests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code: null,
            url: '/api/requests',
            pageSize: 10,
            sizes: [10, 20, 50, 100, 200],
            value: 0,
            selectedRequests: [],
            filter: {
                fullName: '',
                email: this.props.match.params.email || '',
                userGroup: '',
                requestStatus: this.props.match.params.type || '',
            },
            columnNames: [
                {
                    id: 'requestStatusName',
                    label: 'Status',
                    width: 90,
                    sort: 'requestStatus',
                },
                {
                    id: 'fullName',
                    label: 'Full Name',
                    sort: 'firstName',
                    width: 150,
                },
                {
                    id: 'email',
                    label: 'Email',
                    sort: 'email'
                },
                {
                    id: 'phone',
                    label: 'Phone',
                    width: 110,
                    sort: 'phone'
                },
                {
                    id: 'userGroupName',
                    label: 'User Type',
                    width: 110,
                    sort: 'userGroup'
                },
                {
                    id: 'institutionName',
                    label: 'Institution Name',
                    sort: 'institutionName'
                },
                {
                    id: 'countryName',
                    label: 'Country',
                    sort: 'country'
                },
                {
                    id: 'amount',
                    label: 'Amount',
                    width: 80,
                    sort: 'amount'
                },
                {
                    id: 'paymentMethod',
                    label: 'Payment Method',
                    sort: 'paymentMethod'
                },
                {
                    id: 'dateCreated',
                    label: 'Date Created',
                    type: 'date',
                    datePattern: 'L LT',
                    sort: 'dateCreated'
                }
            ]
        }
    }

    componentDidMount() {

    }

    onChangeSearchField = (value, field) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [field]: value.id ? value.id : value
            }
        })
    }

    onPageSizeChange = (value) => {
        this.setState({
            pageSize: value
        });
    };

    addRequest = (request) => {
        const selected = [...this.state.selectedRequests]

        if (this.state.selectedRequests.includes(request)) {
            const index = this.state.selectedRequests.map(value => value.id).indexOf(request.id)
            selected.splice(index, 1)

        } else {
            selected.push(request)
        }

        this.setState({
            selectedRequests: selected
        })
    }

    selectAllRequests = () => {
        let ids = this.table.state.data

        if (this.state.selectedRequests.length === ids.length) {
            ids = []
        }

        this.setState({
            selectedRequests: ids
        })
    }

    exportData = () => {
        RequestService.exportUserRequest(this.state.selectedRequests.map(request => request.id))
    }

    invite = () => {
        this.state.selectedRequests.forEach(value => {
            UserService.addUserByAdmin({
                firstName: value.firstName.charAt(0).toUpperCase() + value.firstName.slice(1),
                lastName: value.lastName.charAt(0).toUpperCase() + value.lastName.slice(1),
                email: value.email,
                userGroup: value.userGroup.id,
                registrationType: 'WAIT_LIST'
            })
                .then(res => {
                    toast.success('User invited successfully')
                    this.setState({
                        selectedRequests: []
                    })
                    this.table.fetchData()
                })
                .catch(err => {
                    toast.error('User with this email already exists')
                })
        })
    }

    archive = () => {
        const requests = this.state.selectedRequests.map(request => {
            return {
                ...request,
                userGroup: request.userGroup.id,
                requestStatus: 'ARCHIVED',
            }
        })

        RequestService.updateRequests(requests)
            .then(res => {
                toast.success('Users archived successfully')
                this.setState({
                    selectedRequests: []
                })
                this.table.fetchData()
            })
            .catch(err => {
                toast.error('Error')
            })
    }

    process = () => {
        const requests = this.state.selectedRequests.map(request => {
            return {
                ...request,
                userGroup: request.userGroup.id,
                requestStatus: 'PROCESSED',
            }
        })

        RequestService.updateRequests(requests)
            .then(res => {
                toast.success('Users archived successfully')
                this.setState({
                    selectedRequests: []
                })
                this.table.fetchData()
            })
            .catch(err => {
                toast.error('Error')
            })
    }


    render() {
        return (
            <Grid container spacing={2}
                  style={{paddingTop: 80}}>
                <Grid item lg={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={12} xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item lg={2} sm={6} xs={12}>
                                    <Typography variant="subtitle2">
                                        Full Name
                                    </Typography>
                                    <InputField
                                        name={'fullName'}
                                        value={this.state.filter.fullName}
                                        size={"small"}
                                        onChange={this.onChangeSearchField}
                                    />
                                </Grid>

                                <Grid item lg={2} sm={6} xs={12}>
                                    <Typography variant="subtitle2">
                                        Email
                                    </Typography>
                                    <InputField
                                        name={'email'}
                                        value={this.state.filter.email}
                                        size={"small"}
                                        onChange={this.onChangeSearchField}
                                    />
                                </Grid>

                                <Grid item lg={1} sm={6} xs={12}>
                                    <Typography variant="subtitle2">
                                        Status
                                    </Typography>
                                    <SelectField
                                        name={'requestStatus'}
                                        value={this.state.filter.requestStatus}
                                        emptyLabel={'All'}
                                        size={"small"}
                                        options={RequestStatusService.fetch()}
                                        onChange={this.onChangeSearchField}
                                    />
                                </Grid>

                                <Grid item lg={1} sm={6} xs={12}>
                                    <Typography variant="subtitle2">
                                        User Type
                                    </Typography>
                                    <SelectField
                                        name={'userGroup'}
                                        value={this.state.filter.userGroup}
                                        emptyLabel={'All'}
                                        size={"small"}
                                        options={UserGroupService.fetch().filter(value => value.id !== 'USER_ADMIN')}
                                        onChange={this.onChangeSearchField}
                                    />
                                </Grid>

                                <Grid item lg={1} sm={6} xs={12}>
                                    <Typography variant="subtitle2">
                                        Show
                                    </Typography>
                                    <SelectField
                                        name={"pageSize"}
                                        value={this.state.pageSize}
                                        options={this.state.sizes}
                                        size={"small"}
                                        onChange={this.onPageSizeChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} xs={12} sm={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<ImportExportIcon/>}
                        disabled={this.state.selectedRequests.length === 0}
                        onClick={this.exportData}>
                        Export
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<AddIcon/>}
                        style={{color: 'white', marginLeft: '10px'}}
                        disabled={this.state.selectedRequests.length !== 1 || (this.state.selectedRequests.length > 0
                            && this.state.selectedRequests.some(request => request.requestStatus.id !== 'PENDING'))}
                        onClick={this.invite}>
                        Invite
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<CheckIcon/>}
                        style={{color: 'white', marginLeft: '10px'}}
                        disabled={this.state.selectedRequests.length === 0
                        || this.state.selectedRequests.some(request => request.requestStatus.id !== 'PENDING')}
                        onClick={this.process}>
                        Processed
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<ArchiveIcon/>}
                        style={{color: 'white', marginLeft: '10px'}}
                        disabled={this.state.selectedRequests.length === 0
                        || this.state.selectedRequests.some(request => request.requestStatus.id === 'ARCHIVED')}
                        onClick={this.archive}>
                        Archive
                    </Button>
                </Grid>

                <Grid item lg={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={12}>
                            <CrudTable
                                {...this.props}
                                ref={(c) => this.table = c}
                                url={this.state.url}
                                theadClassName=""
                                columns={this.state.columnNames}
                                sort={this.state.sort}
                                pageSize={this.state.pageSize}
                                filter={this.state.filter}
                                transform={RequestService.transform}
                                loader={this.state.loader}
                                flagCheck={true}
                                checkbox
                                selected={this.state.selectedRequests}
                                handleClick={(row) => this.addRequest(row)}
                                handleClickAll={this.selectAllRequests}
                                //user={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default UserRequests;