import React, {Component} from 'react';
import UserService from "../../services/UserService";
import EntityTranslationService from "../../services/EntityTranslationService";
import Grid from "@material-ui/core/Grid";
import CountIcon from "./CountIcon";
import {OfferingStatusDashboard} from "./OfferingStatus";
import {InvestmentStatus} from "./InvestmentStatus";
import {UserStatus} from "./UsersStatus";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import {Typography} from "@material-ui/core";
import DealService from "../../services/DealService";
import InvestmentService from "../../services/InvestmentService";
import Divider from "@material-ui/core/Divider";
import TasksCard from "./TasksCard";
import RequestService from "../../services/RequestService";

// data all users block


class Dashboard extends Component {

    state = {
        user: null,
        invitedIssuerUrl: '/rest/issuers',
        offeringUrl: '/rest/deal',
        pendingInvestorsCurrentPage: 0,
        pendingInvestors: [],
        pendingIssuers: [],
        page: 1,
        pageSize: 10,
        pendingInvestorsPages: 0,
        filterByEmailForKycAml: {accountStatus: "PENDING_KYC_AML"},
        filterByEmailForInvestors: {email: ''},
        filterByEmailForIssuers: {userAccountStatus: "WAITING_FOR_APPROVAL"},
        sumReport: {},
        sortUsers: '',
        sortIssuerEditModal: '',
        sortOffering: '',
        sortApprove: '',


        usersData: {},
        usersRequests: [],
        usersRequestsKindsCount: [],
        usersKindsCount: [],
        dealsData: {},
        investmentsData: {},


        pendingKycColumnNames: [
            // {
            //     id: 'id',
            //     label: 'Id'
            // },
            {
                id: 'firstName',
                label: 'first Name'
            },
            {
                id: 'lastName',
                label: 'last Name'
            },
            {
                id: 'email',
                label: 'E-mail'
            },
            {
                id: 'accountStatus',
                label: 'Account Status'
            },
            {
                id: 'userGroup',
                label: 'User type',
                type: 'translation',
                translate: EntityTranslationService.translateUserGroup
            }
        ],
        invitedIssuerColumnNames: [
            {
                id: 'id',
                label: 'Id'
            },
            {
                id: 'email',
                label: 'E-mail'
            },
            {
                id: 'accountStatus',
                label: 'Account Status'
            },

        ],
        dealsColumnNames: [
            // {
            //     id: 'id',
            //     label: 'ID'
            // },
            // {
            //     id: 'code',
            //     label: 'Code'
            // },
            {
                id: 'name',
                label: 'Short Name'
            },
            // {
            //     id: 'fullName',
            //     label: 'Full Name'
            // },
            {
                id: 'shortDescription',
                label: 'Short Description',
                visible: false
            },
            {
                id: 'securityTokenOffering',
                label: 'Raise Goal',
                type: 'currency',
                symbol: '$'
            },
            // {
            //     id: 'imageUrl',
            //     label: 'Image',
            //     type: 'image'
            // },
            {
                id: 'amtRaised',
                label: 'AMT Raised',
                type: 'currency',
                symbol: '$'
            }, {
                id: 'percentGoal',
                label: '% of goal',
                type: 'percent'
            }, {
                id: 'numInvestors',
                label: '# of investors',
                type: 'currency',
                symbol: ''
            }, {
                id: 'usdPerInvestor',
                label: 'USD/Investors',
                type: 'currency',
                symbol: ''
            },
        ],
        pendingInvestorsColumnNames: ['First name', 'Last name', 'E-mail', 'Date main completed', 'Investor Type', 'Status'],

        pending: [
            {
                id: 'id',
                label: 'Id'
            },
            {
                id: 'email',
                label: 'E-mail'
            },
            {
                id: 'accountStatus',
                label: 'Account Status'
            },

        ],
    };


    componentDidMount() {
        this.fetchUser();
        this.fetchAllDeals();
        this.fetchAllInvestments();
        this.fetchAllUsers();
        this.fetchUsersRequest();

        // this.fetchPendingInvestors(0);
        // this.fetchSumStatistics();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }

    fetchAllUsers() {
        UserService.fetchAllUsers().then(response => {
            this.getUsersStatistics(response.data)

        })
    }

    fetchUsersRequest() {
        RequestService.fetchUserRequests()
            .then(response => {
                this.getUsersRequestsStatistics(response.data)

            })
    }

    fetchAllDeals() {
        DealService.fetchDeals()
            .then(response => {
                this.getDealsStatistic(response.data)
            })
    }

    fetchAllInvestments() {
        InvestmentService.fetchAllInvestment().then(response => {
            this.getInvestmentStatistic(response.data)
        })
    }

    getInvestmentStatistic(investments) {
        let allInvestmentsCount = investments.length;
        let investmentPendingKyc = 0;
        let investmentApprovedDocs = 0;
        let investmentApprovedFunds = 0;
        let investmentRejected = 0;

        for (let i = 0; i < allInvestmentsCount; i++) {
            switch (investments[i].investmentStatus) {
                case 'PENDING_KYC_AML':
                    investmentPendingKyc++
                    break;
                case 'APPROVED_PENDING_DOCS':
                    investmentApprovedDocs++
                    break;
                case 'APPROVED_PENDING_FUNDS':
                    investmentApprovedFunds++
                    break;
                case 'REJECTED':
                    investmentRejected++
                    break;
                default:
            }

            let investmentsData = {
                allInvestmentsCount,
                investmentPendingKyc,
                investmentApprovedDocs,
                investmentApprovedFunds,
                investmentRejected
            }

            this.setState({
                investmentsData: investmentsData
            })
        }
    }

    getDealsStatistic(deals) {
        let dealsCount = deals.length;
        let dealsPending = 0;
        let dealsActive = 0;
        let dealsFinished = 0;

        for (let i = 0; i < dealsCount; i++) {
            if (deals[i].dealStatus.id === 'DEAL_PENDING') {
                dealsPending++
            }

            if (deals[i].dealStatus.id === 'DEAL_ACTIVE') {
                dealsActive++
            }

            if (deals[i].dealStatus.id === 'DEAL_FINISHED') {
                dealsFinished++
            }
        }

        let dealsData = {
            dealsCount,
            dealsPending,
            dealsActive,
            dealsFinished
        }

        this.setState({
            dealsData: dealsData
        })

    }

    getUsersStatistics(users) {
        let allUsersCount = users.length;
        let allInvestorsCount = 0;
        let allIssuersCount = 0;
        let allCompaniesCount = 0;
        let allNewUsersCount = 0;

        let investorsOnRegistration = 0;
        let investorsWaiting = 0;
        let investorsPendingKyc = 0;
        let investorsPending = 0;
        let investorsActive = 0;
        let investorsBlocked = 0;
        let investorsRemoved = 0;

        let issuersOnRegistration = 0;
        let issuersWaiting = 0;
        let issuersPendingKyc = 0;
        let issuersPending = 0;
        let issuersActive = 0;
        let issuersBlocked = 0;
        let issuersRemoved = 0;


        let companiesOnRegistration = 0;
        let companiesWaiting = 0;
        let companiesPendingKyc = 0;
        let companiesPending = 0;
        let companiesActive = 0;
        let companiesBlocked = 0;
        let companiesRemoved = 0;


        for (let i = 0; i < allUsersCount; i++) {
            if (users[i].userGroup && users[i].userGroup.id === 'USER_INVESTOR_INDIVIDUAL') {
                allInvestorsCount++

                switch (users[i].accountStatus) {
                    case null:
                        investorsOnRegistration++;
                        break;
                    case 'PENDING':
                        investorsPending++;
                        break;
                    case 'ACTIVE':
                        investorsActive++;
                        break;
                    case 'BLOCKED':
                        investorsBlocked++;
                        break;
                    case 'REMOVED':
                        investorsRemoved++;
                        break;
                    case 'PENDING_KYCAML':
                        investorsPendingKyc++;
                        break;
                    case 'WAITING_FOR_APPROVAL':
                        investorsWaiting++;
                        break;
                    default:
                }
            }

            if (users[i].userGroup && users[i].userGroup.id === 'USER_ISSUER') {
                allIssuersCount++

                switch (users[i].accountStatus) {
                    case null:
                        issuersOnRegistration++;
                        break;
                    case 'PENDING':
                        issuersPending++;
                        break;
                    case 'ACTIVE':
                        issuersActive++;
                        break;
                    case 'BLOCKED':
                        issuersBlocked++;
                        break;
                    case 'REMOVED':
                        issuersRemoved++;
                        break;
                    case 'PENDING_KYCAML':
                        issuersPendingKyc++;
                        break;
                    case 'WAITING_FOR_APPROVAL':
                        issuersWaiting++;
                        break;
                    default:
                }
            }

            if (users[i].userGroup && users[i].userGroup.id === 'USER_INVESTOR_COMPANY') {
                allCompaniesCount++
                switch (users[i].accountStatus) {
                    case null:
                        companiesOnRegistration++;
                        break;
                    case 'PENDING':
                        companiesPending++;
                        break;
                    case 'ACTIVE':
                        companiesActive++;
                        break;
                    case 'BLOCKED':
                        companiesBlocked++;
                        break;
                    case 'REMOVED':
                        companiesRemoved++;
                        break;
                    case 'PENDING_KYCAML':
                        companiesPendingKyc++;
                        break;
                    case 'WAITING_FOR_APPROVAL':
                        companiesWaiting++;
                        break;
                    default:
                }
            }

            if (users[i].userGroup && users[i].userGroup.id === 'USER_BEGINNER') {
                allNewUsersCount++
            }
        }

        let usersData = {
            allUsersCount,
            allInvestorsCount,
            allIssuersCount,
            allCompaniesCount,
            allNewUsersCount,

            usersOnRegistration: investorsOnRegistration + issuersOnRegistration + companiesOnRegistration + allNewUsersCount,
            usersWaiting: investorsWaiting + issuersWaiting + companiesWaiting,
            usersPendingKyc: investorsPendingKyc + issuersPendingKyc + companiesPendingKyc,
            usersPending: investorsPending + issuersPending + companiesPending,
            usersActive: investorsActive + issuersActive + companiesActive,
            usersBlocked: investorsBlocked + issuersBlocked + companiesBlocked,
            usersRemoved: investorsRemoved + issuersRemoved + companiesRemoved,

            investorsOnRegistration,
            investorsWaiting,
            investorsPendingKyc,
            investorsPending,
            investorsActive,
            investorsBlocked,
            investorsRemoved,

            issuersOnRegistration,
            issuersWaiting,
            issuersPendingKyc,
            issuersPending,
            issuersActive,
            issuersBlocked,
            issuersRemoved,

            companiesOnRegistration,
            companiesWaiting,
            companiesPendingKyc,
            companiesPending,
            companiesActive,
            companiesBlocked,
            companiesRemoved,
        }

        this.setState({
                usersData: usersData,
                usersKindsCount: [
                    {
                        id: 'USER_INVESTOR_INDIVIDUAL',
                        name: 'Investors',
                        count: allInvestorsCount
                    },

                    {
                        id: 'USER_ISSUER',
                        name: 'Issuers',
                        count: allIssuersCount
                    },

                    {
                        id: 'USER_INVESTOR_COMPANY',
                        name: 'Companies',
                        count: allCompaniesCount
                    },
                ]
            }
        )


    }

    getUsersRequestsStatistics(requests) {
        let allPendingCount = 0;
        let allInvitedCount = 0;
        let allProcessedCount = 0;
        let allArchivedCount = 0;

        requests.forEach(request => {
            switch (request.requestStatus.id) {
                case 'PENDING':
                    allPendingCount++;
                    break;
                case 'INVITED':
                    allInvitedCount++;
                    break;
                case 'PROCESSED':
                    allProcessedCount++;
                    break;
                case 'ARCHIVED':
                    allArchivedCount++;
                    break;
                default:
            }
        })

        this.setState({
            usersRequests: requests,
            usersRequestsKindsCount: [
                {
                    id: 'PENDING',
                    name: 'Pending',
                    count: allPendingCount
                },
                {
                    id: 'INVITED',
                    name: 'Invited',
                    count: allInvitedCount
                },
                {
                    id: 'PROCESSED',
                    name: 'Processed',
                    count: allProcessedCount
                },
                {
                    id: 'ARCHIVED',
                    name: 'Archived',
                    count: allArchivedCount
                },
            ]
        })
    }

    fetchUser() {
        UserService.fetchCurrentUser()
            .then(response => {
                this.setState({
                    user: response.data
                });
            }).catch(error => {
            console.log(error);
        })
    }

    // fetchPendingInvestors(page) {
    //
    //     this.setState({
    //         pendingInvestorsCurrentPage: page
    //     }, () => {
    //         InvestorService.fetchPendingInvestors(this.state.sortApprove ? this.state.sortApprove : '', this.state.pendingInvestorsCurrentPage, this.state.pageSize, this.state.filterByEmailForInvestors)
    //             .then(response => {
    //                 this.setState({
    //                     pendingInvestorsPages: response.data.totalPages,
    //                     pendingInvestors: InvestorService.transformInvestors(response.data.content)
    //                 });
    //                 debugger
    //             }).catch(error => {
    //             console.log(error);
    //         });
    //     });
    // }

    // approveInvestor(email) {
    //     InvestorService.approveInvestor(email)
    //         .then(response => {
    //             this.fetchPendingInvestors(this.state.pendingInvestorsCurrentPage);
    //             toast.success('Approved successfully');
    //         }).catch(error => {
    //         console.log(error);
    //     });
    // }
    //
    // declineInvestor(email) {
    //     InvestorService.declineInvestor(email)
    //         .then(response => {
    //             this.fetchPendingInvestors(this.state.pendingInvestorsCurrentPage);
    //         }).catch(error => {
    //         console.log(error);
    //     });
    // }
    //
    // fetchSumStatistics() {
    //     ReportService.fetch().then(data => {
    //         debugger
    //         this.setState({
    //             sumReport: data.data
    //         });
    //     })
    // }
    //
    // onSortChangeUsers = (e) => {
    //     this.setState({
    //         sortUsers: e.target.value
    //     });
    // };
    //
    // onSortChangeIssuer = (e) => {
    //     this.setState({
    //         sortIssuerEditModal: e.target.value
    //     });
    // };
    //
    // onSortChangeOffering = (e) => {
    //     this.setState({
    //         sortOffering: e.target.value
    //     });
    // };
    //
    //
    // onSortChangeApprove = (e) => {
    //     this.setState({
    //         sortApprove: e.target.value
    //     }, () => {
    //         this.fetchPendingInvestors(this.state.pendingInvestorsCurrentPage)
    //     });
    // };
    //
    // onChangeEmail = (e) => {
    //     this.setState({
    //         filter: {
    //             ...this.state.filter,
    //             'email': e.target.value
    //         }
    //     });
    // };
    //
    // onChangeEmailKycAmlTable = (e) => {
    //     this.setState({
    //         filterByEmailForKycAml: {
    //             ...this.state.filterByEmailForKycAml,
    //             'email': e.target.value
    //         }
    //     });
    // };
    //
    // onChangeEmailInvestorsTable = (e) => {
    //     this.setState({
    //         filterByEmailForInvestors: {
    //             ...this.state.filterByEmailForInvestors,
    //             'email': e.target.value,
    //         },
    //         page: 1
    //
    //     }, () => {
    //         this.fetchPendingInvestors(this.state.pendingInvestorsCurrentPage);
    //     });
    //
    // };
    //
    // onChangeEmailIssuersTable = (e) => {
    //     this.setState({
    //         filterByEmailForIssuers: {
    //             ...this.state.filterByEmailForIssuers,
    //             'email': e.target.value
    //         }
    //     });
    // };


    render() {
        return (
            <>
                <Grid container spacing={2}
                      style={{marginTop: '70px', marginBottom: '20px'}}>
                    <Grid item xs={12} lg={12}>
                        <Typography variant="h6">
                            Dashboard
                        </Typography>

                        <Divider/>
                    </Grid>

                    <Grid item lg={3} sm={6} xs={12}
                          style={{height: '100%'}}>
                        <CountIcon
                            name="All users"
                            onClick={(type) => this.props.history.push(`/users/type/${type}`)}
                            count={this.state.usersData.allUsersCount}
                            backgroundColor="#3F627A"
                            bottomPanel={true}
                            data={this.state.usersKindsCount}
                            icon={<SupervisedUserCircleIcon/>}
                        />
                    </Grid>

                    <Grid item lg={3} sm={6} xs={12}
                          style={{height: '100%'}}>
                        <CountIcon
                            onClick={(type) => this.props.history.push(`/wait-list/type/${type}`)}
                            name="Waiting User List"
                            count={this.state.usersRequests.length}
                            backgroundColor="#07B2C6"
                            bottomPanel={true}
                            data={this.state.usersRequestsKindsCount}
                            icon={<EmojiPeopleIcon/>}/>
                    </Grid>

                    <Grid item lg={3} sm={6} xs={12}
                          style={{height: '100%'}}>
                        <CountIcon
                            onClick={(e) => this.props.history.push('/offerings')}
                            name="Offerings"
                            count={this.state.dealsData.dealsCount}
                            backgroundColor="#41B45C"
                            icon={<LocalOfferIcon/>}
                        />
                    </Grid>

                    <Grid item lg={3} sm={6} xs={12}
                          style={{height: '100%'}}>
                        <CountIcon
                            onClick={(e) => this.props.history.push('/investors/all')}
                            name="Investments"
                            count={this.state.investmentsData.allInvestmentsCount}
                            backgroundColor="#EA4541"
                            icon={<AttachMoneyIcon/>}/>
                    </Grid>

                    <Grid item lg={7} sm={12} xs={12}>
                        <TasksCard {...this.props}/>
                    </Grid>

                    <Grid item lg={3} sm={12} xs={12}>
                        <OfferingStatusDashboard
                            dealsData={this.state.dealsData}/>
                    </Grid>

                    <Grid item lg={2} sm={12} xs={12}>
                        <InvestmentStatus
                            investmentsData={this.state.investmentsData}/>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12}>
                        <UserStatus
                            usersData={this.state.usersData}/>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default Dashboard;