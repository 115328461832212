import React, {useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {toast} from "react-toastify";
import InputField from "../../components/Molecules/InputField";
import SelectField from "../../components/Molecules/SelectField";
import SettingGroupService from "../../services/SettingGroupService";
import AttributeService from "../../services/AttributeService";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";


const CreateSettingModal = (props) => {

    const [name, setName] = useState('')
    const [group, setGroup] = useState('')
    const [settingGroups, setSettingGroups] = useState([])
    const [attributes, setAttributes] = useState([])
    const [selectedAttributes, setSelectedAttributes] = useState([])
    const [errors, setErrors] = useState({})

    useEffect(() => {
        fetchSettingGroups()
        fetchAttributes()
    }, [])

    const fetchSettingGroups = () => {
        SettingGroupService.fetchSettingGroups()
            .then(response => setSettingGroups(SettingGroupService.transform(response.data)))
            .catch(error => {
            })
    }

    const fetchAttributes = () => {
        AttributeService.fetchAttributes()
            .then(response => setAttributes(AttributeService.transform(response.data)))
            .catch(error => {
            })
    }

    const addAttribute = (value) => {
        const atrr = [...selectedAttributes]
        atrr.push(value)
        setSelectedAttributes(atrr)
    }

    const handleValidation = () => {
        let errors = {}
        let valid = true

        if (!name) {
            valid = false
            errors.name = 'Cannot be empty'
        }

        if (!group) {
            valid = false
            errors.group = 'Cannot be empty'
        }

        setErrors(errors)

        return valid
    }

    const handleSubmit = () => {
        if (handleValidation()) {
            const request = {
                name: name,
                settingGroupId: group.id,

            }

            props.submit(request)
        } else {
            toast.error('Check required fields')
        }
    }

    const close = (e) => {
        props.close();
    }


    return (
        <>
            <Dialog
                open={props.show}
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">
                    Add setting
                    <Divider/>
                </DialogTitle>

                <DialogContent style={{overflow: 'hidden', paddingTop: 24}}>
                    <Grid container spacing={3}>
                        <Grid item lg={6} xs={6}>
                            <InputField
                                title={'Name'}
                                required={true}
                                value={name}
                                hasError={!!errors.name}
                                errorMessage={errors.name}
                                onChange={setName}/>
                        </Grid>

                        <Grid item lg={6} xs={6}>
                            <SelectField
                                title={'Group'}
                                required
                                value={group ? group.id : ''}
                                options={settingGroups}
                                hasError={!!errors.group}
                                errorMessage={errors.group}
                                onChange={setGroup}/>
                        </Grid>

                        <Grid item lg={6} xs={6}>
                            <SelectField
                                title={'Rule'}
                                required
                                value={group ? group.id : ''}
                                options={attributes}
                                hasError={!!errors.group}
                                errorMessage={errors.group}
                                onChange={addAttribute}/>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead style={{background: '#263238'}}>
                                        <TableRow>
                                            <TableCell
                                                style={{padding: '15px 0px 15px 15px', color: '#FBFCFC'}}
                                            >
                                                Name
                                            </TableCell>

                                            <TableCell
                                                style={{padding: '15px 0px 15px 15px', color: '#FBFCFC'}}
                                            >
                                                Value
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {selectedAttributes.length === 0 ? (
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        variant="subtitle1"
                                                        style={{margin: 5}}
                                                    >
                                                        No Rules
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            selectedAttributes.map((attribute, index) => {
                                                return <TableRow>
                                                    <TableCell
                                                        key={attribute.id}
                                                        style={{padding: '10px 0px 10px 15px'}}
                                                        align={"left"}
                                                    >
                                                        {attribute.value}
                                                    </TableCell>

                                                    <TableCell
                                                        key={attribute.id}
                                                        style={{padding: '10px 0px 10px 15px'}}
                                                        align={"left"}
                                                    >
                                                        {attribute.name}
                                                    </TableCell>
                                                </TableRow>
                                            })
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item lg={12} xs={12}>
                            <DialogActions>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit}>
                                    Add
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={close}>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreateSettingModal;