import React, {useEffect, useState} from 'react';

import {NavLink} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import SettingsIcon from '@material-ui/icons/Settings';


const items = [
    {
        label: 'Dashboard',
        icon: <DashboardIcon/>,
        imgSrc: 'icon-dashboard.png',
        path: '/dashboard'
    },
    {
        label: 'Issuers',
        icon: <AssignmentIndIcon/>,
        imgSrc: 'icon-issuers.png',
        path: '/issuers'
    },
    {
        label: 'Offerings',
        icon: <LocalOfferIcon/>,
        imgSrc: 'icon-offerings.png',
        path: '/offerings'
    },

    {
        label: 'Investments',
        icon: <LocalMallIcon/>,
        imgSrc: 'icon-investment.png',
        path: '/investors/all'
    },
    {
        label: 'Users',
        icon: <PeopleAltIcon/>,
        imgSrc: 'icon-point-contact.png',
        path: '/users'
    },
    {
        label: 'Waiting User List',
        icon: <EmojiPeopleIcon/>,
        imgSrc: 'icon-point-contact.png',
        path: '/wait-list'
    },
    {
        label: 'Audit Log',
        icon: <LibraryBooksIcon/>,
        imgSrc: 'icon-audit.png',
        path: '/auditlog'
    },
    {
        label: 'Settings',
        icon: <SettingsIcon/>,
        imgSrc: 'icon-audit.png',
        path: '/settings'
    },
]

export const Sidebar = ({classesDrawer, drawerClose, classToolbar, classDrawer, path}) => {

    const [activeSection, setActiveSection] = useState("")

    useEffect(() => {
        setActiveSection(path)
    });

    return (
        <Drawer
            variant="permanent"
            classes={classesDrawer}
            className={classDrawer}>

            <div className={classToolbar}>
                <div>
                    <img
                        style={{display: 'block', width: '100%'}}
                        src={require('../assets/images/logo-blue.png')}
                        alt={'logo'}/>
                </div>

                <IconButton onClick={drawerClose}>
                    <ChevronLeftIcon/>
                </IconButton>
            </div>

            <Divider/>

            <Box pt="70px">
                <List>
                    {
                        items.map(item => {
                            return (
                                <NavLink
                                    key={item.path}
                                    to={item.path}
                                    style={{textDecoration: 'none'}}
                                >
                                    <ListItem
                                        style={{
                                            borderBottom: '1px solid #ddd',
                                            paddingTop: '16px',
                                            paddingBottom: '16px',
                                        }}
                                        className={activeSection === item.path ? 'currentSection__sidebar' : 'active__sidebar'}
                                    >
                                        <ListItemIcon style={{color: '#263238'}}>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText style={{color: '#263238'}}>
                                            {item.label}
                                        </ListItemText>
                                    </ListItem>
                                </NavLink>
                            )
                        })
                    }
                </List>
            </Box>
        </Drawer>
    );
}

export default Sidebar;
