import React, {useEffect} from 'react';

import {AUTH_TOKEN} from "../../shared/utility";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';

const ImagePreviewModal = (props) => {
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(!!props.show);
    })

    const PaperComponent = (props) => {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper {...props} />
            </Draggable>
        );
    }

    return (
        <Dialog
            onClose={props.close}
            open={open}
            disableScrollLock
            disableBackdropClick
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title">

            <DialogTitle
                style={{cursor: 'move'}}
                id="draggable-dialog-title">
                Image Preview
            </DialogTitle>

            <DialogContent dividers>
                {props.cms ?
                    <img
                        src={process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/cms/download/' + props.file + '?access_token=' + localStorage.getItem(AUTH_TOKEN) + '&embedded=true'}
                        className={"img img-responsive text-center"}
                        style={{"max-width": "400px;", "margin-left": "auto", "margin-right": "auto"}}
                        alt={''}
                    /> :
                    <img
                        src={process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/' + props.file + '?access_token=' + localStorage.getItem(AUTH_TOKEN) + '&embedded=true'}
                        className={"img img-responsive text-center"}
                        style={{"max-width": "400px;", "margin-left": "auto", "margin-right": "auto"}}
                        alt={''}
                    />
                }
            </DialogContent>

            <DialogActions>
                <Button
                    type="button"
                    data-dismiss="modal"
                    onClick={props.close}>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default ImagePreviewModal;
