import React, {Component} from 'react';
import CrudService from '../../services/CrudService';
import Aux from '../../hoc/AuxWrapper';
import DeleteModal from '../modal/DeleteModal';
import OfferingEdit from '../../components/edit/OfferingEdit';
import {toast} from 'react-toastify';
import {NavLink} from 'react-router-dom';
import Moment from 'react-moment';
import Select from "../../components/Select";
import RegistrationStatusService from "../../services/RegistrationStatusService";
import AccountStatusService from "../../services/AccountStatusService";
import EditModal from "../modal/EditModal";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IssuerService from "../../services/IssuerService";
import TableBody from "@material-ui/core/TableBody";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import UserGroupService from "../../services/UserGroupService";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import AttachmentIcon from '@material-ui/icons/Attachment';
import DocumentService from "../../services/DocumentService";
import Tooltip from "@material-ui/core/Tooltip";
import {AUTH_TOKEN} from "../../shared/utility";
import AlertModal from "../modal/AlertModal";
import InputFieldS from "../../components/input/InputFieldS";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from '@material-ui/icons/Check';
import TableSortLabel from "@material-ui/core/TableSortLabel";


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
});

class CrudTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            page: 1,
            pagePagination: 1,
            totalPages: 0,
            maxPageListSize: 5,
            filter: this.props.filter,
            pageList: [],
            showDeleteModal: false,
            showEditModal: false,
            showEditModalInvestment: false,
            showAlertModal: false,
            entityToBeDeleted: {},
            entityToBeEdited: {},
            editModalBody: null,
            listEntitiesToBeEdit: [],
            flag: this.props.flag,
            flagForShowActiveDeals: this.props.flagForShowActiveDeals,
            loader: true,
            order: 'desc',
            orderBy: '',
            onChangeHandler: () => {
            }
        };

    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProp, props) {
        if (prevProp.filter !== this.props.filter
            || prevProp.pageSize !== this.props.pageSize
            || prevProp.sort !== this.props.sort) {
            this.setState({
                page: 1,
            }, () => {
                this.fetchData();
            })
        }
    }

    changeEntity = () => {
        this.setState({
            entityToBeEdited: ""
        })
    };


    fetchData() {
        this.setState({
            loader: true
        })

        CrudService.fetch(this.props.url, this.state.orderBy, this.state.order,
            this.state.page - 1, this.props.pageSize, this.props.filter)
            .then(response => {
                    let data = response.data.content;

                    /*get the data for columns "Current req types" and "Exp. date" on the USER TABLE */
                    data = this.getDataForSomeColumns(data)

                    if (this.state.totalPages !== response.data.totalPages) {
                        this.setState({
                            // data: this.props.transform(response.data.content),
                            totalPages: response.data.totalPages,

                        }, () => {
                            this.generatePageList();
                        });
                    } else {
                        this.setState({
                            data: this.props.transform(data),
                        });
                    }

                    /* For showing only active deals in the table "Life Offerings"*/
                    if (this.state.flagForShowActiveDeals) {
                        let listActiveDeals = this.props.transform(response.data.content).filter(f => f.dealStatus === "Active")
                        this.setState({
                            data: listActiveDeals
                        })
                    } else {
                        this.setState({
                            data: this.props.transform(data),
                            loader: false
                        });
                    }
                }
            ).catch(error => {
                console.log(error);
            }
        )
    }

    getDataForSomeColumns = (data) => {
        let users = [];

        data.forEach(user => {
                if (this.props.flagCheck) {
                    if (user.investor) {
                        if (user.investor.accreditationBlock) {
                            Object.assign(user, {expirationDate: user.investor.accreditationBlock.expirationDate})
                        } else {
                            (Object.assign(user, {expirationDate: 'Not Acc.'}))
                        }

                        if (user.investor.activationBlockList.length !== 0) {
                            let flag = true;

                            user.investor.activationBlockList.forEach(block => {
                                if (block.blockName === "BLOCK_HOME_ADDRESS") {
                                    flag = false;

                                    let regTypes = ''
                                    user.investor.regTypes.forEach(type => regTypes += type.shortName + ', ')
                                    let index = regTypes.lastIndexOf(',')
                                    regTypes = regTypes.substring(0, index)


                                    if (block.expirationDate !== "" && block.expirationDate !== "0" && block.verificationDate !== "") {
                                        if (user.countryInvestor === "United States") {
                                            Object.assign(user, {regType: regTypes})
                                        } else {
                                            Object.assign(user, {regType: regTypes})
                                        }
                                    } else {
                                        Object.assign(user, {regType: 'Check Docs.'})
                                    }
                                }

                                if (flag) {
                                    Object.assign(user, {regType: 'Check Docs.'})
                                }
                            })
                        } else {
                            Object.assign(user, {regType: 'Check Docs.'})
                        }
                    }
                }

                users.push(user)
            }
        )

        return users
    }

    onEntityChangeHandler(field, value) {
        let newEntity = JSON.parse(JSON.stringify(this.state.entityToBeEdited));
        newEntity[field] = value;

        this.setState({
            entityToBeEdited: newEntity
        });
    }

    generatePageList() {
        let initialPageList = [];
        for (let i = 1; i <= Math.min(this.state.totalPages, this.state.maxPageListSize); i++) {
            initialPageList.push(i);
        }
        this.setState({
                pageList: initialPageList
            }, () => {
            }
        )
    }

    toggleDeleteModal() {
        this.setState((prevState) => ({
            showDeleteModal: !prevState.showDeleteModal
        }));
    }

    toggleAlertModal() {
        this.setState({
            showAlertModal: false
        })

        this.fetchData()
    }

    onFullEdit = () => {
        IssuerService.fetchIssuerById(this.state.entityToBeEdited.id)
            .then(response => {
                let issuerId = response.data.id;
                window.location.href = "/issuers/edit/" + issuerId;
            })
    };

    toggleEditModal() {
        let modalBody = null;

        if (this.props.editModalBody === 'offering') {
            modalBody = (
                <OfferingEdit entityName={this.props.entityName}
                              offer={this.state.entityToBeEdited}
                              onChange={(field, value) => this.onEntityChangeHandler(field, value)}/>
            );
        } else if (this.props.editModalBody === "user") {
            this.props.history.push(`/users/${this.state.entityToBeEdited.id}`)
        } else if (this.props.editModalBody === 'issuer') {
            this.props.history.push(`/users/${this.state.entityToBeEdited.user.id}`)
        } else if (this.props.editModalBody === 'unvalidatedIssuer') {
            this.props.history.push(`/users/unvalidated_issuer/${this.state.entityToBeEdited.id}`)
        } else if (this.props.editModalBody === "investment") {
            this.props.history.push(`/investments/${this.state.entityToBeEdited.id}`)
        }

        this.setState({
                editModalBody: modalBody
            }, () => {
                this.setState((prevState) => ({
                    showEditModal: !prevState.showEditModal
                }));
            }
        )
    }

    downloadHandler(entity) {
        return DocumentService.downloadUserFiles(entity.email)
    }

    deleteTableHandler(entity) {
        this.setState({
            entityToBeDeleted: entity
        }, () => {
            this.toggleDeleteModal();
        })
    }

    deleteModalHandler(entity, isFull) {
        if (this.props.deleteHandler) {
            this.props.deleteHandler(entity, isFull)
        } else {
            CrudService.delete(this.props.url, {id: entity.id})
                .then(response => {
                    toast.success('Deleted successfully');
                    this.fetchData();
                }).catch(error => {
                toast.error('Error while deleting');
            })
        }
    }

    editTableHandler(entity) {
        this.setState({
            entityToBeEdited: entity
        }, () => {
            this.toggleEditModal();
        })
    }

    editModalHandler() {
        CrudService.edit(this.props.url + '/' + this.state.entityToBeEdited.id, this.state.entityToBeEdited)
            .then(response => {
                toast.success('Edited successfully');
                this.fetchData()
            }).catch(error => {
            toast.error('Error while editing');
        });
    }

    firstPage() {
        if (this.state.page > 1) {
            this.setState((prevState) => ({
                page: 1,
            }), () => {
                this.generatePageList()
                this.fetchData();
            });
        }
    }

    lastPage() {
        if (this.state.page < this.state.totalPages) {
            let oldPage = this.state.page
            this.setState((prevState) => ({
                page: this.state.totalPages,
            }), () => {
                let newPageList = [...this.state.pageList];
                for (let i = oldPage; i <= this.state.totalPages; i++) {
                    newPageList.push(i);
                    newPageList.shift();
                    this.setState({
                        pageList: newPageList
                    })
                }
                this.fetchData();

            });
        }
    }

    currentPaginationPage = () => {
        let paginationPageChecked = this.state.pagePagination;
        if (paginationPageChecked <= this.state.totalPages && paginationPageChecked > 0) {
            this.setState((prevState) => ({
                page: paginationPageChecked,
            }), () => {
                let newPageList = [];
                for (let i = this.state.page; i <= Math.min(this.state.totalPages, this.state.pagePagination + this.state.maxPageListSize - 1); i++) {
                    newPageList.push(i);
                    this.setState({
                        pageList: newPageList
                    }, () => {
                    })
                }
                this.fetchData();
            });
        } else {
            toast.error('This page does not exist');
        }
    };

    nextPage() {
        if (this.state.page < this.state.totalPages) {
            this.setState((prevState) => ({
                page: prevState.page + 1,

            }), () => {
                if (this.state.page > this.state.pageList[this.state.pageList.length - 1]) {
                    let newPageList = [...this.state.pageList];
                    newPageList.push(this.state.page);
                    newPageList.shift();
                    this.setState({
                        pageList: newPageList
                    })
                }
                this.fetchData();
            });
        }
    }

    previousPage() {
        if (this.state.page > 1) {
            this.setState((prevState) => ({
                page: prevState.page - 1,
            }), () => {
                if (this.state.page < this.state.pageList[0]) {
                    let newPageList = [...this.state.pageList];
                    newPageList.unshift(this.state.page);
                    newPageList.pop();
                    this.setState({
                        pageList: newPageList
                    })
                }
                this.fetchData();
            });
        }
    }

    setPage(page) {
        this.setState({
            page: page,
        }, () => {
            this.fetchData();
        });

    }

    onChangeHandlerRegistrationStatus(e, row) {
        row.registrationStatus = e.target.value;

        if (this.state.listEntitiesToBeEdit.length > 0) {
            let flag = false;
            for (let i = 0, list = this.state.listEntitiesToBeEdit.length; i < list; i++) {
                if (this.state.listEntitiesToBeEdit[i].id === row.id) {
                    flag = true;
                    break
                }
            }

            if (flag) {
                let newList = this.state.listEntitiesToBeEdit.filter(f => f.id !== row.id);
                newList.push(row);
                for (let i = 0, list1 = newList; i < list1.length; i++) {
                }
                this.setState({
                    listEntitiesToBeEdit: newList,
                    showAlertModal: true
                });
            } else {
                let list = this.state.listEntitiesToBeEdit;
                list.push(row);
                this.setState({
                    listEntitiesToBeEdit: list,
                    showAlertModal: true
                })
            }

        } else {
            let list = this.state.listEntitiesToBeEdit;
            list.push(row);

            this.setState({
                listEntitiesToBeEdit: list,
                showAlertModal: true
            })
        }
    }

    onChangeHandlerAccountStatus(e, row) {
        row.accountStatus = e.target.value;

        if (this.state.listEntitiesToBeEdit.length > 0) {
            let flag = false;

            for (let i = 0, list = this.state.listEntitiesToBeEdit.length; i < list; i++) {
                if (this.state.listEntitiesToBeEdit[i].id === row.id) {
                    flag = true;
                    break
                }
            }

            if (flag) {
                let newList = this.state.listEntitiesToBeEdit.filter(f => f.id !== row.id);

                newList.push(row);

                this.setState({
                    listEntitiesToBeEdit: newList,
                    showAlertModal: true
                })
            } else {
                let list = this.state.listEntitiesToBeEdit;
                list.push(row)

                this.setState({
                    listEntitiesToBeEdit: list,
                    showAlertModal: true
                })
            }
        } else {
            let list = this.state.listEntitiesToBeEdit;
            list.push(row);

            this.setState({
                listEntitiesToBeEdit: list,
                showAlertModal: true
            })
        }
    }

    editTableHandlerAll() {
        if (this.props.user) {
            this.state.listEntitiesToBeEdit.forEach((user) => {
                //CrudService.edit(this.props.url + '/' + user.id, user)
                CrudService.edit(this.props.url, user)
                    .then(response => {
                        this.props.update()
                        this.fetchData();
                    })
            });
        } else {
            this.state.listEntitiesToBeEdit.forEach((user) => {
                CrudService.edit(this.props.url, user)
                    .then(response => {
                        this.props.update()
                        this.fetchData();
                    })
            });
        }

        toast.success('Edited successfully');

        this.setState({
            listEntitiesToBeEdit: [],
            showAlertModal: false
        })
    }

    editText = (value) => {
        let fullText = [];

        let textArray = value.split('_');
        for (let i = 0; i < textArray.length; i++) {
            fullText.push(textArray[i][0] + textArray[i].toLowerCase().slice(1))
        }
        return fullText.join(" ")
    }

    generateColumn(col, row) {
        switch (col.type) {
            case 'simpleLink':
                return <NavLink
                    to={col.url}> {row[col.paramValue]} </NavLink>;
            case 'link':
                return <NavLink
                    to={col.url + `/${row[col.paramLink]}`}> {row[col.paramValue]} </NavLink>;
            case 'offerLink':
                return <Link
                    component="button"
                    color={"secondary"}
                    onClick={() => this.props.onLinkClick(row)}
                >
                    {row[col.id]}
                </Link>
            case 'editLink':
                return <Link
                    component="button"
                    color={row.accountStatus === 'DELETED' ? '#ff0000' : "secondary"}
                    onClick={() => this.editTableHandler(row)}
                >
                    {row[col.id]}
                </Link>
            case 'image':
                return <img alt={"img"} className="img-thumbnail tableThumbnail img-responsive"
                            src={row[col.id]}/>;
            case 'switch':
                return <>{row[col.id] && <span>Yes</span>} {!row[col.id] && <span>No</span>}</>;
            case 'splitUserGroup':
                return <>{row[col.id] && <span>{this.getFilterElement(row)}</span>}</>
            case 'splitAccountStatus':
                return <>{row[col.id] &&
                <span>{this.editText(row.accountStatus ? row.accountStatus : row.userAccountStatus)}</span>}</>;
            case 'splitDealStatus':
                return <>{row[col.id] && <span>{row.dealStatus}</span>}</>;
            case 'splitInvestmentStatus':
                return <>{row[col.id] && <span>{this.editText(row.investmentStatus)}</span>}</>;
            case 'firstCharUp':
                return <>{row[col.id] &&
                <span>{row.investmentType[0] + row.investmentType.toLowerCase().slice(1)}</span>}</>;
            case 'date':
                return <>{!row[col.id]}
                    {row[col.id] && <Moment format={col.datePattern}>{row[col.id]}</Moment>}</>;
            case 'currency':
                return <span> {row[col.id] ? col.symbol + row[col.id] : col.symbol + '  0'} </span>;
            case 'percent':
                return <span> {Math.round(row[col.id] * 100) / 100} </span>;
            case 'translation':
                return <span> {col.translate(row[col.id])} </span>;
            case undefined:
                return <span className={col.className ? col.className : ''}>  {row[col.id]} </span>;
            case 'dropdownRegistrationStatus':
                return <>
                    {row.userGroup === 'N/A' || row.userGroup.id === 'USER_ADMIN' || row.userGroup.id === 'USER_SUPER_ADMIN' ? null : (
                        <Select row={row}
                                disabled={row.userGroup.id === 'USER_BEGINNER'}
                                mustEditText={true}
                                style={{height: 35, fontSize: 12}}
                                name={'registrationStatus'}
                                data={row.userGroup.id === 'USER_INVESTOR_INDIVIDUAL'
                                    ? RegistrationStatusService.fetchInvestorStatus()
                                    : row.userGroup.id === 'USER_ISSUER'
                                        ? RegistrationStatusService.fetchIssuerStatus()
                                        : RegistrationStatusService.fetchCompanyStatus()
                                }
                                className={'form-control'}
                                defaultValue={row.registrationStatus}
                                onChange={(e, row) => this.onChangeHandlerRegistrationStatus(e, row)}/>
                    )}
                </>
            case 'dropdownAccountStatus':
                return <>
                    {row.userGroup.id === 'USER_ADMIN' ? (
                            <Select row={row}
                                    disabled
                                    style={{height: 35, fontSize: 12}}
                                    name={true}
                                    data={AccountStatusService.fetch()}
                                    className={'form-control'}
                                    defaultValue={row.accountStatus ? row.accountStatus : row.userAccountStatus}/>
                        )
                        : (
                            <Select row={row}
                                    mustEditText={true}
                                    disabled={row.userGroup.id === 'USER_BEGINNER'}
                                    style={{height: 35, fontSize: 12}}
                                    name={true}
                                    data={AccountStatusService.fetch()}
                                    className={'form-control'}
                                    defaultValue={row.accountStatus ? row.accountStatus : row.userAccountStatus}
                                    onChange={(e, row) => this.onChangeHandlerAccountStatus(e, row)}/>
                        )}
                </>
            case 'ips':
                return <InputFieldS
                    value={row.ips.value}
                    size={"small"}
                    onBlur={(e) => this.props.onBlurIPs(e, row)}
                />
            case 'boolean':
                return row.invited ? <CheckIcon color={"secondary"}/> : null
            case 'list':
                return <>
                    {row.offers.map(offer => {
                        return <div>{offer.name}</div>
                    })}
                </>
            default:
        }

        return (row[col.id]);
    }

    getFilterElement = (row) => {
        const group = UserGroupService.fetch().filter(value => value.id === row.userGroup.id)[0]

        return group ? group.value : 'N/A';
    }

    onChangeGetSpecifiedPage = (e) => {
        this.setState({
            pagePagination: +e.target.value
        });

    }

    sortHandler = (value, direction) => {
        this.setState({
            order: direction,
            orderBy: value
        }, this.fetchData)
    }

    isSelected = (row) => this.props.selected.includes(row)

    isAllSelected = () => this.props.selected.length === this.state.data.length && this.props.selected.length > 0


    render() {
        return (
            <Aux>
                <DeleteModal
                    show={this.state.showDeleteModal}
                    close={(e) => this.toggleDeleteModal(e)}
                    submit={(entity, isFull) => this.deleteModalHandler(entity, isFull)}
                    entity={this.state.entityToBeDeleted}
                />

                <AlertModal
                    show={this.state.showAlertModal}
                    close={(e) => this.toggleAlertModal()}
                    submit={() => this.editTableHandlerAll()}
                />

                <EditModal
                    editModalId={this.props.editModalId}
                    show={this.state.showEditModal}
                    entityName={this.props.entityName}
                    close={(e) => this.toggleEditModal(e)}
                    submit={() => this.editModalHandler()}
                    deleteTableHandler={(entity) => this.deleteTableHandler(entity)}
                    entity={this.state.entityToBeEdited}
                    body={this.state.editModalBody}
                    canManage={this.props.canManage}
                />

                <TableContainer component={Paper}>
                    {this.state.loader && (
                        <LinearProgress
                            variant="indeterminate"
                            color="primary"
                        />
                    )}

                    <Table aria-label="simple table">
                        {!this.props.nothead ? (
                            <TableHead style={{background: '#263238'}}>
                                <TableRow>
                                    {this.props.checkbox ? (
                                        <TableCell
                                            width={50}
                                            padding="checkbox"
                                        >
                                            <Checkbox
                                                color={"secondary"}
                                                checked={this.isAllSelected()}
                                                onChange={this.props.handleClickAll}
                                                inputProps={{'aria-label': 'select all desserts'}}
                                            />
                                        </TableCell>
                                    ) : null}

                                    {this.props.columns.map((column, index) => {
                                        if (column.visible === undefined || column.visible === true) {
                                            return (
                                                column.sort ? (
                                                <TableCell
                                                    width={column.width}
                                                    key={column.label}
                                                    style={{padding: '15px 0px 15px 15px'}}
                                                    className={index !== 0 ? '' : ''}
                                                    sortDirection={this.state.order}
                                                >
                                                        <TableSortLabel
                                                            style={{color: '#FBFCFC'}}
                                                            className={'table'}
                                                            active={this.state.orderBy === column.sort}
                                                            direction={this.state.order}
                                                            onClick={() => this.sortHandler(column.sort, this.state.order === 'asc' ? 'desc' : 'asc')}
                                                        >
                                                            {column.label}
                                                        </TableSortLabel>
                                                </TableCell>
                                                ) : (
                                                    <TableCell
                                                        width={column.width}
                                                        key={column.label}
                                                        style={{
                                                            padding: '15px 0px 15px 15px',
                                                            color: '#FBFCFC'}}
                                                        className={index !== 0 ? '' : ''}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                )
                                            )
                                        }

                                        return null
                                    })}

                                    {this.props.canEdit && (
                                        <TableCell
                                            key={"edit"}
                                            align={"center"}
                                            style={{
                                                padding: '0px 0px 0px 15px',
                                                color: '#FBFCFC'
                                            }}
                                        >
                                            Actions
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                        ) : null}

                        {!this.state.loader && (
                            <TableBody>
                                {this.state.data.length === 0 ? (
                                    <TableRow>
                                        <TableCell>
                                            <Typography
                                                variant="subtitle1"
                                                style={{margin: 10}}
                                            >
                                                No data
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : this.state.data.map((row, index) => (
                                    this.props.checkbox ? (
                                        <TableRow
                                            hover
                                            onClick={(event) => this.props.handleClick(row)}
                                            role="checkbox"
                                            aria-checked={this.isSelected(row)}
                                            tabIndex={-1}
                                            key={index}
                                            selected={this.isSelected(row)}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={this.isSelected(row)}
                                                    inputProps={{'aria-labelledby': row.id}}
                                                />
                                            </TableCell>

                                            {this.props.columns.map((col, index) => {
                                                if (col.visible === undefined || col.visible === true) {
                                                    return (
                                                        <TableCell
                                                            key={col.id}
                                                            style={row.accountStatus === 'DELETED' ? {
                                                                padding: '10px 0px 10px 15px',
                                                                color: '#ff0000'
                                                            } : {padding: '10px 5px 10px 15px'}}
                                                            align={"left"}
                                                        >
                                                            {col.id === 'numInvestors' ?
                                                                this.generateColumn(col, row) === null ? 0
                                                                    : this.generateColumn(col, row)
                                                                : this.generateColumn(col, row)}
                                                        </TableCell>
                                                    )
                                                }

                                                return null
                                            })}

                                            {this.props.canEdit && (
                                                <TableCell
                                                    key={"editButton"}
                                                    style={{padding: '0px 0px 0px 15px', textAlign: 'center'}}
                                                    align="right">
                                                    {this.props.editModalBody && this.props.editModalBody !== 'user' ? (
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                style={{padding: 0, textAlign: 'center'}}
                                                                variant="outlined"
                                                                color="primary"
                                                                onClick={() => this.editTableHandler(row)}
                                                            >
                                                                {row.userGroup && row.userGroup.id === 'USER_BEGINNER' ? null :
                                                                    <EditIcon/>}
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : null}


                                                    {this.props.deleteHandler && (row.userGroup && row.userGroup.id !== 'USER_SUPER_ADMIN') && (
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                style={{marginLeft: 10, textAlign: 'center'}}
                                                                variant="outlined"
                                                                disabled={row.accountStatus === 'DELETED'}
                                                                color="primary"
                                                                onClick={() => this.deleteTableHandler(row)}
                                                            >
                                                                {row.userGroup && row.userGroup.id === 'USER_BEGINNER' ? null :
                                                                    <DeleteIcon/>}
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}

                                                    {this.props.user && (
                                                        <Tooltip title="Export files">
                                                            <IconButton
                                                                style={{marginLeft: 10, textAlign: 'center'}}
                                                                variant="outlined"
                                                                color="primary"
                                                                type={"submit"}
                                                                disabled={row.userGroup && (row.userGroup.id === 'USER_ADMIN' || row.userGroup.id === 'USER_SUPER_ADMIN')}
                                                                onClick={() => window.open(process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/files?access_token=' + localStorage.getItem(AUTH_TOKEN) + '&email=' + row.email)}
                                                            >
                                                                <AttachmentIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ) : (
                                        <TableRow key={index}>
                                            {this.props.columns.map((col, index) => {
                                                if (col.visible === undefined || col.visible === true) {
                                                    return (
                                                        <TableCell
                                                            key={col.id}
                                                            style={ row && row.accountStatus === 'DELETED' ? {
                                                                padding: '10px 0px 10px 15px',
                                                                color: '#ff0000'
                                                            } : {padding: '10px 5px 10px 15px'}}
                                                            align={"left"}
                                                        >
                                                            {col.id === 'numInvestors' ?
                                                                this.generateColumn(col, row) === null ? 0
                                                                    : this.generateColumn(col, row)
                                                                : this.generateColumn(col, row)}
                                                        </TableCell>
                                                    )
                                                }

                                                return null
                                            })}

                                            {this.props.canEdit && (
                                                <TableCell
                                                    key={"editButton"}
                                                    style={{padding: '0px 15px 0px 15px', textAlign: 'right'}}
                                                    align="right">
                                                    {this.props.editModalBody && this.props.editModalBody !== 'user' ? (
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                style={{padding: 0, textAlign: 'center'}}
                                                                variant="outlined"
                                                                color="primary"
                                                                onClick={() => this.editTableHandler(row)}
                                                            >
                                                                {row.userGroup && row.userGroup.id === 'USER_BEGINNER' ? null :
                                                                    <EditIcon/>}
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : null}


                                                    {this.props.deleteHandler && (row.userGroup && row.userGroup.id !== 'USER_SUPER_ADMIN') && (
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                style={{marginLeft: 10, textAlign: 'center'}}
                                                                variant="outlined"
                                                                disabled={row.accountStatus === 'DELETED'}
                                                                color="primary"
                                                                onClick={() => this.deleteTableHandler(row)}
                                                            >
                                                                {row.userGroup && row.userGroup.id === 'USER_BEGINNER' ? null :
                                                                    <DeleteIcon/>}
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}

                                                    {this.props.user && (
                                                        <Tooltip title="Export files">
                                                            <IconButton
                                                                style={{marginLeft: 10, textAlign: 'center'}}
                                                                variant="outlined"
                                                                color="primary"
                                                                type={"submit"}
                                                                disabled={row.userGroup && (row.userGroup.id === 'USER_ADMIN' || row.userGroup.id === 'USER_SUPER_ADMIN')}
                                                                onClick={() => window.open(process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/files?access_token=' + localStorage.getItem(AUTH_TOKEN) + '&email=' + row.email)}
                                                            >
                                                                <AttachmentIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    )
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>

                <Grid item xs={12} lg={12}>
                    <Box width="100%" display="flex"
                         justifyContent="flex-end">
                        <Box style={{overflow: "auto"}}
                             mt={2} mb={4}
                             display="flex"
                             alignItems="center"
                        >
                            <Button
                                size="small"
                                onClick={() => this.firstPage()}>
                                <FirstPageIcon/>
                            </Button>

                            <Button
                                size="small"
                                onClick={() => this.previousPage()}>
                                <ChevronLeftIcon/>
                            </Button>

                            {this.state.pageList.map(item => {
                                return (
                                    <Box
                                        key={item}
                                        px={2} onClick={() => this.setPage(item)}>
                                        <Box
                                            onClick={() => this.setPage(item)}
                                            className={item === this.state.page ? 'paginationActive' : 'paginationPassive'}>
                                            {item}
                                        </Box>
                                    </Box>
                                )
                            })}

                            <Button
                                size="small"
                                onClick={() => this.nextPage()}>
                                <ChevronRightIcon/>
                            </Button>

                            <Button
                                size="small"
                                onClick={() => this.lastPage()}>
                                <LastPageIcon/>
                            </Button>

                            <TextField
                                size="small"
                                variant="outlined"
                                onChange={this.onChangeGetSpecifiedPage}
                                type={"number"}
                                style={{width: 80, margin: 'auto', minWidth: '80px'}}
                                min={0}/>

                            <Box mx={1}>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={this.currentPaginationPage}>Go
                                </Button>
                            </Box>

                            {/*{this.state.flag ? (*/}
                            {/*    <Button*/}
                            {/*        color="primary"*/}
                            {/*        variant="contained"*/}
                            {/*        onClick={() => this.editTableHandlerAll()}>*/}
                            {/*        Save Status Changes*/}
                            {/*    </Button>*/}
                            {/*) : null}*/}
                        </Box>
                    </Box>
                </Grid>
            </Aux>
        );
    }
}

export default withStyles(styles)(CrudTable);