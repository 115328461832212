import React, {Component} from 'react';
import DocumentService from "../services/DocumentService";
import InvestorService from "../services/InvestorService";
import IssuerService from "../services/IssuerService";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {CardContent, Divider, Table} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Card from "@material-ui/core/Card";
import PdfPreviewModal from "../containers/modal/PdfPreviewModal";
import ImagePreviewModal from "../containers/modal/ImagePreviewModal";
import Box from "@material-ui/core/Box";
import Dropzone from "react-dropzone";
import PublishIcon from '@material-ui/icons/Publish';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from "@material-ui/core/CircularProgress";
import Moment from "react-moment";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import {toast} from "react-toastify";
import DocxPreviewModal from "../containers/modal/DocxPreviewModal";
import {AUTH_TOKEN} from "../shared/utility";


class UserDocumentation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            documentsToSave: [],
            isLoading: false,
            fileToPreview: null,
            showPreview: false,
            showDocxPreview: false,
            showPreviewImage: false,
            loader: true,
            noDocuments: false,
            documentBlockVerified: false
        }
    }

    componentDidMount() {
        // document.body.setAttribute('class', 'dashboard-body');
        this.fetchDocuments();
    }

    componentDidUpdate(prevProps) {
        if (this.props.investorId !== prevProps.investorId) {
            this.fetchDocuments();
        }
    }

    pushInvestorChange = (request) => {
        if (this.props.issuerId) {
            request.issuerId = this.props.issuerId;
            IssuerService.saveAttributeData(request)
                .then(data => {
                    this.fetchDocuments();
                    // toast.success("Updated!");
                })
        }
        if (this.props.investorId) {
            request.investorId = this.props.investorId;
            InvestorService.saveAttributeData(request)
                .then((data) => {
                    this.fetchDocuments();
                    // toast.success("Updated!");
                });
        }
    };

    fetchDocuments = () => {
        this.setState({
            loader: true
        })

        if (this.props.issuerId) {
            InvestorService.fetchByAttributeCodeAndIssuerId(this.props.attributeCode, this.props.issuerId)
                .then(data => {
                    this.setState({
                        documents: data.data,
                        noDocuments: data.data.length === 0,
                        loader: false,
                    }, () => {
                        this.checkVerifyDocumentsFirstTime(this.state.documents)
                    });
                });
        } else if (this.props.investorId) {
            InvestorService.fetchByAttributeCodeAndInvestorId(this.props.attributeCode, this.props.investorId)
                .then(data => {
                    this.setState({
                        documents: data.data,
                        noDocuments: data.data.length === 0,
                        loader: false,
                    }, () => {
                        this.checkVerifyDocumentsFirstTime(this.state.documents)
                    });
                });
        }
    };

    checkVerifyDocumentsFirstTime = (documents) => {
        let currentVerifiedDocuments = 0;
        let listDocuments = documents;

        for (let i = 0; i < listDocuments.length; i++) {
            if (listDocuments[i].documentVerify === true) {
                currentVerifiedDocuments++
            }
        }

        if (currentVerifiedDocuments === listDocuments.length && currentVerifiedDocuments !== 0) {
            this.setState({
                documentBlockVerified: true
            }, () => {
                this.props.onVerifyBlock(this.props.attributeCode, true)
            })
        } else {
            this.setState({
                documentBlockVerified: false
            }, () => {
                this.props.onVerifyBlock(this.props.attributeCode, false)
            })
        }
    };

    saveDocument = (files) => {
        this.setState({isLoading: true});
        const groupId = this.props.attributeCode === 'KYC_AML' ? 'kyc-aml' : 'other'

        DocumentService.saveDocumentMultiple(groupId, files)
            .then(response => {
                let files = response.data.map(file => {
                    return {
                        'id': file.id,
                        'filename': file.filename,
                        'originalFilename': file.originalFilename
                    }
                });

                this.setState(
                    {
                        'documentsToSave':
                            [...this.state.documentsToSave,
                                ...files
                            ]
                    });
                // this.setState({isLoading: false});
            }).catch(error => {
            this.setState({isLoading: false});
        });
    };

    remove(document) {
        let documents = [...this.state.documentsToSave];
        let newDocuments = documents.filter(d => {
            return (d.id !== document.id);
        });
        this.setState({documentsToSave: newDocuments});
    }

    onFilePreview = (item) => {
        let fileToPreview = '';

        if (item.filename) {
            fileToPreview = item.filename.split('.');

            if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
                this.setState({
                    fileToPreview: item.filename,
                    showPreview: true
                });
            } else if (fileToPreview[fileToPreview.length - 1] === 'docx') {
                this.setState({
                    fileToPreview: item.filename,
                    showDocxPreview: true
                });
            } else {
                this.setState({
                    fileToPreview: item.filename,
                    showPreviewImage: true
                });
            }

        } else {
            fileToPreview = item.chosenValue.split('.');

            if (fileToPreview[fileToPreview.length - 1] === 'pdf') {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreview: true
                });
            } else if (fileToPreview[fileToPreview.length - 1] === 'docx') {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showDocxPreview: true
                });
            } else {
                this.setState({
                    fileToPreview: item.chosenValue,
                    showPreviewImage: true
                });
            }
        }
    };

    onVerifyDocuments = (document, stateToEdit) => {
        let listDocuments = stateToEdit;
        let doc = document.chosenValue.split('.');

        InvestorService.verifyDocument(doc[0]).then(response => {
                if (response.data.documentVerify) {
                    for (let i = 0; i < listDocuments.length; i++) {
                        if (listDocuments[i].chosenValue === document.chosenValue) {
                            listDocuments[i].documentVerify = true;
                        }

                        this.setState({
                            documents: listDocuments
                        }, () => {
                            this.checkVerifyDocumentsFirstTime(listDocuments)
                        })
                    }
                } else {
                    toast.error('Error verification');
                }
            }
        )
    };

    onUnverifyDocuments = (document, stateToEdit) => {
        let listDocuments = stateToEdit;
        let doc = document.chosenValue.split('.');

        InvestorService.unVerifyDocument(doc[0])
            .then(response => {
                    if (!response.data.documentVerify) {
                        for (let i = 0; i < listDocuments.length; i++) {
                            if (listDocuments[i].chosenValue === document.chosenValue) {
                                listDocuments[i].documentVerify = false;
                            }

                            this.setState({
                                documents: listDocuments
                            }, () => {
                                this.checkVerifyDocumentsFirstTime(listDocuments)
                            })
                        }
                    } else {
                        toast.error('Error');
                    }
                }
            )
    }

    handleClose = () => {
        this.setState({
            fileToPreview: null,
            showPreview: false
        })
    };

    handleCloseDocxModel = () => {
        this.setState({
            fileToPreview: null,
            showDocxPreview: false
        })
    };

    handleCloseImage = () => {
        this.setState({
            showPreviewImage: false
        })
    };

    submit = () => {
        this.state.documentsToSave.forEach(item => {
            this.pushInvestorChange({
                value: item.filename,
                attributeCode: this.props.attributeCode,
                documentId: item.id
            });
        });

        this.setState({documentsToSave: []})
        this.fetchDocuments()
    };

    startLoading = () => {
        this.setState({
            isLoading: !this.state.isLoading
        })
    }

    render() {
        return (
            <>
                <PdfPreviewModal
                    className="modal"
                    show={this.state.showPreview}
                    file={this.state.fileToPreview}
                    close={this.handleClose}/>

                <DocxPreviewModal
                    className="modal"
                    show={this.state.showDocxPreview}
                    file={this.state.fileToPreview}
                    close={this.handleCloseDocxModel}/>

                <ImagePreviewModal
                    className="modal"
                    show={this.state.showPreviewImage}
                    file={this.state.fileToPreview}
                    close={this.handleCloseImage}/>


                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} xs={12}>
                                        <Typography variant='h6'>
                                            Documents
                                        </Typography>
                                    </Grid>

                                    <Divider/>
                                </Grid>

                                <Box className="upload__block--title"
                                     onClick={this.startLoading}>
                                    {
                                        this.state.isLoading
                                            ? <RemoveIcon/>
                                            : <AddIcon/>
                                    }

                                    <Box ml="8px" component="span">
                                        Add document
                                    </Box>
                                </Box>

                                <Dropzone multiple={true}
                                          accept={
                                              this.props.attributeCode !== 'KYC_AML'
                                                  ? 'application/pdf,image/png,image/jpeg'
                                                  : 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                          }
                                          onDrop={acceptedFiles => this.saveDocument(acceptedFiles)}>
                                    {
                                        ({getRootProps, getInputProps}) => (
                                            <Box
                                                mt="15px"
                                                mb="15px">

                                                <Box mb="15px" p="25px"
                                                     className={this.state.isLoading ? 'upload__view' : 'upload__hidden'}
                                                     style={{background: '#FCFCFC'}}>
                                                    <Box>
                                                        <Box>
                                                            <Typography
                                                                variant="subtitle2"
                                                                gutterBottom>
                                                                Only

                                                                {
                                                                    this.props.attributeCode !== 'KYC_AML'
                                                                        ? ' JPG, PNG or PDF '
                                                                        : ' PDF or DOCX '
                                                                }

                                                                files. File must be less than 20 Mb
                                                                <span className="required__circle">*</span>
                                                            </Typography>
                                                        </Box>

                                                        {
                                                            this.state.documentsToSave.map(document => {
                                                                    return (
                                                                        <Box
                                                                            pt="20px"
                                                                            pb="5px"
                                                                            style={{borderColor: '#DFDFDF'}}
                                                                            borderBottom={1}
                                                                            display="flex"
                                                                            key={document.id}>

                                                                            <Box flexGrow={1}>
                                                                                {document.originalFilename}
                                                                            </Box>
                                                                            <Box>
                                                                                <Button
                                                                                    color="primary"
                                                                                    name={'file-01'}
                                                                                    type={"button"}
                                                                                    onClick={() => this.onFilePreview(document)}>
                                                                                    Preview
                                                                                </Button>

                                                                                <Button
                                                                                    style={{color: '#B71C1C'}}
                                                                                    name={'file-01'}
                                                                                    type={"button"}
                                                                                    onClick={(e) => this.remove(document)}>
                                                                                    Delete
                                                                                </Button>
                                                                            </Box>
                                                                        </Box>
                                                                    )
                                                                }
                                                            )
                                                        }

                                                        {this.state.documentsToSave.length !== 0
                                                            ? <Box style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                                paddingTop: 10
                                                            }}>

                                                                <Button variant='contained'
                                                                        color='primary'
                                                                        onClick={this.submit}>
                                                                    Submit Documents
                                                                </Button>

                                                            </Box>
                                                            : null}
                                                    </Box>

                                                    <Box mt="25px" display="flex">
                                                        <Button onClick={this.startLoading}>
                                                            Cancel
                                                        </Button>

                                                        <Box ml="20px">
                                                            <input
                                                                id={'file-01'}
                                                                type={"file"} {...getInputProps()}
                                                                className="custom-file-input"
                                                                accept={'application/pdf,image/png,image/jpeg,application/vnd.openxmlformats-officedocument.wordprocessingml.document'}
                                                                required>
                                                            </input>

                                                            <label htmlFor={'file-01'}>
                                                                <Button
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    endIcon={<PublishIcon/>}
                                                                    component="span">
                                                                    Upload document
                                                                </Button>
                                                            </label>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                </Dropzone>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item lg={9} xs={9}>
                                        <Typography variant='h6'>
                                            Uploaded Documents
                                        </Typography>
                                    </Grid>

                                    <Divider/>

                                    {
                                        this.state.loader
                                            ? <Grid item lg={12} xs={12}
                                                    style={{display: "flex", justifyContent: "center"}}>
                                                <CircularProgress/>
                                            </Grid>
                                            : this.state.noDocuments
                                            ? <Grid item lg={12} xs={12}>
                                                <Typography>
                                                    No Documents
                                                </Typography>
                                            </Grid>
                                            : <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Document Name</TableCell>
                                                            <TableCell>Date Uploaded</TableCell>
                                                            <TableCell width={257}/>
                                                        </TableRow>
                                                    </TableHead>

                                                    {
                                                        this.state.documents.map(document => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell>
                                                                        {document.documentOriginalName}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            <Moment
                                                                                format={'LLL'}>
                                                                                {document.dateCreated}
                                                                            </Moment>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div>
                                                                            <Button
                                                                                onClick={() => this.onFilePreview(document)}
                                                                                variant='contained'
                                                                                color='primary'
                                                                                size='small'
                                                                                startIcon={<VisibilityIcon/>}>
                                                                                Preview
                                                                            </Button>

                                                                            <Button
                                                                                onClick={() => window.open(process.env.REACT_APP_DMS_GATEWAY + '/v1/api/document/download/'
                                                                                    + document.chosenValue + '?access_token=' + localStorage.getItem(AUTH_TOKEN)
                                                                                    + '&embedded=false')}
                                                                                type={"submit"}
                                                                                variant='contained'
                                                                                color='primary'
                                                                                size='small'
                                                                                style={{marginLeft: 6}}
                                                                                startIcon={<SaveIcon/>}>
                                                                                Download
                                                                            </Button>
                                                                        </div>

                                                                        <div>
                                                                            {this.props.verifyButton
                                                                                ? document.documentVerify
                                                                                    ? <Button
                                                                                        fullWidth
                                                                                        variant='contained'
                                                                                        color='primary'
                                                                                        size='small'
                                                                                        style={{marginTop: 6}}
                                                                                        startIcon={<ClearIcon/>}
                                                                                        onClick={(e) => this.onUnverifyDocuments(document, this.state.documents)}>
                                                                                        Unverify
                                                                                    </Button>
                                                                                    : <Button
                                                                                        fullWidth
                                                                                        variant='contained'
                                                                                        color='secondary'
                                                                                        style={{marginTop: 6}}
                                                                                        size='small'
                                                                                        startIcon={
                                                                                            <CheckIcon/>}
                                                                                        onClick={(e) => this.onVerifyDocuments(document, this.state.documents)}>
                                                                                        Verify
                                                                                    </Button>
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </Table>
                                            </TableContainer>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default UserDocumentation;