import axios from '../custom-axios/backoffice';
import React from 'react';

const ConversationService = {
    fetchByUserId: (userId) => {
        return axios.get(`/rest/conversation/user/${userId}`);
    },
    postMessage: (channelId,content) => {
        return axios.post("/rest/conversation/add",{"channelId":channelId,"content":content});
    },
    formatMessage: (message) => {
        return message.split('\n').map(part => (<> {part} <br/></>));
    },

    updateMessage: (id) => {
        return axios.put(`/rest/conversation/${id}/read`)
    },
}

export default ConversationService;