import React from 'react';

const TextInput = (props) => {
    let defaultValue = {
        chosenValue: ''
    };

    const handleOnBlur = (e) => {
        let value = e.target.value;
        if (props.capitalize) {
            value = value.charAt(0).toUpperCase() + value.slice(1);
        }
        let request = {
            "attributeCode": props.attributeCode,
            "value": value
        };
        props.onBlurHandler(request);
    };

    return (
        <input
            onBlur={(e) => handleOnBlur(e)}
            autoComplete="0"
            disabled={props.disabled ? props.disabled : false}
            readOnly={props.readOnly ? props.readOnly : false}
            defaultValue={props.defaultValue ? props.defaultValue.chosenValue : defaultValue.chosenValue}
            type="text"
            className="input__custom--big--16"/>
    );
};

export default TextInput;