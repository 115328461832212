const OfferingService = {

    transform: (deals) => {
        return deals.map(i => {
            const percentGoal = i.amtRaised / (i.deal ? i.deal.raiseTarget : 1) * 100;
            const usdPerInvestor = i.amtRaised / i.numOfInvestors;

            return {
                id: i.deal ? i.deal.id : '',
                code: i.deal ? i.deal.code : '',
                name: i.deal ? i.deal.name : '',
                fullName: i.deal ? i.deal.fullName : '',
                shortDescription: i.deal ? i.deal.shortDescription : '',
                securityTokenOffering: i.deal ? i.deal.securityTokenOffering : '',
                imageUrl: i.deal ? i.deal.imageUrl : '',
                description: i.deal ? i.deal.description : '',
                amtRaised: i.amtRaised,
                percentGoal: percentGoal,
                numInvestors: i.numOfInvestors,
                usdPerInvestor: usdPerInvestor,
                issuer_id: i.deal ? i.deal.issuer.id : '',
                issuerEmail: i.deal ? i.deal.issuer.user.email : '',
                issuerFullname: i.deal ? i.deal.issuer.pocFullName : '',
                dealStatus: i.deal ? i.deal.dealStatus : '',
                dateCreated: i.deal ? i.deal.dateCreated : '',
                regTypes: i.deal ? i.deal.regTypes : '',
                raiseTarget: i.deal.raiseTarget
            }
        });
    },

    transformDeal: (deals) => {
        return deals.map(i => {
            const percentGoal = i.amtRaised / (i.deal ? i.deal.raiseTarget : 1) * 100;
            const usdPerInvestor = i.amtRaised / i.numOfInvestors;

            return {
                id: i ? i.id : '',
                code: i ? i.code : '',
                name: i ? i.name : '',
                fullName: i ? i.fullName : '',
                shortDescription: i.deal ? i.deal.shortDescription : '',
                securityTokenOffering: i.deal ? i.deal.securityTokenOffering : '',
                imageUrl: i.deal ? i.deal.imageUrl : '',
                description: i.deal ? i.deal.description : '',
                preMoney: i.preMoney,
                percentGoal: percentGoal,
                numInvestors: i.numOfInvestors,
                usdPerInvestor: usdPerInvestor,
                issuer_id: i.deal ? i.deal.issuer.id : '',
                issuerEmail: i.deal ? i.deal.issuer.user.email : '',
                // issuerFullname: i ? i.issuer.pocFullName : '',
                dealStatus: i ? i.dealStatus : '',
                dateCreated: i.deal ? i.deal.dateCreated : '',
                regTypes: i ? i.regTypes : '',
                raiseTarget: i.raiseTarget,
                // companyName: i ? i.issuer.companyName : ''
            }
        });
    },
    transformDealUser: (deals) => {
        let c = deals.flatMap(i => {
            return i.users.filter(user => user.accountStatus !== 'APPROVED')
                .map(user => {
                    return {
                        id: i ? i.id : '',
                        code: i ? i.code : '',
                        name: i ? i.name : '',
                        fullName: i ? i.fullName : '',
                        userId: user.id,
                        userName: `${user.firstName} ${user.lastName}`
                    }
                })
        });

        return c
    }
};

export default OfferingService;