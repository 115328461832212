import React, {Component} from 'react';
import Moment from 'react-moment';
import CommentsComposeModal from '../containers/modal/CommentsComposeModal';
import UserCommentsService from "../services/UserCommentsService";
import ConversationService from "../services/ConversationService";
import {Button, Card, Typography} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from '@material-ui/core/CircularProgress';


class UserCommentsPanelComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            show: false,
            channel: {},
            loader: true,
            noComments: false,
        }

    }

    componentDidMount() {
        if (this.props.user.id) {
            this.loadConversation(this.props.user.id);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user.id !== prevProps.user.id) {
            this.loadConversation(this.props.user.id);
        }
    }

    loadConversation = (userId) => {
        this.setState({
            loader: true
        })
        UserCommentsService.fetchByUserId(userId).then(data => {
            this.setState({
                messages: data.data,
                loader: false,
                noComments: data.data.length === 0,
            });
        })
    }
    showComposeModal = () => {
        this.setState({
            ...this.state,
            show: true
        })
    }

    onMessageSend = (req) => {
        UserCommentsService.postMessage(req.channelId, req.content).then(data => {
            this.loadConversation(this.props.user.id);
        })
    }

    handleClose = () => {
        this.setState({
            ...this.state,
            show: false
        })
    }

    // handleModifyPoc = (e) => {
    //     e.preventDefault();
    //     let req = {
    //         pocFullName: e.target.pocFullName.value,
    //         pocIssuerEmail: e.target.pocIssuerEmail.value,
    //         postalCode: e.target.postalCode.value
    //     }
    // }

    render() {
        return (
            <>

                {/*<MessageComposeModal
                    className="modal"
                    submit={this.onMessageSend}
                    show={this.state.show}
                    channels={[{id:this.props.user.id,name:this.props.user.firstName + ' ' + this.props.user.id,name:this.props.user.lastName}]}
                    close={this.handleClose}>
                </MessageComposeModal>*/}

                <CommentsComposeModal
                    className="modal"
                    submit={this.onMessageSend}
                    show={this.state.show}
                    channels={[{
                        id: this.props.user.id,
                        name: this.props.user.firstName + ' ' + this.props.user.lastName
                    }]}
                    close={this.handleClose}>
                </CommentsComposeModal>


                <Grid container spacing={3}>
                    <Grid item xs={6} lg={6}>
                        <Typography variant="h6">Comments</Typography>
                    </Grid>
                    <Grid item xs={6} lg={6} style={{textAlign: 'end'}}>
                        <Button onClick={this.showComposeModal}
                                variant='contained'
                                color='primary'
                                startIcon={<AddIcon/>}
                        >Add comment
                        </Button>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Divider/>
                    </Grid>
                    {this.state.loader ?

                        <Grid item
                              lg={12}
                              xs={12}
                              style={{display: "flex", justifyContent: "center"}}>
                            <CircularProgress/>
                        </Grid> :
                        <Grid item lg={12} xs={12}>
                            {this.state.noComments ?
                                <Typography>No Comments</Typography> :
                                this.state.messages.map(v => {
                                    return (
                                        <Grid xs={12} lg={12} style={{paddingBottom: 10}}>
                                            <Card>
                                                <CardContent style={{backgroundColor: '#D9E9F3'}}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6} lg={6}>
                                                            <Typography variant='subtitle2'>Date: <Moment
                                                                format={'LLL'}>{v.dateTime}</Moment>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} lg={6}>
                                                            <Typography variant='subtitle2'>
                                                                Author: {v.comment.firstName ? 'Support' : v.commentFrom.firstName + ' ' + v.commentFrom.lastName}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={12} xs={12}>
                                                            <Divider/>
                                                        </Grid>
                                                        <Grid item lg={12} xs={12}>
                                                            <Typography variant='subtitle1'>{ConversationService.formatMessage(v.comment)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )
                                })
                            }
                            <Card>
                            </Card>
                        </Grid>}
                </Grid>
            </>
        )
    }
}

export default UserCommentsPanelComponent;