import axios from '../custom-axios/backoffice';

const DealPaymentTypeService = {
    fetchTypes: () => {
        return axios.get('/rest/deal-payment-type/types');
    },
    save: (type) => {
        return axios.post('/rest/deal-payment-type', type);
    },
    fetchByDealId: (dealId) => {
        return axios.get(`/rest/deal-payment-type?filter%5Bdeal.id%5D=${dealId}`);
    },
    delete: (id) => {
        return axios.delete(`/rest/deal-payment-type/${id}`);
    }
};

export default DealPaymentTypeService;