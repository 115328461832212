import React, {useEffect, useState} from 'react';

import CrudTable from '../../containers/tables/CrudTable';
import Grid from "@material-ui/core/Grid";
import InputField from "../../components/Molecules/InputField";
import Typography from "@material-ui/core/Typography";
import SelectField from "../../components/Molecules/SelectField";
import SettingService from "../../services/SettingService";
import SettingGroupService from "../../services/SettingGroupService";
import CreateSettingModal from "../modal/CreateSettingModal";

const Settings = (props) => {

    const url = '/api/settings'
    const [pageSize, setPageSize] = useState(10)
    const [sizes, setSizes] = useState([10, 20, 50, 100, 200])
    const [sort, setSort] = useState('')
    const [settingGroups, setSettingGroups] = useState([])
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [filter, setFilter] = useState({
        name: '',
        settingGroup: ''
    })
    const [columnNames, setColumnNames] = useState([
        {
            id: 'name',
            label: 'Name',
            sort: 'name'
        },
        {
            id: 'settingGroup',
            label: 'Group',
            sort: 'settingGroup'
        },
        {
            id: 'ips',
            label: 'Allowed IP addresses (use "," for multiple IPs)',
            type: 'ips',
            sort: ''
        }
    ])

    let table

    useEffect(() => {
        fetchSettingGroups()
    }, [])

    const fetchSettingGroups = () => {
        SettingGroupService.fetchSettingGroups()
            .then(response => setSettingGroups(SettingGroupService.transform(response.data)))
            .catch(error => {
            })
    }


    const onChangeSearchField = (value, field) => {
        setFilter({
            ...filter,
            [field]: value.id ? value.id : value
        })
    }

    const onPageSizeChange = (value) => {
        setPageSize(value)
    }

    const onSortChange = (value) => {
        setSort(value.id ? value.id : value)
    }

    const createSetting = (request) => {
        SettingService.createSetting(request)
            .then(response => {
            })
            .then(error => {
            })
    }

    const onUpdate = (value, data) => {
        SettingService.updateDataSetting({
            id: data.ips.id,
            value: value
        })
            .then(response => {
                table.fetchData()
            })
            .catch(error => {
            })
    }


    return (
        <Grid container spacing={2}
              style={{paddingTop: 80}}>
            <Grid item lg={12} xs={12}>
                <Grid container spacing={2}>
                    <Grid item lg={12} xs={12} sm={6}>
                        <Grid container spacing={2}>
                            {/*<Grid item lg={12} xs={12} sm={12}>*/}
                            {/*    <Button*/}
                            {/*        variant="contained"*/}
                            {/*        color="secondary"*/}
                            {/*        endIcon={<AddIcon/>}*/}
                            {/*        style={{color: 'white'}}*/}
                            {/*        onClick={() => setShowCreateModal(true)}>*/}
                            {/*        Add new setting*/}
                            {/*    </Button>*/}
                            {/*</Grid>*/}

                            <Grid item lg={2} sm={6} xs={12}>
                                <Typography variant="subtitle2">
                                    Name
                                </Typography>
                                <InputField
                                    name={'name'}
                                    value={filter.name}
                                    size={"small"}
                                    onChange={onChangeSearchField}
                                />
                            </Grid>

                            <Grid item lg={2} sm={6} xs={12}>
                                <Typography variant="subtitle2">
                                    Group
                                </Typography>
                                <SelectField
                                    name={"settingGroup"}
                                    value={filter.settingGroup}
                                    options={settingGroups}
                                    size={"small"}
                                    onChange={onChangeSearchField}
                                />
                            </Grid>

                            <Grid item lg={1} sm={6} xs={12}>
                                <Typography variant="subtitle2">
                                    Show
                                </Typography>
                                <SelectField
                                    name={"pageSize"}
                                    value={pageSize}
                                    options={sizes}
                                    size={"small"}
                                    onChange={onPageSizeChange}
                                />
                            </Grid>

                            <Grid item lg={2} sm={6} xs={12}>
                                <Typography variant="subtitle2">
                                    Sort by
                                </Typography>
                                <SelectField
                                    name={"sort"}
                                    value={sort}
                                    emptyLabel={"Default"}
                                    size={"small"}
                                    options={columnNames.filter(value => value.sort)
                                        .map(value => {
                                            return {id: value.sort, value: value.label}
                                        })}
                                    onChange={onSortChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item lg={12} xs={12}>
                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <CrudTable
                            {...props}
                            ref={(c) => table = c}
                            url={url}
                            theadClassName=""
                            columns={columnNames}
                            sort={sort}
                            pageSize={pageSize}
                            filter={filter}
                            editModalBody={'setting'}
                            //onChangeIPs={onUpdate}
                            onBlurIPs={onUpdate}
                            transform={SettingService.transform}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {showCreateModal && (
                <CreateSettingModal
                    show={showCreateModal}
                    submit={(data) => createSetting(data)}
                    close={() => setShowCreateModal(false)}
                />
            )}
        </Grid>
    )
}

export default Settings