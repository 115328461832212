import React, {useEffect, useState} from 'react';

import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import Box from "@material-ui/core/Box";


export const UsersAll = ({usersData}) => {

    const [data, setData] = useState([])

    useEffect(() => {
        setData([
            {
                name: 'Waiting for Approval:', users: usersData.usersWaiting ? usersData.usersWaiting : 0,
            },
            {
                name: 'Pending:', users: usersData.usersPending ? usersData.usersPending : 0,
            },
            {
                name: 'Pending KYC/AML:', users: usersData.usersPendingKyc ? usersData.usersPendingKyc : 0,
            },
            {
                name: 'Active:', users: usersData.usersActive ? usersData.usersActive : 0,
            },
            {
                name: 'Blocked:', users: usersData.usersBlocked ? usersData.usersBlocked : 0,
            },
            {
                name: 'Removed:', users: usersData.usersRemoved ? usersData.usersRemoved : 0,
            },
            {
                name: 'On Registration:', users: usersData.usersOnRegistration ? usersData.usersOnRegistration : 0,
            },
        ])
    }, [usersData])


    return (
        <Box pt="20px">
            <ResponsiveContainer height={400}>
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5, right: 0, left: 0, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" style={{fontSize: 12}}/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Bar dataKey="users" fill="#032F66"/>
                </BarChart>
            </ResponsiveContainer>
        </Box>
    )
}