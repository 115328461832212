const AuditLogService = {

    transform: (data) => {
        return data.map(d => {
            return {
                id: d.id,
                date: d.date,
                action: d.action,
                username: d.username,
                property: d.property,
                entityClass: d.entityClass,
                entityId: d.entityId,
                oldValue: d.oldValue,
                newValue: d.newValue
            }
        });
    },
    transformIntoActivity: (data) => {
        return data.map(d => {
            return {
                id: d.id,
                email: d.username,
                action: (d.action === 'UPDATE' ? 'updated' : d.action === 'DELETE' ? 'deleted' : 'inserted') + ' information',
                on: 'on',
                date: d.date
            }
        });
    }
};

export default AuditLogService;