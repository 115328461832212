const EntityTranslationService = {
    translateInvestmentStatus: (status) => {
        switch (status) {
            case 'INVESTMENT_STATUS_PENDING':
                return 'PENDING APPROVAL';
            case 'INVESTMENT_STATUS_IN_PROCESSING':
                return 'WAITING ON SUBSCRIPTION DOCUMENTS';
            case 'INVESTMENT_STATUS_PENDING_FUNDS':
                return 'PENDING FUNDS';
            case 'INVESTMENT_STATUS_REJECTED':
                return 'REJECTED';
            case 'INVESTMENT_STATUS_CANCELLED':
                return 'CANCELLED';
            case 'INVESTMENT_STATUS_APPROVED':
                return 'APPROVED';
            case 'INVESTMENT_STATUS_FINISHED':
                return 'COMPLETED';
            default:
                return status;
        }
    }, translateEntityClass: (entityClass) => {
        let className = entityClass.split('.');

        switch (className[className.length - 1]) {
            case 'Deal':
                return 'Offering information';
            case 'DealData':
                return 'Offering information';
            case 'Investment':
                return 'Investment';
            case 'Investor':
                return 'Investor information';
            case 'InvestorData':
                return 'Investor information';
            case 'Issuer':
                return 'Issuer';
            case 'UnvalidatedUser':
                return 'Unvalidated user information';
            case 'User':
                return 'User information';
            case 'TaskEntity':
                return 'Task';
            case 'ChannelSubscription':
                return 'Channel Subscription'
            case 'MessageChannel':
                return 'Message Channel'
            default:
                return entityClass;
        }
    }, translateUserGroup: (userGroup) => {
        switch (userGroup) {
            case 'USER_INVESTOR_INDIVIDUAL':
                return 'Investor individual';
            case 'USER_INVESTOR_COMPANY':
                return 'Investor company';
            case 'USER_ISSUER':
                return 'Issuer';
            default:
                return userGroup;
        }
    }
};

export default EntityTranslationService;