import React from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

const HomeAddressVerifyModal = (props) => {

    let handleFormSubmit = (e) => {
        e.preventDefault();
        props.submit("1", props.blockName);
        props.close(props.blockName);
    };

    let close = (e) => {
        props.close(props.blockName);
    };

    return (
        <Dialog open={props.show}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                Verification
            </DialogTitle>
            <DialogContent style={{overflow: 'hidden'}}>
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <Typography>
                            Are you sure the address matches the documents and the documents are not older than 90 days?
                        </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <DialogActions>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleFormSubmit}>Yes
                            </Button>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={close}>Cancel
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default HomeAddressVerifyModal;