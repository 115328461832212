import React from 'react';

import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import Box from "@material-ui/core/Box";


export const CompanyGraph = ({usersData}) => {

    const data = [
        {
            name: 'Waiting for Approval:', companies: usersData.companiesWaiting,
        },

        {
            name: 'Pending', companies: usersData.companiesPending,
        },
        {
            name: 'Pending KYC/AML:', companies: usersData.companiesPendingKyc,
        },

        {
            name: 'Active:', companies: usersData.companiesActive,
        },

        {
            name: 'Blocked:', companies: usersData.companiesBlocked,
        },
        {
            name: 'Removed:', companies: usersData.companiesRemoved,
        },
        {
            name: 'On Registration:', companies: usersData.companiesOnRegistration,
        },
    ];

    return (
        <Box pt="20px">
            <ResponsiveContainer height={400}>
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5, right: 0, left: 0, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" style={{fontSize: 12}}/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Bar dataKey="companies" fill="#032F66"/>

                </BarChart>
            </ResponsiveContainer>
        </Box>
    )
}