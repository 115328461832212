import React, {Component} from 'react';
import SaveModal from "../../containers/modal/SaveModal";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";


class Text extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isShowing: false,
            value: '',
            errorText: '',
            error: false,
            defaultValue: undefined,
            firstValue: ' ',
            emailErrorValid: false,
            errorEmailText: '',

        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.defaultValue !== prevState.defaultValue) {
            return {
                value: nextProps.defaultValue.chosenValue ? nextProps.defaultValue.chosenValue : nextProps.defaultValue,
                defaultValue: nextProps.defaultValue,
                firstValue: nextProps.defaultValue.chosenValue
            }
        }
    }

    validEmail = (email) => {
        let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(email);
    }


    handleOnBlur = (e) => {
        if (this.state.firstValue !== ' ') {
            if (e.target.value !== this.props.defaultValue.chosenValue) {
                if (e.target.value.length <= 11 && !this.state.emailErrorValid) {
                    this.setState({
                        isShowing: !this.state.isShowing
                    })
                }
            }
        }
    };

    onChangeEmail = (e) => {
        let error;
        let errorEmailText;

        if (this.validEmail(e) || e === '') {
            error = false
            errorEmailText = ''
        } else {
            errorEmailText = 'ERROR: Email is not valid'
            error = true;
        }
        this.setState({
            errorEmailText: errorEmailText,
            emailErrorValid: error,
        })
    }


    onChange = (e) => {
        let value;
        let errorText;
        let error;

        if (e.target.value.length > 11) {
            errorText = "Error: max number of characters"
            value = this.state.value
            error = true
        } else {
            if (this.props.email) {
                this.onChangeEmail(e.target.value)
            }

            errorText = ''
            value = e.target.value;
            error = false
        }
        this.setState({
            errorText: errorText,
            error: error,
            value: value
        })
    }

    saveChanges = () => {
        if (this.props.capitalize) {
            this.setState({
                value: this.state.value.charAt(0).toUpperCase() + this.state.value.slice(1)
            })
        }
        let request = {
            "attributeCode": this.props.attributeCode,
            "value": this.state.value
        };
        this.props.onBlurHandler(request);
        this.setState({
            isShowing: !this.state.isShowing
        })
    };

    changeInput = () => {
        this.setState({
            value: this.state.firstValue,
            error: ''
        })
    };

    closeModal = () => {
        this.setState({
            isShowing: !this.state.isShowing
        })
    };

    render() {
        return (
            <Box>
                <SaveModal show={this.state.isShowing}
                           close={this.closeModal}
                           submit={this.saveChanges}
                           changeInput={this.changeInput}
                />
                <Grid container spacing={3}>
                    <Grid item lg={12} xs={12}>
                        <TextField
                            fullWidth
                            onBlur={(e) => this.handleOnBlur(e)}
                            onChange={(e) => this.onChange(e)}
                            variant="outlined"
                            id={this.props.id ? this.props.id : null}
                            value={this.state.value}
                            type={this.props.type ? this.props.type : "text"}
                            disabled={this.props.flagDisabled}
                            error={this.state.error ? true : this.state.emailErrorValid}
                            helperText={this.state.error ? this.state.errorText : this.state.emailErrorValid ? this.state.errorEmailText : ''}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default Text;