import axios from "../custom-axios/backoffice.js"

const TaskService = {

    fetchTasks: () => {
        return axios.get("/api/tasks")
    },

    deleteTask: (id) => {
        return axios.delete('/api/tasks/' + id)
    }

}

export default TaskService