import React, {Component} from 'react';
import CrudTable from "./tables/CrudTable";
import AuditLogService from "../services/AuditLogService";
import EntityTranslationService from "../services/EntityTranslationService";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";


class AuditLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiUrl: '/rest/auditlog',
            filter: {
                username: this.props.match.params.email || '',
            },
            colNames: [
                {
                    id: 'date',
                    label: 'Date',
                    type: 'date',
                    datePattern: "DD.MM.YYYY",
                    sort: 'date'
                },
                {
                    id: 'action',
                    label: 'Action',
                    sort: 'action'
                },
                {
                    id: 'username',
                    label: 'Email',
                    sort: 'username'
                },
                {
                    id: 'entityClass',
                    label: 'Entity',
                    type: 'translation',
                    translate: EntityTranslationService.translateEntityClass,
                    sort: 'entityClass'
                },
                {
                    id: 'property',
                    label: 'Property',
                    sort: 'property'
                },
                {
                    id: 'oldValue',
                    label: 'Old Value',
                    sort: 'oldValue'
                },
                {
                    id: 'newValue',
                    label: 'New Value',
                    sort: 'newValue'
                },
            ]
        }
    };

    onChange = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                'entityClass': e.target.value
            }
        });
    }

    onChangeUsername = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                'username': e.target.value
            }
        });
    }


    render() {
        return (
            <Grid container spacing={2}
                  style={{marginTop: '70px'}}>
                <Grid item lg={12}>
                    <Typography variant={'h6'}>
                        Audit Log
                    </Typography>

                    <Divider/>
                </Grid>

                <Grid item lg={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} lg={3}>
                            <Typography variant="subtitle2">
                                Entity Class
                            </Typography>

                            <select
                                className="input__custom"
                                onChange={this.onChange}
                                data-width="100%">

                                <option value={''} defaultValue>All</option>
                                <option value={"mk.iwmn.amerx.app.models.users.User"}>User</option>
                                <option value={"mk.iwmn.amerx.app.models.deals.Deal"}>Deal</option>
                                <option value={"mk.iwmn.amerx.app.models.deals.DealData"}>Deal Data</option>
                                <option value={"mk.iwmn.amerx.app.models.investors.InvestorData"}>InvestorData</option>
                                <option value={"mk.iwmn.amerx.app.models.investors.Investor"}>Investor</option>
                                <option value={"mk.iwmn.amerx.app.models.issuers.Issuer"}>Issuer</option>
                                <option value={"mk.iwmn.amerx.app.models.Document"}>Document</option>
                            </select>
                        </Grid>

                        <Grid item xs={12} sm={4} lg={3}>
                            <Typography variant="subtitle2">
                                Email
                            </Typography>

                            <input
                                className="input__custom"
                                defaultValue={this.state.filter.username}
                                onChange={this.onChangeUsername}
                                type={"text"}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={12} xs={12}>
                    <CrudTable
                        url={this.state.apiUrl}
                        filter={this.state.filter}
                        sort={''}
                        pageSize={10}
                        theadClassName=""
                        columns={this.state.colNames}
                        transform={AuditLogService.transform}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default AuditLog